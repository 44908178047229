import { useEffect, useMemo, useState } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import { ApiException } from '~/api/apiClient';

function throwUnexpected(exception: Error | undefined) {
  if (!exception) return;

  if (exception instanceof ApiException) {
    throw new ApiException(exception.code, exception.name, exception.message);
  }

  throw exception;
}

export const useApiFetch = <F extends (...args: any[]) => any>(
  params: Parameters<F>,
  fetcher: F,
  {
    noCache = false,
    refreshInterval = undefined,
    throwError = true,
    useDelay = true,
  }: {
    noCache?: boolean;
    refreshInterval?: number;
    throwError?: boolean;
    useDelay?: boolean;
  } = {}
) => {
  const { cache } = useSWRConfig();
  const apiKey = (
    fetcher as unknown as {
      apiKey: string;
    }
  ).apiKey;

  if (!apiKey)
    throw new Error('Invalid fetcher function passed. No api key defined.');

  const cacheBuster = useMemo(
    () => (noCache ? new Date().toISOString() : null),
    [noCache]
  );

  const swrKey = useMemo(
    () => (params ? { key: apiKey, cacheBuster, params } : null),
    [apiKey, cacheBuster, ...params]
  );

  const stringKey = JSON.stringify(swrKey);

  const { data, error, mutate, isValidating } = useSWR<
    Awaited<ReturnType<F>>,
    ApiException
  >(
    swrKey,
    async ({
      key,
      cacheBuster,
      params,
    }: {
      key: string;
      cacheBuster: string;
      params: Parameters<F>;
    }) => {
      const result = fetcher(...params);
      if (useDelay) {
        const delay = new Promise((resolve) => setTimeout(resolve, 200));
        await Promise.all([result, delay]);
        return result;
      } else {
        return await result;
      }
    },
    {
      revalidateOnMount: true,
      dedupingInterval: 2000,
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      refreshInterval: refreshInterval,
    }
  );

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(isValidating);
  }, [isValidating]);

  useEffect(() => {
    if (isValidating && error) {
      setLoading(false);
    }
  }, [error, isValidating]);

  useEffect(() => {
    if (noCache && stringKey) {
      return () => {
        cache.delete(stringKey);
      };
    }
  }, [cache, noCache, stringKey]);

  useEffect(() => {
    if (error) {
      if (throwError) {
        throwUnexpected(error);
      }
    }
  }, [error, throwError]);

  return {
    data,
    loading,
    error,
    isValidating,
    mutate,
  };
};
