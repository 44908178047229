import {
  styled,
  Typography as MuiTypography,
  Box as MuiBox,
} from '@mui/material';

export const Box = styled(MuiBox)(
  () => `
    
`
);

export const Typography = styled(MuiTypography)`
  font-size: 12px;
  font-weight: 700;
`;
