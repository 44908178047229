import { styled } from '@mui/material/styles';
import { Box as MuiBox, Typography as MuiTypography } from '@mui/material';
import { DataPagination as _DataPagination } from '~/components/commons/DataPagination';
import { ComboBox as _ComboBox } from '~/components/commons/ComboBox';
import { Tooltip as _Tooltip } from '~/components/commons/Tooltip';
import { TextField as _TextField } from '~/components/commons/TextField';
import { Label as _Label } from '~/components/commons/Label2';
import { color } from '~/theme';
import { ModalHeader as _ModalHeader } from '~/components/commons/modals/ModalHeader';
import { DropdownList as _DropdownList } from '~/components/commons/DropdownList_v1';

export const ModalHeader = styled(_ModalHeader)`
  &.p-30 {
    padding: 30px;
  }
  min-height: 57px;
`;

export const DataPagination = styled(_DataPagination)`
  margin-left: auto;
`;

export const Box = styled(MuiBox)`
  &.modal-body {
    padding: 30px;
  }
  &.search-wrapper {
    display: flex;
    flex-direction: row;

    .search-btn {
      min-width: 52px;
      height: 32px;
      border-radius: 2px;
      margin-left: 10px;
    }
  }
  &.search-input-field {
    display: flex;
    flex-direction: row;
    border: 1px solid ${color.common.line};
    & > *:not(:last-child) {
      border-right: 1px solid ${color.common.line};
    }
    .MuiInputBase-root {
      input {
        background: unset;
      }
    }
  }

  &.pagination-wrapper {
    margin: 14px 0;
    display: flex;
  }
  &.table-wrapper {
    overflow: auto;
    table {
      width: 820px;
      margin-top: 0px;
    }
  }
  &.search-btn-box {
    display: flex;
    align-items: center;
  }
  &.dropdown-box {
    width: 250px;
    background: white;
  }
`;

export const ModalWrapper = styled('div')`
  width: 880px;
  max-height: calc(100vh - 40px);
  overflow: auto;
`;

export const TextField = styled(_TextField)`
  background-color: #f3f8ff;
  font-size: 14px;

  .MuiInputBase-root {
    height: 32px;
  }

  input {
    background-color: #f3f8ff;
  }
`;

export const DropdownList = styled(_DropdownList)`
  height: 32px;
  .MuiAutocomplete-inputRoot.MuiInputBase-root.MuiOutlinedInput-root {
    height: 32px;
  }
`;

export const Typography = styled(MuiTypography)(
  ({ theme }) => `
    width: 64px;
    font-size: 12px;
    padding-left: 1rem;
    font-weight: 500;
  `
);
