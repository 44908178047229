import {
  MenuItem,
  outlinedInputClasses,
  Select,
  Typography,
} from '@mui/material';

const times: number[] = [];
for (let i = 0; i < 24; i++) {
  times.push(i);
}

type HourFieldProps = {
  value: any;
  onChange: (value: any) => void;
  placeholder?: string;
};

export const HourField = ({
  value,
  onChange,
  placeholder = '',
}: HourFieldProps) => {
  const onChangeValue = (e: any) => {
    onChange(e.target.value);
  };

  return (
    <Select
      sx={{
        [`& .${outlinedInputClasses.input}`]: {
          p: 0,
          pl: 2,
          minWidth: 100,
        },
      }}
      displayEmpty
      value={value ?? ''}
      onChange={onChangeValue}
      renderValue={(selected) => {
        if (selected === '')
          return (
            <Typography sx={{ color: '#a1a1a1' }}>{placeholder}</Typography>
          );
        return `${selected.toString().padStart(2, '0')}:00`;
      }}
    >
      {times.map((t) => (
        <MenuItem key={t} value={t}>{`${t
          .toString()
          .padStart(2, '0')}:00`}</MenuItem>
      ))}
    </Select>
  );
};
