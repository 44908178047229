import { getRoleName, getStatusName } from '~/pages/management/management';
import {
  Content,
  Container,
  Item,
  ItemTitle,
  ItemWide,
} from './DetailManagementTable.styled';

export const DetailManagementPasswordTable = () => {
  return (
    <Content>
      <Container>
        <ItemTitle>비밀번호</ItemTitle>
        <ItemWide className="label">********</ItemWide>
      </Container>
    </Content>
  );
};

type DetailManagementTableProps = {
  user: any;
};

export const DetailManagementTable = ({ user }: DetailManagementTableProps) => {
  return (
    <Content>
      <Container>
        <ItemTitle>아이디(이메일)</ItemTitle>
        <ItemWide className="label">
          <span>{user.email}</span>
        </ItemWide>
      </Container>
      <Container>
        <ItemTitle>관리자 번호</ItemTitle>
        <Item className="label">
          <span>{user.id}</span>
        </Item>
        <ItemTitle>담당자 이름</ItemTitle>
        <Item className="label">
          <span>{user.name}</span>
        </Item>
      </Container>
      <Container>
        <ItemTitle>보안등급</ItemTitle>
        <Item className="label">
          <span>{getRoleName(user.role)}</span>
        </Item>
        <ItemTitle>사용여부</ItemTitle>
        <Item className="label">
          <span>{getStatusName(user.status)}</span>
        </Item>
      </Container>
    </Content>
  );
};
