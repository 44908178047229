import $http from 'axios';

export class PopupRepo {
  all(params: any) {
    return $http.get('/popups', { params });
  }

  get(id: number) {
    return $http.get(`/popups/${id}`);
  }

  create(data: any) {
    return $http.post('/popups', data);
  }

  update(id: number, data: any) {
    return $http.put(`/popups/${id}`, data);
  }

  delete(id: number) {
    return $http.delete(`/popups/${id}`);
  }
}
