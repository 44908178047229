import React from 'react';

type CheckboxProps = {
  checked?: boolean;
  toggleHandler: (event: any) => void;
  label: string;
};

export const Checkbox = ({ checked, toggleHandler, label }: CheckboxProps) => (
  <>
    <input
      onClick={toggleHandler}
      className="checkbox checkbox-item-label"
      type="checkbox"
      readOnly
      checked={checked}
    />
    {label}
  </>
);

export default React.memo(Checkbox);
