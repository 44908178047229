import { useState, useMemo, useRef, useEffect } from 'react';
import { observer } from 'mobx-react';
import { stripEmpty } from '~/utils/objUtil';
import { useApi } from '~/components/providers/ApiProvider';
import { ClinicsDataTable } from './DataTable';
import { useApp } from '~/hooks/useApp';
import { SearchForm } from './SearchForm';
import {
  DataPagination,
  Summary,
  SummaryRightSection,
  Button,
} from './Clinics.styled';
import PageNav from '~/components/commons/PageNav';
import { useHistory, useLocation } from 'react-router-dom';
import querystring from 'query-string';
import { Tooltip } from '~/components/commons/Tooltip';

const defaultParams = {
  createdAtStart: '', // 등록일
  createdAtEnd: '', // 등록일
  id: '', // id
  name: '', // 거래처명
  oldName: '', // 구 거래처명
  employerNo: '', // 사업자등록번호
  contactName: '', // 담당자명
  contactTel: '', // 전화번호
  clinicTel: '', // 대표번호
  status: '', // 계약 상태
  contractDateStart: '', // 최초 계약일
  contractDateEnd: '', // 최초 계약일
  test: '', // 계정 유형
  agency: '', // 계약대행사
  userCountMin: '', // 계약 계정 수 min
  userCountMax: '', // 계약 계정 수 max
  s3SizeMin: '', // 계약 용량 min
  s3SizeMax: '', // 계약 용량 max
  carrier: '', // 센트릭스
  page: 1,
  limit: 50,
  orderBy: {
    id: 'id',
    value: 'desc',
  },
};

const Clinics = () => {
  const app = useApp();
  const location = useLocation();
  const defaultParamsRef = useRef(defaultParams);
  const querySearchParams = useMemo(() => {
    const _searchParams = querystring.parse(location.search);
    const { limit, page, orderBy, ...rest } = _searchParams;
    return Object.keys(_searchParams).length > 0
      ? {
          ...rest,
          page:
            page && !isNaN(Number(page))
              ? Number(page)
              : defaultParamsRef.current.page,
          limit:
            limit && !isNaN(Number(limit))
              ? Number(limit)
              : defaultParamsRef.current.limit,
          orderBy:
            orderBy &&
            typeof orderBy === 'string' &&
            !orderBy.includes('undefined') &&
            orderBy.split(' ').length === 2
              ? {
                  id: orderBy.split(' ')[0].trim(),
                  value: orderBy.split(' ')[1].trim(),
                }
              : defaultParamsRef.current.orderBy,
        }
      : null;
  }, [location.search]);
  const [searchParams, setSearchParams] = useState(
    querySearchParams !== null
      ? {
          ...defaultParamsRef.current,
          ...querySearchParams,
        }
      : defaultParamsRef.current
  );
  const history = useHistory();
  const [data_v2, setData_v2] = useState<any>([]);
  const [summaryData, setSummaryData] = useState({
    total: 0,
    active: 0,
    inactive: 0,
  });
  const { clinicsApi } = useApi();

  useEffect(() => {
    if (
      querySearchParams !== null &&
      Object.keys(querySearchParams)?.length > 3
    ) {
      const mixed = { ...defaultParamsRef.current, ...querySearchParams };
      handleSearchSubmit(mixed);
    } else if (
      querySearchParams === null &&
      JSON.stringify(searchParams) !== JSON.stringify(defaultParamsRef.current)
    ) {
      resetAll();
    }
  }, [querySearchParams]);

  const resetAll = () => {
    setSearchParams(defaultParamsRef.current);
    setData_v2([]);
    setSummaryData({
      total: 0,
      active: 0,
      inactive: 0,
    });
  };

  const handleGetSearch = async (params: any) => {
    const response: any = await clinicsApi.list(params);
    setData_v2(response || []);
  };

  const handleGetSearchSummary = async (params: any) => {
    const response: any = await clinicsApi.getSummary(params);
    setSummaryData(response?.data || []);
  };

  const handleCreate = () => {
    history.push('/clinic_add');
  };

  const handleSearchSubmit = (newSearchParams: any) => {
    const _newParams = stripEmpty(
      {
        ...newSearchParams,
        orderBy: `${newSearchParams.orderBy.id} ${newSearchParams.orderBy.value}`,
      },
      {
        emptyString: true,
        emptyArray: true,
      }
    );
    handleGetSearch(_newParams);
    handleGetSearchSummary(_newParams);
    setSearchParams(newSearchParams);
  };

  const handleSearch = async (newParams: any) => {
    const newSearchParams = {
      ...searchParams,
      ...newParams,
    };
    const _newParams = stripEmpty(
      {
        ...newSearchParams,
        name: newSearchParams?.name?.length < 2 ? '' : newSearchParams.name,
        clinicTel:
          newSearchParams?.clinicTel?.length < 4
            ? ''
            : newSearchParams.clinicTel,
        contactTel:
          newSearchParams?.contactTel?.length < 4
            ? ''
            : newSearchParams.contactTel,
        orderBy: `${searchParams.orderBy.id} ${searchParams.orderBy.value}`,
        page: 1,
      },
      {
        emptyString: true,
        emptyArray: true,
      }
    );
    history.push(`/clinics?${querystring.stringify(_newParams)}`);
  };

  const handleChangePagination = (pg: any) => {
    const newSearchParams = {
      ...searchParams,
      page: pg.page,
      limit: pg.limit,
    };
    const _newParams = stripEmpty(
      {
        ...newSearchParams,
        name: newSearchParams?.name?.length < 2 ? '' : newSearchParams.name,
        clinicTel:
          newSearchParams?.clinicTel?.length < 4
            ? ''
            : newSearchParams.clinicTel,
        contactTel:
          newSearchParams?.contactTel?.length < 4
            ? ''
            : newSearchParams.contactTel,
        orderBy: `${searchParams.orderBy.id} ${searchParams.orderBy.value}`,
      },
      {
        emptyString: true,
        emptyArray: true,
      }
    );
    if (Object.keys(_newParams)?.length > 3) {
      history.push(`/clinics?${querystring.stringify(_newParams)}`);
    }
  };

  const searchFilterValues = useMemo(() => {
    return stripEmpty(
      {
        ...searchParams,
        name: searchParams?.name?.length < 2 ? '' : searchParams.name,
        clinicTel:
          searchParams?.clinicTel?.length < 4 ? '' : searchParams.clinicTel,
        contactTel:
          searchParams?.contactTel?.length < 4 ? '' : searchParams.contactTel,
      },
      {
        emptyString: true,
        emptyArray: true,
      }
    );
  }, [searchParams, querySearchParams]);

  return (
    <div className="main-contents">
      <PageNav pages={['CLIENT', '거래처 계정관리']} />
      <SearchForm
        onSearch={handleSearch}
        searchParams={searchParams}
        originalParams={defaultParamsRef.current}
      />
      <Summary>
        <Tooltip title={app.$me.role === 'manager' ? '권한이 없습니다.' : ''}>
          <div>
            <Button
              styled="fill"
              className="black"
              onClick={handleCreate}
              disabled={app.$me.role === 'manager'}
            >
              거래처 신규 등록
            </Button>
          </div>
        </Tooltip>
        <SummaryRightSection>
          {`조회결과 [ 총 `}
          <b>{summaryData.total}</b>
          {`ㅣ 사용중 `}
          <b>{summaryData.active ?? 0}</b>
          {`ㅣ 해지 `}
          <b>{summaryData.inactive ?? 0}</b>
          {` ]`}
          <DataPagination
            options={[
              {
                label: '50개씩',
                value: 50,
              },
              {
                label: '100개씩',
                value: 100,
              },
            ]}
            value={{
              limit: searchParams.limit,
              page: searchParams.page,
            }}
            pages={data_v2?.pagination?.pages || 1}
            total={data_v2?.pagination?.total || 1}
            onChange={handleChangePagination}
            disabled={Object.keys(searchFilterValues).length < 3}
          />
        </SummaryRightSection>
      </Summary>
      <ClinicsDataTable
        data={data_v2}
        orderBy={searchParams.orderBy}
        pagination={{
          limit: searchParams.limit,
          page: searchParams.page,
        }}
        onChangePagination={handleChangePagination}
      />
    </div>
  );
};
export default observer(Clinics);
