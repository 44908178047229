import { useState } from 'react';
import { observer } from 'mobx-react';
import { useSnackbarContext } from '~/components/providers/SnackbarProvider';
import { useApp } from '~/hooks/useApp';
import { ImageRepo } from '~/components/repo/ImageRepo';
import { FileSampleRepo } from '~/components/repo/FileSampleRepo';
import { FileSampleType } from '~/components/model/FileSample';

type FileSampleFormModalProps = {
  options: any;
  close: (button?: any) => void;
};

export const FileSampleFormModal = observer(
  ({ options, close }: FileSampleFormModalProps) => {
    const app = useApp();
    const snackbar = useSnackbarContext();
    const repo = new FileSampleRepo();
    const imageRepo = new ImageRepo();
    const { action, item } = options;

    const [name, setName] = useState(item?.name ?? '');
    const [type, setType] = useState(FileSampleType.clinic);
    const [file, setFile] = useState<any>();

    const uploadImage = async () => {
      return await imageRepo.upload(file);
    };

    const create = async () => {
      try {
        const imageId = await uploadImage();

        const data = {
          type,
          name,
          imageId: imageId,
        };

        await repo.create(data);

        snackbar.success('생성했습니다.');

        close();
      } catch (e) {
        console.error(e);
        snackbar.alert('실패했습니다.');
      }
    };

    const update = async () => {
      try {
        const data: any = {
          type,
          name,
        };

        if (file) {
          const imageId = await uploadImage();
          data.imageId = imageId;
        }

        await repo.update(item.id, data);

        snackbar.success('수정했습니다.');

        close();
      } catch (e) {
        console.error(e);
        snackbar.alert('실패했습니다.');
      }
    };

    const onClickSave = async () => {
      if (action === 'create') {
        create();
      } else {
        update();
      }
    };

    const onChangeFile = (e: any) => {
      const file = e.target.files[0];

      setFile(file);
    };

    return (
      <div className="modal-clinic-input-form">
        <div className="head flex-row flex-between items-start">
          <div className="title">{action === 'create' ? '생성' : '수정'}</div>
          <i onClick={() => close()} className="zmdi zmdi-close" />
        </div>
        <div>
          <div>
            <span>타입</span>
            <select value={type} onChange={(e) => setType(e.target.value)}>
              {Object.keys(FileSampleType).map((key) => (
                <option
                  key={key}
                  value={FileSampleType[key as 'clinic' | 'customer']}
                >
                  {FileSampleType[key as 'clinic' | 'customer']}
                </option>
              ))}
            </select>
          </div>
          <div>
            <span>이름</span>
            <input value={name} onChange={(e) => setName(e.target.value)} />
          </div>
          <div>
            <span>파일</span>
            <input
              type="file"
              accept="image/jpg;image/png"
              onChange={onChangeFile}
            />
          </div>
        </div>
        <div className="buttons">
          <div className="flex-row">
            <button onClick={onClickSave} className="btn btn-primary">
              {action === 'create' ? '생성' : '저장'}
            </button>
          </div>
        </div>
      </div>
    );
  }
);
