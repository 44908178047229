import { observer } from 'mobx-react';
import { useApp } from '~/hooks/useApp';

type ModalClinicInputFormProps = {
  options: any;
  close: () => void;
};

const ModalClinicInputForm = ({
  options,
  close,
}: ModalClinicInputFormProps) => {
  const app = useApp();
  const t = (key: string) => app.$translate(key);

  const formControl = ({ fieldName, value }: any) => (
    <div className="form-control required">
      <label>{t(fieldName)}</label>
      <input disabled={true} value={value} autoComplete="new-password" />
    </div>
  );

  return (
    <div className="modal-clinic-input-form">
      <div className="head flex-row flex-between items-start">
        <div className="title">{t('SMS_TOTAL_INFO')}</div>
        <i onClick={() => close()} className="zmdi zmdi-close" />
      </div>
      <div className="body">
        <div className="card-title">{t('ACCOUNT_INFO')}</div>
        <div className="form-control required">
          <div className="grid-col">
            {formControl({
              fieldName: 'SMS_PAID_COUNT',
              value: options.totalPaidCount,
            })}
            {formControl({
              fieldName: 'SMS_FREE_COUNT',
              value: options.totalFreeCount,
            })}
            {formControl({
              fieldName: 'SMS_PAID_AMOUNT',
              value: options.totalPaidAmount,
            })}
            {formControl({
              fieldName: 'SMS_FREE_AMOUNT',
              value: options.totalFreeAmount,
            })}
            {formControl({
              fieldName: 'SMS_REMAINING_PAID_AMOUNT',
              value: options.paidBalance,
            })}
            {formControl({
              fieldName: 'SMS_REMAINING_FREE_AMOUNT',
              value: options.freeBalance,
            })}
            {formControl({
              fieldName: 'SMS_REFUND_AMOUNT',
              value: options.totalRefundAmount,
            })}
            {/*<div className="form-control required">
              <label>{app.$translate('SMS_PAID_COUNT')}</label>
              <input
                disabled={true}
                value={options.totalPaidCount}
                autoComplete="new-password"
              />
            </div>
            <div className="form-control required">
              <label>{app.$translate('SMS_FREE_COUNT')}</label>
              <input
                disabled={true}
                value={options.totalFreeCount}
                autoComplete="new-password"
              />
            </div>
            <div className="form-control required">
              <label>{app.$translate('SMS_PAID_AMOUNT')}</label>
              <input
                disabled={true}
                value={options.totalPaidAmount}
                autoComplete="new-password"
              />
            </div>
            <div className="form-control required">
              <label>{app.$translate('SMS_FREE_AMOUNT')}</label>
              <input
                disabled={true}
                value={options.totalFreeAmount}
                autoComplete="new-password"
              />
            </div>
            <div className="form-control required">
              <label>{app.$translate('SMS_REMAINING_PAID_AMOUNT')}</label>
              <input
                disabled={true}
                value={options.paidBalance}
                autoComplete="new-password"
              />
            </div>
            <div className="form-control required">
              <label>{app.$translate('SMS_REMAINING_FREE_AMOUNT')}</label>
              <input
                disabled={true}
                value={options.freeBalance}
                autoComplete="new-password"
              />
            </div>
            <div className="form-control required">
              <label>{app.$translate('SMS_REFUND_AMOUNT')}</label>
              <input
                disabled={true}
                value={options.totalRefundAmount}
                autoComplete="new-password"
              />
            </div>*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(ModalClinicInputForm);
