import { Box, Typography, styled } from '@mui/material';
import { Button } from '~/components/button/Button';
import { useRef } from 'react';

const HiddenInput = styled('input')({
  display: 'none',
});

type ImageFieldProps = {
  label: string;
  onChange: (value: any) => void;
  disabled?: boolean;
};

export const ImageField = ({ label, onChange, disabled }: ImageFieldProps) => {
  const inputRef: any = useRef();

  const onClickButton = () => {
    inputRef.current.click();
  };

  const onChangeInput = (e: any) => {
    if (e.target.files?.length) {
      onChange(e.target.files[0]);
    }
  };

  return (
    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
      <Button variant="outlined" onClick={onClickButton} disabled={disabled}>
        {label}
      </Button>
      <HiddenInput
        type="file"
        ref={inputRef}
        onChange={onChangeInput}
        accept="image/jpeg,image/jpg,image/png"
      />
    </Box>
  );
};
