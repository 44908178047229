import { useState } from 'react';
import { observer } from 'mobx-react';
import { useSnackbarContext } from '~/components/providers/SnackbarProvider';
import { PriceTableRepo } from '~/components/repo/PriceTableRepo';
import { PriceTableCode } from '~/components/model/PriceTable';

type PriceTableFormModalProps = {
  options: any;
  close: (button?: any) => void;
};

export const PriceTableFormModal = observer(
  ({ options, close }: PriceTableFormModalProps) => {
    const snackbar = useSnackbarContext();
    const repo = new PriceTableRepo();
    const { action, item } = options;

    const [name, setName] = useState(item?.name ?? '');
    const [code, setCode] = useState(item?.code ?? PriceTableCode.sms);
    const [price, setPrice] = useState(item?.price ?? 0);
    const [cost, setCost] = useState(item?.cost ?? 0);

    const create = async () => {
      try {
        const data = {
          name: name,
          code: code,
          price: price,
          cost: cost,
        };

        await repo.create(data);

        snackbar.success('생성했습니다.');

        close();
      } catch (e) {
        console.error(e);
        snackbar.alert('실패했습니다.');
      }
    };

    const update = async () => {
      try {
        const data = {
          name: name,
          code: code,
          price: price,
          cost: cost,
        };

        await repo.update(item.id, data);

        snackbar.success('수정했습니다.');

        close();
      } catch (e) {
        console.error(e);
        snackbar.alert('실패했습니다.');
      }
    };

    const onClickSave = async () => {
      if (action === 'create') {
        create();
      } else {
        update();
      }
    };

    return (
      <div className="modal-clinic-input-form">
        <div className="head flex-row flex-between items-start">
          <div className="title">{action === 'create' ? '생성' : '수정'}</div>
          <i onClick={() => close()} className="zmdi zmdi-close" />
        </div>
        <div>
          <div>
            <span>이름</span>
            <input value={name} onChange={(e) => setName(e.target.value)} />
          </div>
          <div>
            <span>코드</span>
            <select
              disabled={true}
              value={code}
              onChange={(e) => setCode(e.target.value)}
            >
              {Object.keys(PriceTableCode).map((key) => (
                <option
                  key={key}
                  disabled={true}
                  value={(PriceTableCode as any)[key]}
                >
                  {(PriceTableCode as any)[key]}
                </option>
              ))}
            </select>
          </div>
          <div>
            <span>가격</span>
            <input
              value={price}
              onChange={(e) => setPrice(Number(e.target.value))}
            />
          </div>
          <div>
            <span>비용</span>
            <input
              value={cost}
              onChange={(e) => setCost(Number(e.target.value))}
            />
          </div>
        </div>
        <div className="buttons">
          <div className="flex-row">
            <button onClick={onClickSave} className="btn btn-primary">
              {action === 'create' ? '생성' : '저장'}
            </button>
          </div>
        </div>
      </div>
    );
  }
);
