import { styled, css } from '@mui/material/styles';
import {
  Box as MuiBox,
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
} from '@mui/material';
import { Button as _Button } from '~/components/commons/Button';
import { ComponentType } from 'react';

export const Button = styled(_Button)`` as ComponentType<any>;

export const Dialog = styled(MuiDialog)`
  .MuiPaper-root {
    max-width: 400px;
  }
`;

export const DialogActions = styled(MuiDialogActions)`
  padding: 0 24px 16px 24px;
`;

export const DialogContent = styled(MuiDialogContent)`
  font-size: 14px;
  padding: 40px 27px 24px;

  .deco {
    display: block;
    position: relative;
    padding-left: 14px;
    &::before {
      content: '';
      display: block;
      width: 4px;
      height: 4px;
      background: black;
      border-radius: 50%;
      position: absolute;
      top: 9px;
      left: 0;
    }
    & + .deco {
      margin-top: 8px;
    }
  }

  .underline {
    text-decoration: underline;
  }
`;

export const Box = styled(MuiBox)`
  &.body {
    display: flex;
    width: 1164px;

    &.max-size {
      width: 100%;

      .left-section {
        min-height: 670px;
        height: calc(100vh - 62px);
      }

      .right-section {
        min-height: 670px;
        height: calc(100vh - 62px);
        .sms-data-table {
          min-height: 450px;
          height: calc(100vh - 255px);
        }
      }
    }
  }
  &.wrapper {
    display: flex;
    column-gap: 18px;
    p {
      font-weight: 400;
    }
  }
`;
