import styled, { css } from 'styled-components';
import { Select, FormControl } from '@mui/material';
import { ComponentType } from 'react';

export const InnerBoxStyle = css`
  width: 100%;
  height: 29px;
  border: 1px solid #dee2ec;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 6px 8px;
  font-size: 12px;
  transition: border-color 0.1s;
  &:focus {
    border-color: #2c62f6;
  }
`;

export const SmallWrapper = styled.div`
  &&& {
    select {
      height: 23px;
      padding: 4px;
      padding-right: 16px;
      line-height: 1;
    }
    svg {
      right: -3px;
      transform: scale(0.7);
    }
  }
`;

export const Wrapper = styled(FormControl)`
  width: 100%;
  background: #ffffff;
  & select {
    padding: 0 8px;
  }
  .MuiNativeSelect-select {
    font-size: 12px;
    line-height: 16px;
  }
`;

export const SelectBox = styled(Select)(
  ({ $invalid, optioncolor }: any) => ` 
    ${InnerBoxStyle};
    border: 0;
    color: ${$invalid ? '#a1b1ca' : 'inherit'};
    & option {
      color: ${!optioncolor ? '#2D2D2D !important' : ''};
    }
`
) as ComponentType<any>;
