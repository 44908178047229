import { useState } from 'react';
import { observer } from 'mobx-react';
import { OverlayWrapper } from '~/components/commons/modals/Overlay';
import { useApi } from '~/components/providers/ApiProvider';
import Icon from '~/components/commons/Icon';
import {
  ModalWrapper,
  Button,
  Box,
  TextField,
  ModalHeader,
  Stack,
  IconButton,
  DropdownList,
} from './ModalCtiNumber.styled';

type CallerType = {
  id?: number;
  number?: string;
  updateType?: 'CREATE' | 'DELETE' | 'UPDATE';
  visible?: boolean;
};

type ModalCtiNumberProps = {
  clinicId: number;
  onClose: (flag: boolean) => void;
  title: string;
  disabledList: CallerType[];
  editableList: CallerType[];
};

const statusList = [
  { id: '사용', label: '사용', value: true },
  { id: '미사용', label: '미사용', value: false },
];

const ModalCtiNumber = ({
  title,
  clinicId,
  onClose,
  disabledList,
  editableList,
}: ModalCtiNumberProps) => {
  const [callerList, setCallerList] = useState<CallerType[]>(editableList);
  const [deleteIds, setDeleteIds] = useState<number[]>([]);
  const { clinicsApi } = useApi();

  const handleSubmit = async () => {
    try {
      const deleteList = editableList
        .filter((item): any => item.id && deleteIds.includes(item.id))
        .map((item) => {
          item.visible = false;
          item.updateType = 'DELETE';
          return item;
        });
      const saveData = callerList.map((item) => {
        if (item.id) {
          item.updateType = 'UPDATE';
        } else {
          item.visible = true;
          item.updateType = 'CREATE';
        }
        return item;
      });
      await clinicsApi.putCallerList(clinicId, {
        callerNumberList: deleteList
          .concat(saveData)
          .filter((item) => item.number !== ''),
      });
    } catch (error) {
      console.log('error', error);
    } finally {
      onClose(true);
    }
  };

  const handleChangeValue = (
    item: any,
    value: {
      number?: string;
      visible?: boolean;
    }
  ) => {
    const newCallerList = callerList.map((listItem) => {
      if (item === listItem) {
        return {
          ...listItem,
          ...value,
        };
      }
      return listItem;
    });
    setCallerList(newCallerList);
  };

  const handleAddCaller = () => {
    setCallerList([
      ...callerList,
      {
        number: '',
      },
    ]);
  };

  const handleDeleteCaller = (item: any) => {
    const newCallerList = callerList.filter((listItem) => item !== listItem);
    setCallerList(newCallerList);
    setDeleteIds([...deleteIds, item.id]);
  };

  const handleClose = () => {
    onClose(false);
  };

  return (
    <OverlayWrapper onClose={handleClose}>
      <ModalWrapper>
        <ModalHeader title={title} onClose={handleClose} className={'p-30'} />
        <Box className="modal-body">
          <Stack flexDirection={'column'}>
            {disabledList.map((item: CallerType, index) => {
              return (
                <Stack
                  className="data-row disabled"
                  flexDirection={'row'}
                  key={index}
                >
                  <Box className="label">발신번호</Box>
                  <Box className="value w-200">{item.number}</Box>
                  <Box className="label">상태</Box>
                  <Box className="value">사용</Box>
                </Stack>
              );
            })}
            {callerList.map((item: CallerType, index: number) => {
              if (item.id) {
                return (
                  <Stack
                    className="data-row new-data"
                    flexDirection={'row'}
                    key={index}
                  >
                    <Box className="label">발신번호</Box>
                    <Box className="value w-200">{item.number}</Box>
                    <Box className="label">상태</Box>
                    <Box className="value input-value">
                      <DropdownList
                        value={statusList.filter((o) => {
                          return o.value === item?.visible;
                        })}
                        disableClearable
                        options={statusList}
                        onChange={(v: any) => {
                          handleChangeValue(item, {
                            visible: v.value,
                          });
                        }}
                        variant="search"
                        getOptionLabel={(o: any) => {
                          if (o[0]) {
                            return o[0]?.label ? o[0].label : '';
                          } else {
                            return o ? (o?.label ? o.label : '') : '';
                          }
                        }}
                        placeholder="전체"
                      />
                    </Box>
                    <IconButton onClick={() => handleDeleteCaller(item)}>
                      <Icon variant="minus" />
                    </IconButton>
                  </Stack>
                );
              } else {
                return (
                  <Stack
                    className="data-row new-data"
                    flexDirection={'row'}
                    key={index}
                  >
                    <Box className="label">발신번호</Box>
                    <Box className="value input-value">
                      <TextField
                        value={item.number || ''}
                        onChange={(v: string) => {
                          handleChangeValue(item, { number: v });
                        }}
                        placeholder="비즈엠에 등록된 발신번호만 입력하세요"
                      />
                    </Box>
                    <IconButton onClick={handleAddCaller}>
                      <Icon variant="plus" />
                    </IconButton>
                  </Stack>
                );
              }
            })}
          </Stack>
        </Box>
        <Box className="modal-footer">
          <Stack
            className=""
            flexDirection={'row'}
            justifyContent={'flex-end'}
            gap={'8px'}
          >
            <Button
              styled="outline"
              size="m"
              className="cancel-btn"
              onClick={handleClose}
              color="mix"
            >
              취소
            </Button>
            <Button
              styled="fill"
              size="m"
              className="save-btn"
              onClick={handleSubmit}
            >
              저장
            </Button>
          </Stack>
        </Box>
      </ModalWrapper>
    </OverlayWrapper>
  );
};

export default observer(ModalCtiNumber);
