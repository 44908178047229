import styled, { css } from 'styled-components';
import { TextField as MuiTextField, InputAdornment } from '@mui/material';
import { ComponentType } from 'react';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 2px;
  width: 100%;
`;

export const StyledInput = styled(MuiTextField)`
  background: white;
  border: 0;
  width: 100%;
  > * {
    padding-left: 0 !important;
    padding-right: 0 !important;
    height: 34px;
  }
  input {
    font-size: 12px;
    height: 34px;
    text-overflow: ellipsis;
    ${({ $type }: any) =>
      $type !== 'search'
        ? css`
            padding: 0 8px;
          `
        : css`
            padding: 0 4px 0 0;
          `}
  }

  .MuiOutlinedInput-notchedOutline {
    ${({ $type }: any) =>
      $type === 'search' &&
      css`
        border: none !important;
        border-radius: 0 !important;
      `}
    ${({ $active, theme }: any) =>
      $active &&
      css`
        border: solid 1px ${theme.color.primary.unoblue} !important;
      `}

    ${({ $border, theme }: any) =>
      $border &&
      css`
        border: solid 1px ${theme.color.bluegrey[600]};
      `}
  }
` as ComponentType<any>;

export const EndAdornmentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  font-size: 12px;
  font-weight: 500;

  &.min-width {
    min-width: 50px;
    flex-shirink: 0;
  }

  ${({ $disabled, theme }: any) =>
    $disabled &&
    css`
      background-color: ${theme.color.bluegrey[200]};
    `}
` as ComponentType<any>;

export const InputStartAdornment = styled(InputAdornment)`
  &.MuiInputAdornment-outlined {
    & p {
      font-size: 12px;
      width: 64px;
      padding-left: 8px;
      word-break: keep-all;
      line-height: 1rem;
      color: #2d2d2d;
    }
  }
`;

export const InputEndAdornment = styled(InputAdornment)`
  &.MuiInputAdornment-outlined {
    margin-left: 0;
    margin-right: 4px;
  }
`;
