import { ComponentType, ReactNode } from 'react';
import styled, { css } from 'styled-components';

export const StyledIconButton = styled.button`
  border: 1px solid rgba(222, 226, 236, 1);

  display: flex;
  justify-content: center;
  align-items: center;

  ${({ size }: any) =>
    size === 'default' &&
    css`
      padding: 8px 8px;

      svg {
        width: 10px;
        height: 10px;
      }
    `}

  ${({ size }: any) =>
    size === 'default' &&
    css`
      padding: 4px 4px;

      svg {
        width: 10px;
        height: 10px;
      }
    `}


  ${(props: any) =>
    props.variant === 'transparent' &&
    css`
      border: none;
    `}

  ${(props: any) =>
    props.variant === 'default' &&
    css`
      background: rgba(255, 255, 255, 1);
      color: rgba(32, 32, 32, 1);
    `}

  ${(props: any) =>
    props.variant === 'dark' &&
    css`
      background: rgba(41, 49, 66, 1);
      color: rgba(255, 255, 255, 1);
    `}

${(props: any) =>
    props.variant === 'primary' &&
    css`
      border: none;
      background: #2c62f6;
      color: white;
    `}
` as ComponentType<any>;

type IconButtonProps = {
  variant?: 'transparent' | 'default' | 'dark' | 'primary';
  size?: 'default' | 'small';
  children: ReactNode;
  onClick?: () => void;
  sx?: any;
};

export const IconButton = (props: IconButtonProps) => {
  return <StyledIconButton {...props} />;
};
