import { Wrapper, StyledCheckbox } from './Checkbox.styled';
import Icon from './Icon';
import { ReactNode } from 'react';

type CheckboxProps = {
  checked: boolean;
  onChange?: (event?: any) => void;
  label?: string | ReactNode;
  disabled?: boolean;
  className?: string;
};

export const Checkbox = ({
  label = '',
  checked,
  onChange,
  disabled,
  ...props
}: CheckboxProps) => {
  return (
    <Wrapper
      control={
        <StyledCheckbox
          color="primary"
          checked={checked}
          onChange={onChange}
          icon={<Icon variant="unchecked" />}
          checkedIcon={
            <Icon variant="checked" color={disabled ? '#BBBBBB' : '#2C62F6'} />
          }
        />
      }
      label={label}
      disabled={disabled}
      {...props}
    />
  );
};
