import styled, { css } from 'styled-components';
import { Button, styled as muiStyled } from '@mui/material';
import { ComponentType } from 'react';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: '2px';
  ${({ styleType }: any) =>
    (styleType === 'chart' || styleType === 'statistics') &&
    css`
      column-gap: 4px;
      * {
        font-size: 10px;
        line-height: 10px;
      }
      > button {
        min-width: auto;
        padding: 2px 4px;
      }
    `}
` as ComponentType<any>;

export const PageButton = muiStyled(Button)`
  font-family: Noto Sans KR;
  font-size: 10px;
  line-height: 10px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  min-width: 32px;
  color: rgba(0, 0, 0, 0.87);

  ${({ selected }: any) =>
    selected &&
    css`
      background: #d7e3f1;
    `}

  ${({ current }: any) =>
    current &&
    css`
      font-weight: 500;
    `}
` as ComponentType<any>;

export const Ellipsis = styled.span``;

export const MoveButton = muiStyled(Button)`
  font-family: Noto Sans KR;
  font-size: 10px;
  line-height: 10px;
  font-style: normal;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.87);
  letter-spacing: 0em;
  text-align: left;

  &:disabled {
    border: none !important;
    background: none !important;
  }
`;
