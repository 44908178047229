import { ApiResource } from './apiResource';

export class ExcelDownloadApi extends ApiResource {
  async postExcelsClinic(params: any) {
    return this.client.post('/admin/excels/clinic', params);
  }

  async getTasks(id: number) {
    return this.client.get(`/admin/tasks/${id}`);
  }

  async downloadExcel(location: string, name: string) {
    return this.client.get(`${location}/download`, { name: name });
  }
}
