import { ComponentType } from 'react';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
`;

export const LabelDom = styled.label`
  display: inline-block;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  margin-bottom: 4px;
  ${({ $isRequire }: any) =>
    $isRequire
      ? css`
          &::after {
            content: ' *';
            color: #eb5757;
          }
        `
      : ''}
` as ComponentType<any>;

export const LabelWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(
    ${({ column }: any) => (column ? column : 2)},
    1fr
  );
  column-gap: 8px;
`;

export const InnerBoxStyle = css`
  width: 100%;
  height: 29px;
  border: 1px solid #dee2ec;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 0 8px;
  font-size: 12px;
  transition: border-color 0.1s;
  &:focus {
    border-color: #2c62f6;
  }
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  min-height: 29px;
`;
