import { observer } from 'mobx-react';
import { useState } from 'react';
import {
  FilterItem,
  LabelText,
  Wrapper,
  TextField,
  Fade,
  ClearButton,
  InputEndAdornment,
  Span,
} from './SearchFilterRangeInput.styled';

type SearchFilterRangeInputProps = {
  startValue: any;
  endValue: any;
  onChange: (value1: any, value2: any) => void;
  onSelect: (value: any) => void;
  placeholder:
    | {
        start: string;
        end: string;
      }
    | string;
  focus?: boolean;
  label?: string;
  useClearButton?: boolean;
};

export const SearchFilterRangeInput = observer(
  ({
    startValue,
    endValue,
    onChange,
    onSelect,
    placeholder,
    focus,
    label,
    useClearButton = true,
    ...props
  }: SearchFilterRangeInputProps) => {
    const [isFocused, setIsFocused] = useState({
      start: false,
      end: false,
    });

    const handleChangeValue = (start: Date | string, end: Date | string) => {
      onChange(start, end);
    };

    const handleReset = () => {
      onChange(null, null);
    };

    return (
      <FilterItem $focus={isFocused.start || isFocused.end} {...props}>
        <LabelText className="label-text">{label}</LabelText>
        <Wrapper>
          <TextField
            $type="search"
            value={startValue}
            $focus={isFocused.start}
            onFocus={() =>
              setIsFocused({
                ...isFocused,
                start: true,
              })
            }
            onBlur={() =>
              setIsFocused({
                ...isFocused,
                start: false,
              })
            }
            placeholder={
              typeof placeholder === 'string'
                ? placeholder
                : placeholder?.start || ''
            }
            onChange={(v: any) => {
              setIsFocused({
                ...isFocused,
                start: true,
              });
              handleChangeValue(v, endValue);
            }}
            {...props}
          />
          <Span>-</Span>
          <TextField
            $type="search"
            value={endValue}
            onFocus={() =>
              setIsFocused({
                ...isFocused,
                end: true,
              })
            }
            onBlur={() =>
              setIsFocused({
                ...isFocused,
                end: false,
              })
            }
            placeholder={
              typeof placeholder === 'string'
                ? placeholder
                : placeholder?.start || ''
            }
            onChange={(v: any) => {
              setIsFocused({
                ...isFocused,
                end: true,
              });
              handleChangeValue(startValue, v);
            }}
            {...props}
          />
          {useClearButton && (
            <Fade
              in={
                (isFocused.start || isFocused.end) &&
                (Boolean(startValue) || Boolean(endValue))
              }
            >
              <InputEndAdornment position="end">
                <ClearButton onClick={handleReset} />
              </InputEndAdornment>
            </Fade>
          )}
        </Wrapper>
      </FilterItem>
    );
  }
);

export default SearchFilterRangeInput;
