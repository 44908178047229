import {
  Box,
  outlinedInputClasses,
  TextField as MuiTextField,
  Typography,
} from '@mui/material';

type TextAreaProps = {
  value: any;
  onChange: (value: any) => void;
  limit?: number;
  placeholder?: string;
};

export const TextArea = ({
  value,
  onChange,
  limit,
  placeholder,
}: TextAreaProps) => {
  return (
    <Box
      sx={{
        border: `1px solid #E9E9ED`,
        borderRadius: 1,
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      <MuiTextField
        value={value}
        onChange={onChange}
        multiline
        placeholder={placeholder}
        sx={{
          [`& .${outlinedInputClasses.root}`]: {
            p: 1,
            pb: 4,
          },
          [`& .${outlinedInputClasses.input}`]: {
            p: 1,
            pb: 4,
          },
        }}
      />
      {limit && (
        <Box
          sx={{
            position: 'absolute',
            bottom: 4,
            right: 8,
          }}
        >
          <Typography sx={{ '&&': { fontSize: 14, opacity: 0.5 } }}>
            {value.length} / {limit}자
          </Typography>
        </Box>
      )}
    </Box>
  );
};
