import { observer } from 'mobx-react';
import { useApp } from '~/hooks/useApp';

type ModalBasicProps = {
  options: any;
  close: (button?: any) => void;
};

const ModalBasic = ({ options, close }: ModalBasicProps) => {
  const app = useApp();
  const onClickButton = (button: any) => {
    close(options.buttons.indexOf(button));
  };

  return (
    <div className="modal-basic">
      {(options.title || options.showCloseButton) && (
        <div className="head flex-row flex-between items-start">
          <div className={`title ${options.titleClass || ''}`}>
            {app.$translate(options.title)}
          </div>
          {options.showCloseButton ? (
            <i onClick={() => close()} className="zmdi zmdi-close" />
          ) : null}
        </div>
      )}
      <div
        className={`body pre-line ${options.bodyClass}`}
        dangerouslySetInnerHTML={{ __html: options.body }}
      />
      {options.buttons ? (
        <div className="buttons">
          <div className="flex-row">
            {options.buttons.map((button: any, idx: number) => {
              return (
                <button
                  key={idx}
                  onClick={() => onClickButton(button)}
                  className={button.class}
                >
                  {app.$translate(button.text)}
                </button>
              );
            })}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default observer(ModalBasic);
