import { format } from 'date-fns';
import { NoWrap } from './UsersLogTable.styled';

type UsersLogTableProps = {
  data: any;
};

export const UsersLogTable = ({ data }: UsersLogTableProps) => {
  const getMethodName = (method: string) => {
    switch (method) {
      case 'GET':
        return '조회';
      case 'PUT':
        return '수정';
      case 'POST':
        return '등록';
      case 'DELETE':
        return '삭제';
      default:
        return '';
    }
  };

  const getStatusCodeName = (code: number) => {
    if (code >= 200 && code < 300) {
      return '성공';
    } else {
      return `실패:${code}`;
    }
  };

  return data.map((item: any) => (
    <tr key={item.id}>
      <td>{item.id}</td>
      <td>{item.adminUserId}</td>
      <td>{item.adminUser.email}</td>
      <td>{item.adminUser.name}</td>
      <td>{item.requestPath}</td>
      <td>{getMethodName(item.requestMethod)}</td>
      <td>{item.requestParam}</td>
      <td>{getStatusCodeName(item.statusCode)}</td>
      <td>
        <NoWrap>
          {format(new Date(item.createdAt), 'yyyy-MM-dd HH:mm:ss')}
        </NoWrap>
      </td>
      <td>{item.remoteIp}</td>
    </tr>
  ));
};
