import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { agencies } from '~/hooks/constants';
import ModalDeactivateReason from './ModalDeactivateReason';
import { Box, Link, DataTable } from './DataTable.styled';
import { toThousandCommas } from '~/filters';
import { format } from 'date-fns';
import { useApp } from '~/hooks/useApp';
import { Tooltip } from '~/components/commons/Tooltip';
import { Button } from '~/components/commons/Button';

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-top: 4px;
`;

type ClinicsDeactivateDataTableProps = {
  data: any;
  orderBy: { id: any; value: any };
  onRefresh: () => void;
  pagination: {
    page: number;
    limit: number;
  };
  onChangePagination: (params: any) => void;
};

export const ClinicsDeactivateDataTable = (
  props: ClinicsDeactivateDataTableProps
) => {
  const app = useApp();
  const { data, orderBy, onRefresh } = props;
  const items = useMemo(() => data?.data ?? [], [data]);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);

  const onClickDeactivateClinic = (item: any) => {
    setShowDeactivateModal(item);
  };

  const handleOpenCancelRequestFile = (url: string) => {
    window.open(url, '_blank');
  };

  const schema = useMemo(
    () => ({
      columns: [
        {
          id: 'id',
          name: 'ID',
          value: (item: any) => (
            <Link to={`/clinics/${item.clinicId}`}>{item.clinicId}</Link>
          ),
        },
        {
          id: 'name',
          name: '거래처명',
          value: (item: any) => (
            <Link to={`/clinics/${item.clinicId}`}>{item.name || '-'}</Link>
          ),
        },
        {
          id: 'action',
          name: 'ACTION',
          value: (item: any) => {
            return (
              <Tooltip
                title={app.$me.role === 'manager' ? '권한이 없습니다.' : ''}
              >
                <ButtonWrapper>
                  <button
                    onClick={() => onClickDeactivateClinic(item)}
                    className="btn-sm btn-primary"
                    disabled={app.$me.role === 'manager'}
                  >
                    수정
                  </button>
                </ButtonWrapper>
              </Tooltip>
            );
          },
        },
        {
          id: 'cancelExcuteAt',
          name: '요청일',
          value: (item: any) => (
            <Box className={`date`}>
              {format(new Date(item.cancelExcuteAt), 'yyyy-MM-dd')}
            </Box>
          ),
        },
        {
          id: 'cancelAt',
          name: '해지일',
          value: (item: any) => (
            <Box className={`date`}>
              {format(new Date(item.cancelAt), 'yyyy-MM-dd')}
            </Box>
          ),
        },
        {
          id: 'reason',
          name: '해지사유',
          value: (item: any) => (
            <Box className={`deactivate-reason`}>
              {item.reason ? item.reason : '-'}
            </Box>
          ),
        },
        {
          id: 'refundPrice',
          name: '환불 금액',
          value: (item: any) =>
            item.refundPrice && !isNaN(Number(item.refundPrice))
              ? toThousandCommas(item.refundPrice)
              : '-',
        },
        {
          id: 'cancelRequestFileUrl',
          name: '해지 신청서',
          value: (item: any) =>
            item.cancelRequestFileUrl && (
              <Button
                size="xs"
                styled="outline"
                color="black"
                onClick={() =>
                  handleOpenCancelRequestFile(item.cancelRequestFileUrl)
                }
              >
                보기
              </Button>
            ),
        },
        {
          id: 'contractAgency',
          name: '계약 대행사',
          value: (item: any) => {
            return !(item.contractAgency == null || item.contractAgency === '')
              ? agencies.find(
                  (agent: any) => agent.value === item.contractAgency
                )?.key || '없음'
              : '없음';
          },
        },
        {
          id: 'educationAgency',
          name: '교육 대행사',
          value: (item: any) => {
            return !(
              item.educationAgency == null || item.educationAgency === ''
            )
              ? agencies.find(
                  (agent: any) => agent.value === item.educationAgency
                )?.key || '없음'
              : '없음';
          },
        },
      ],
    }),
    []
  );

  return (
    <>
      <DataTable
        data={items}
        schema={schema}
        sorts={[orderBy]}
        placeholder={'조회 결과가 없습니다.'}
        resizable
      />
      {showDeactivateModal !== false && (
        <ModalDeactivateReason
          info={showDeactivateModal}
          title={'거래처 해지'}
          onRefresh={onRefresh}
          onClose={() => setShowDeactivateModal(false)}
        />
      )}
    </>
  );
};
