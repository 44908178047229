import { color } from '~/theme';
import { useMemo } from 'react';
import {
  DropdownList,
  Button,
  Box,
  Typography,
  FilterMonthRange,
} from './SearchView.styled';
import { stripEmpty } from '~/utils/objUtil';

const sortOptions = [
  { label: '개업', value: '1', id: '1' },
  { label: '폐업', value: '2', id: '2' },
];

type SearchViewProps = {
  width: number;
  onSubmit: () => void;
  onChange: (params: any) => void;
  value: any;
  onSearch: (params: any) => void;
};

export const SearchView = ({
  onSearch,
  value,
  onChange,
  width,
}: SearchViewProps) => {
  const searchFilterValues = useMemo(() => {
    return stripEmpty(
      {
        ...value,
        name: value?.name?.length < 3 ? '' : value.name,
        isOverLimit: value?.isOverLimit === true ? true : undefined,
      },
      {
        emptyString: true,
        emptyArray: true,
      }
    );
  }, [value]);

  const disabled = useMemo(() => {
    return Object.keys(searchFilterValues).length <= 3;
  }, [searchFilterValues]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        width: width ?? undefined,
        maxWidth: 1800,
      }}
    >
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            border: `1px solid ${color.common.line}`,
            '& > *:not(:last-child)': {
              borderRight: `1px solid ${color.common.line}`,
            },
          }}
        >
          <Box width={340}>
            <FilterMonthRange
              label="날짜"
              year={value.year}
              month={value.month}
              placeholder={'YYYY-MM-DD'}
              disableClearable={true}
              onChange={(year: string, month: string) => {
                onChange({
                  year: year ? year : value.year,
                  month: month ? month : value.month,
                });
              }}
            />
          </Box>
          <Box
            sx={{
              width: 250,
              background: 'white',
            }}
          >
            <DropdownList
              value={sortOptions.filter((o) =>
                value.opCloTp?.includes(o.value)
              )}
              options={sortOptions}
              onChange={(v: any) => {
                onChange({ opCloTp: v?.value });
              }}
              variant="search"
              getOptionLabel={(o: any) => {
                if (o[0]) {
                  return o[0]?.label ? o[0].label : '';
                } else {
                  return o ? (o?.label ? o.label : '') : '';
                }
              }}
              disableClearable={true}
              startAdornment={
                <Typography className="width-wide">{'개폐업 구분'}</Typography>
              }
              placeholder="전체"
            />
          </Box>
        </Box>
        <Button
          styled="outline"
          onClick={onSearch}
          className="search-btn"
          disabled={disabled}
        >
          조회
        </Button>
      </Box>
    </Box>
  );
};
