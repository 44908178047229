import { useState } from 'react';
import { useApp } from '~/hooks/useApp';
import { useApi } from '~/components/providers/ApiProvider';
import { NewManagementTable } from '~/components/routes/management/NewManagementTable';
import { Button } from '~/components/button/Button';
import CreateUserConfirmModal from '~/components/modals/management/CreateUserConfirmModal';
import { useSnackbarContext } from '~/components/providers/SnackbarProvider';
import {
  Depth,
  Title,
  SubTitle,
  Contents,
} from './DetailManagementPage.styled';
import { useHistory } from 'react-router-dom';

export const NewManagementPage = () => {
  const history = useHistory();
  const snackbar = useSnackbarContext();
  const app = useApp();
  const { userApi } = useApi();

  const [value, setValue] = useState<any>({
    email: '',
    role: null,
    name: '',
  });

  const validate = () => {
    if (value.email === '' || value.role === null || value.name === '') {
      throw new Error('필수값이 누락되었습니다.');
    }

    const checkEmailFormat = /^\S+@\S+\.\S+$/.test(value.email);
    if (!checkEmailFormat) {
      throw new Error('올바르지 않은 이메일 형식입니다.');
    }
  };

  const onClickSave = async () => {
    try {
      validate();
    } catch (e: any) {
      snackbar.alert(e.message);
      return;
    }

    try {
      const response: any = await userApi.createUser(
        value.email,
        value.name,
        value.role.id
      );

      if (response) {
        app.$modal.custom({
          component: CreateUserConfirmModal,
          options: {
            password: response.data.password,
            tempPasswordCreatedAt: new Date(
              response.data.tempPasswordCreatedAt
            ),
            email: value.email,
            name: value.name,
            role: value.role.id,
          },
        });

        history.push(`/management/detail/${response.data.id}`);
      }
    } catch (e: any) {
      if (e.code === 409 && e.name === 'EMAIL_ALREADY_EXISTS') {
        snackbar.alert('동일한 이메일이 존재합니다');
        return;
      }
      if (e.name === 'NO_PERMISSION') {
        snackbar.alert('접근 권한이 없습니다.');
        return;
      }
      snackbar.alert(e.name);
    }
  };

  return (
    <div className="main-contents">
      <Depth>{`관리자 관리 > 계정 등록`}</Depth>
      <Title className="content-title">
        <span>계정 등록</span>
        <Button onClick={onClickSave}>저장</Button>
      </Title>
      <SubTitle>계정정보</SubTitle>
      <Contents>
        <NewManagementTable value={value} setValue={setValue} />
      </Contents>
    </div>
  );
};
