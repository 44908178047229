import { Box } from '@mui/material';
import { PopupRepo } from '~/components/repo/PopupRepo';
import { PageTitle } from '~/components/page/PageTitle';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSnackbarContext } from '~/components/providers/SnackbarProvider';
import { observer } from 'mobx-react';
import { Page } from '~/components/page/Page';
import { Button } from '~/components/button/Button';
import { format } from 'date-fns';
import { PopupForm } from './PopupForm';
import { ImageRepo } from '~/components/repo/ImageRepo';
import { validateHttpsUrl } from '~/validateUtil';

const combineDateAndTime = (date: Date, time: Date) => {
  return `${format(date, 'yyyy-MM-dd')} ${time.toString().padStart(2, '0')}:00`;
};

export const EditPopupPage = observer(() => {
  const snackbar = useSnackbarContext();
  const [value, setValue] = useState<any>(null);
  const repo = new PopupRepo();
  const imageRepo = new ImageRepo();
  const history = useHistory();
  const { id }: any = useParams();

  useEffect(() => {
    setupValue();
  }, [id]);

  const setupValue = async () => {
    try {
      const res: any = await repo.get(id);
      setValue({
        title: res.title,
        startDate: new Date(format(new Date(res.startAt), 'yyyy-MM-dd')),
        startHour: Number(format(new Date(res.startAt), 'HH')),
        endDate: new Date(format(new Date(res.endAt), 'yyyy-MM-dd')),
        endHour: Number(format(new Date(res.endAt), 'HH')),
        type: res.type === 'header_refresh' ? 'header' : res.type,
        contents: res.contents,
        image: res.image,
        color: res.backgroundColor,
        linkUrl: res.linkUrl,
        bannerOption: res.type === 'header_refresh' ? 'refresh' : 'default',
      });
    } catch (error: any) {
      snackbar.alert(
        error?.message || '데이터 불러오던 중 서버에러가 발생했습니다.'
      );
    }
  };

  const validate = () => {
    if (!value.title) {
      throw new Error('제목은 필수값입니다.');
    }
    if (
      value.startDate == null ||
      value.startHour == null ||
      value.endDate == null ||
      value.endHour == null
    ) {
      throw new Error('시작 및 종료 시간은 필수값입니다.');
    }

    const start = new Date(
      combineDateAndTime(value.startDate, value.startHour)
    );
    const end = new Date(combineDateAndTime(value.endDate, value.endHour));
    if (start >= end) {
      throw new Error('종료 시간이 시작 시간보다 빠릅니다');
    }

    if (value.type === 'header' && !value.contents) {
      throw new Error('내용은 필수값입니다.');
    }

    if (
      (value.type === 'image' ||
        value.type === 'logo' ||
        value.type === 'home_banner') &&
      !value.imageFile &&
      !value.image
    ) {
      throw new Error('이미지는 필수값입니다.');
    }

    if (value.linkUrl && !validateHttpsUrl(value.linkUrl)) {
      throw new Error('연결 링크 형식이 올바르지 않습니다.');
    }
  };

  const onClickEdit = async () => {
    try {
      validate();

      const type =
        value.type === 'header' && value.bannerOption === 'refresh'
          ? 'header_refresh'
          : value.type;
      const params: any = {
        startAt: combineDateAndTime(value.startDate, value.startHour),
        endAt: combineDateAndTime(value.endDate, value.endHour),
        linkUrl: value.linkUrl,
        title: value.title,
        type,
      };

      if (
        value.type === 'image' ||
        value.type === 'home_banner' ||
        value.type === 'logo'
      ) {
        if (value.imageFile) {
          params.imageId = await imageRepo.upload(value.imageFile);
        } else {
          params.imageId = value.image?.id;
        }
        params.backgroundColor = null;
        params.contents = null;
      } else {
        params.imageId = null;
        params.backgroundColor = value.color;
        params.contents = value.contents;
      }

      if (
        (value.type === 'image' ||
          value.type === 'home_banner' ||
          value.type === 'logo') &&
        !params.imageId
      ) {
        throw new Error('image id is required');
      }

      await repo.update(id, params);
      snackbar.success('수정완료되었습니다.');
      history.push('/popups');
    } catch (e: any) {
      snackbar.alert(e.message);
    }
  };

  return (
    <Page>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <PageTitle>팝업 수정</PageTitle>
        <Button color="primary" onClick={onClickEdit}>
          수정
        </Button>
      </Box>
      {value && <PopupForm value={value} onChange={setValue} mode="edit" />}
    </Page>
  );
});
