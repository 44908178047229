import styled from 'styled-components';
import {
  Autocomplete,
  TextField,
  textFieldClasses,
  Popper as MuiPopper,
  autocompleteClasses,
  styled as muiStyled,
} from '@mui/material';
import { ClearButton as _ClearButton } from '~/components/commons/ClearButton';
import { NoOptionsText as _NoOptionsText } from '~/components/commons/NoOptionsText';
import { ComponentType } from 'react';

const fontSize = '12px';

export const ClearButton = styled(_ClearButton)``;

export const NoOptionsText = styled(_NoOptionsText)``;

export const Input = styled(TextField)`
  &.${textFieldClasses.root} {
    width: 100%;
    height: 100%;
    padding-left: 0;

    & input {
      height: 29px;
      box-sizing: border-box;
      padding: 4px 8px;
      font-size: 12px;
      text-align: left;
      line-height: 1rem;
      margin-right: 20px;
    }

    & .MuiOutlinedInput-root {
      position: absolute;
      padding: 0 !important;
      height: ${({ $isopen }: any) => (!$isopen ? '29px' : '')};
      overflow: ${({ $isopen }: any) => (!$isopen ? 'clip' : '')};
      background: ${({ $isopen }: any) => ($isopen ? 'white' : '')};
    }

    & .MuiInputBase-root.Mui-focused {
      z-index: 2;
    }
  }
` as ComponentType<any>;

export const Li = muiStyled('li')(
  ({ theme }) => `
  height: 29px;
  font-size: ${fontSize};
  padding: 0 10px !important;
  display: flex;
  align-items: center;
  &.selected {
    background-color: ${theme.palette.bluegrey['300']};
  }
  &:hover {
    background-color: ${theme.palette.grey['200']};
  }
`
);

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  svg {
    width: 16px;
    height: 16px;
  }
`;

export const Popper = styled(MuiPopper)`
  .${autocompleteClasses.noOptions} {
    font-size: ${fontSize};
    padding: 4px 10px !important;
    min-height: 100px;
  }

  .${autocompleteClasses.paper} {
    box-shadow: none !important;
    border: 1px solid #dee2ec;
    border-radius: initial !important;
  }

  .${autocompleteClasses.listbox} {
    padding: 4px 0 !important;
  }
`;

export const AutoCompleteSelect = styled(Autocomplete)`
  &.${autocompleteClasses.root} {
    width: 100%;

    & .MuiOutlinedInput-notchedOutline {
      border: ${({ variant, $active }: any) =>
        $active
          ? 'solid 1px #2C62F6'
          : variant === 'search'
          ? 'none !important'
          : ''};
      border-radius: ${({ variant }: any) => variant === 'search' && 0};
    }

    & .MuiOutlinedInput-root {
      padding: 0 !important;
    }

    & .MuiInputBase-root.Mui-disabled {
      background-color: #f1f1f1 !important;
      color: #273142 !important;
    }

    & .MuiInputBase-root.Mui-disabled input {
      background-color: #f1f1f1 !important;
      color: #bbbbbb !important;
    }

    .MuiAutocomplete-clearIndicator {
      width: 18px;
      height: 18px;
      display: flex;
      align-items: center;
      padding: 0;

      svg,
      path {
        position: relative;
        left: 1px;
      }
    }
  }
  .${autocompleteClasses.input} {
    font-size: ${fontSize};
  }
  .${autocompleteClasses.endAdornment} {
    right: 4px !important;
    position: relative !important;
    top: 0 !important;
    display: flex;
    align-items: center;
  }
  .${autocompleteClasses.clearIndicator} {
    margin-right: 8px;
  }
`;

export const SelectLabel = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 29px;
  cursor: default;
  font-size: 11px;
  color: ${({ theme }) => theme && theme.color.bluegrey[600]};
  padding: 0 10px;
`;
