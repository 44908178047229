import { useState, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { runInAction, toJS } from 'mobx';
import { useSnackbarContext } from '~/components/providers/SnackbarProvider';
import { useApp } from '~/hooks/useApp';
import { format } from 'date-fns';
import { DropdownList } from './ModalSmsFreeDeposit.styled';

type ModalSmsFreeDepositProps = {
  options: any;
  close: (value?: any) => void;
};

const ModalSmsFreeDeposit = ({ options, close }: ModalSmsFreeDepositProps) => {
  const app = useApp();
  const snackbar = useSnackbarContext();
  const [obj, setObj] = useState<any>({ clinicId: '' });
  const [memo, setMemo] = useState('');
  const [clinics, setClinics] = useState<any>([]);

  useEffect(() => {
    let copyClinics = options.clinics.map((item: any) => {
      return { ...clinics, name: item.name + ` (${item.id})`, value: item.id };
    });

    setClinics(copyClinics);
  }, [setClinics]);

  const setMemoContents = (clinicMemo: any, memo: any) => {
    const currentMemoTimeStamp = format(new Date(), 'yy-MM-dd');
    const currentMemo = {
      content: memo,
      timestamp: currentMemoTimeStamp,
    };
    return JSON.stringify((JSON.parse(clinicMemo) || []).concat(currentMemo));
  };

  const onClickSave = () => {
    const findClinics = toJS(options.clinics).find(
      (v: any) => v.id === Number(obj.clinicId)
    );
    const clinic = { ...toJS(findClinics) };
    runInAction(() => {
      app.$modal
        .basic({
          body:
            `상호(병원명): ${clinic.name} <br/>` +
            `무료 충전금: ${obj.amount} <br/>` +
            `충전하시겠습니까?`,
          buttons: [
            {
              text: 'CANCEL',
              class: 'btn-default',
            },
            {
              text: 'CONFIRM',
              class: 'btn-primary',
            },
          ],
        })
        .then((selectedIdx) => {
          if (selectedIdx === 1) {
            freeDepositCallApi(obj, clinic, memo).then(() => {
              app.sleep(500);
              options.callApi();
            });
          }
        });
    });
  };

  const freeDepositCallApi = useCallback(
    async (obj, clinic, currentMemo) => {
      try {
        const { id, memo } = clinic;
        const editClinicMemo = {
          clinicId: id,
          memo: setMemoContents(memo, currentMemo),
        };
        const payload = {
          clinicId: id,
          amount: Number(obj.amount),
          status: 'complete',
          requestType: 'deposit',
          type: 'free',
          clinicMemo: editClinicMemo.memo,
        };

        await app.$smsRepo.sms.create(payload);
        snackbar.success(`충전하였습니다.`);
        close();
      } catch (e: any) {
        snackbar.alert(e.description);
      }
    },
    [app, close]
  );

  return (
    <div className="modal-department-category">
      <div className="head flex-row flex-between items-start">
        <div className="title">{'문자 무료충전'}</div>
        <i onClick={() => close()} className="zmdi zmdi-close" />
      </div>
      <div className="body">
        <div className="form-control section required">
          <label>{'상호(병원명)'}</label>
          <DropdownList
            value={clinics.filter((o: any) => {
              return obj.clinicId === o.id;
            })}
            options={clinics.map((item: any) => {
              item.id = item.value;
              item.label = item.name;
              return item;
            })}
            onChange={(v: any) => {
              setObj({ ...obj, clinicId: v?.value });
            }}
            variant="search"
            getOptionLabel={(o: any) => {
              if (o[0]) {
                return o[0]?.label ? o[0].label : '';
              } else {
                return o ? (o?.label ? o.label : '') : '';
              }
            }}
            isOptionEqualToValue={(option: any, value: any) => true}
            placeholder="병원을 선택해주세요"
          />
        </div>
        <div className="form-control section required">
          <label>{'무료 충전금'}</label>
          <input
            name="amount"
            onChange={(e) =>
              setObj({ ...obj, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className="form-control section">
          <label>{'CS 메모'}</label>
          <textarea
            name="memo"
            onChange={(e) => setMemo(e.target.value)}
            value={memo}
            placeholder={app.$translate('MEMO_PLACEHOLDER')}
          />
        </div>
      </div>
      <div className="buttons">
        <div className="flex-row">
          <button onClick={() => close(0)} className="btn btn-default">
            {app.$translate('CANCEL')}
          </button>
          <button onClick={() => onClickSave()} className="btn btn-primary">
            {app.$translate('SAVE')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default observer(ModalSmsFreeDeposit);
