import {
  Typography as MuiTypography,
  styled,
  css,
  Box as MuiBox,
} from '@mui/material';
import { TextField as _TextField } from './TextField';
import { Button as _Button } from './Button';
import { ComboBox as _ComboBox } from './ComboBox';
import {
  Fade as MuiFade,
  InputAdornment as MuiInputAdornment,
} from '@mui/material';
import { ClearButton as _ClearButton } from './ClearButton';
import { ComponentType } from 'react';

export const Box = styled(MuiBox)(({ theme }) => css``);

export const TextField = styled(_TextField)(
  ({ theme }) => css`
    height: 34px;
    .MuiInputBase-input {
      text-align: right;
    }
  `
) as ComponentType<any>;

export const ComboBox = styled(_ComboBox)(
  ({ theme }) => css`
    height: 34px;
    .MuiAutocomplete-inputRoot.MuiInputBase-root.MuiOutlinedInput-root {
      height: 34px;
    }
  `
);

export const FilterItem = styled('div')`
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  height: 34px;

  ${({ $focus, theme }: any) =>
    $focus &&
    css`
      border: solid 1px ${theme.palette.primary.unoblue} !important;
    `}
` as ComponentType<any>;

export const LabelText = styled('div')`
  font-size: 12px;
  width: 100px;
  padding-left: 8px;
  word-break: keep-all;
  line-height: 1;
  color: #2d2d2d;
`;

export const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  column-gap: 2px;

  .MuiFormControl-root {
    background-color: unset;
  }
`;

export const Fade = styled(MuiFade)``;

export const InputEndAdornment = styled(MuiInputAdornment)`
  &.MuiInputAdornment-outlined {
    margin-left: 0px !important;
    margin-right: 4px !important;
  }
`;

export const ClearButton = styled(_ClearButton)``;

export const Span = styled('span')`
  display: inline-box;
  width: 50px;
  text-align: center;
`;
