import { useEffect, useState, useCallback } from 'react';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import ModalSmsFreeDeposit from '~/components/modals/sms/ModalSmsFreeDeposit';
import { ClinicModel } from '../../model/Clinic/ClinicModel';
import { SmsDataTable } from './DataTable';
import Pagination from '../../app/Pagination';
import { useApp } from '~/hooks/useApp';
import { format } from 'date-fns';
import { Tooltip } from '~/components/commons/Tooltip';
import { color } from '~/theme';
import { FilterDateRange } from '~/components/commons/SearchFilterDateRange';
import PageNav from '~/components/commons/PageNav';
import { Box, TextField } from './SmsPointManagements.styled';

const SmsPointManagements = () => {
  const app = useApp();
  const defaultParams = app.$qb().limit(20).orderBy('id desc');
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [params, setParams] = useState<any>(defaultParams);
  const [searchValue, setSearchValue] = useState<any>({});
  const [startAt, setStartAt] = useState<Date | null>(null);
  const [endAt, setEndAt] = useState<Date | null>(null);
  const [completeStartAt, setCompleteStartAt] = useState<Date | null>(
    new Date(new Date().setMonth(new Date().getMonth(), 1))
  );
  const [completeEndAt, setCompleteEndAt] = useState<Date | null>(new Date());
  const [limit] = useState(params ? params.queryParams.limit : 10);
  const [currentIndex, setCurrentIndex] = useState(0);

  const callApi = useCallback(async () => {
    try {
      const response: any = await app.$smsRepo.sms.all(params.build());
      if (response?.data) {
        setData(response.data);
      }
      if (response?.pagination) {
        setTotal(response.pagination.total);
      }
    } catch (error) {
      console.error(error);
    }
  }, [params, app.$smsRepo.sms]);

  useEffect(() => {
    if (startAt) {
      params.customParam(
        'startDateAt',
        format(new Date(startAt), 'yyyy-MM-dd')
      );
    } else {
      params.deleteCustomParam('startDateAt');
    }
    if (endAt) {
      params.customParam('endDateAt', format(new Date(endAt), 'yyyy-MM-dd'));
    } else {
      params.deleteCustomParam('endDateAt');
    }
    if (completeStartAt) {
      params.customParam(
        'completeStartAt',
        format(new Date(completeStartAt), 'yyyy-MM-dd')
      );
    } else {
      params.deleteCustomParam('completeStartAt');
    }
    if (completeEndAt) {
      params.customParam(
        'completeEndAt',
        format(new Date(completeEndAt), 'yyyy-MM-dd')
      );
    } else {
      params.deleteCustomParam('completeEndAt');
    }
  }, [app, params, startAt, endAt, completeStartAt, completeEndAt]);

  const onClickSmsFreeDeposit = async () => {
    const clinicData = ClinicModel.create({});
    clinicData.setData(await app.$clinicRepo.clinic.namesAll());

    runInAction(() => {
      app.$modal.custom({
        component: ModalSmsFreeDeposit,
        options: {
          clinics: clinicData.clinicList,
          callApi,
        },
      });
    });
  };

  const onChangeSearch = (name: string, value: string) => {
    const searchParam = { ...searchValue, [name]: value };
    setSearchValue(searchParam);
  };

  const onClickSearch = () => {
    params.customParam('page', 1);
    setCurrentIndex(0);
    const editParams = {
      ...params,
      queryParams: {
        ...params.queryParams,
        ...searchValue,
      },
    };
    setParams(editParams);
    callApi();
  };

  const onChangeStartAt = (date: Date | null) => {
    setStartAt(date);
  };

  const onChangeEndAt = (date: Date | null) => {
    setEndAt(date);
  };

  const onChangeCompleteStartAt = (date: Date | null) => {
    setCompleteStartAt(date);
  };

  const onChangeCompleteEndAt = (date: Date | null) => {
    setCompleteEndAt(date);
  };

  const onPage = (idx: number) => {
    if (!params) return;
    if (idx >= 0) {
      setCurrentIndex(idx);
    }
    params.customParam('page', idx + 1);
    setParams(params);
    callApi();
  };

  return (
    <div className="main-contents">
      <PageNav pages={['SMS', `문자충전 관리 (${total})`]} />
      <div className="search-bar">
        <Box
          sx={{
            display: 'flex',
            background: 'white',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              border: `1px solid ${color.common.line}`,
              '& > *:not(:last-child)': {
                borderRight: `1px solid ${color.common.line}`,
              },
            }}
          >
            <Box width={380}>
              <FilterDateRange
                label="입금일"
                startAt={completeStartAt || undefined}
                endAt={completeEndAt || undefined}
                placeholder={'YYYY-MM-DD'}
                onChange={(start: string, end: string) => {
                  onChangeCompleteStartAt(start ? new Date(start) : null);
                  onChangeCompleteEndAt(end ? new Date(end) : null);
                }}
              />
            </Box>
            <Box width={380}>
              <FilterDateRange
                label="충전신청일"
                startAt={startAt || undefined}
                endAt={endAt || undefined}
                placeholder={'YYYY-MM-DD'}
                onChange={(start: string, end: string) => {
                  onChangeStartAt(start ? new Date(start) : null);
                  onChangeEndAt(end ? new Date(end) : null);
                }}
              />
            </Box>
            <Box width={250}>
              <TextField
                $type="search"
                value={searchValue?.['name'] || ''}
                startAdornment="병원명"
                placeholder="입력"
                onChange={(v: any) => {
                  onChangeSearch('name', v);
                }}
                onEnter={onClickSearch}
              />
            </Box>
          </Box>
        </Box>
        <div className="search-btns" style={{ marginLeft: '8px' }}>
          <button
            className="btn btn-black"
            onClick={onClickSearch}
            disabled={
              startAt === null &&
              endAt === null &&
              completeStartAt === null &&
              completeEndAt === null
            }
          >
            {app.$translate('FIND')}
          </button>
        </div>
      </div>
      <Tooltip title={app.$me.role === 'manager' ? '권한이 없습니다.' : ''}>
        <div className="content-title">
          <button
            className="btn btn-black btn-sm"
            onClick={onClickSmsFreeDeposit}
            disabled={app.$me.role === 'manager'}
          >
            문자 무료충전
          </button>
        </div>
      </Tooltip>
      <SmsDataTable items={data} callApi={callApi} />
      <div className="flex-row flex-between wrap-pagination">
        <Pagination
          total={total}
          limit={limit}
          currentIndex={currentIndex}
          onPage={onPage}
        />
      </div>
    </div>
  );
};

export default observer(SmsPointManagements);
