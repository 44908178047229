import {
  styled,
  Box as MuiBox,
  Button as MuiButton,
  Stack as MuiStack,
  TextField as MuiTextField,
} from '@mui/material';
import { color } from '~/theme';
import { ComponentType } from 'react';

export const TextField = styled(MuiTextField)`
  &.MuiOutlinedInput-root {
    &.Mui-focused {
      border: 0 none;
    }
  }
  fieldset {
    border: 0 none;
  }
  input {
    height: 52px;
    box-sizing: border-box;
    background: #f9fbff;
    font-size: 13px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    border: 0 none;

    &::placeholder {
      color: ${color.bluegrey['600']};
      opacity: 1;
    }
  }
` as ComponentType<any>;

export const Box = styled(MuiBox)(
  ({ theme }) => `
    &.login-page-wrapper {
      width: 100vw;
      height: 100vh;
      min-height: 670px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &.login-contents-wrapper {
      width: 400px;
      min-height: 350px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-grow: 1;

      .id-input {
        margin-top: 48px;
      }
    }

    &.login-page-footer {
      font-size: 12px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: ${color.bluegrey['600']};
      padding-top: 30px;
      min-height: 150px;
      box-sizing: border-box;
    }
`
);

export const Button = styled(MuiButton)`
  width: 100%;
  height: 54px;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  border-radius: 4px;
  box-shadow: none;
  &.black {
    background: #293142;
  }
`;

export const Stack = styled(MuiStack)`
  &.mt-48 {
    margin-top: 48px;
  }
`;
