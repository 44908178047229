import { useEffect, useCallback, useState } from 'react';
import { useApp } from '~/hooks/useApp';
import { MyInfoTable } from '~/components/routes/management/MyInfoTable';
import { PasswordChangeTable } from '~/components/routes/management/PasswordChangeTable';
import { useApi } from '~/components/providers/ApiProvider';
import { SubTitle, Contents } from './MyInfoPage.styled';
import PageNav from '~/components/commons/PageNav';

export const MyInfoPage = () => {
  const app = useApp();
  const { userApi } = useApi();
  const [user, setUser] = useState({
    id: null,
    email: null,
    name: null,
    status: null,
    role: null,
  });

  const callApi = useCallback(async () => {
    if (app.$me?.id) {
      const resp: any = await userApi.getUser(app.$me.id);
      setUser({ ...resp.data });
    }
  }, []);

  useEffect(() => {
    callApi();
  }, []);

  return (
    <div className="main-contents">
      <PageNav pages={['MANAGEMENT', `내 정보`]} />
      <SubTitle>계정정보</SubTitle>
      <Contents>
        <MyInfoTable user={user} />
        <PasswordChangeTable />
      </Contents>
    </div>
  );
};
