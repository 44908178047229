import { Box } from '@mui/material';
import { ReactNode } from 'react';

type PageProps = {
  children: ReactNode;
  sx?: any;
};

export const Page = ({ children, sx }: PageProps) => {
  return <Box sx={{ p: 2, ...sx }}>{children}</Box>;
};
