import { ComponentType } from 'react';
import styled, { css } from 'styled-components';
import _DateRangeInput from '~/components/commons/SearchFilterDateRangeInput';
import { InputAdornment } from '@mui/material';

export const FilterItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  ${({ focus, theme }: any) =>
    focus
      ? css`
          border: solid 1px ${theme.color.primary.unoblue} !important;
        `
      : ''}
` as ComponentType<any>;

export const LabelText = styled.div`
  font-size: 12px;
  min-width: 67px;
  padding-left: 8px;
  word-break: keep-all;
  line-height: 1;
  color: #2d2d2d;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const InputEndAdornment = styled(InputAdornment)`
  &.MuiInputAdornment-outlined {
    margin-left: 0px !important;
    margin-right: 4px !important;
  }
`;
