import { useState } from 'react';
import { Fade } from '@mui/material';
import { ClearButton } from '~/components/commons/ClearButton';
import {
  FilterItem,
  LabelText,
  Wrapper,
  InputEndAdornment,
  DropdownList,
} from './SearchFilterMonthRange.styled';
import update from 'immutability-helper';

type FilterMonthRangeProps = {
  year?: string | null;
  month?: string | null;
  label: string;
  onChange: (param1: any, param2: any) => void;
  useClearButton?: boolean;
  showMonthYearPicker?: boolean;
  dateFormat?: string;
  disableClearable: boolean;
  placeholder?:
    | {
        year: string;
        month: string;
      }
    | string;
};

const range = (start: number, end: number, unit: string) => {
  return Array(end - start + 1)
    .fill('')
    .map((_, idx) => start + idx)
    .map((v) => ({
      label: `${v}${unit}`,
      value: `${v}`.padStart(2, '0'),
    }));
};

const currentYear = new Date().getFullYear();
const yearsOptions = range(new Date().getFullYear() - 1, currentYear, '년');
const monthOptions = range(1, 12, '월');

export const FilterMonthRange = ({
  year,
  month,
  label,
  onChange,
  disableClearable,
  useClearButton = !disableClearable,
  placeholder,
  ...props
}: FilterMonthRangeProps) => {
  const [isFocused, setIsFocused] = useState({
    year: false,
    month: false,
  });

  const onChangeDateRange = (
    start: string | null | undefined,
    end: string | null | undefined
  ) => {
    onChange(start ? start : '', end ? end : '');
  };

  const onReset = () => {
    onChange(null, null);
  };

  return (
    <FilterItem focus={isFocused.year || isFocused.month} {...props}>
      <LabelText>{label}</LabelText>
      <Wrapper className="month-range-wrapper">
        <DropdownList
          value={yearsOptions.filter((o) => o?.value === year)}
          options={yearsOptions}
          disableClearable={disableClearable}
          onChange={(v: any) => {
            onChangeDateRange(v?.value, month);
          }}
          variant="search"
          getOptionLabel={(o: any) => {
            if (o[0]) {
              return o[0]?.label ? o[0].label : '';
            } else {
              return o ? (o?.label ? o.label : '') : '';
            }
          }}
          placeholder="YYYY"
          onFocus={() =>
            setIsFocused(
              update(isFocused, {
                ['year']: { $set: true },
              })
            )
          }
          onBlur={() =>
            setIsFocused(
              update(isFocused, {
                ['year']: { $set: false },
              })
            )
          }
        />
        <span>-</span>
        <DropdownList
          value={monthOptions.filter((o) => o?.value === month)}
          options={monthOptions}
          onChange={(v: any) => {
            onChangeDateRange(year, v?.value);
          }}
          variant="search"
          disableClearable={disableClearable}
          getOptionLabel={(o: any) => {
            if (o[0]) {
              return o[0]?.label ? o[0].label : '';
            } else {
              return o ? (o?.label ? o.label : '') : '';
            }
          }}
          placeholder="MM"
          onFocus={() =>
            setIsFocused(
              update(isFocused, {
                ['month']: { $set: true },
              })
            )
          }
          onBlur={() =>
            setIsFocused(
              update(isFocused, {
                ['month']: { $set: false },
              })
            )
          }
        />
        {useClearButton && (
          <Fade
            in={
              (isFocused.year || isFocused.month) &&
              (Boolean(year) || Boolean(month))
            }
          >
            <InputEndAdornment position="end">
              <ClearButton onClick={onReset} />
            </InputEndAdornment>
          </Fade>
        )}
      </Wrapper>
    </FilterItem>
  );
};
