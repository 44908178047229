import { useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import { useSnackbarContext } from '~/components/providers/SnackbarProvider';
import { useApp } from '~/hooks/useApp';
import { CentrexTable } from './CentrexTable';
import { CentrexRepo } from './CentrexRepo';
import Pagination from '~/components/app/Pagination';
import styled from 'styled-components';
import { Tooltip } from '~/components/commons/Tooltip';
import PageNav from '~/components/commons/PageNav';

const FlexRow = styled.div`
  display: flex;
  margin-bottom: 30px;
`;

const Select = styled.select`
  display: inline;
  width: 300px;
  margin-right: 8px;
`;

const Input = styled.input`
  width: 300px;
  margin-right: 8px;
  border: 1px solid #dee2ec;
`;
const defaultParams = {
  // limit: 30,
  // page: 1,
  orderBy: 'createdAt desc',
  paginate: false,
};

export const Centrex = observer(() => {
  const app = useApp();
  const snackbar = useSnackbarContext();
  const [data, setData] = useState<any>([]);
  const [input, setInput] = useState('');
  const [clinics, setClinics] = useState<any>([]);
  const [clinic, setClinic] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [params, setParams] = useState<any>(defaultParams);

  const repo = new CentrexRepo();

  const clinicCallApi = useCallback(async () => {
    const resp = await app.$clinicRepo.clinic.all();
    setClinics([
      ...resp.data
        .filter((v: any) => v.callManagement?.type === 1)
        .map((d: any) => ({ id: d.id, value: d.name })),
    ]);
  }, []);

  const callApi = useCallback(async () => {
    const resp = await repo.all({ params });
    if (!resp) return;
    setData(resp);
  }, [params]);

  useEffect(() => {
    clinicCallApi();
  }, []);

  const onPage = (idx: number) => {
    if (!params) return;

    if (idx >= 0) {
      setCurrentIndex(idx);
    }
    setParams({ ...params, page: idx + 1 });
  };

  useEffect(() => {
    if (!params.clinicId) {
      return;
    }

    callApi();
  }, [params]);

  const search = () => {
    if (!clinic) {
      snackbar.alert('대상 병원을 지정하세요');
      return;
    }

    setParams({
      ...params,
      clinicId: clinic,
    });
  };

  const validation = () => {
    if (!input) {
      snackbar.alert('발신번호를 입력해주세요.');
      return false;
    }
    if (!clinic) {
      snackbar.alert('병원을 선택해주세요');
      return false;
    }
    return true;
  };

  const createCallEvent = async () => {
    if (validation()) {
      try {
        await repo.create({
          clinicId: clinic,
          phoneNumber: input,
        });
        callApi();
      } catch (e: any) {
        snackbar.alert(e.description);
      }
    }
  };

  return (
    <div className="main-contents">
      <PageNav pages={['TEST', `센트릭스 테스트`]} />
      <div className="content-title-sub">
        실제 센트릭스 전화기의 call 생성, 수신, 끊기, 부재처리를 테스트 할 수
        있습니다. (crm 상태를 바꾸는건 아님)
      </div>
      <FlexRow>
        <Select onChange={(e) => setClinic(e.target.value)}>
          <option value={''}>병원을 선택하세요</option>
          {clinics.map((v: any) => (
            <option key={v.id} value={v.id}>
              {v.value}
            </option>
          ))}
        </Select>
        <button className="btn btn-info cursor-pointer" onClick={search}>
          조회
        </button>
      </FlexRow>
      <FlexRow>
        <Input
          placeholder="&nbsp;발신번호 입력(070-5555-4444)"
          value={input}
          onChange={(e) => setInput(e.target.value)}
        />
        <Tooltip title={app.$me.role === 'manager' ? '권한이 없습니다.' : ''}>
          <div>
            <button
              className="btn btn-black cursor-pointer"
              onClick={createCallEvent}
              disabled={app.$me.role === 'manager'}
            >
              call 이벤트 생성
            </button>
          </div>
        </Tooltip>
      </FlexRow>
      {data.data && <CentrexTable items={data.data} callApi={callApi} />}
      <div className="flex-row flex-between wrap-pagination">
        <Pagination
          total={data.pagination?.total}
          limit={params.limit}
          currentIndex={currentIndex}
          onPage={onPage}
        />
      </div>
    </div>
  );
});
