import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as Sentry from '@sentry/react';
import { icons } from './assets/icons';

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    environment: process.env.REACT_APP_SENTRY_ENV,
    ignoreErrors: ['Missing JWT in headers or json', 'Expired Access Token'],
  });
}

(React as any).icons = icons;

ReactDOM.render(<App />, document.getElementById('root'));
