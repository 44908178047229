import { Typography } from '@material-ui/core';
import { ReactNode } from 'react';

type PageTitleProps = {
  children: ReactNode;
};

export const PageTitle = ({ children }: PageTitleProps) => {
  return <Typography variant="h4">{children}</Typography>;
};
