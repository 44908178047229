import Login from '~/components/routes/Login/Login';
import Clinics from '~/components/routes/Clinic/Clinics';
import Clinics_Management from '~/components/routes/Clinics_Management/Clinics_Management';
import Clinics_Management_Detail from '~/components/routes/Clinics_Management_Detail/Clinics_Management_Detail';
import ClinicUse from '~/components/routes/ClinicUse/ClinicUse';
import Certifications from '~/components/routes/Certification/Certifications';
import SmsPointManagements from '~/components/routes/Sms/SmsPointManagements';
import ClinicDeactivate from '../routes/ClinicDeactivate';
import ClinicDetail from '~/components/routes/ClinicDetail';
import ClinicAdd from '~/components/routes/ClinicAdd';
import ChangePasswordPage from '~/components/routes/Login/ChangePasswordPage';

export default {
  Login,
  ChangePasswordPage,
  Clinics,
  Clinics_Management,
  Clinics_Management_Detail,
  ClinicDeactivate,
  Certifications,
  SmsPointManagements,
  ClinicUse,
  ClinicDetail,
  ClinicAdd,
};
