import { ApiResource } from './apiResource';
import { ApiDataResponse } from '../apiClient';
interface SigninData {
  data: {
    accessToken: string;
    refreshToken: string;
    user: any;
  };
}

interface UserData {
  duty: string | null;
  email: string;
  id: number;
  name: string;
  phoneNumber: string | null;
  position: string | null;
  role: string;
  status: string;
}

export class UserApi extends ApiResource {
  async signin(email: string, password: string) {
    return this.client.post<SigninData>(
      '/admin/users/signin',
      { email, password },
      {
        retry: false,
      }
    );
  }

  async confirmCertification(email: string, confirmNumber: string) {
    return this.client.post('/admin/users/otp/confirm', {
      email,
      otpCode: confirmNumber,
    });
  }

  async sendCertificationEmail(email: string) {
    return this.client.post('/admin/users/otp', { email });
  }

  async getUser(id: number) {
    return this.client.get<ApiDataResponse<UserData>>(`/admin/users/${id}`);
  }

  async getUsers(params: any) {
    return this.client.get('/admin/users', { ...params });
  }

  async createUser(email: string, name: string, role: string) {
    return this.client.post('/admin/users', { email, name, role });
  }

  async updateUser(id: number, name: string, role: string, status: string) {
    return this.client.put(`/admin/users/${id}`, { name, role, status });
  }

  async getUsersLog(params: any) {
    return this.client.get('/admin/users/log', { ...params });
  }

  async changePassword(password: string) {
    return this.client.put(`/admin/users/password`, {
      password,
    });
  }

  async minePassword(oldPassword: string, newPassword: string) {
    return this.client.post(`/admin/users/mine`, {
      newPassword,
      oldPassword,
    });
  }

  async userTempPassword(userId: number) {
    return this.client.post('/admin/users/temp/password', {
      userId,
    });
  }
}
