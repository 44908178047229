import { isAfter, isBefore } from 'date-fns';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import _FilterDateInput from './SearchFilterDateInput';
import { ComponentType } from 'react';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 2px;
`;

const FilterDateInput = _FilterDateInput as ComponentType<any>;

type DateRangeInputProps = {
  value: {
    start: Date | string;
    end: Date | string;
  };
  onChange: (start: Date | string, end: Date | string) => void;
  onSelect: (start: Date | string, end: Date | string) => void;
  placeholder:
    | {
        start: Date | string;
        end: Date | string;
      }
    | string;
  focus: {
    startAt: boolean;
    endAt: boolean;
  };
  onFocus: (params?: any) => void;
  onBlur: (params?: any) => void;
};

const DateRangeInput = ({
  value,
  onChange,
  onSelect,
  placeholder,
  focus,
  onFocus,
  onBlur,
  ...props
}: DateRangeInputProps) => {
  const onChangeDate = (start: Date | string, end: Date | string) => {
    onChange(start, end);
  };

  const onSelectDate = (start: Date | string, end: Date | string) => {
    onSelect && onSelect(start, end);
  };

  return (
    <Wrapper>
      <FilterDateInput
        focus={focus.startAt}
        onFocus={() => onFocus('startAt')}
        onBlur={() => onBlur('startAt')}
        value={value?.start}
        onChange={(start: string | Date) => {
          onBlur('startAt');
          onChangeDate(
            start,
            value.end && isAfter(new Date(start), new Date(value.end))
              ? start
              : value.end
          );
        }}
        onSelect={(start: string | Date) =>
          onSelectDate(
            start,
            value.end && isAfter(new Date(start), new Date(value.end))
              ? start
              : value.end
          )
        }
        placeholder={
          typeof placeholder === 'string'
            ? placeholder
            : placeholder?.start || ''
        }
        {...props}
      />
      -
      <FilterDateInput
        focus={focus.endAt}
        onFocus={() => onFocus('endAt')}
        onBlur={() => onBlur('endAt')}
        value={value?.end}
        onChange={(end: string | Date) => {
          onBlur('endAt');
          onChangeDate(
            value.start && isBefore(new Date(end), new Date(value.start))
              ? end
              : value.start,
            end
          );
        }}
        onSelect={(end: string | Date) =>
          onSelectDate(
            value.start && isBefore(new Date(end), new Date(value.start))
              ? end
              : value.start,
            end
          )
        }
        placeholder={
          typeof placeholder === 'string' ? placeholder : placeholder?.end || ''
        }
        {...props}
      />
    </Wrapper>
  );
};

export default observer(DateRangeInput);
