import { observer } from 'mobx-react';
import { Box } from '@mui/material';
import { format } from 'date-fns';
import { getRoleName } from '~/pages/management/management';
import {
  Info,
  Body,
  Text,
  BlueText,
  Contents,
} from './CreateUserConfirmModal.styled';

type CreateUserConfirmModalProps = {
  options: any;
  close: () => void;
};

const CreateUserConfirmModal = ({
  options,
  close,
}: CreateUserConfirmModalProps) => {
  return (
    <div>
      <div className="head flex-row flex-between items-start">
        <div className="title">계정 정보 확인</div>
        <i onClick={() => close()} className="zmdi zmdi-close" />
      </div>
      <Body className="body">
        <Text>계정 등록이 완료되었습니다.</Text>
        <Text>임시 비밀번호를 담당자에게 전달해주세요.</Text>
        <br />
        <Text>
          ∙ 임시 비밀번호는 <BlueText>발송 후 24시간 동안만 유효</BlueText>
          합니다.
        </Text>
        <Text>
          ∙ 임시 비밀번호로 로그인한 후에는 반드시 새로운 비밀번호로 변경해야
          합니다.
        </Text>
        <Info>
          <Box sx={{ display: 'flex', width: '100%' }}>
            <Box sx={{ width: '100px' }}>아이디(이메일)</Box>
            <Contents>{options.email}</Contents>
          </Box>
          <Box sx={{ display: 'flex', width: '100%' }}>
            <Box sx={{ width: '100px' }}>보안등급</Box>
            <Contents>{getRoleName(options.role)}</Contents>
          </Box>
          <Box sx={{ display: 'flex', width: '100%' }}>
            <Box sx={{ width: '100px' }}>담당자 이름</Box>
            <Contents>{options.name}</Contents>
          </Box>
          <Box sx={{ display: 'flex', width: '100%' }}>
            <Box sx={{ width: '100px' }}>임시 비밀번호</Box>
            <Contents>{options.password}</Contents>
          </Box>
          <Box sx={{ display: 'flex', width: '100%' }}>
            <Box sx={{ width: '100px' }}> 발급 시간</Box>
            <Contents>
              {format(options.tempPasswordCreatedAt, 'yyyy년 MM월 dd일 HH:mm')}
            </Contents>
          </Box>
        </Info>
      </Body>

      <div className="buttons">
        <div className="flex-row">
          <button onClick={() => close()} className="btn btn-primary">
            확인
          </button>
        </div>
      </div>
    </div>
  );
};

export default observer(CreateUserConfirmModal);
