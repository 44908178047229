import { ReactNode } from 'react';
import { StyledButton } from './Button.styled';

type ButtonProps = {
  size?: 'l' | 'm' | 's' | 'xs' | 'xxs';
  styled?: 'fill' | 'outline' | 'text';
  color?:
    | 'primary'
    | 'secondary'
    | 'alert'
    | 'mix'
    | 'grey'
    | 'green'
    | 'black'
    | 'deepGrey';
  onClick?: (params?: any) => void;
  disabled?: boolean;
  children: ReactNode;
  icon?: ReactNode;
  style?: any;
  className?: string;
  value?: any;
  sx?: any;
};

export const Button = ({
  size = 'm',
  styled = 'fill',
  color = 'primary',
  onClick,
  disabled,
  children,
  icon,
  className,
  ...rest
}: ButtonProps) => {
  return (
    <StyledButton
      className={className}
      size={size}
      $color={color}
      styled={styled}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      {icon && icon}
      {children}
    </StyledButton>
  );
};

export default Button;
