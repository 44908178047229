import { observable } from 'mobx';
import json from '~/assets/translations';

let locale = 'kr';

const Translation = observable({
  loadStoredLocale() {
    const supportedLocales = ['kr', 'en'];
    const storedLocale = window.localStorage.getItem('locale') || '';
    if (supportedLocales.indexOf(storedLocale) !== -1) {
      locale = storedLocale;
    } else {
      window.localStorage.setItem('locale', locale);
    }
  },
  locale: locale,
  texts: json,
  toggleLocale: () => {
    let next = locale === 'kr' ? 'en' : 'kr';
    locale = next;
    window.localStorage.setItem('locale', next);
  },
});

export default Translation;
