import { useState, useCallback } from 'react';
import {
  TextField as Input,
  Li,
  IconWrapper,
  SelectLabel,
  Total,
  AutoCompleteSelect,
  Popper,
} from './ComboBox.styled';
import { createFilterOptions } from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { Chip } from '~/components/commons/Chip';
import { NoOptionsText } from '~/components/commons/NoOptionsText';
import { Checkbox } from '~/components/commons/Checkbox';
import Icon from './Icon';

const SelectLabelText = '직접 검색 또는 선택해주세요.';

type ComboBoxProps = {
  variant?: string;
  options: any;
  onChange: (p: any) => void;
  value: any;
  placeholder?: string;
  size?: string;
  noOptionsText?: string;
  style?: any;
  startAdornment?: any;
  multiple?: boolean;
  unlimit?: boolean;
  limit?: number;
  selectAll?: boolean;
  disabled?: boolean;
  disableCloseOnSelect?: boolean;
  disableClearable?: boolean;
  getOptionLabel?: (option: any) => void;
};

export const ComboBox = ({
  variant = 'default',
  options = [],
  onChange,
  value = [],
  placeholder = '',
  size = 'large',
  noOptionsText = '',
  style,
  limit,
  startAdornment,
  multiple = false,
  unlimit = false,
  selectAll = false,
  disabled = false,
  disableCloseOnSelect = true,
  disableClearable,
  ...props
}: ComboBoxProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const getChipSize = () => {
    switch (size) {
      case 'medium':
        return isOpen ? '52px' : '38px';
      case 'large':
      default:
        return isOpen ? '104px' : '76px';
    }
  };

  const [selectedOptions, setSelectedOptions] = useState([...value]);
  const allSelected = options.length === selectedOptions.length;
  const filter = createFilterOptions();
  const handleSelectAll = (isSelected: boolean) => {
    if (isSelected) {
      setSelectedOptions(options);
    } else {
      handleClearOptions();
    }
  };
  const handleToggleOption = (selectedOptions: any) => {
    setSelectedOptions(selectedOptions);
  };
  const handleClearOptions = () => setSelectedOptions([]);
  const handleToggleSelectAll = () => {
    handleSelectAll(!allSelected);
  };

  const handleClickChip = () => {
    if (!disabled) {
      setIsOpen(true);
    }
  };

  return (
    <AutoCompleteSelect
      multiple={multiple}
      variant={variant}
      $active={isOpen}
      open={isOpen}
      options={options}
      onChange={(event: any, v: any, reason: any) => {
        multiple === false && setIsOpen(false);
        if (selectAll) {
          if (reason === 'selectOption' || reason === 'removeOption') {
            if (v.find((option: any) => option.value === 'select-all')) {
              handleToggleSelectAll();
              let result = [];
              if (!allSelected) {
                result = options.filter((o: any) => o.value !== 'select-all');
              }
              return onChange(result);
            } else {
              handleToggleOption(v);
              return onChange(v);
            }
          } else if (reason === 'clear') {
            handleClearOptions();
            onChange(v);
          }
        } else {
          onChange(v);
        }
      }}
      value={value}
      disableCloseOnSelect={disableCloseOnSelect}
      selectOnFocus={true}
      disabled={disabled}
      noOptionsText={
        noOptionsText ? (
          <NoOptionsText value={noOptionsText} />
        ) : (
          <>
            <SelectLabel>{SelectLabelText}</SelectLabel>
            <NoOptionsText />
          </>
        )
      }
      PopperComponent={Popper}
      onOpen={() => {
        setIsOpen(true);
      }}
      onClose={() => {
        setIsOpen(false);
      }}
      renderOption={(props: any, option: any) => {
        const selectAllProps =
          option.value === 'select-all' // To control the state of 'select-all' checkbox
            ? { checked: allSelected }
            : {};
        return (
          <div key={props.key}>
            {props['data-option-index'] === 0 && (
              <div style={{ marginLeft: '10px' }}>
                <SelectLabel>{SelectLabelText}</SelectLabel>
              </div>
            )}
            <Li {...props}>
              <Checkbox
                checked={
                  value.find((f: any) => f.id === option.id) != undefined
                    ? true
                    : false
                }
                {...selectAllProps}
              />
              {option.label}
            </Li>
          </div>
        );
      }}
      filterOptions={(options: any, params: any) => {
        const filtered = filter(options, params);
        if (selectAll && options.length > 0) {
          return [{ label: '전체선택', value: 'select-all' }, ...filtered];
        } else {
          return [...filtered];
        }
      }}
      renderTags={(tags: any) => {
        if (unlimit) {
          const items = [tags[0]];
          return (
            <div
              style={
                startAdornment
                  ? { width: 'calc(100% - 64px)' }
                  : { width: '100%' }
              }
              onClick={handleClickChip}
            >
              {items.map((option, index) => (
                <Chip
                  key={index}
                  style={{
                    maxWidth: getChipSize,
                  }}
                  onClick={handleClickChip}
                  showClosedButton={false}
                  value={option.label}
                />
              ))}
              {tags.length > 1 && <Total>+{tags.length - 1}</Total>}
            </div>
          );
        } else {
          return tags.map((option: any, index: number) => (
            <Chip
              key={index}
              style={{
                maxWidth: getChipSize,
              }}
              onClick={handleClickChip}
              showClosedButton={isOpen}
              value={option.label}
              onDelete={() => {
                onChange(value.filter((f: any) => f.id !== option.id));
              }}
            />
          ));
        }
      }}
      isOptionEqualToValue={useCallback((option, value) => {
        if (
          (Array.isArray(value) && value.length === 0) ||
          (typeof value === 'string' && value === '')
        ) {
          return true;
        }
        return (
          option.value === value.value || option.value === value?.[0]?.value
        );
      }, [])}
      renderInput={(params: any) => {
        if (multiple && !unlimit && limit) {
          return (
            <Input
              {...params}
              $isopen={isOpen}
              placeholder={value.length === 0 ? placeholder : ''}
              inputProps={{
                ...params.inputProps,
                readOnly: limit > 0 && value.length >= limit,
              }}
            />
          );
        }
        return (
          <Input
            style={{
              background: 'white',
              ...style,
            }}
            startadornment={startAdornment && value.length}
            $isopen={isOpen}
            {...params}
            placeholder={value.length === 0 ? placeholder : ''}
            inputProps={{
              ...params.inputProps,
              readOnly: disabled,
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  {startAdornment && startAdornment}
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
          />
        );
      }}
      disableClearable={
        disableClearable !== undefined
          ? disableClearable
            ? !isOpen
            : true
          : value.length > 0
          ? !isOpen
          : true
      }
      clearIcon={<Icon variant="close" width={10} height={10} />}
      popupIcon={
        <IconWrapper>
          <KeyboardArrowDownRoundedIcon />
        </IconWrapper>
      }
      {...props}
    />
  );
};
