import React, { useEffect, useState } from 'react';
import { numArray } from '~/filters';

type PaginationProps = {
  limit: number;
  total: number;
  currentIndex: number;
  onPage: (value: any) => void;
};

const Pagination = ({
  limit,
  total,
  currentIndex,
  onPage,
}: PaginationProps) => {
  let initPageArr = () => numArray(Math.ceil(total / limit));
  const [pageArr, setPageArr] = useState([]);
  const [pageArrIndex, setPageArrIndex] = useState(0);
  const [pages, setPages] = useState([]);

  useEffect(() => {
    pageUpdate();
  }, [total, limit, currentIndex]);

  const pageUpdate = () => {
    if (!initPageArr()) return;

    let arr = initPageArr(); // 전체 페이지네이션  [1,2,3,4,5,6,7]
    let result: any = []; // 임시 페이지 배열 [[1,2,3],[4,5,6],[7]]
    let index = Math.floor(currentIndex / 5); // 현재 선택된 페이지가 있는 페이지 배열의 인덱스
    arr.forEach((x, y, z) => (!(y % 5) ? result.push(z.slice(y, y + 5)) : '')); // 5페이지씩

    setPageArr(result);
    setPageArrIndex(index);
    setPages(result[index]);
  };

  const prevPages = () => {
    if (pageArrIndex == 0) return;
    onPage(pageArr[pageArrIndex - 1][0]);
  };

  const nextPages = () => {
    if (pageArrIndex == pageArr.length - 1) return;
    onPage(pageArr[pageArrIndex + 1][0]);
  };

  if (!pages) return false;
  return (
    <div className="pagination">
      <div className="flex-row items-center">
        <span
          className={`page-icon page-prev ${
            pageArrIndex == 0 ? 'disabled' : ''
          }`}
          onClick={() => prevPages()}
        />
        {pages &&
          pages.map((page) => (
            <span
              onClick={() => onPage(page)}
              key={page}
              className={`page-icon number flex-wrap ${
                currentIndex === page ? 'selected' : ''
              }`}
            >
              {page + 1}
            </span>
          ))}
        <span
          className={`page-icon page-next ${
            pageArrIndex == pageArr.length - 1 ? 'disabled' : ''
          }`}
          onClick={() => nextPages()}
        />
      </div>
    </div>
  );
};

export default React.memo<any>(Pagination);
