import $http from 'axios';

export const SmsRepo = {
  sms: {
    all: (params: any) => $http.get('/balances', { params }),
    create: (params: any) => {
      $http.post('/balances', { ...params });
    },
    update: (clinicId: number, params: any) =>
      $http.put(`/balances/tax-bill/${clinicId}`, params),
    refund: (balanceId: number, memo: any) =>
      $http.put(`/balances/refund/${balanceId}`, {
        clinicMemo: memo,
      }),
  },
};
