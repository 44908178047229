import { useEffect, useMemo, useState } from 'react';
import ModalCtiNumber from './ModalCtiNumber';
import { useApi } from '~/components/providers/ApiProvider';
import { Box, Typography, Stack, Button } from './ClinicDetail.styled';
import { useApp } from '~/hooks/useApp';
import { useSnackbarContext } from '~/components/providers/SnackbarProvider';

const DataRow = (props: {
  item: { number: string; visible: boolean };
  index: number;
  className?: string;
}) => {
  const { item, index, className } = props;
  return (
    <Stack
      className={`${className ? className : ''}`}
      flexDirection={'row'}
      key={index}
    >
      <Box className="label">발신번호</Box>
      <Box className="value">{item.number || ''}</Box>
      <Box className="label">상태</Box>
      <Box className="value">{item.visible ? '사용' : '미사용'}</Box>
    </Stack>
  );
};

const CtiSenderSection = ({ clinicId }: { clinicId: number }) => {
  const [showCtiNumberModal, setShowCtiNumberModal] = useState<any>(false);
  const app = useApp();
  const [data, setData] = useState<{ data: any[] }>();
  const { clinicsApi } = useApi();
  const snackbar = useSnackbarContext();

  useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    try {
      const data: any = await clinicsApi.getCallerList(clinicId);
      setData(data);
    } catch (error) {
      snackbar.alert('서버에서 발신번호 리스트를 불러오는데 실패하였습니다. ');
      console.log('error: ', error);
    }
  };

  const list = useMemo(() => {
    return data?.data || [];
  }, [data]);

  const disabledList = useMemo(() => {
    return list.filter((item: { visible: number }) => item.visible);
  }, [list]);
  const editableList = useMemo(() => {
    return list.filter((item: { visible: number }) => !item.visible);
  }, [list]);

  return (
    <>
      <Stack flexDirection={'row'}>
        <Typography variant="h2">발신번호</Typography>
        {app.$me.role !== 'manager' && (
          <Button
            variant="contained"
            className="edit-mode-btn mt-15"
            onClick={() => {
              setShowCtiNumberModal(true);
            }}
          >
            등록/수정
          </Button>
        )}
      </Stack>
      <Stack flexDirection={'column'} className="mt-10 border">
        {list.length > 0 ? (
          <>
            {disabledList.map((item: any, index: number) => {
              return <DataRow item={item} index={index} key={index} />;
            })}
            {editableList.map((item: any, index: number) => {
              return (
                <DataRow
                  item={item}
                  index={index}
                  key={index}
                  className="disabled"
                />
              );
            })}
          </>
        ) : (
          <Box className="no-data">등록된 발신번호가 없습니다.</Box>
        )}
      </Stack>
      {app.$me.role !== 'manager' && showCtiNumberModal !== false && (
        <ModalCtiNumber
          clinicId={clinicId}
          disabledList={disabledList}
          editableList={editableList.concat([{ number: '' }])}
          title={'발신번호 등록/수정'}
          onClose={(flag: boolean) => {
            if (flag) {
              getList();
            }
            setShowCtiNumberModal(false);
          }}
        />
      )}
    </>
  );
};

export default CtiSenderSection;
