import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { ApiClient } from '~/api/apiClient';
import { useApi } from './ApiProvider';
import { observer } from 'mobx-react';
import { useAuthStorage } from './AuthStorageProvider';
import { useAuth as useAuthOld } from '~/hooks/useAuth';
import { useApp } from '~/hooks/useApp';

interface Auth {
  authed: boolean;
  unmatched: boolean;
  signin: (email: string, password: string) => Promise<any>;
  signinLegacy: (data: any) => void;
  signout: () => void;
  error: string | null;
}

export const AuthContext = createContext<Auth | null>(null);

export const AuthError = {
  ACCESS_DENIED: 'ACCESS_DENIED',
  UNAUTHORIZED: 'UNAUTHORIZED',
};

export const AuthProvider = observer(
  ({ children }: { children: ReactNode }) => {
    const { refreshToken, unmatched, set, clear } = useAuthStorage();
    const app: any = useApp();
    const api = useApi();
    const [error, setError] = useState<string | null>(null);
    const auth = useAuthOld();
    const { userApi } = api;

    const signin = useCallback(
      (email: any, confirmNumber: any) => {
        return new Promise((resolve, reject) => {
          userApi
            .confirmCertification(email, confirmNumber)
            .then((response: any) => {
              const { accessToken, refreshToken, user } = response.data;
              set({ accessToken, refreshToken });
              setError(null);
              auth.signInWithData({ accessToken, refreshToken, user });
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      [userApi, set, auth]
    );

    const signinLegacy = useCallback(
      (data: any) => {
        const { accessToken, refreshToken, user } = data;
        set({ accessToken, refreshToken });
        auth.signInWithData({ accessToken, refreshToken, user });
        app.$router.replace('/clinics');
      },
      [userApi, set, auth]
    );

    const signout = useCallback(() => {
      clear();
      auth.signOut();
    }, [clear]);

    useEffect(() => {
      return api.on(
        ApiClient.Event.REFRESH_TOKEN,
        (tokens: { accessToken: string; refreshToken: string }) => {
          set(tokens);
        }
      );
    }, [api, set]);

    useEffect(() => {
      return api.on(ApiClient.Event.ACCESS_DENIED, () => {
        setError(AuthError.ACCESS_DENIED);
      });
    }, [api, clear]);

    useEffect(() => {
      return api.on(ApiClient.Event.UNAUTHORIZED, () => {
        setError(AuthError.UNAUTHORIZED);
      });
    }, [api, clear]);

    useEffect(() => {
      if (
        app.$me.passwordExpiration === true ||
        app.$me.passwordChanged === false
      ) {
        app.$router.replace('/change-password', {
          passwordExpired: app.$me.passwordExpiration,
        });
      }
    }, [app.$me, app.$router]);

    const loadUser = async () => {
      if (app.$me.id) {
        const resp = await userApi.getUser(app.$me.id);
        auth.loadMe({
          ...app.$me,
          ...resp.data,
        });
      }
    };

    useEffect(() => {
      loadUser();
    }, []);

    return (
      <AuthContext.Provider
        value={{
          authed: Boolean(refreshToken),
          unmatched,
          signin,
          signout,
          signinLegacy,
          error,
        }}
      >
        {children}
      </AuthContext.Provider>
    );
  }
);

export function useAuth() {
  return useContext(AuthContext) as Auth;
}
