import { observer } from 'mobx-react';
import ReactLoading from 'react-loading';

type ModalLoaingProps = {
  options: any;
};

const ModalLoaing = ({ options }: ModalLoaingProps) => {
  return (
    <div
      style={{
        position: 'fixed',
        zIndex: '9999',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'transparent',
      }}
    >
      <h2>{options.message}</h2>
      <ReactLoading
        type={options.barType}
        color={options.color}
        height={'80%'}
        width={'80%'}
      />
    </div>
  );
};

export default observer(ModalLoaing);
