import { useState } from 'react';
import { observer } from 'mobx-react';
import { runInAction } from 'mobx';
import { useSnackbarContext } from '~/components/providers/SnackbarProvider';
import { useApp } from '~/hooks/useApp';
import { format } from 'date-fns';
import { Tooltip } from '~/components/commons/Tooltip';

type ModalEditClinicMemoProps = {
  options: any;
  close: () => void;
};

const ModalEditClinicMemo = ({ options, close }: ModalEditClinicMemoProps) => {
  const app = useApp();
  const snackbar = useSnackbarContext();
  const memo = app.$mustParse(options.clinic.memo);
  const [currentMemo, setCurrentMemo] = useState(
    (memo && memo[options.memoIndex]) || {
      content: '',
      timestamp: format(new Date(), 'yy-MM-dd'),
    }
  );

  const onConfirmSave = async () => {
    try {
      // CS MEMO 변경시 memo 항목만 있어도 update 가능.
      // 오히려 다른 클리닉 정보에 null 값이 있을 경우 update되지 않아 예외처리를 하게 될 경우가 있다.
      // clinicId, memo 항목만 body parameter로 넘겨 update api를 call
      const option = {
        id: options.clinic.id,
        memo: options.clinic.memo,
      };
      await app.$clinicRepo.memo.update(option);
      options.callApi();
      snackbar.success('UPDATED');
      close();
    } catch (e: any) {
      snackbar.alert(e.description);
    }
  };

  const onClickSave = () => {
    if (!currentMemo.timestamp)
      currentMemo.timestamp = format(new Date(), 'yy-MM-dd');

    if (options.memoIndex >= 0) {
      memo[options.memoIndex] = currentMemo;
      runInAction(() => {
        options.clinic.memo = memo;
      });
    } else {
      options.clinic.memo = (memo || []).concat([currentMemo]);
    }
    runInAction(() => {
      options.clinic.memo = JSON.stringify(options.clinic.memo);
    });
    // //bizStatus, bizItem not null check
    // if(options.clinic.bizStatus === null) {
    //   options.clinic.bizStatus = ''
    // }
    // if(options.clinic.bizItem === null) {
    //   options.clinic.bizItem = ''
    // }
    onConfirmSave();
  };

  const onClickDelete = () => {
    memo.splice(options.memoIndex, 1);
    options.clinic.memo = JSON.stringify(memo);
    onConfirmSave();
  };

  return (
    <div className="modal-edit-clinic-memo">
      <div className="head flex-row flex-between items-start">
        <div className="title">CS {app.$translate('MEMO')}</div>
        <i onClick={() => close()} className="zmdi zmdi-close" />
      </div>
      <div className="body">
        <textarea
          placeholder={app.$translate('MEMO_PLACEHOLDER')}
          value={currentMemo.content}
          onChange={(e) => {
            currentMemo.content = e.target.value;
            setCurrentMemo({ ...currentMemo });
          }}
        />
      </div>
      <div className="buttons">
        <div className="flex-row">
          <button onClick={() => close()} className="btn btn-default">
            {app.$translate('CANCEL')}
          </button>
          {options.memoIndex >= 0 ? (
            <Tooltip
              title={app.$me.role === 'manager' ? '권한이 없습니다.' : ''}
            >
              <button
                onClick={() => onClickDelete()}
                className="btn btn-danger"
                disabled={app.$me.role === 'manager'}
              >
                {app.$translate('DELETE')}
              </button>
            </Tooltip>
          ) : null}
          <Tooltip title={app.$me.role === 'manager' ? '권한이 없습니다.' : ''}>
            <button
              disabled={!currentMemo.content || app.$me.role === 'manager'}
              onClick={() => onClickSave()}
              className="btn btn-primary"
            >
              {app.$translate('SAVE')}
            </button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default observer(ModalEditClinicMemo);
