import { getRoleName, getStatusName } from '~/pages/management/management';
import { Content, Container } from './DetailManagementTable.styled';
import { Item, ItemWide, ItemTitle } from './DetailManagementTable.styled';

type MyInfoTableProps = {
  user: any;
};

export const MyInfoTable = ({ user }: MyInfoTableProps) => {
  return (
    <Content>
      <Container>
        <ItemTitle disabled>아이디(이메일)</ItemTitle>
        <ItemWide disabled className="label">
          {user.email}
        </ItemWide>
      </Container>
      <Container>
        <ItemTitle disabled>관리자 번호</ItemTitle>
        <Item disabled className="label">
          {user.id}
        </Item>
        <ItemTitle disabled>담당자 이름</ItemTitle>
        <Item disabled className="label">
          {user.name}
        </Item>
      </Container>
      <Container>
        <ItemTitle disabled>보안등급</ItemTitle>
        <Item disabled className="label">
          {getRoleName(user.role)}
        </Item>
        <ItemTitle disabled>사용여부</ItemTitle>
        <Item disabled className="label">
          {getStatusName(user.status)}
        </Item>
      </Container>
    </Content>
  );
};
