import { useState } from 'react';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

import {
  AutoCompleteSelect,
  SelectLabel,
  Popper,
  Li,
  Input,
  IconWrapper,
  NoOptionsText,
  ClearButton,
} from './DropdownList_v1.styled';

const SelectLabelText = '직접 검색 또는 선택해주세요.';

export const DropdownList = ({
  options = [],
  onChange,
  value = null,
  placeholder = '',
  getOptionLabel,
  useClearIcon,
  disableClearable,
  disabled,
  style,
  startAdornment,
  ...props
}: any) => {
  const [isClose, setIsClose] = useState(true);
  return (
    <AutoCompleteSelect
      style={{
        background: 'white',
      }}
      options={options}
      onChange={(event, value) => {
        onChange(value);
      }}
      value={value}
      getOptionLabel={getOptionLabel}
      noOptionsText={
        <>
          <SelectLabel>{SelectLabelText}</SelectLabel>
          <NoOptionsText />
        </>
      }
      PopperComponent={Popper}
      onOpen={() => {
        setIsClose(false);
      }}
      $active={!isClose}
      onClose={() => {
        setIsClose(true);
      }}
      isOptionEqualToValue={(o: any, v: any) => {
        if (Array.isArray(v) && v.length === 0) {
          return true;
        }
        if (o[0]) {
          return o[0]?.label ? o[0].label : '';
        } else {
          return o ? (o?.label ? o.label : '') : '';
        }
      }}
      renderOption={(props: any, option: any) => {
        return (
          <div key={props.key}>
            {props['data-option-index'] === 0 && (
              <SelectLabel>{SelectLabelText}</SelectLabel>
            )}
            <Li
              {...props}
              className={`${value[0] === option ? 'selected' : ''}`}
            >
              <div>{option.label}</div>
            </Li>
          </div>
        );
      }}
      renderInput={(params) => {
        return (
          <Input
            style={{
              background: 'white',
              ...style,
            }}
            startadornment={startAdornment && value.length}
            $isopen={!isClose}
            {...params}
            placeholder={value.length === 0 ? placeholder : ''}
            inputProps={{
              ...params.inputProps,
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  {startAdornment && startAdornment}
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
          />
        );
      }}
      disableClearable={
        disableClearable ? disableClearable : value.length > 0 ? isClose : true
      }
      clearIcon={<ClearButton />}
      disabled={disabled}
      popupIcon={
        <IconWrapper>
          <KeyboardArrowDownRoundedIcon />
        </IconWrapper>
      }
      {...props}
    />
  );
};
