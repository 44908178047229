import { Typography } from '@material-ui/core';
import { Box } from '@mui/material';
import { ReactNode } from 'react';

type FormItemProps = {
  title: string;
  children: ReactNode;
};

export const FormItem = ({ title, children }: FormItemProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      <Box
        sx={{
          width: 120,
        }}
      >
        <Typography>{title}</Typography>
      </Box>
      <Box sx={{ flexGrow: 1 }}>{children}</Box>
    </Box>
  );
};
