import $http from 'axios';

export class PriceTableRepo {
  all() {
    return $http.get('/price_tables');
  }

  create(data: any) {
    return $http.post('/price_tables', data);
  }

  update(id: number, data: any) {
    return $http.put(`/price_table/${id}`, data);
  }

  delete(id: number) {
    return $http.delete(`/price_table/${id}`);
  }
}
