import { ComponentType } from 'react';
import styled, { css } from 'styled-components';
import _DateRangeInput from '~/components/commons/SearchFilterDateRangeInput';
import { DropdownList as _DropdownList } from './DropdownList_v1';
import { InputAdornment } from '@mui/material';

export const DropdownList = styled(_DropdownList)(
  () => `
    height: 32px;
  .MuiOutlinedInput-notchedOutline {
    border: 0 none !important;
  }
  .MuiInputBase-root{
    position: relative;
    top: 2px;
    height: 30px;
  }
`
);

export const FilterItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 34px;

  ${({ focus, theme }: any) =>
    focus
      ? css`
          border: solid 1px ${theme.color.primary.unoblue} !important;
        `
      : ''}
` as ComponentType<any>;

export const LabelText = styled.div`
  font-size: 12px;
  min-width: 64px;
  padding-left: 8px;
  word-break: keep-all;
  line-height: 1;
  color: #2d2d2d;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  span {
    display: inline-block;
    padding: 0 8px;
  }
`;

export const InputEndAdornment = styled(InputAdornment)`
  &.MuiInputAdornment-outlined {
    margin-left: 0px !important;
    margin-right: 4px !important;
  }
`;
