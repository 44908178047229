import { translate } from '~/filters/index';

const _nav = [
  {
    _tag: 'CSidebarNavTitle',
    _children: [`CLIENT`],
  },
  {
    _tag: 'CSidebarNavItem',
    name: `거래처 계정관리`,
    to: '/clinics',
  },
  {
    _tag: 'CSidebarNavItem',
    name: `거래처 사용현황`,
    to: '/clinic_use',
  },
  {
    _tag: 'CSidebarNavItem',
    name: `거래처 기초정보 관리`,
    to: '/clinics_management',
  },
  {
    _tag: 'CSidebarNavItem',
    name: `거래처 해지현황`,
    to: '/clinic_deactivate_reason',
  },
  {
    _tag: 'CSidebarNavItem',
    name: `${translate('CERTIFICATIONS', 'kr')}`,
    to: '/certifications',
  },
  {
    _tag: 'CSidebarNavTitle',
    _children: ['SMS'],
  },
  {
    _tag: 'CSidebarNavItem',
    name: `${translate('SMS_POINT_MANAGEMENTS', 'kr')}`,
    to: '/sms-point-managements',
  },
  {
    _tag: 'CSidebarNavTitle',
    _children: ['NOTICE'],
  },
  {
    _tag: 'CSidebarNavItem',
    name: `배너 관리`,
    to: '/popups',
  },
  {
    _tag: 'CSidebarNavTitle',
    _children: ['GENERAL'],
  },
  {
    _tag: 'CSidebarNavItem',
    name: `요금`,
    to: '/price_tables',

    permission: 'price-tables',
  },
  {
    _tag: 'CSidebarNavItem',
    name: `샘플파일`,
    to: '/file_samples',
  },
  {
    _tag: 'CSidebarNavItem',
    name: `Credentials`,
    to: '/credentials',
  },
  {
    _tag: 'CSidebarNavItem',
    name: `요양기관 개폐업 정보`,
    to: '/hosp_pharmacy_op_list',
  },
  {
    _tag: 'CSidebarNavItem',
    name: `의약품 등록/수정`,
    to: '/prescription_drug',
  },
  {
    _tag: 'CSidebarNavTitle',
    _children: ['Test'],
  },
  {
    _tag: 'CSidebarNavItem',
    name: `센트릭스`,
    to: '/centrex',
  },
  {
    _tag: 'CSidebarNavTitle',
    _children: ['MANAGEMENT'],
  },
  {
    _tag: 'CSidebarNavItem',
    name: '관리자 관리',
    to: '/management',

    permission: 'management',
  },
  {
    _tag: 'CSidebarNavItem',
    name: '관리자 사용 기록',
    to: '/users-log',

    permission: 'users-log',
  },
  {
    _tag: 'CSidebarNavItem',
    name: '내 정보',
    to: '/my-info',
  },
];

export default _nav;
