import { ChipButton, Label, IconWrapper, Closed } from './Chip.styled';

type ChipProps = {
  disabled?: boolean;
  value: string;
  onDelete?: (params?: any) => void;
  onClick: (params?: any) => void;
  style?: any;
  showClosedButton?: boolean;
};

export const Chip = ({
  disabled,
  value,
  onDelete,
  onClick,
  style,
  showClosedButton = true,
}: ChipProps) => {
  return (
    <ChipButton
      disabled={disabled}
      label={<Label style={{ ...style }}>{value}</Label>}
      onClick={onClick}
      onDelete={() => !disabled && onDelete && onDelete()}
      deleteIcon={
        showClosedButton ? (
          <IconWrapper disabled={disabled}>
            <Closed disabled={disabled} />
          </IconWrapper>
        ) : (
          <></>
        )
      }
    />
  );
};
