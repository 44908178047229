import { useState } from 'react';
import { observer } from 'mobx-react';
import { useSnackbarContext } from '~/components/providers/SnackbarProvider';
import { useApp } from '~/hooks/useApp';
import { TextField } from '~/components/fields/TextField';
import { useApi } from '~/components/providers/ApiProvider';
import { Body, Field } from './ManagementChangedPasswordModal.styled';

type ManagementChangedPasswordModalProps = {
  options: any;
  close: () => void;
};

const ManagementChangedPasswordModal = ({
  options,
  close,
}: ManagementChangedPasswordModalProps) => {
  const app = useApp();
  const snackbar = useSnackbarContext();
  const { userApi } = useApi();

  const [oldPassword, setOldPassword] = useState<any>(null);
  const [newPassword, setNewPassword] = useState<any>(null);
  const [confirmPassword, setConfirmPassword] = useState(null);

  const validate = () => {
    const passwordRegex =
      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*#?&])[a-zA-Z\d@$!%*#?&]{8,20}$/;

    if (!passwordRegex.test(newPassword)) {
      throw new Error('8~20자 영문자, 숫자, 특수문자를 입력해주세요.');
    }

    if (newPassword !== confirmPassword) {
      throw new Error('입력한 비밀번호가 다릅니다.');
    }
  };

  const onClickSave = async () => {
    try {
      validate();
    } catch (e: any) {
      snackbar.alert(e.message);
      return;
    }

    try {
      await userApi.minePassword(oldPassword, newPassword);
      snackbar.success('변경되었습니다.');
      close();
    } catch (e: any) {
      if (e.code === 403 && e.name === 'INCORRECT_PASSWORD') {
        snackbar.alert('현재 비밀번호가 다릅니다');
        return;
      }
      snackbar.alert(e.name);
    }
  };

  return (
    <div>
      <div className="head flex-row flex-between items-start">
        <div className="title">비밀번호 변경</div>
        <i onClick={() => close()} className="zmdi zmdi-close" />
      </div>
      <Body className="body">
        <Field>
          <span>현재 비밀번호</span>
          <TextField
            type="password"
            value={oldPassword}
            placeholder="현재 비밀번호를 입력해주세요."
            onChange={(e) => {
              setOldPassword(e.target.value);
            }}
          />
        </Field>
        <Field>
          <span>새 비밀번호</span>
          <TextField
            type="password"
            value={newPassword}
            placeholder="8~20자 영문자, 숫자, 특수문자"
            onChange={(e) => {
              setNewPassword(e.target.value);
            }}
          />
        </Field>
        <Field>
          <span>비밀번호 확인</span>
          <TextField
            type="password"
            value={confirmPassword}
            onChange={(e) => {
              setConfirmPassword(e.target.value);
            }}
          />
        </Field>
      </Body>

      <div className="buttons">
        <div className="flex-row">
          <button onClick={() => close()} className="btn btn-default">
            {app.$translate('CANCEL')}
          </button>
          <button onClick={() => onClickSave()} className="btn btn-primary">
            {app.$translate('SAVE')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default observer(ManagementChangedPasswordModal);
