import styled, { css } from 'styled-components';
import { IconButton } from '~/components/commons/IconButton';

export const Button = styled(IconButton)`
  &&& {
    margin: 0px auto;
    padding: 5px 4px;
  }
  width: 16px;
  height: 16px;
  border-radius: 50% !important;
  padding: 0;
  border: 0;
  color: #202020;
  background: transparent;
  ${({ $collapsed }: { $collapsed: boolean }) =>
    $collapsed &&
    css`
      transform: rotate(180deg);
    `}
`;
