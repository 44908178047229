import {
  LabelWrapper as _LabelWrapper,
  InnerBoxStyle as _InnerBoxStyle,
  Wrapper,
  LabelDom,
  InnerWrapper,
} from './Label.styled';
import { ReactNode } from 'react';

export const LabelWrapper = _LabelWrapper;
export const InnerBoxStyle = _InnerBoxStyle;

type LabelProps = {
  isRequire: boolean;
  text: string;
  children: ReactNode;
};

export const Label = (props: LabelProps) => {
  const { isRequire, text, children } = props;

  return (
    <Wrapper>
      {text && (
        <LabelDom $isRequire={isRequire} htmlFor={text}>
          {text}
        </LabelDom>
      )}
      {children && <InnerWrapper>{children}</InnerWrapper>}
    </Wrapper>
  );
};

export default Label;
