import { useState, useEffect, useCallback } from 'react';
import { UsersLogTable } from '~/components/routes/management/UsersLogTable';
import { LogSearchForm } from '~/components/routes/management/LogSearchForm';
import { DataPagination } from '~/components/commons/DataPagination';
import { useApi } from '~/components/providers/ApiProvider';
import { format } from 'date-fns';
import { Body, Summary } from './ManagementPage.styled';
import { FlexRow } from './UsersLogPage.styled';
import { useSnackbarContext } from '~/components/providers/SnackbarProvider';
import PageNav from '~/components/commons/PageNav';

export const UsersLogPage = () => {
  const { userApi } = useApi();
  const snackbar = useSnackbarContext();

  const [paginationParams, setPaginationParams] = useState({
    limit: 10,
    page: 1,
  });
  const [searchValue, setSearchValue] = useState<any>({
    startAt: new Date(),
    endAt: new Date(),
    email: '',
    name: '',
    requestMethod: [],
    adminUserId: '',
  });

  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    limit: 10,
    pages: 1,
    total: 0,
  });

  const callApi = useCallback(async () => {
    const request = {
      ...paginationParams,
      email: searchValue.email ? searchValue.email : undefined,
      name: searchValue.name ? searchValue.name : undefined,
      requestMethod: searchValue.requestMethod
        ? searchValue.requestMethod.map((v: any) => v?.id).join(',') === 'ALL'
          ? undefined
          : searchValue.requestMethod.map((v: any) => v?.id).join(',')
        : undefined,
      adminUserId: searchValue.adminUserId
        ? searchValue.adminUserId
        : undefined,
      startAt: searchValue.startAt
        ? format(new Date(searchValue.startAt), 'yyyy-MM-dd')
        : undefined,
      endAt: searchValue.endAt
        ? format(new Date(searchValue.endAt), 'yyyy-MM-dd')
        : undefined,
    };

    try {
      const resp: any = await userApi.getUsersLog({ ...request });
      setData(resp.data);
      setPagination(resp.pagination);
    } catch (e: any) {
      if (e.name === 'NO_PERMISSION') {
        snackbar.alert('접근 권한이 없습니다.');
      } else {
        snackbar.alert(e.description);
      }
    }
  }, [paginationParams, searchValue]);

  useEffect(() => {
    callApi();
  }, [paginationParams]);

  const onClickSearch = () => {
    setPaginationParams((params) => ({ ...params, page: 1 }));
  };

  return (
    <div className="main-contents">
      <PageNav pages={['MANAGEMENT', `관리자 사용 기록`]} />
      <div>
        <LogSearchForm
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          onClickSearch={onClickSearch}
        />
      </div>
      <Body>
        <FlexRow>
          <Summary>
            {`조회결과 [ 총 `}
            <b>{pagination.total}</b>
            {` ]`}
          </Summary>
          <div>
            <DataPagination
              value={paginationParams}
              pages={pagination.pages}
              total={pagination.total}
              options={[
                {
                  label: '10개씩',
                  value: 10,
                },
                {
                  label: '15개씩',
                  value: 15,
                },
                {
                  label: '20개씩',
                  value: 20,
                },
                {
                  label: '50개씩',
                  value: 50,
                },
              ]}
              onChange={(v) => {
                setPaginationParams(v);
              }}
            />
          </div>
        </FlexRow>
        <table>
          <thead>
            <tr>
              <th>NO</th>
              <th>관리자 번호</th>
              <th>ID</th>
              <th>이름</th>
              <th>URL</th>
              <th>메소드</th>
              <th>파라미터</th>
              <th>결과</th>
              <th>일시</th>
              <th>IP</th>
            </tr>
          </thead>
          <tbody>
            <UsersLogTable data={data} />
          </tbody>
        </table>
      </Body>
    </div>
  );
};
