import { outlinedInputClasses, TextField as MuiTextField } from '@mui/material';

type TextFieldProps = {
  value: any;
  onChange: (value: any) => void;
  placeholder?: string;
  sx?: any;
  type?: 'text' | 'password';
  disabled?: boolean;
};

export const TextField = ({
  value,
  onChange,
  placeholder = '',
  sx,
  disabled = false,
  ...props
}: TextFieldProps) => {
  return (
    <MuiTextField
      sx={{
        [`& .${outlinedInputClasses.input}`]: {
          py: 0,
          px: 1,
          height: 36,
          backgroundColor: 'white',
          ...(disabled && {
            backgroundColor: '#E9E9ED',
          }),
        },
        [`& .${outlinedInputClasses.notchedOutline}`]: {
          borderColor: '#E9E9ED',
        },
        ...sx,
      }}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      disabled={disabled}
      {...props}
    />
  );
};
