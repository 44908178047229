import { Route, Redirect, Switch } from 'react-router-dom';
import SideNav from './navigation/SideNav';
import { observer } from 'mobx-react';
import routes from './routes';
import { useAuth } from '~/hooks/useAuth';
import { PriceTablePage } from '~/pages/priceTable/PriceTablePage';
import { FileSamplePage } from '~/pages/fileSample/FileSamplePage';
import { CredentialPage } from '~/pages/credential/CredentialPage';
import { DrugPage } from '~/pages/prescriptionDrug/DrugPage';
import { Centrex } from '~/pages/centrex/Centrex';
import { PopupPage } from '~/pages/popup/PopupPage';
import { NewPopupPage } from '~/pages/popup/NewPopupPage';
import { EditPopupPage } from '~/pages/popup/EditPopupPage';
import { ManagementPage } from '~/pages/management/ManagementPage';
import { NewManagementPage } from '~/pages/management/NewManagementPage';
import { DetailManagementPage } from '~/pages/management/DetailManagementPage';
import { EditManagementPage } from '~/pages/management/EditManagementPage';
import { UsersLogPage } from '~/pages/management/UsersLogPage';
import { MyInfoPage } from '~/pages/management/MyInfoPage';
import { ProtectedRouter } from '~/components/app/ProtectedRouter';
import ClinicAdd from '~/components/routes/ClinicAdd';
import HospPharmacy from '../routes/HospPharmacy/HospPharmacy';
import { useSnackbarContext } from '../providers/SnackbarProvider';

const AuthedRouter = () => {
  const auth = useAuth();
  const snackbar = useSnackbarContext();

  const localAuth = localStorage.getItem('access_token');
  if (!auth.token) {
    if (localAuth) {
      snackbar.alert('인증이 만료되었습니다. 다시 로그인하세요.');
    }

    return <Redirect to="/login" />;
  }

  return (
    <div className="main">
      <SideNav />
      <div className="c-wrapper c-body content">
        <Switch>
          <Route path="/clinics/:id" component={routes.ClinicDetail} />
          <Route path="/clinics" component={routes.Clinics} />
          <Route
            path="/clinics_management/:id"
            component={routes.Clinics_Management_Detail}
          />
          <Route
            path="/clinics_management"
            component={routes.Clinics_Management}
          />
          <Route
            path="/clinic_add"
            component={() => (
              <ProtectedRouter
                permission="clinic-add"
                checkRole={['super', 'admin']}
                component={ClinicAdd}
              />
            )}
          />
          <Route
            path="/clinic_deactivate_reason"
            component={routes.ClinicDeactivate}
            exact
          />
          <Route path="/clinic_use" component={routes.ClinicUse} />
          <Route path="/certifications" component={routes.Certifications} />
          <Route
            path="/sms-point-managements"
            component={routes.SmsPointManagements}
          />
          <Route path="/popups/new" component={NewPopupPage} />
          <Route path="/popups/edit/:id" component={EditPopupPage} />
          <Route path="/popups" component={PopupPage} />
          <Route
            path="/price_tables"
            component={() => (
              <ProtectedRouter
                permission="price-tables"
                checkRole={['super']}
                component={PriceTablePage}
              />
            )}
          />
          <Route path="/file_samples" component={FileSamplePage} />
          <Route path="/hosp_pharmacy_op_list" component={HospPharmacy} />
          <Route path="/prescription_drug" component={DrugPage} />
          {process.env.REACT_APP_ENV === 'prod' ? (
            <Route
              path="/credentials"
              render={() => <Redirect to="/clinics" />}
            />
          ) : (
            <Route path="/credentials" component={CredentialPage} />
          )}
          <Route path="/centrex" component={Centrex} />
          <Route
            path="/management/new"
            component={() => (
              <ProtectedRouter
                permission="management"
                checkRole={['super']}
                component={NewManagementPage}
              />
            )}
          />
          <Route
            path="/management/edit/:id"
            component={() => (
              <ProtectedRouter
                permission="management"
                checkRole={['super']}
                component={EditManagementPage}
              />
            )}
          />
          <Route
            path="/management/detail/:id"
            component={() => (
              <ProtectedRouter
                permission="management"
                checkRole={['super', 'admin']}
                component={DetailManagementPage}
              />
            )}
          />
          <Route
            path="/management"
            component={() => (
              <ProtectedRouter
                permission="management"
                checkRole={['super', 'admin']}
                component={ManagementPage}
              />
            )}
          />
          <Route
            path="/users-log"
            component={() => (
              <ProtectedRouter
                permission="users-log"
                checkRole={['super', 'admin']}
                component={UsersLogPage}
              />
            )}
          />
          <Route path="/my-info" component={MyInfoPage} />
          <Route path="/*" render={() => <Redirect to="/clinics" />} />
        </Switch>
      </div>
    </div>
  );
};

export default observer(AuthedRouter);
