import { ComponentType } from 'react';
import { styled, css } from '@mui/material/styles';
import { Box as MuiBox } from '@mui/material';
import { Tooltip as _Tooltip } from '~/components/commons/Tooltip';
import { Link as _Link } from 'react-router-dom';
import { DataTable as _DataTable } from '~/components/commons/DataTable/DataTable';

export const DataTable = styled(_DataTable)`` as ComponentType<any>;

export const Link = styled(_Link)`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #2c62f6;
  text-decoration: underline;
`;

export const Button = styled('button')`
  margin-left: 10px;
  padding: 3px;
  font-weight: 400;
  font-size: 11px;
  border: 1px solid #2d2d2d;
`;

export const Box = styled(MuiBox)`
  &.date {
    padding: 0 1rem;
  }
  &.phone-number {
    padding: 0 1rem;
  }
  &.memo {
    width: 200px;
  }
  &.deactivate-reason {
    width: 250px;
  }
`;

export const DisabledButton = styled('button')`
  margin-left: 10px;
  padding: 3px;
  font-weight: 400;
  font-size: 11px;
  border: 1px solid #bbbbbb;
  color: #bbbbbb;
  background-color: #fff;
`;

export const Tooltip = styled(_Tooltip)(() => css``) as ComponentType<any>;
