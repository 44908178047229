import { useMemo } from 'react';
import styled from 'styled-components';
import { DataTable } from '../DataTable.styled';

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-top: 4px;
`;

type ClinicsDataTableProps = {
  data: any;
  orderBy: { id: any; value: any };
  pagination: {
    page: number;
    limit: number;
  };
  onChangePagination: (params: any) => void;
};

export const ClinicsDataTable = (props: ClinicsDataTableProps) => {
  const { data, orderBy } = props;
  const items = useMemo(() => {
    return data?.data || [];
  }, [data]);

  const schema = useMemo(
    () => ({
      columns: [
        {
          id: 'name',
          name: '부서',
          value: (item: { name: string }) => item.name,
        },

        {
          id: 'visible',
          name: '부서 상태',
          value: (item: { visible: boolean | null | string }) => {
            return !(item.visible == null || item.visible === '') ? (
              item.visible ? (
                <span>사용중</span>
              ) : (
                <span>미사용</span>
              )
            ) : (
              <span>미사용</span>
            );
          },
        },
        {
          id: 'departments.name',
          name: '세부부서',
          value: (item: { departments: { name: string }[] }) =>
            item.departments.map((v: any) => v.name),
        },
        {
          id: 'departments.visible',
          name: '세부부서 상태',
          value: (item: {
            departments: { visible: boolean | null | string }[];
          }) => {
            return item.departments.map((v) =>
              !(v.visible == null || v.visible === '') ? (
                v.visible ? (
                  <span>사용중</span>
                ) : (
                  <span>미사용</span>
                )
              ) : (
                <span>미사용</span>
              )
            );
          },
        },
      ],
    }),
    []
  );

  return (
    <DataTable
      data={items}
      schema={schema}
      sorts={[orderBy]}
      placeholder={'조회 결과가 없습니다.'}
    />
  );
};
