import { ComponentType } from 'react';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  row-gap: 4px;
  ${({ disabled }: any) =>
    disabled
      ? css`
          label {
            color: #bbbbbb;
          }
          select,
          input {
            background: #edeff1 !important;
            color: #a1b1ca !important;
          }
        `
      : ''}
` as ComponentType<any>;

export const LabelDom = styled.label`
  display: inline-block;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  ${({ isRequire }: any) =>
    isRequire
      ? css`
          &::after {
            content: ' *';
            color: #eb5757;
          }
        `
      : ''}
` as ComponentType<any>;

export const LabelWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(
    ${({ column }: any) => (column ? column : 2)},
    1fr
  );
  column-gap: 8px;
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  min-height: 29px;
`;

export const LabelDomWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const ConnectChip = styled.div`
  width: 10px;
  height: 10px;
  background: #2c62f6;
  border-radius: 50%;
`;
