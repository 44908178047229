import { observer } from 'mobx-react';
import { useApp } from '~/hooks/useApp';
import { PriceTableFormModal } from './PriceTableFormModal';
import styled from 'styled-components';
import { Tooltip } from '~/components/commons/Tooltip';

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
`;

type PriceTableTable = {
  items: any;
  onChange: () => void;
};

export const PriceTableTable = observer(
  ({ items, onChange }: PriceTableTable) => {
    const app = useApp();

    const onClickEdit = async (item: any) => {
      await app.$modal.custom({
        component: PriceTableFormModal,
        options: {
          action: 'modify',
          item: item,
        },
      });

      onChange();
    };

    return items.map((item: any) => (
      <tr key={item.id}>
        <td>{item.id}</td>
        <td>{item.name}</td>
        <td>{item.code}</td>
        <td>{item.price}</td>
        <td>{item.cost}</td>
        <td>
          <Buttons>
            <Tooltip
              title={app.$me.role === 'manager' ? '권한이 없습니다.' : ''}
            >
              <div>
                <button
                  onClick={() => onClickEdit(item)}
                  className="btn btn-info"
                  disabled={app.$me.role === 'manager'}
                >
                  수정
                </button>
              </div>
            </Tooltip>
          </Buttons>
        </td>
      </tr>
    ));
  }
);
