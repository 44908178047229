import { styled, css } from '@mui/material/styles';

export const Wrapper = styled('div')(
  ({ theme }) => css`
    margin-bottom: 20px;
  `
);

export const Name = styled('span')(
  ({ theme }) => css`
    font-size: 12px;
    &:not(:last-child) {
      &::after {
        content: '>';
        display: inline-block;
        margin: 0 6px;
      }
    }
    &.bold {
      font-weight: 700;
      line-height: 10px;
      letter-spacing: 0em;
    }
  `
);
