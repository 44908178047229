import { useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import { runInAction } from 'mobx';
import { banks } from '~/hooks/constants';
import { useApp } from '~/hooks/useApp';
import { useSnackbarContext } from '~/components/providers/SnackbarProvider';
import { format } from 'date-fns';

type ModalAdminBalanceRefundProps = {
  options: any;
  close: () => void;
};

const ModalAdminBalanceRefund = ({
  options,
  close,
}: ModalAdminBalanceRefundProps) => {
  const app = useApp();
  const snackbar = useSnackbarContext();
  const memo: any = app.$mustParse(options.data.memo);
  const [currentMemo, setCurrentMemo] = useState({
    content: '',
    timestamp: format(new Date(), 'yy-MM-dd'),
  });

  const onClickCancel = () => {
    app.$modal
      .basic({
        body: '입력을 취소하시겠습니까?',
        buttons: [{ text: 'CONFIRM', class: 'btn-primary' }],
      })
      .then((idx) => {
        if (idx === 0) {
          close();
        }
      });
  };

  const balanceRefundCallApi = useCallback(async () => {
    let memoData: any;
    if (!currentMemo.content) {
      memoData = memo;
    } else {
      currentMemo.timestamp = format(new Date(), 'yy-MM-dd');
      memoData = (memo || []).concat([currentMemo]);
    }

    runInAction(() => {
      memoData = JSON.stringify(memoData);
    });

    try {
      app.loadingToggle(true);
      await app.$smsRepo.sms.refund(options.data.id, memoData);
      app.loadingToggle(false);
      app.sleep(500);
      snackbar.success(`환불하였습니다.`);
      options.callApi();
      close();
      close();
    } catch (e: any) {
      snackbar.alert(e.description);
    }
  }, [app, close, app.$smsRepo.sms, memo]);

  const onClickSave = () => {
    runInAction(() => {
      app.$modal
        .basic({
          body: '충전금을 환불하시겠습니까?',
          buttons: [
            { text: 'CANCEL', class: 'btn-default' },
            { text: 'CONFIRM', class: 'btn-primary' },
          ],
        })
        .then((idx) => {
          if (idx === 1) {
            balanceRefundCallApi();
          }
        });
    });
  };

  return (
    <div className="modal-payment-payouts">
      <div className="head flex-row flex-between items-start">
        <div className="title">충전금 환불</div>
        <i onClick={() => close()} className="zmdi zmdi-close" />
      </div>
      <div className="body">
        <div className="form-control">
          <label>환불일</label>
          <input value={format(new Date(), 'yyyy/MM/dd')} disabled={true} />
        </div>
        <div className="form-control">
          <label>상호(사업명)</label>
          <input defaultValue={options.data.clinicName} disabled={true} />
        </div>
        <div className="form-control">
          <label>충전금 잔액</label>
          <input
            defaultValue={Number(
              options.data.paidBalance + options.data.freeBalance || ''
            )}
            disabled={true}
          />
          <div>유료 충전금 {options.data.totalPaidAmount}</div>
          <div>무료 충전금 {options.data.totalFreeAmount}</div>
        </div>
        <div className="form-control">
          <label>환불신청금액</label>
          <input defaultValue={options.data.amount} disabled={true} />
        </div>
        <div className="form-control">
          <label>입금해야할 금액(VAT포함)</label>
          <input
            defaultValue={Number(options.data.totalRefundAmount * 1.1)}
            disabled={true}
          />
        </div>
        <div className="form-control">
          <label>은행명</label>
          <input
            defaultValue={banks.findIndex(
              (bank) => bank.value === options.data.refundBankCd
            )}
            disabled={true}
          />
        </div>
        <div className="form-control">
          <label>계좌번호</label>
          <input defaultValue={options.data.refundAcctNo} disabled={true} />
        </div>
        <div className="form-control">
          <label>예금주</label>
          <input defaultValue={options.data.refundAcctNm} disabled={true} />
        </div>
        <div className="form-control">
          <label>CS 메모</label>
          <textarea
            name="memo"
            onChange={(e) => {
              currentMemo.content = e.target.value;
              setCurrentMemo({ ...currentMemo });
            }}
            value={currentMemo.content}
            placeholder={app.$translate('MEMO_PLACEHOLDER')}
          />
        </div>
      </div>

      <div className="buttons">
        <div className="flex-row">
          <button onClick={onClickCancel} className="btn btn-default">
            {app.$translate('CANCEL')}
          </button>
          <button onClick={onClickSave} className="btn btn-primary">
            {app.$translate('SAVE')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default observer(ModalAdminBalanceRefund);
