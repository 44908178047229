import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  font-size: 12px;

  ${({ theme }) =>
    css`
      color: ${theme.color.bluegrey[600]};
    `}
`;

type NoOptionsTextProps = {
  value?: string;
};

export const NoOptionsText = ({ value }: NoOptionsTextProps) => {
  return <Wrapper>{value ?? '검색결과가 없습니다.'}</Wrapper>;
};
