import { types } from 'mobx-state-tree';
const ConditionData = types.model({
  id: types.optional(types.maybeNull(types.number)),
  clinicId: types.optional(types.maybeNull(types.number)),
  origin: types.optional(types.maybeNull(types.string)),
  database: types.optional(types.maybeNull(types.string)),
  status: types.optional(types.maybeNull(types.string)),
  activateAt: types.optional(types.maybeNull(types.string)),
  clinicName: types.optional(types.maybeNull(types.string)),
  salesName: types.optional(types.maybeNull(types.string)),
  contractAt: types.optional(types.maybeNull(types.string)),
  migration: types.optional(types.maybeNull(types.boolean)),
  educationAgency: types.optional(types.maybeNull(types.string)),
  educationManager: types.optional(types.maybeNull(types.string)),
  educationAt: types.optional(types.maybeNull(types.string)),
  submitDocument: types.optional(types.maybeNull(types.boolean)),
  contactUserName: types.optional(types.maybeNull(types.string)),
  contactUserTel: types.optional(types.maybeNull(types.string)),
  contactCall: types.optional(types.maybeNull(types.boolean)),
  installInsuranceType: types.optional(types.maybeNull(types.string)),
  installCrm: types.optional(types.maybeNull(types.number)),
  installCertification: types.optional(types.maybeNull(types.number)),
  installCti: types.optional(types.maybeNull(types.number)),
  installInsurance: types.optional(types.maybeNull(types.number)),
  installTaxbillAt: types.optional(types.maybeNull(types.string)),
  install080Enable: types.optional(types.maybeNull(types.boolean)),
  cmsConfirm: types.optional(types.maybeNull(types.boolean)),
  managementCrm: types.optional(types.maybeNull(types.number)),
  managementCti: types.optional(types.maybeNull(types.number)),
  management080: types.optional(types.maybeNull(types.number)),
  managementCertification: types.optional(types.maybeNull(types.number)),
  managementPaymentAt: types.optional(types.maybeNull(types.string)),
});

const CancelHistory = types.model({
  id: types.optional(types.maybeNull(types.number)),
  clinicId: types.optional(types.maybeNull(types.number)),
  reason: types.optional(types.maybeNull(types.string)),
  refundPrice: types.optional(types.maybeNull(types.number)),
  cancelExcuteAt: types.optional(types.maybeNull(types.string)),
  cancelAt: types.optional(types.maybeNull(types.string)),
  contractAgency: types.optional(types.maybeNull(types.string)),
  educationAgency: types.optional(types.maybeNull(types.string)),
});

const CallManagement = types.model({
  type: types.optional(types.maybeNull(types.number)),
});

const Additional = types.model({
  agency: types.optional(types.maybeNull(types.string)),
  chartNoType: types.optional(types.maybeNull(types.string)),
  chartNoNumStart: types.optional(types.maybeNull(types.number)),
  chartNoNumLast: types.optional(types.maybeNull(types.string)),
});

const ClinicData = types.model({
  id: types.optional(types.maybeNull(types.number)),
  name: types.optional(types.maybeNull(types.string)),
  createdAt: types.optional(types.maybeNull(types.string)),
  contactManager: types.optional(types.maybeNull(types.string)),
  contactTel: types.optional(types.maybeNull(types.string)),
  contactEmail: types.optional(types.maybeNull(types.string)),
  memo: types.optional(types.maybeNull(types.string)),
  status: types.optional(types.maybeNull(types.string)),
  userCount: types.optional(types.maybeNull(types.number)),
  condition: types.optional(types.maybeNull(ConditionData)),
  cancelHistory: types.optional(types.maybeNull(CancelHistory)),
  callManagement: types.optional(types.maybeNull(CallManagement)),
  additional: types.optional(types.maybeNull(Additional)),
});

const Data = types.model({
  clinicList: types.optional(types.array(ClinicData), []),
  total: types.optional(types.number, 0),
});

export const ClinicModel = types
  .model('clinicModel', {
    clinicsData: types.optional(Data, {}),
  })
  .views((self) => ({
    get clinicList() {
      return self.clinicsData.clinicList;
    },
    get total() {
      return self.clinicsData.total;
    },
  }))
  .actions((self) => ({
    setData(data) {
      if (!data) return;
      self.clinicsData = {
        clinicList: data.data,
        total: data?.pagination?.total,
      };
    },
  }));
