import { makeObservable, observable, runInAction } from 'mobx';
import $http from 'axios';
import * as axios from '~/scripts/axios';
import app from './app';

class Auth {
  constructor() {
    axios.init(this);

    makeObservable(this, {
      token: observable,
    });

    this.initialize();
  }

  token: string | null = null;

  initialize() {
    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');
    const userData = JSON.parse(localStorage.getItem('user') || '{}');
    if (!accessToken || !refreshToken || !userData) {
      return;
    }
    this.token = accessToken;
    axios.setAuthToken(accessToken, refreshToken);
    this.loadMe(userData);
  }

  signInWithData = async (data: any) => {
    const userData = JSON.stringify(data.user);
    localStorage.setItem('user', userData);
    runInAction(() => {
      axios.setAuthToken(data.accessToken, data.refreshToken);
      this.token = data.accessToken;
      app.$me = {
        ...data.user,
      };
    });
  };

  loadMe = async (userData: any) => {
    runInAction(() => {
      app.$me = userData;
      localStorage.setItem('user', JSON.stringify(userData));
    });
  };

  signOut = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('user');
    delete $http.defaults.headers.common.Authorization;

    runInAction(() => {
      this.token = null;
      app.$me = { ...app.$me, role: null };
    });

    app.$router.push('/login');
  };
}

export default new Auth();
