import $http from 'axios';

export const CertificationRepo = {
  certification: {
    all: (params: any) => $http.get('/certs', { params }),
    create: (formData: any, headers: any) =>
      $http.post('/certs', formData, headers),
    update: (id: number, formData: any, headers: any) =>
      $http.put(`/certs/${id}`, formData, headers),
  },
};
