import { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { useApp } from '~/hooks/useApp';

type ModalInputProps = {
  options: any;
  close: (value?: any) => void;
};

const ModalInput = ({ options, close }: ModalInputProps) => {
  const app = useApp();
  const me: any = useRef();

  useEffect(() => {
    const inputs = me.current.getElementsByTagName('input');
    if (!inputs) return;

    inputs[0].focus();
  }, []);

  return (
    <div className="modal-input" ref={me}>
      <div className="head flex-row flex-between items-start">
        <div className={`title ${options.titleClass || ''}`}>
          {app.$translate(options.title)}
        </div>
        {options.showCloseButton ? (
          <i onClick={() => close()} className="zmdi zmdi-close" />
        ) : null}
      </div>
      <div
        className={`body pre-line ${options.bodyClass}`}
        dangerouslySetInnerHTML={{ __html: options.body }}
      />
      {options.inputs ? (
        <div className="inputs">
          {options.inputs.map((input: any, idx: number) => (
            <div className="form-control" key={idx}>
              <label
                dangerouslySetInnerHTML={{
                  __html: app.$translate(input.label),
                }}
              />
              <input
                value={input.text ? input.text : ''}
                onChange={(e) => (input.text = e.target.value)}
                className={`model-input-${idx}`}
                placeholder={app.$translate(input.placeholder)}
              />
            </div>
          ))}
        </div>
      ) : null}

      <div className="buttons">
        <div className="flex-row">
          <button onClick={() => close()} className="btn btn-default">
            {app.$translate('CANCEL')}
          </button>
          <button
            onClick={() => close(options.inputs)}
            className="btn btn-primary"
          >
            저장
          </button>
        </div>
      </div>
    </div>
  );
};

export default observer(ModalInput);
