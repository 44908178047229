import { styled } from '@mui/material';

export const SubTitle = styled('div')`
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 10px;
`;

export const Contents = styled('div')`
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
`;
