import styled from 'styled-components';

export const IconWrapper = styled.div`
  svg {
    width: 8px;
    height: 8px;
  }
  margin-right: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;
