import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useApp } from '~/hooks/useApp';
import { CredentialRepo } from '~/components/repo/CredentialRepo';
import { CredentialFormModal } from './CredentialFormModal';
import { CredentialTable } from './CredentialTable';
import { Tooltip } from '~/components/commons/Tooltip';
import PageNav from '~/components/commons/PageNav';

export const CredentialPage = observer(() => {
  const repo = new CredentialRepo();
  const app = useApp();
  const [items, setItems] = useState([]);

  async function load() {
    const res = await repo.all();
    setItems(res.data);
  }

  useEffect(() => {
    load();
  }, []);

  const onClickCreate = async () => {
    await app.$modal.custom({
      component: CredentialFormModal,
      options: {
        action: 'create',
      },
    });

    load();
  };

  function onChange() {
    load();
  }

  return (
    <div className="main-contents">
      <PageNav pages={['GENERAL', `Credentials`]} />
      <div className="content-title" style={{ verticalAlign: 'middle' }}>
        <Tooltip title={app.$me.role === 'manager' ? '권한이 없습니다.' : ''}>
          <div className="search-item">
            <button
              className="btn-sm btn-black cursor-pointer"
              onClick={onClickCreate}
              disabled={app.$me.role === 'manager'}
            >
              생성
            </button>
          </div>
        </Tooltip>
      </div>
      <CredentialTable items={items} onChange={onChange} />
    </div>
  );
});
