import {
  styled,
  Box as MuiBox,
  Button as MuiButton,
  Stack as MuiStack,
  TextField as MuiTextField,
  Typography as MuiTypography,
  typographyClasses,
  buttonClasses,
} from '@mui/material';
import { color } from '~/theme';

export const Typography = styled(MuiTypography)`
  font-family: Noto Sans KR;
  &.${typographyClasses.body1} {
    margin-top: 20px;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: -0.01em;
  }

  &.time-count {
    font-size: 13px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: right;
  }
`;

export const TextField = styled(MuiTextField)(
  ({ theme }) => `
  &.MuiOutlinedInput-root {
    &.Mui-focused {
      border: 0 none;
    }
    
  }
  .Mui-disabled {
    input {
      background: ${theme.palette.grey['200']};
    }
  }

  &.error {
    fieldset {
      border: 1px solid ${theme.palette.error['main']};
    }

    .Mui-focused  {
      fieldset {
        border-color: ${theme.palette.error['main']};
      }
    }
  }

  fieldset {
    border: 0 none;
  }
  
  input {
    height: 52px;
    box-sizing: border-box;
    background: #f9fbff;
    font-size: 13px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    border: 0 none;

    &::placeholder {
      color: ${color.bluegrey['600']};
      opacity: 1;
    }
  }
`
);

export const Box = styled(MuiBox)(
  ({ theme }) => `
    &.confirm-certification-number-wrapper {
      width: 400px;
      min-height: 350px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-grow: 1;

      .confirm-input {
        margin-top: 30px;
      }

      form {
        width: 100%;
      }
    }

`
);

export const Button = styled(MuiButton)(
  ({ theme }) => `
  font-family: Noto Sans KR;
  width: 100%;
  height: 54px;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  border-radius: 4px;
  box-shadow: none;

  &.black {
    background: #293142;
  }

  &.${buttonClasses.disabled} {
    color: #ffffff;
  }


  &.resend-btn {
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    text-decoration: underline;
    color: ${theme.palette.grey['700']};
  }
`
);

export const Stack = styled(MuiStack)`
  &.mt-48 {
    margin-top: 48px;
  }
`;
