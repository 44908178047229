import { styled, css } from '@mui/material';
import { Box, Typography } from '@mui/material';
import { ComboBox as _ComboBox } from '~/components/commons/ComboBox';
import { DropdownList as _DropdownList } from '~/components/commons/DropdownList_v1';
import { Button as _Button } from '~/components/commons/Button';
import { ComponentType } from 'react';

export const DropdownList = styled(_DropdownList)`
  height: 34px;
  .MuiAutocomplete-inputRoot.MuiInputBase-root.MuiOutlinedInput-root {
    height: 34px;
  }
`;

export const Wrapper = styled(Box)`
  display: flex;
  width: 1800px;
  margin-bottom: 10px;

  & .MuiAutocomplete-root {
    height: 34px;
  }
`;

export const FormWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border: 1px solid #dee2ec;
  width: 1000px;
  max-width: 1000px;

  & .MuiOutlinedInput-root {
    background: white;
  }
`;

export const Form = styled(Box)`
  display: flex;
  background: white;
  & > *:not(:last-of-type) {
    border-right: 1px solid #dee2ec;
  }
`;

export const ButtonWrapper = styled('div')`
  margin-left: 10px;

  button {
    height: 100%;
  }
`;

export const Label = styled(Typography)`
  width: 64px;
  font-size: 12px !important;
  padding-left: 10px;
  font-weight: 500;
`;

export const ComboBox = styled(_ComboBox)(
  ({ theme }) => css`
    height: 34px;
    .MuiAutocomplete-inputRoot.MuiInputBase-root.MuiOutlinedInput-root {
      height: 34px;
    }
  `
);

export const Button = styled(_Button)`
  height: 34px;
` as ComponentType<any>;
