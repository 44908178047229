import { styled } from '@mui/material';

export const Body = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const Field = styled('div')`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  gap: 10px;

  & input::placeholder {
    font-size: 12px;
  }
`;
