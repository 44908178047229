import { useEffect } from 'react';
import { observer } from 'mobx-react';

type ModalConfirmProps = {
  options: any;
  close: () => void;
};

const ModalConfirm = ({ options, close }: ModalConfirmProps) => {
  /*
  options : { type, msg }
  type : SUCCESS / ERROR
  msg : 노출될 메시지 
  */
  useEffect(() => {
    setTimeout(function () {
      //팝업 로딩 후 0.5초 뒤에 팝업 종료
      close();
    }, 500);
  });

  return (
    <div className="modal-confirm">
      <div className={`icon ${options.type.toLowerCase()}`}>
        {options.type === 'SUCCESS' && (
          <i className="zmdi zmdi-check-circle"></i>
        )}
        {options.type === 'ERROR' && <i className="zmdi zmdi-close-circle"></i>}
      </div>
      <p>{options.msg}</p>
    </div>
  );
};

export default observer(ModalConfirm);
