import {
  FormControlLabel,
  RadioGroup,
  Radio as RadioIcon,
  styled,
} from '@mui/material';

export const RadioWrapper = styled(RadioGroup)`
  height: 100%;
`;

export const RadioButton = styled(FormControlLabel)`
  min-width: 40px;
  margin-left: 0;
  font-size: 11px;

  .MuiTypography-root {
    font-size: inherit;
    line-height: 1rem;
  }
`;

export const Icon = styled(RadioIcon)`
  padding: 0;
  padding-right: 4px;
  & .MuiSvgIcon-root {
    font-size: 15px;
  }
`;
