import { Box as MuiBox, styled } from '@mui/material';
import Pagination from '~/components/app/Pagination';
import { Button } from '~/components/button/Button';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { PopupPageContext } from './PopupPage';
import { useApp } from '~/hooks/useApp';
import { Tooltip } from '~/components/commons/Tooltip';

const Box = styled(MuiBox)`
  height: 32px;
  display: flex;
  justify-content: flex-end;
  .pagination {
    padding: 0;
  }
`;

export const TableToolbar = () => {
  const app = useApp();
  const { page, total, limit, onChangePage }: any =
    useContext(PopupPageContext);
  const history = useHistory();

  const onClickCreate = () => {
    history.push('/popups/new');
  };

  return (
    <Box>
      <Pagination
        total={total}
        currentIndex={page}
        onPage={onChangePage}
        limit={limit}
      />
      <Tooltip title={app.$me.role === 'manager' ? '권한이 없습니다.' : ''}>
        <div>
          <Button
            color="primary"
            onClick={onClickCreate}
            disabled={app.$me.role === 'manager'}
          >
            신규 등록
          </Button>
        </div>
      </Tooltip>
    </Box>
  );
};
