import $http from 'axios';

export class CentrexRepo {
  all(data: any) {
    return $http.get('/centrex/calls', data);
  }

  create(data: any) {
    return $http.post('/centrex/calls', data);
  }

  update(id: number, data: any) {
    return $http.put(`/centrex/calls/${id}/signal`, data);
  }
}
