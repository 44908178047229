import { styled, css } from '@mui/material';
import { ComponentType } from 'react';

export const Button = styled('button')`
  display: flex;
  margin: auto;
  text-decoration: underline;
  color: #0060ff;
  font-size: 12px;
  font-weight: 500;
  white-space: pre-line;
  width: max-content;
  max-width: -webkit-fill-available;
  word-break: keep-all;
  border: none;
  background: none;

  ${({ disabled }) =>
    disabled &&
    css`
      color: #bbbbbb !important;
      text-decoration: none;
    `}
`;

export const Td = styled('td')`
  ${({ disabled }: any) =>
    disabled &&
    css`
      color: #bbbbbb !important;
    `}
` as ComponentType<any>;
