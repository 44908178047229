import { useEffect, useState } from 'react';
import { SearchView } from './SearchView';

type SearchFormProps = {
  searchParams: any;
  originalParams: any;
  onSearch: (params: any) => void;
};

export const SearchForm = (props: SearchFormProps) => {
  const { onSearch, searchParams, originalParams } = props;
  const [params, setParams] = useState(searchParams);

  useEffect(() => {
    setParams(searchParams);
  }, [searchParams]);

  const onChangeParams = (newParams: any) => {
    setParams({
      ...params,
      ...newParams,
    });
  };

  const onSubmit = () => {
    onSearch(params);
  };

  return (
    <SearchView
      width={1630}
      value={params}
      onChange={onChangeParams}
      onSubmit={onSubmit}
      onSearch={onSubmit}
    />
  );
};
