import {
  Select as MuiSelect,
  MenuItem,
  ListItemText,
  listItemTextClasses,
} from '@mui/material';
import styled, { css } from 'styled-components';

const fieldSizeStyles = {
  default: css`
    padding: 5px 12px;
    height: 26px;
  `,
  small: css`
    padding: 0 8px;
    padding-right: 24px;
    height: 24px;
  `,
  xsmall: css`
    padding: 2px 8px;
    height: 24px;
  `,
};

export const CustomSelect = styled(MuiSelect)`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 24px;
  padding: 0px 24px 0px 8px;
  background: #ffffff;

  .MuiSelect-root {
    display: flex;
    align-items: center;
    background: #fff;
    ${({ $fieldSize }: { $fieldSize: 'default' | 'small' | 'xsmall' }) =>
      fieldSizeStyles[$fieldSize]}
  }
  
  .MuiSelect-select {
    padding: 0 !important;
    
  }

  .MuiSelect-icon {
    top: 50%;
    transform: translate(0, -50%);
    ${({ $fieldSize }: { $fieldSize: 'default' | 'small' | 'xsmall' }) =>
      $fieldSize === 'small'
        ? css`
            right: 0;
          `
        : ''}

  .MuiSelect-nativeInput {
    padding: 0px !important;
    background: none;
  }
`;

export const CustomMenuItem = styled(MenuItem)`
  height: 30px;
`;

export const CustomListItemText = styled(ListItemText)`
  & .${listItemTextClasses.primary} {
    font-family: Noto Sans KR;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0;
    text-align: center;
  }
`;
