import {
  Typography as MuiTypography,
  styled,
  css,
  Box as MuiBox,
} from '@mui/material';
import { TextField as _TextField } from '~/components/commons/TextField';
import { Button as _Button } from '~/components/commons/Button';
import { ComboBox as _ComboBox } from '~/components/commons/ComboBox';
import { FilterMonthRange as _FilterMonthRange } from '~/components/commons/SearchFilterMonthRange';
import { Checkbox as _Checkbox } from '~/components/commons/Checkbox';
import { FilterDateRange as _FilterDateRange } from '~/components/commons/SearchFilterDateRange';
import { DropdownList as _DropdownList } from '~/components/commons/DropdownList_v1';
import { ComponentType } from 'react';

export const DropdownList = styled(_DropdownList)`
  height: 34px;
  .MuiAutocomplete-inputRoot.MuiInputBase-root.MuiOutlinedInput-root {
    height: 34px;
  }
`;

export const FilterMonthRange = styled(_FilterMonthRange)`
  height: 36px;
  background: white;
`;

export const Box = styled(MuiBox)(
  ({ theme }) => css`
    &.checkbox-box {
      margin-left: 1rem;
      display: flex;
      width: 200px;
      flex-direction: row;
      align-items: center;
    }
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
  `
);

export const CheckBox = styled(_Checkbox)``;

export const TextField = styled(_TextField)(
  ({ theme }) => css`
    height: 34px;
  `
) as ComponentType<any>;

export const ComboBox = styled(_ComboBox)(
  ({ theme }) => css`
    height: 34px;
    .MuiAutocomplete-inputRoot.MuiInputBase-root.MuiOutlinedInput-root {
      height: 34px;
    }
  `
);

export const Typography = styled(MuiTypography)(
  ({ theme }) => css`
    width: 64px;
    font-size: 12px;
    padding-left: 1rem;
    font-weight: 500;

    &.width-wide {
      width: 80px;
    }
  `
);

export const Button = styled(_Button)`
  height: 34px;

  &.search-btn {
    margin-left: 0.5rem;
  }
` as ComponentType<any>;
