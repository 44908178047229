import { styled } from '@mui/material/styles';
import {
  Box as MuiBox,
  Typography as MuiTypography,
  Stack as MuiStack,
  IconButton as MuiIconButton,
} from '@mui/material';
import { TextareaAutosize as MuiTextareaAutosize } from '@mui/base';
import { ComboBox as _ComboBox } from '~/components/commons/ComboBox';
import { Tooltip as _Tooltip } from '~/components/commons/Tooltip';
import { TextField as _TextField } from '~/components/commons/TextField';
import { Label as _Label } from '~/components/commons/Label2';
import { ModalHeader as _ModalHeader } from '~/components/commons/modals/ModalHeader';
import { DropdownList as _DropdownList } from '~/components/commons/DropdownList_v1';
import _Button from '~/components/commons/Button';

export const DropdownList = styled(_DropdownList)`
  height: 34px;
  .MuiAutocomplete-inputRoot.MuiInputBase-root.MuiOutlinedInput-root {
    height: 34px;
  }
`;

export const ModalHeader = styled(_ModalHeader)`
  &.p-30 {
    padding: 30px;
  }
  min-height: 57px;
`;

export const IconButton = styled(MuiIconButton)`
  &:hover {
    background: unset;
  }
`;

export const Stack = styled(MuiStack)(
  ({ theme }) => `
  .MuiBox-root {
    padding: 8.5px 12px;
    align-items: center;
  }

  &.disabled {
    .value,.label {
      background: ${theme.palette.grey['200']};
    }
  }

  &.data-row {
    position: relative;
    &:last-of-type {
      .label, .value {
        border-bottom: 1px solid ${theme.palette.layout.line};
      }
    }
    &:not(.new-data) {
      padding-right: 32px;
    }
  }

  &.new-data {
    .input-value {
      padding: 0;
    }
  }
 

  &.mt-10 {
    margin-top: 10px;
  }
  &.mt-13 {
    margin-top: 13px;
  }

  .flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
`
);

export const Box = styled(MuiBox)(
  ({ theme }) => `
  width: 100%;

  &.modal-footer {
    padding: 16px 24px;
  }

  &.label {
    border-top: 1px solid ${theme.palette.layout.line};
    border-left: 1px solid ${theme.palette.layout.line};
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 17px;
    background: #f9fbff;
    min-height: 34px;
    width: 200px;
    flex-shrink: 0;
    display: flex;
    padding: 8.5px 12px;
    .required {
      color: #eb5757;
    }
    &.dim {
      background: #f1f1f1;
    }
  }
  &.value {
    border-top: 1px solid ${theme.palette.layout.line};
    border-left: 1px solid ${theme.palette.layout.line};
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    background: #ffffff;
    min-height: 34px;
    display: block;
    position: relative;
    &.dim {
      background: #f1f1f1;
    }
    &:last-of-type {
      border-right: 1px solid ${theme.palette.layout.line};
    }

    &.align-center {
      display: flex;
      align-items: center;
    }

    &.total-price {
      padding: 0 8px;
      color: #2c62f6;
    }

    &.w-220 {
      width: 220px;
      flex-shrink: 0;
    }

    &.w-200 {
      width: 200px;
      flex-shrink: 0;
    }

    .MuiFormControl-root {
      background: #ffffff;
    }

    .multi-line {
      display: inline-block;
      width: 100%;
      white-space: normal;
      word-wrap: break-word;
    }

    .user-count {
      color: #2c62f6;
      text-decoration: underline;
      cursor: pointer;
    }

    .contract-wrapper,
    .biz-license-wrapper {
      display: block;
      & + .contract-wrapper {
        margin-top: 8px;
      }
    }

    .MuiInputBase-root {
      border-radius: 0;
      input {
        background: unset;
      }
      .MuiOutlinedInput-notchedOutline {
        border: 0 none;
      }
    }

    .daum-post-code {
      display: inline-table;
      position: absolute;
      height: 448px;
      left: 0;
      top: 34px;
      border: 1px solid #000000;
      z-index: 10;
    }

    &.chart-no-type {
      padding: 12px 13px;
      .warning-text {
        color: #eb5757;
      }
      .MuiBox-root {
        margin-top: 8px;
      }
    }
  }
  &.clinic-detail {
    width: 900px;
  }
  &.memo {
    .memo-wrapper + .memo-btn {
      margin-top: 10px;
    }
  }
  &.MuiBox-root.memo-btn {
    padding: 0;
    display: block;
  }
  &.modal-body {
    padding: 16px 24px;
    height: 320px;
    overflow: auto;
  }
`
);

export const TextareaAutosize = styled(MuiTextareaAutosize)`
  width: 100% !important;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.23);
  &:focus-visible {
    outline: solid 1px ${({ theme }) => theme.palette.primary.unoblue};
  }
`;

export const FormControl = styled(MuiBox)`
  &.label {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 17px;
    background: #f9fbff;
    min-height: 34px;
    width: 200px;
    flex-shrink: 0;
  }

  &.value {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    background: #ffffff;
    min-height: 34px;
    display: block;

    .user-count {
      color: #2c62f6;
      text-decoration: underline;
      cursor: pointer;
    }
    .contracts-wrapper {
      display: flex;
      align-items: center;
      width: 200px;
      height: 100%;
      overflow: hidden;

      div {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .centrex-wrapper {
      display: block;
      & + .centrex-wrapper {
        margin-top: 8px;
      }
    }
    &.w-200 {
      width: 200px;
      flex-shrink: 0;
    }
    .multi-line {
      display: inline-block;
      width: 100%;
      white-space: normal;
      word-wrap: break-word;
    }
  }
`;

export const ComboBox = styled(_ComboBox)`
  height: 34px;
  .MuiOutlinedInput-root {
    height: 34px;
  }
`;

export const Label = styled(_Label)`
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
`;

export const Button = styled(_Button)``;

export const Typography = styled(MuiTypography)`
  font-size: 12px;
  line-height: 24px;
  &.MuiTypography-subtitle1 {
    margin-top: 25px;
    font-weight: 700;
  }
  &.text-section {
    line-height: 50px;
    vertical-align: top;
  }
`;

export const DisabledButton = styled('button')`
  margin-left: 10px;
  padding: 3px;
  font-weight: 400;
  font-size: 11px;
  border: 1px solid #bbbbbb;
  color: #bbbbbb;
  background-color: #fff;
`;

export const ModalWrapper = styled('div')`
  width: 874px;
  max-height: calc(100vh - 40px);
  overflow: auto;
`;

export const TextField = styled(_TextField)`
  background-color: #f3f8ff;
  font-size: 14px;

  .MuiInputBase-root {
    height: 34px !important;
  }

  &.white-bg {
    background-color: #ffffff;
    input {
      background-color: #ffffff;
    }
  }
  .MuiInputBase-root {
    height: 32px;
  }

  input {
    background-color: #f3f8ff;
  }
`;
