import { styled } from '@mui/material';

export const Info = styled('div')`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  height: 200px;
  padding: 50px;
  background-color: #fbfcfc;
  border-top: solid 1px #dee2ec;
  border-bottom: solid 1px #dee2ec;
  font-size: 13px;
`;

export const Body = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const Text = styled('div')`
  font-size: 13px;
`;

export const BlueText = styled('span')`
  font-weight: bold;
  color: #2c62f6;
`;

export const Contents = styled('div')`
  font-weight: 700;
`;
