import common from './common.json';
import errors from './errors.json';
import filters from './filters.json';
import modals from './modals.json';
import routes from './routes.json';
import snackbars from './snackbars.json';

let result: any = {};
let translations = [common, errors, filters, modals, routes, snackbars];
translations.forEach((json: any) =>
  Object.keys(json).forEach((key) => (result[key] = json[key]))
);

export default result;
