export const $qb = () => ({
  queryParams: {} as any,

  limit: function (limit: number) {
    this.queryParams.limit = limit;
    return this;
  },

  page: function (page: number) {
    this.queryParams.page = page;
    return this;
  },

  offset: function (offset: number) {
    this.queryParams.offset = offset;
    return this;
  },

  customParam: function (name: string, value: any) {
    const splitted = (name || '').split('__');
    this.queryParams[
      splitted.length === 1 ? name : splitted[splitted.length - 1]
    ] = value;
    return this;
  },

  deleteCustomParam: function (name: string, value: any) {
    const splitted = (name || '').split('__');
    delete this.queryParams[
      splitted.length === 1 ? name : splitted[splitted.length - 1]
    ];
    return this;
  },

  orderBy: function (orderBy: any) {
    this.queryParams.orderBy = orderBy;
    return this;
  },

  build: function () {
    return JSON.parse(JSON.stringify(this.queryParams));
  },
});
