import styled, { css } from 'styled-components';
import { Button as MuiButton } from '@mui/material';
import { color as colorTheme } from '~/theme';
import { ComponentType } from 'react';

const getColor = (color: string) => {
  switch (color) {
    case 'primary':
      return {
        default: colorTheme.primary.unoblue,
        hover: { fill: '#234EC5', outline: colorTheme.bluegrey[200] },
      };
    case 'secondary':
      return {
        default: colorTheme.bluegrey[700],
        hover: { fill: '#212735', outline: colorTheme.grey[200] },
      };
    case 'alert':
      return {
        default: colorTheme.alert,
        hover: { fill: '#BC4646', outline: '#eb57570d' },
      };
    case 'mix':
      return {
        default: colorTheme.primary.deepblue,
        hover: { fill: colorTheme.primary.deepblue, outline: '#EDEFF133' },
      };
    case 'grey':
      return {
        default: colorTheme.line,
        hover: { fill: colorTheme.line, outline: '#EDEFF133' },
      };
    case 'green':
      return {
        default: '#68D083',
        hover: { fill: '#6CA97C', outline: '#EDEFF133' },
      };
    case 'black':
      return {
        default: '#3A3A3A',
        hover: { fill: '#454545', outline: colorTheme.grey[200] },
      };
    case 'deepGrey':
      return {
        default: colorTheme.grey[700],
        hover: { fill: '#212735', outline: colorTheme.grey[200] },
      };
    default:
      return {
        default: colorTheme.primary.unoblue,
        hover: { fill: '#234EC5', outline: colorTheme.bluegrey[200] },
      };
  }
};

export const StyledButton = styled(MuiButton)`
  &&& {
    flex: 0 0 auto;
    gap: 4px;
    min-width: auto;
    line-height: inherit;
    font-size: 14px;
    letter-spacing: normal;
    padding: 0px 18px;
    border: 1px solid;
    transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1), width 0s;
    ${({ size }: any) => {
      if (size === 'l') {
        return css`
          min-height: 28px;
          padding: 0px 32px;
          border-radius: 2px;
          font-weight: bold;
        `;
      }
      if (size === 'm') {
        return css`
          min-height: 28px;
          padding: 0px 18px;
          border-radius: 2px;
          font-weight: bold;
        `;
      }
      if (size === 's') {
        return css`
          min-height: 24px;
          padding: 0px 8px;
          font-size: 12px;
          border-radius: 2px;
          font-weight: 500;
        `;
      }
      if (size === 'xs') {
        return css`
          min-height: 18px;
          padding: 0px 4px;
          font-size: 12px;
          border-radius: 2px;
          font-weight: 500;
        `;
      }
      if (size === 'xxs') {
        return css`
          min-width: 48px;
          height: 19px;
          padding: 4px 6px;
          font-size: 11px;
          border-radius: 2px;
        `;
      }
    }}
    ${({ $color, styled }: any) => {
      if (styled === 'fill') {
        return css`
          color: #fff;
          background: ${getColor($color).default};
          border-color: ${getColor($color).default};
          &:hover {
            background: ${getColor($color).hover.fill};
            border-color: ${getColor($color).hover.fill};
          }
          &:disabled {
            color: #fff;
            border-color: ${colorTheme.bluegrey[500]};
            background-color: ${colorTheme.bluegrey[500]};
            opacity: 1;
          }
        `;
      }
      if (styled === 'outline')
        return css`
          color: ${getColor($color).default};
          border-color: ${$color === 'mix'
            ? colorTheme.line
            : getColor($color).default};
          background: #fff;
          &:hover {
            background: ${getColor($color).hover.outline};
          }
          &:disabled {
            color: ${$color === 'primary'
              ? colorTheme.bluegrey[500]
              : colorTheme.grey[500]};
            border-color: ${$color === 'primary'
              ? colorTheme.bluegrey[500]
              : colorTheme.grey[500]};
            opacity: 1;
          }
        `;
      if (styled === 'text')
        return css`
          height: auto;
          padding: 0;
          border: 0;
          color: ${getColor($color).default};
          text-decoration-line: underline;
          &:hover {
            text-decoration-line: underline;
            background-color: transparent;
          }
          &:disabled {
            border: 0;
            color: ${getColor($color).default};
            background-color: transparent;
            opacity: 1;
          }
        `;
    }};
    }};
  }
` as ComponentType<any>;
