import { useState, useRef, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { Box, Typography, Stack, Button } from './ClinicDetail.styled';
import PageNav from '~/components/commons/PageNav';
import { useApi } from '~/components/providers/ApiProvider';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import Readview from './Readview';
import Editview from './Editview';
import { useSnackbarContext } from '~/components/providers/SnackbarProvider';
import { objectToFormData } from 'object-to-formdata';
import { format } from 'date-fns';
import { useApp } from '~/hooks/useApp';
import querystring from 'query-string';
import { Tooltip } from '~/components/commons/Tooltip';

const defaultParams = {
  contactManager: '',
  contactTel: '',
  category: '병원',
  name: '',
  oldName: '',
  ownerName: '',
  clinicTel: '',
  employerNo: '',
  clinicNo: '',
  address: '',
  addressDetail: '',
  treatmentFields: '',
  status: '',
  bizStatus: '',
  bizItem: '',
  bizLicenseFile: '',
  contractDate: null,
  cms: {
    bank: '',
    bankAccount: '',
    autoTransferDay: 0,
    taxbillEmail: '',
  },
  additional: {
    agency: '',
    educationAgency: '',
    chartNoType: 'date',
  },
  callManagement: {
    type: -1,
  },
  crmManagementPlanPrice: 0,
  crmManagementPlanLimit: 0,
  penchartPlanPrice: 0,
  penchartPlanLimit: 0,
  signCertPlanPrice: 0,
  signCertPlanLimit: 0,
  number080PlanPrice: 0,
  number080PlanLimit: 0,
  insurancePlanPrice: 0,
  insurancePlanLimit: 0,
  rejectionPhoneNumber: '',
  isUberSync: false,
  memo: '',
  test: false,
};

const ClinicDetail = () => {
  const app = useApp();
  const snackbar = useSnackbarContext();
  const { id }: any = useParams();
  const location = useLocation();
  const history = useHistory();
  const defaultParamsRef = useRef(defaultParams);
  const { clinicsApi } = useApi();
  const [items, setItems] = useState<any>({});
  const [value, setValue] = useState<any>(defaultParamsRef.current);
  const [data, setData] = useState<any>(null);
  const editMode = useMemo(() => {
    const { edit } = querystring.parse(location.search);
    return edit ? edit : false;
  }, [location.search]);

  useEffect(() => {
    getData(id);
  }, [id]);

  useEffect(() => {
    if (data) {
      setItems(data);
    }
  }, [data]);

  useEffect(() => {
    if (Object.keys(items).length > 0) {
      const {
        memo,
        test,
        name,
        contactManager,
        contactTel,
        billingPlan,
        address,
        addressDetail,
        bizStatus,
        bizItem,
        cms,
        status,
        contracts,
        callManagement,
        ownerName,
        oldName,
        clinicTel,
        category,
        employerNo,
        clinicNo,
        treatmentFields,
        rejectionPhoneNumber,
        additional,
        contractDate,
        isUberSync,
      } = items;

      let newTreatmentFields = [];
      try {
        newTreatmentFields =
          treatmentFields !== '[]' || treatmentFields !== ''
            ? JSON.parse(treatmentFields)
            : [];
      } catch (error) {
        console.error('json parsing error: ', error);
        newTreatmentFields = [];
      }

      setValue({
        ...value,
        memo,
        test,
        name,
        contactManager,
        contactTel,
        address,
        addressDetail,
        bizStatus,
        bizItem,
        cms: {
          bank: cms?.bank || '',
          bankAccount: cms?.bankAccount || '',
          autoTransferDay: cms?.autoTransferDay || 0,
          taxbillEmail: cms?.taxbillEmail || '',
        },
        additional: {
          agency: additional?.agency || '',
          educationAgency: additional?.educationAgency || '',
          chartNoType: additional?.chartNoType || 'date',
        },
        isUberSync,
        status,
        contracts,
        callManagement,
        ownerName,
        oldName,
        clinicTel,
        category,
        employerNo,
        clinicNo,
        treatmentFields: newTreatmentFields,
        rejectionPhoneNumber,
        crmManagementPlanPrice: billingPlan?.crmManagementPlanPrice || 0,
        crmManagementPlanLimit: billingPlan?.crmManagementPlanLimit || 0,
        penchartPlanPrice: billingPlan?.penchartPlanPrice || 0,
        penchartPlanLimit: billingPlan?.penchartPlanLimit || 0,
        signCertPlanPrice: billingPlan?.signCertPlanPrice || 0,
        signCertPlanLimit: billingPlan?.signCertPlanLimit || 0,
        number080PlanPrice: billingPlan?.number080PlanPrice || 0,
        number080PlanLimit: billingPlan?.number080PlanLimit || 0,
        insurancePlanPrice: billingPlan?.insurancePlanPrice || 0,
        insurancePlanLimit: billingPlan?.insurancePlanLimit || 0,
        bizLicenseFile: '',
        contractsFiles: contracts.length
          ? contracts.map((v: any) => ({
              contractId: v.id,
              url: v.url,
              contractFile: null,
              contractFileName: v.contractFileName,
              contractSignDate: v.contractSignDate,
            }))
          : [
              {
                contractId: null,
                contractFile: null,
                contractFileName: null,
                contractSignDate: null,
              },
            ],
        contractDate,
      });
    }
  }, [items]);

  const getData = async (id: number) => {
    try {
      const { data }: any = await clinicsApi.getDetail(id);
      setData(data || null);
    } catch (error: any) {
      snackbar.alert(`서버에러가 발생했습니다. (${error.name})`);
    }
  };

  const handleEditMode = () => {
    history.push(`/clinics/${id}?edit=true`);
  };

  const handleChangeValue = (newValue: any) => {
    setValue({ ...value, ...newValue });
  };

  const handleSaveSubmit = async () => {
    try {
      const params: any = {
        ...value,
        memo:
          value.memo !== ''
            ? [
                {
                  timestamp: format(new Date(), 'yy-MM-dd'),
                  content: value.memo,
                },
              ]
            : '',
        owner: {
          email: value.contactEmail,
          password: value.contactTempPassword,
        },
        treatmentFields:
          (value.treatmentFields || []).length > 0
            ? JSON.stringify(value.treatmentFields)
            : '[]',
        contractDate: value.contractDate
          ? format(new Date(value.contractDate), 'yyyy-MM-dd')
          : null,
      };

      delete params.contracts;
      delete params.contractsFiles;
      delete params.bizLicenseFile;

      let res: any = await clinicsApi.update(items.id, params);
      let formData: any = {};
      if (value?.contractsFiles.length > 0) {
        let fileList = value.contractsFiles.map((f: any) => {
          let obj: any = {
            contractFileName: f.contractFileName,
            contractSignDate: f.contractSignDate
              ? format(new Date(f.contractSignDate), 'yyyy-MM-dd')
              : undefined,
            contractFile: f.contractFile,
          };
          if (!f.contractId && (f.contractFile || f.contractSignDate)) {
            obj.updateType = 'CREATE';
          }

          if (
            f.contractId &&
            (f.contractFile ||
              items.contracts.find((c: any) => c.id === f.contractId)
                .contractSignDate !== f.contractSignDate)
          ) {
            obj.contractId = f.contractId;
            obj.updateType = 'UPDATE';
          }
          return obj;
        });

        for (let contract of items.contracts) {
          if (
            !value.contractsFiles.find((f: any) => f.contractId === contract.id)
          ) {
            fileList.push({
              contractId: contract.id,
              updateType: 'DELETE',
            });
          }
        }

        formData.contractFileList = fileList.filter((f: any) => f.updateType);
      }
      if (value?.bizLicenseFile) {
        formData.bizLicenseFile = value.bizLicenseFile;
      }

      if (formData?.contractFileList.length > 0 || formData?.bizLicenseFile) {
        const response = await clinicsApi.updateFiles(
          res.data.id,
          objectToFormData(
            {
              ...formData,
            },
            {
              indices: true,
            }
          )
        );
        res = response;
      }
      snackbar.success(`수정되었습니다.`);
      setItems(res.data);
      history.push(`/clinics/${id}`);
    } catch (e: any) {
      console.error(e);
      if (e.name === 'Error') {
        snackbar.alert('오류가 발생했습니다. 잠시후 다시 시도해주세요');
      } else if (e.name === 'NO_PERMISSION') {
        snackbar.alert('접근 권한이 없습니다.');
      } else if (e.name !== null) {
        snackbar.alert('모든값을 입력해주세요');
      } else {
        snackbar.alert(e.description);
      }
    }
  };

  return (
    <Box className="clinic-detail main-contents">
      <PageNav pages={['거래처 계정관리', '거래처 상세정보']} />
      <Stack flexDirection={'row'} alignItems={'center'} className="mt-13">
        <div className="flex">
          {data && (
            <Tooltip
              title={app.$me.role === 'manager' ? '권한이 없습니다.' : ''}
            >
              <div>
                <Button
                  variant="contained"
                  className="edit-mode-btn"
                  onClick={editMode ? handleSaveSubmit : handleEditMode}
                  disabled={app.$me.role === 'manager'}
                >
                  {editMode ? '저장' : `수정`}
                </Button>
              </div>
            </Tooltip>
          )}
        </div>
      </Stack>
      {editMode && data ? (
        <Editview
          data={items}
          value={value}
          onChangeValue={handleChangeValue}
        />
      ) : data ? (
        <Readview data={items} />
      ) : (
        <Box className="no-data no-contents">조회된 데이터가 없습니다.</Box>
      )}
    </Box>
  );
};
export default observer(ClinicDetail);
