import { color } from '~/theme';
import { FilterDateRange } from '~/components/commons/SearchFilterDateRange';
import { useMemo } from 'react';
import { DropdownList, Button, Box, Typography } from './SearchView.styled';
import { agencies as agenciesOptions } from '~/hooks/constants';
import { stripEmpty } from '~/utils/objUtil';
import { format } from 'date-fns';

type SearchViewProps = {
  width: number;
  onSubmit: () => void;
  onChange: (params: any) => void;
  value: any;
  onSearch: (params: any) => void;
};

export const SearchView = ({
  onSearch,
  value,
  onChange,
  width,
}: SearchViewProps) => {
  const searchFilterValues = useMemo(() => {
    return stripEmpty(
      {
        ...value,
      },
      {
        emptyString: true,
        emptyArray: true,
      }
    );
  }, [value]);

  const disabled = useMemo(() => {
    return Object.keys(searchFilterValues).length <= 3;
  }, [searchFilterValues]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        width: width ?? undefined,
        maxWidth: 1800,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          background: 'white',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            border: `1px solid ${color.common.line}`,
            '& > *:not(:last-child)': {
              borderRight: `1px solid ${color.common.line}`,
            },
          }}
        >
          <Box width={380}>
            <FilterDateRange
              label="해지일"
              placeholder={'YYYY-MM-DD'}
              startAt={value.startCancelAt}
              endAt={value.endCancelAt}
              onChange={(start: string, end: string) => {
                onChange({
                  startCancelAt: start
                    ? format(new Date(start), 'yyyy-MM-dd')
                    : undefined,
                  endCancelAt: end
                    ? format(new Date(end), 'yyyy-MM-dd')
                    : undefined,
                });
              }}
            />
          </Box>
          <Box
            sx={{
              width: 250,
              background: 'white',
            }}
          >
            <DropdownList
              value={agenciesOptions.filter((o) =>
                value.contractAgency?.includes(o.value)
              )}
              options={agenciesOptions.map((item: any) => {
                item.label = item.key;
                item.id = item.value;
                return item;
              })}
              onChange={(v: any) => {
                onChange({ contractAgency: v?.value });
              }}
              variant="search"
              getOptionLabel={(o: any) => {
                if (o[0]) {
                  return o[0]?.label ? o[0].label : '';
                } else {
                  return o ? (o?.label ? o.label : '') : '';
                }
              }}
              startAdornment={
                <Typography className="width-wide">{'계약대행사'}</Typography>
              }
              placeholder="전체"
            />
          </Box>
          <Box
            sx={{
              width: 250,
              background: 'white',
            }}
          >
            <DropdownList
              value={agenciesOptions.filter((o) =>
                value.educationAgency?.includes(o.value)
              )}
              options={agenciesOptions.map((item: any) => {
                item.label = item.key;
                item.id = item.value;
                return item;
              })}
              onChange={(v: any) => {
                onChange({ educationAgency: v?.value });
              }}
              variant="search"
              getOptionLabel={(o: any) => {
                if (o[0]) {
                  return o[0]?.label ? o[0].label : '';
                } else {
                  return o ? (o?.label ? o.label : '') : '';
                }
              }}
              startAdornment={
                <Typography className="width-wide">{'교육대행사'}</Typography>
              }
              placeholder="전체"
            />
          </Box>
        </Box>
        <Button
          styled="outline"
          onClick={onSearch}
          className="search-btn"
          disabled={disabled}
        >
          조회
        </Button>
      </Box>
    </Box>
  );
};
