import styled from 'styled-components';

export const PaginationContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas: 'pagination select';
  margin-left: auto;
  gap: 10px;

  & > *:first-child {
    grid-area: pagination;
  }
  & > *:last-child {
    grid-area: select;
  }
`;

export const PaginationLimitSelectContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 70px;
`;
