import {
  styled,
  TextField as MuiTextField,
  Typography as MuiTypography,
  typographyClasses,
  Box as MuiBox,
  Stack as MuiStack,
  Button as MuiButton,
  buttonClasses,
} from '@mui/material';
import { Button as _Button } from '~/components/button/Button';

export const Typography = styled(MuiTypography)(
  ({ theme }) => `
  font-family: Noto Sans KR;
  color: ${theme.palette.grey['700']};

  &.new-password-label {
    margin-top: 20px;
  }

  &.${typographyClasses.subtitle1} {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: -0.01em;
    
  }

  &.${typographyClasses.body1} {
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
  }
`
);

export const TextField = styled(MuiTextField)(
  ({ theme }) => `
  &.MuiOutlinedInput-root {
    &.Mui-focused {
      border: 0 none;
    }
    
  }
  .Mui-disabled {
    input {
      background: ${theme.palette.grey['200']};
    }
  }

  &.error {
    fieldset {
      border: 1px solid ${theme.palette.error['main']};
    }

    .Mui-focused  {
      fieldset {
        border-color: ${theme.palette.error['main']};
      }
    }
  }

  fieldset {
    border: 0 none;
  }
  
  input {
    height: 52px;
    box-sizing: border-box;
    background: ${theme.palette.bluegrey['100']};
    font-size: 13px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    border: 0 none;

    &::placeholder {
      color: ${theme.palette.bluegrey['600']};
      opacity: 1;
    }
  }
`
);

export const Box = styled(MuiBox)(
  () => `

  &.new-password-page-container{
    width: 100vw;
    height: 100vh;
    min-height: 670px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &.new-password-contents-wrapper {
    width: 340px;
    min-height: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;

    form {
      width: 100%;
    }
  }

`
);

export const Stack = styled(MuiStack)`
  &.mt-48 {
    margin-top: 48px;
  }
`;

export const Wrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
`;

export const ButtonWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  gap: 1;
`;

export const Button = styled(MuiButton)(
  () => `
  min-width: 58px;
  padding: 0;
  height: 28px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  box-shadow: none;

  &.black {
    background: #293142;
  }

  &.${buttonClasses.disabled} {
    color: #ffffff;
  }

`
);
