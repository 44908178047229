import { action, makeObservable, observable } from 'mobx';
import ModalBasic from '~/components/modals/ModalBasic';
import ModalImages from '~/components/modals/ModalImages';
import ModalInput from '~/components/modals/ModalInput';
import ModalSlot from '~/components/modals/ModalSlot';
import ModalLoading from '~/components/modals/ModalLoading';
import { translate, copy } from '~/filters';
import { $qb } from '~/scripts/querybuilder';
import * as moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import { ClinicRepo } from '~/components/repo/Clinic/ClinicRepo';
import { CertificationRepo } from '~/components/repo/Certification/CertificationRepo';
import { SmsRepo } from '../components/repo/Sms/SmsRepo';
import { SalesRepo } from '../components/repo/Sales/SalesRepo';
import { ConfirmModal } from '~/components/modals/ConfirmModal';
import { KeyboardEvent } from 'react';

momentDurationFormatSetup(moment);

class App {
  constructor() {
    makeObservable(this, {
      loading: observable,
      modals: observable,
      $me: observable,
      sleep: observable,
      $loading: observable,
      loadingToggle: action,
      onKeyPressEnterFunction: action,
    });
  }
  uiState = JSON.parse(window.localStorage.getItem('ui') || '{}') || {};
  modals: any[] = [];
  loading = false;
  $modal = {
    confirm: (options: any) =>
      new Promise((resolve) => this.addModal(ConfirmModal, options, resolve)),
    basic: (options: any) =>
      new Promise((resolve) => this.addModal(ModalBasic, options, resolve)),
    images: (options: any) =>
      new Promise((resolve) => this.addModal(ModalImages, options, resolve)),
    custom: ({ component, options }: any) =>
      new Promise((resolve) => this.addModal(component, options, resolve)),
    input: (options: any) =>
      new Promise((resolve) => this.addModal(ModalInput, options, resolve)),
    slot: (options: any) =>
      new Promise((resolve) => this.addModal(ModalSlot, options, resolve)),
    loading: (options: any) =>
      new Promise((resolve) => this.addModal(ModalLoading, options, resolve)),
    close: (options: any) => new Promise(() => this.closeModal(options.type)),
  };
  $bus: any = {
    events: {},
    $emit: (eventName: string, args: any) => {
      if (this.$bus.events[eventName]) {
        this.$bus.events[eventName].forEach((foo: any) => foo(args));
      }
    },
    $on: (eventName: string, callback: () => void) =>
      (this.$bus.events[eventName] = (this.$bus.events[eventName] || []).concat(
        [callback]
      )),
    $off: (eventName: string) => delete this.$bus.events[eventName],
  };
  $qb = $qb;
  $router: any;
  $me: {
    role: string | null;
    email: string | null;
    name: string | null;
    phoneNumber: string | null;
    duty: string | null;
    position: string | null;
    status: string | null;
    id: number | null;
    passwordExpiration: boolean;
    passwordChanged?: boolean;
    authorityGroup?: any;
  } = {
    role: null,
    email: null,
    name: null,
    phoneNumber: null,
    duty: null,
    position: null,
    status: null,
    id: null,
    passwordExpiration: false,
  };
  $clinicRepo = ClinicRepo;
  $certificationRepo = CertificationRepo;
  $smsRepo = SmsRepo;
  $salesRepo = SalesRepo;
  $loading = false;

  setUiState = (obj: any) => {
    window.localStorage.setItem(
      'ui',
      JSON.stringify({ ...this.uiState, ...obj })
    );
    this.uiState = JSON.parse(window.localStorage.getItem('ui') || '{}') || {};
  };
  addModal = (component: any, options: any, resolve: (value: any) => void) =>
    this.modals.push({ component, options, resolve });
  closeModal = (type: string) => {
    this.modals = this.modals.filter((v: any) => v.options.type !== type);
  };

  popModal = () => this.modals.pop();

  $translate = (key: string) => translate(key);

  $mustParse = (obj: any) => {
    try {
      return JSON.parse(obj);
    } catch (e) {
      return obj;
    }
  };

  loadingToggle = (loading: boolean) => {
    this.$loading = loading;
  };

  onKeyPressEnterFunction = (e: KeyboardEvent, requestFunction: () => void) => {
    if (e.key === 'Enter') {
      requestFunction();
    }
  };

  sleep = (ms: number) => {
    const wakeUpTime = Date.now() + ms;
    // eslint-disable-next-line no-empty
    while (Date.now() < wakeUpTime) {}
  };
}

export default new App();
