import { useApp } from '~/hooks/useApp';
import { observer } from 'mobx-react';

type ModalSlotProps = {
  options: any;
  close: (event?: any) => void;
};

const ModalSlot = ({ options, close }: ModalSlotProps) => {
  const app = useApp();
  const onClickButton = (button: any) => {
    close(options.buttons.indexOf(button));
  };

  return (
    <div className="modal-slot modal-consulting-evnt">
      <div className="head flex-row flex-between items-start">
        <div className={`title ${options.titleClass || ''}`}>
          {app.$translate(options.title)}
        </div>
        {options.showCloseButton ? (
          <i onClick={() => close()} className="zmdi zmdi-close" />
        ) : null}
      </div>
      {/* preparedDom must be a function that returns a DOM */}
      {options.preparedDom ? options.preparedDom() : null}
      {options.buttons ? (
        <div className="buttons">
          <div className="flex-row">
            {options.buttons.map((button: any, idx: number) => {
              return (
                <button
                  key={idx}
                  onClick={() => onClickButton(button)}
                  className={button.class}
                >
                  {app.$translate(button.text)}
                </button>
              );
            })}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default observer(ModalSlot);
