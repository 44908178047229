import { makeAutoObservable } from 'mobx';

export const FileSampleType = {
  clinic: 'CLINIC',
  customer: 'CUSTOMER',
};

export class FileSample {
  constructor() {
    makeAutoObservable(this);
  }

  type: any;
  name: any;
  image_id: any;
}
