import axios from 'axios';

const init = (auth: any) => {
  axios.interceptors.response.use(
    (res) => {
      if (!res) {
        console.error('server might not be responding');
        auth.signOut();
        return;
      }

      // We almost always just need res.data, not the wrapped object
      return res.data;
    },
    (err) => {
      if (!err.response) {
        throw err;
      }

      if (err.response.status === 401) {
        auth.signOut();
      }

      throw err.response.data;
    }
  );

  axios.defaults.baseURL = process.env.REACT_APP_API_URL + '/admin';
};

const setAuthToken = (accessToken: string, refreshToken: string) => {
  axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  localStorage.setItem('access_token', accessToken);
  localStorage.setItem('refresh_token', refreshToken);
};

export { init, setAuthToken };
