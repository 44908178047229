import { useState, useEffect, useRef, useMemo } from 'react';
import { observer } from 'mobx-react';
import { runInAction, toJS } from 'mobx';
import { objectToFormData } from 'object-to-formdata';
import { ClinicSubAccountModel } from '../../model/Clinic/ClinicSubAccountModel';
import { useApp } from '~/hooks/useApp';
import { useSnackbarContext } from '~/components/providers/SnackbarProvider';

type ModalCertificationsProps = {
  options: any;
  close: () => void;
};

const ModalCertifications = ({ options, close }: ModalCertificationsProps) => {
  const app = useApp();
  const snackbar = useSnackbarContext();
  const derFilename: any = useRef();
  const keyFilename: any = useRef();
  const [obj, setObj] = useState(options.cert || {});
  const [cert, setCert] = useState<any>({ derFilename: {}, keyFilename: {} });
  const [isUpdate, setIsUpdate] = useState(false);
  const [userInfo, setUserInfo] = useState({
    userId: '',
    status: '',
    name: '',
    phoneNumber: '',
  });
  const [userCertFlag, setUserCertFlag] = useState('display-none');

  const disabled = useMemo(() => {
    return !obj.clinicId || !obj.userId || !obj.password;
  }, [obj.clinicId, obj.userId, obj.password]);

  useEffect(() => {
    if (options.cert !== undefined) {
      let user = {
        userId: options.cert.clinicId,
        status: options.cert.userStatus,
        name: options.cert.userName,
        phoneNumber: options.cert.phoneNumber,
      };
      let object = {
        certId: options.cert.certId,
        clinicId: options.cert.clinicId,
        ...user,
        derFilename: '',
        keyFilename: '',
        /*derFilename: options.cert.certification.derFilename,
        keyFilename: options.cert.certification.keyFilename,*/
      };

      const userData = ClinicSubAccountModel.create({});
      const param = app.$qb().customParam('clinicId', options.cert.clinicId);
      getUsers(userData, param).then(() => {
        setObj(object);
        setUserInfo(user);
        setUserCertFlag('display-none');
        const e = { target: { name: 'userId', value: options.cert.userId } };
        onChangeUser(e);
        setIsUpdate(true);
      });
    }
  }, [options.cert]);

  const getUsers = async (userData: any, param: any) => {
    userData.setData(await app.$clinicRepo.users.all(param.build()));
    runInAction(() => {
      options.users = userData.userList;
    });
  };

  const onSelectFile = (e: any, type: string) => {
    const file = e.target.files[0];
    cert[type] = file;
    setCert({ ...cert });
    setObj({ ...obj, [type]: file });
  };

  const onClickCreate = async () => {
    const endpoint = options.cert === undefined ? 'create' : 'update';
    const object = obj;
    //TODO : 유효성 체크
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    if (disabled) {
      snackbar.alert(app.$translate('MISSING_DATA_REQUIRED_FIELD'));
      return;
    }

    try {
      if (endpoint === 'create') {
        await app.$certificationRepo.certification.create(
          objectToFormData(object),
          config
        );
      } else {
        await app.$certificationRepo.certification.update(
          object.certId,
          objectToFormData(object),
          config
        );
      }

      snackbar.success(app.$translate(`${endpoint.toUpperCase()}D`));
      close();
      app.sleep(500);
      options.callApi();
    } catch (e: any) {
      if (
        e.description !== '' &&
        e.description !== null &&
        e.description !== '[]'
      ) {
        snackbar.alert(app.$translate('MISSING_DATA_REQUIRED_FIELD'));
      } else {
        snackbar.alert('오류가 발생했습니다. 잠시후 다시 시도해주세요');
      }
    }
  };

  const onChangeClinic = (e: any) => {
    const { name, value } = e.target;
    const data = { ...obj, [name]: value };
    setObj(data);

    const userData = ClinicSubAccountModel.create({});
    const param = app.$qb().customParam('clinicId', e.target.value);
    getUsers(userData, param);
  };

  const onChangeUser = (e: any) => {
    const { name, value } = e.target;
    const data = { ...obj, [name]: value };

    const user = options.users.filter((v: any) => {
      return v.id === Number(value);
    })[0];

    let changeUser = {
      userId: user.id,
      status: user.status,
      name: user.name,
      phoneNumber: user.phoneNumber,
    };

    setUserCertFlag(user.certification === null ? 'display-none' : '');
    setUserInfo({ ...userInfo, ...changeUser });
    setObj(data);
  };

  return (
    <div className="modal-input">
      <div className="head flex-row flex-between items-start">
        <div className="title">
          {app.$translate(!obj.id ? '전자서명 등록' : '전자서명 수정')}
        </div>
      </div>

      <div className="body">
        <div className="form-control required">
          <div className="label-required">
            <label>상호(병원명)</label>
          </div>

          <select
            name="clinicId"
            onChange={onChangeClinic}
            value={
              !obj.clinicId
                ? options.cert !== undefined
                  ? options.cert.clinic.id
                  : ''
                : obj.clinicId
            }
            disabled={isUpdate}
          >
            <option value=""></option>
            {options.clinics.map((v: any, i: number) => {
              const { id, name } = toJS(v);
              return (
                <option value={id} key={i}>
                  {name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="form-control required">
          <div className="label-required">
            <label>연동 계정</label>
          </div>
          <select
            name="userId"
            onChange={onChangeUser}
            value={
              !obj.userId
                ? options.cert !== undefined
                  ? options.cert.id
                  : ''
                : obj.userId
            }
            disabled={isUpdate}
          >
            <option value=""></option>
            {options.users.map((v: any, i: number) => {
              const { id, email } = toJS(v);
              return (
                <option value={id} key={i}>
                  {email}
                </option>
              );
            })}
          </select>
          <p className={`${userCertFlag}`}>
            이미 전자서명이 연동되어 있는 계정입니다.
          </p>
        </div>
        <div className="form-control">
          <label>계정 상태</label>
          <input disabled={true} value={userInfo.status} />
        </div>
        <div className="form-control required">
          <label>{app.$translate('NAME')}</label>
          <input disabled={true} value={userInfo.name || ''} />
        </div>
        <div className="form-control required">
          <label>휴대폰 번호</label>
          <input disabled={true} value={userInfo.phoneNumber || ''} />
        </div>
        <div className="form-control">
          <label>공인인증서 첨부</label>
          <div className="certificate-attachment">
            <input
              placeholder="signCert.der 첨부"
              defaultValue={cert.derFilename.name}
            />
            <button
              className="btn btn-black btn-sm flex-wrap"
              onClick={() => derFilename.current.click()}
              style={{ marginTop: '8px', marginBottom: '8px' }}
            >
              {'찾기'}
            </button>
            <input
              type="file"
              className="display-none"
              ref={derFilename}
              onChange={(e) => onSelectFile(e, 'derFilename')}
            />
          </div>
          <div>
            연동할 사용자와 일치하는 인증서를 첨부하세요.
            <br />
            파일 경로 : C:\Users\[사용자계정]\AppData\LocaLow\
            <br />
            NPKI\TradeSgin\user\cn=사용자이름\signCert.der
            <br />
          </div>
          <div className="certificate-attachment">
            <input
              placeholder="signPri.key 첨부"
              defaultValue={cert.keyFilename.name}
            />
            <button
              className="btn btn-black btn-sm flex-wrap"
              onClick={() => keyFilename.current.click()}
              style={{ marginTop: '8px', marginBottom: '8px' }}
            >
              {'찾기'}
            </button>
            <input
              type="file"
              className="display-none"
              ref={keyFilename}
              onChange={(e) => onSelectFile(e, 'keyFilename')}
            />
          </div>
          <div>
            파일 경로 : C:\Users\[사용자계정]\AppData\LocaLow\
            <br />
            NPKI\TradeSgin\user\cn=사용자이름\signPri.key
            <br />
          </div>
        </div>
        <div className="form-control required">
          <div className="label-required">
            <label>패스워드 입력</label>
          </div>
          <input
            type="password"
            name="password"
            onChange={(e) => setObj({ ...obj, password: e.target.value })}
            autoComplete="new-password"
          />
        </div>
      </div>
      <div className="buttons">
        <div className="flex-row">
          <button onClick={() => close()} className="btn btn-default">
            {app.$translate('CANCEL')}
          </button>
          <button
            onClick={onClickCreate}
            className="btn btn-primary"
            disabled={disabled}
          >
            {app.$translate('CONFIRM')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default observer(ModalCertifications);
