import { ApiResource } from './apiResource';
import { ApiDataResponse } from '~/api/apiClient';
import $http from 'axios';
import axios from '../axios_2';

interface ClinicsNamesData {
  id: number;
  name: string;
}

export class ClinicsApi extends ApiResource {
  async list(params: any) {
    return this.client.get(`/admin/clinics`, params);
  }

  async getSummary(params: any) {
    return this.client.get(`/statistics/clinics/summary`, params);
  }

  async deactivate(id: number, body: FormData) {
    // const requestHeaders: HeadersInit = new Headers();
    // const accessToken = localStorage.getItem('access_token')
    //   ? `Bearer ${localStorage.getItem('access_token')}`
    //   : '';
    // requestHeaders.set('Content-Type', 'multipart/form-data');
    // requestHeaders.set('Authorization', accessToken);

    return $http.put(
      `${process.env.REACT_APP_API_URL}/admin/clinics/cancel/${id}`,
      body,
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }
    );
  }

  async create(params: any) {
    return this.client.post(`/admin/clinics`, params);
  }

  async update(id: number, params: any) {
    return this.client.put(`/admin/clinics/${id}`, params);
  }

  async userList(params: any) {
    return this.client.get(`/admin/clinics/users`, params);
  }

  async getDetail(id: number) {
    return this.client.get(`/admin/clinics/${id}`);
  }

  async getCallerList(id: number) {
    return this.client.get(`/admin/clinic/caller_number/${id}`);
  }

  async excelCreateCustomers(clinicId: string, payload: any) {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/clinics/${clinicId}/customers/requests/excel`,
      payload,
      {
        responseType: 'blob',
      }
    );
    return response;
  }
  async excelCreateDepartments(clinicId: string, payload: any) {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/clinics/${clinicId}/departments/requests/excel`,
      payload,
      {
        responseType: 'blob',
      }
    );
    return response;
  }

  async getDepartments(clinicId: string, params: any) {
    return this.client.get(`/admin/clinics/${clinicId}/departments`, params);
  }

  async excelCreateProducts(clinicId: string, payload: any) {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/clinics/${clinicId}/products/requests/excel`,
      payload,
      {
        responseType: 'blob',
      }
    );
    return response;
  }

  async excelCreateTreatments(clinicId: string, payload: any) {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/clinics/${clinicId}/treatment_categories/requests/excel`,
      payload,
      {
        responseType: 'blob',
      }
    );
    return response;
  }

  async getProducts(clinicId: string, params: any) {
    return this.client.get(`/admin/clinics/${clinicId}/products`, params);
  }

  async getTreatments(clinicId: string, params: any) {
    return this.client.get(
      `/admin/clinics/${clinicId}/treatment_categories`,
      params
    );
  }

  async getCustomers(clinicId: string, params: any) {
    return this.client.get(`/admin/clinics/${clinicId}/customers`, params);
  }

  async putCallerList(
    id: number,
    body: {
      callerNumberList: {
        id?: number;
        number?: string;
        updateType?: 'CREATE' | 'DELETE' | 'UPDATE';
        visible?: boolean;
      }[];
    }
  ) {
    return this.client.put(`/admin/clinic/caller_number/${id}`, body);
  }

  async memoUpdate(id: number, params: any) {
    return this.client.put(`/admin/clinics/memo/${id}`, params);
  }

  async uploadFiles(id: number, body: any) {
    return $http.post(
      `${process.env.REACT_APP_API_URL}/admin/clinics/${id}/files`,
      body,
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }
    );
  }

  async updateFiles(id: number, body: any) {
    return $http.put(
      `${process.env.REACT_APP_API_URL}/admin/clinics/${id}/files`,
      body,
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }
    );
  }

  async deactivatelist(params: any) {
    return this.client.get(`/admin/clinics/cancel/history`, params);
  }

  async updateDeactivate(id: number, body: FormData) {
    return $http.put(
      `${process.env.REACT_APP_API_URL}/admin/clinics/cancel/history/${id}`,
      body,
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }
    );
  }

  async clinicUserResetPasswrod(userId: number, email: string) {
    return this.client.post(`/admin/clinics/users/password/${userId}`, {
      email,
    });
  }

  async getClinicsNames() {
    return this.client.get<ApiDataResponse<ClinicsNamesData[]>>(
      '/admin/clinics/names'
    );
  }
}
