import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useApp } from '~/hooks/useApp';
import { PriceTableRepo } from '~/components/repo/PriceTableRepo';
import { PriceTableTable } from './PriceTableTable';
import { PriceTableFormModal } from './PriceTableFormModal';
import PageNav from '~/components/commons/PageNav';

export const PriceTablePage = observer(() => {
  const repo = new PriceTableRepo();
  const app = useApp();
  const [items, setItems] = useState([]);

  async function load() {
    const res = await repo.all();
    setItems(res.data);
  }

  useEffect(() => {
    load();
  }, []);

  const onClickCreate = async () => {
    await app.$modal.custom({
      component: PriceTableFormModal,
      options: {
        action: 'create',
      },
    });

    load();
  };

  function onChange() {
    load();
  }

  return (
    <div className="main-contents">
      <PageNav pages={['GENERAL', `요금`]} />
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>이름</th>
            <th>코드</th>
            <th>가격</th>
            <th>비용</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <PriceTableTable items={items} onChange={onChange} />
        </tbody>
      </table>
    </div>
  );
});
