import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { format } from 'date-fns';
import { useApp } from '~/hooks/useApp';
import { maskEmail, maskName } from '~/utils/maskString';
import ModalEditClinicMemo from '../../modals/clinic/ModalEditClinicMemo';

type CertificationTableProps = {
  item: any;
  callApi: () => void;
  openModalEditCertification: (cert?: any) => void;
};

const CertificationTable = ({
  item,
  callApi,
  openModalEditCertification,
}: CertificationTableProps) => {
  const app = useApp();
  const onAddMemo = (memo: any, id: number) => {
    openModalEditClinicMemo(memo, -1, id);
  };

  const onClickMemo = (memo: any, idx: number, id: number) => {
    openModalEditClinicMemo(memo, idx, id);
  };

  const openModalEditClinicMemo = (
    memo: any,
    memoIndex: number,
    id: number
  ) => {
    let clinic: any;

    if (memo == null) {
      clinic = { memo: [], id: id };
    } else {
      clinic = { memo: memo, id: id };
    }

    runInAction(() => {
      app.$modal.custom({
        component: ModalEditClinicMemo,
        options: {
          clinic,
          memoIndex,
          callApi,
        },
      });
    });
  };

  const TableData = ({ item }: any) => {
    return item.map((list: any) => (
      <tr key={list.certId}>
        <td>
          {list.certStatus}
          <div
            style={{
              display: 'table',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <button
              className="btn btn-sm btn-black"
              onClick={() => {
                openModalEditCertification(list);
              }}
            >
              수정
            </button>
          </div>
        </td>
        <td>{format(new Date(list.certEndDate), 'yyyy-MM-dd')}</td>
        <td>{format(new Date(list.certCreatedAt), 'yyyy-MM-dd')}</td>
        <td>{list.certSubjectDn}</td>
        <td>{list.clinicName}</td>
        <td>{maskEmail(list.userEmail)}</td>
        <td>{list.userStatus}</td>
        <td>{maskName(list.userName)}</td>
        <td className="td-align-left td-align-vertical-middle">
          <div style={{ float: 'left' }}>
            {list.clinicMemo !== '' &&
            list.clinicMemo !== null &&
            list.clinicMemo !== '[]'
              ? JSON.parse(list.clinicMemo).map((item: any, idx: number) => {
                  return (
                    <div
                      onClick={() =>
                        onClickMemo(list.clinicMemo, idx, list.clinicId)
                      }
                      className="td-underline"
                      key={idx}
                      style={{ cursor: 'pointer' }}
                    >
                      {item.content} ({item.timestamp})
                    </div>
                  );
                })
              : null}
          </div>
          <div
            style={{
              display: 'inline-block',
              float: 'right',
              verticalAlign: 'middle',
            }}
          >
            <button
              onClick={() => onAddMemo(list.clinicMemo, list.clinicId)}
              className="btn btn-info"
            >
              추가
            </button>
          </div>
        </td>
      </tr>
    ));
  };

  return item.length > 0 ? (
    <TableData item={item} />
  ) : (
    <tr>
      <td colSpan={10}>검색결과가 없습니다.</td>
    </tr>
  );
};

export default observer(CertificationTable);
