import { useHistory } from 'react-router-dom';
import { getRoleName, getStatusName } from '~/pages/management/management';
import { Button, Td } from './ManagementTable.styled';

type ManagementTableProps = {
  data: any;
};

export const ManagementTable = ({ data }: ManagementTableProps) => {
  const history = useHistory();

  const onclick = (item: any) => {
    history.push(`/management/detail/${item.id}`);
  };

  return data.map((item: any) => (
    <tr key={item.id}>
      <Td>
        <Button
          disabled={item.status === 'deleted'}
          onClick={() => onclick(item)}
        >
          {item.email}
        </Button>
      </Td>
      <Td disabled={item.status !== 'active'}>{item.id}</Td>
      <Td disabled={item.status !== 'active'}>{item.name}</Td>
      <Td disabled={item.status !== 'active'}>{getRoleName(item.role)}</Td>
      <Td disabled={item.status !== 'active'}>{getStatusName(item.status)}</Td>
    </tr>
  ));
};
