import { makeAutoObservable } from 'mobx';

export const PriceTableCode = {
  sms: 'sms',
  lms: 'lms',
  mms: 'mms',
  alimtalk: 'alimtalk',
  ftalk_txt: 'ftalk_txt',
  ftalk_img: 'ftalk_img',
  ftalk_wide: 'ftalk_wide',
  babi_link: 'babi_link',
};

export class PriceTable {
  constructor() {
    makeAutoObservable(this);
  }

  id: any;
  name: any;
  code: any;
  price: any;
  cost: any;
}
