import $http from 'axios';

const axiosApi = () => {
  const instance = $http.create();
  instance.interceptors.response.use(
    (res) => {
      if (!res) {
        console.error('server might not be responding');
        return;
      }
      return res;
    },
    (err) => {
      if (!err.response) {
        throw err;
      }
      throw err.response.data;
    }
  );
  return instance;
};

export default axiosApi();
