import { ComponentType, useState } from 'react';
import update from 'immutability-helper';
import _DateRangeInput from '~/components/commons/SearchFilterDateRangeInput';
import { Fade } from '@mui/material';
import { ClearButton } from '~/components/commons/ClearButton';
import {
  FilterItem,
  LabelText,
  Wrapper,
  InputEndAdornment,
} from './SearchFilterDateRange.styled';

const DateRangeInput = _DateRangeInput as ComponentType<any>;

type FilterDateRangeProps = {
  startAt?: string | Date;
  endAt?: string | Date;
  label: string;
  onChange: (param1: any, param2: any) => void;
  useClearButton?: boolean;
  showMonthYearPicker?: boolean;
  dateFormat?: string;
  placeholder?:
    | {
        start: Date | string;
        end: Date | string;
      }
    | string;
};

export const FilterDateRange = ({
  startAt,
  endAt,
  label,
  onChange,
  useClearButton = true,
  placeholder,
  ...props
}: FilterDateRangeProps) => {
  const [isFocused, setIsFocused] = useState({
    startAt: false,
    endAt: false,
  });

  const onChangeDateRange = (start: string | Date, end: string | Date) => {
    onChange(start ? new Date(start) : '', end ? new Date(end) : '');
  };

  const onReset = () => {
    onChange(null, null);
  };

  return (
    <FilterItem focus={isFocused.startAt || isFocused.endAt} {...props}>
      <LabelText>{label}</LabelText>
      <Wrapper>
        <DateRangeInput
          focus={isFocused}
          onFocus={(focus: any) =>
            setIsFocused(
              update(isFocused, {
                [focus]: { $set: true },
              })
            )
          }
          onBlur={(focus: any) =>
            setIsFocused(
              update(isFocused, {
                [focus]: { $set: false },
              })
            )
          }
          placeholder={placeholder}
          dateFormat="yyyy-MM-dd"
          value={{
            start: startAt,
            end: endAt,
          }}
          onChange={onChangeDateRange}
          autocomplete="off"
          {...props}
        />
        {useClearButton && (
          <Fade
            in={
              (isFocused.startAt || isFocused.endAt) &&
              (Boolean(startAt) || Boolean(endAt))
            }
          >
            <InputEndAdornment position="end">
              <ClearButton onClick={onReset} />
            </InputEndAdornment>
          </Fade>
        )}
      </Wrapper>
    </FilterItem>
  );
};
