import {
  CCreateElement,
  CSidebar as _CSidebar,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle as _CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavItem as _CSidebarNavItem,
} from '@coreui/react';
import styled from 'styled-components';
import CIcon from '@coreui/icons-react';
import { useMemo, useState } from 'react';

import '~/scss/sidebar.min.css';
import { observer } from 'mobx-react';
import navigation from './_nav';
import { runInAction } from 'mobx';
import { useApp } from '~/hooks/useApp';
import { useSnackbarContext } from '~/components/providers/SnackbarProvider';
import { useAuth as useProviderAuth } from '~/components/providers/AuthProvider';

const CSidebar = styled(_CSidebar)(
  () => `
  &.c-sidebar-minimized{
    .c-sidebar-nav {
      overflow: hidden;
    }
    
  }
  
`
);

const CSidebarNavTitle = styled(_CSidebarNavTitle)(
  () => `
  background: #38455B;
`
);

const CSidebarNavItem = styled(_CSidebarNavItem)(
  () => `
  .c-sidebar-nav-link  {
    &:hover {
      background: hsla(0,0%,100%,.05)
    }
    &.c-active, &.c-active&:hover {
      background: #28313F; 
    }
  }
`
);

const SideNav = () => {
  const app = useApp();
  const snackbar = useSnackbarContext();
  const auth_v2 = useProviderAuth();
  const [minimize, setMinimize] = useState(false);

  const logOut = () => {
    runInAction(() => {
      app.$modal
        .basic({
          title: 'LOGOUT',
          body: app.$translate('MODAL_LOGOUT_BODY'),
          buttons: [
            {
              text: 'CANCEL',
              class: 'btn-default',
            },
            {
              text: 'CONFIRM',
              class: 'btn-primary',
            },
          ],
        })
        .then((selectedIdx) => {
          if (selectedIdx === 1) {
            snackbar.success('로그아웃되었습니다.');
            auth_v2.signout();
          }
        });
    });
  };

  const initials = useMemo(() => {
    return navigation.map((item) => {
      return { name: item?.name, alter: (item?.name || '')[0] };
    });
  }, navigation);

  const items = useMemo(() => {
    return navigation.filter((f, index) => {
      if (minimize) {
        f.name = initials[index].alter;
      } else {
        f.name = initials[index].name;
      }
      if (process.env.REACT_APP_ENV === 'prod') {
        if (f._tag === 'CSidebarNavTitle' && f._children?.includes('Test')) {
          return false;
        }
        if (f.name === 'Credentials') {
          return false;
        }
      }
      if (f.permission) {
        if (f.permission === 'management' || f.permission === 'users-log') {
          if (app.$me?.role == 'super' || app.$me?.role === 'admin') {
            return f;
          }
        } else {
          f.permission === 'price-tables';
        }
        {
          if (app.$me?.role == 'super') {
            return f;
          }
        }
      } else {
        return f;
      }
    });
  }, [navigation, minimize]);

  return (
    <CSidebar
      style={{ zIndex: 2 }}
      show="responsive"
      onMinimizeChange={(value: boolean) => {
        setMinimize(!(value === undefined ? minimize : value));
      }}
    >
      <CSidebarNav>
        <div>
          <CIcon
            style={{ float: 'right', marginRight: '20px', cursor: 'pointer' }}
            name="cil-account-logout"
            size="xl"
            onClick={logOut}
          />
        </div>
        <CCreateElement
          items={items}
          components={{
            CSidebarNavDivider,
            CSidebarNavItem,
            CSidebarNavTitle,
          }}
        />
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  );
};

export default observer(SideNav);
