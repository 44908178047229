import styled, { css } from 'styled-components';
import Icon from './Icon';
import { Chip as MuiChip, styled as muiStyled } from '@mui/material';
import { ComponentType } from 'react';

export const ChipButton = muiStyled(MuiChip)`
  height: 20px;
  font-size: 12px;
  background-color: #f3f8ff;
  color: #2d2d2d;
  margin: 4px;

  .MuiChip-deleteIcon {
    display: flex;
    margin: 0;
  }

  &.Mui-disabled {
    background-color: #f1f1f1;
    color: #bbbbbb;
    opacity: 1;
  }
`;

export const Closed = styled((props) => <Icon variant="close" {...props} />)`
  width: 8px;
  height: 8px;
  path {
    fill: ${({ theme, disabled }: any) =>
      disabled ? theme.color.grey[300] : theme.color.bluegrey[600]} !important;
  }
` as ComponentType<any>;

export const Label = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  ${({ style }: any) =>
    style &&
    css`
      ${style}
    `}
`;

export const IconWrapper = styled.div`
  padding: 0;
  min-width: 24px;
  cursor: ${({ disabled }: any) => (disabled ? 'initial' : 'pointer')};
` as ComponentType<any>;
