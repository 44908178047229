import { useEffect, useRef } from 'react';

function isDepChanged(lastDeps: any, deps: any) {
  if (!lastDeps) return true;

  for (let i = 0; i < lastDeps.length; i++) {
    if (lastDeps[i] !== deps[i]) return true;
  }

  return false;
}

export const useReaction = (func: () => void, deps: any) => {
  const lastDeps = useRef();
  const cleanup: any = useRef();

  if (isDepChanged(lastDeps.current, deps)) {
    lastDeps.current = deps;
    cleanup.current?.();
    cleanup.current = func();
  }

  useEffect(() => {
    return () => {
      cleanup.current?.();
      cleanup.current = null;
    };
  }, []);
};
