import { color } from '~/theme';
import { FilterDateRange } from '~/components/commons/SearchFilterDateRange';
import { useCallback, useMemo } from 'react';
import {
  Button,
  TextField,
  Box,
  Typography,
  SearchFilterRangeInput,
  DropdownList,
} from './SearchView.styled';
import { agencies as agenciesOptions } from '~/hooks/constants';
import { stripEmpty } from '~/utils/objUtil';
import { format } from 'date-fns';

const contractStatusOptions = [
  { label: '사용중', value: 'active', id: 'active' },
  { label: '해지', value: 'inactive', id: 'inactive' },
];

const accountTypesOptions = [
  { label: '테스트 계정', value: 'true', id: 'true' },
  { label: '병원 계정', value: 'false', id: 'false' },
];

type SearchViewProps = {
  width: number;
  onSubmit: () => void;
  onChange: (params: any) => void;
  value: any;
  onSearch: (params: any) => void;
};

export const SearchView = ({
  onSearch,
  value,
  onChange,
  onSubmit,
  width,
}: SearchViewProps) => {
  const searchFilterValues = useMemo(() => {
    return stripEmpty(
      {
        ...value,
        name: value?.name?.length < 3 ? '' : value.name,
        clinicTel: value?.clinicTel?.length < 4 ? '' : value.clinicTel,
        contactTel: value?.contactTel?.length < 4 ? '' : value.contactTel,
      },
      {
        emptyString: true,
        emptyArray: true,
      }
    );
  }, [value]);

  const disabled = useMemo(() => {
    return Object.keys(searchFilterValues).length <= 3;
  }, [searchFilterValues]);

  const handleEnter = useCallback(() => {
    !disabled && onSubmit();
  }, [disabled, searchFilterValues]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        width: width ?? undefined,
        maxWidth: 1000,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          background: 'white',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            border: `1px solid ${color.common.line}`,
            '& > *:not(:last-child)': {
              borderRight: `1px solid ${color.common.line}`,
            },
          }}
        >
          <Box width={250}>
            <TextField
              $type="search"
              value={value.id === 0 ? '' : String(value.id)}
              startAdornment="ID"
              placeholder="입력"
              onChange={(v: any) => {
                const newId = !isNaN(Number(v))
                  ? v
                  : value.id !== ''
                  ? value.id
                  : '';
                onChange({ id: newId });
              }}
              onEnter={handleEnter}
            />
          </Box>
          <Box
            sx={{
              width: 250,
              background: 'white',
            }}
          >
            <DropdownList
              value={contractStatusOptions.filter((o) => value.status === o.id)}
              options={contractStatusOptions}
              onChange={(v: any) => {
                onChange({ status: v?.id || '' });
              }}
              variant="search"
              getOptionLabel={(o: any) => {
                if (o[0]) {
                  return o[0]?.label ? o[0].label : '';
                } else {
                  return o ? (o?.label ? o.label : '') : '';
                }
              }}
              startAdornment={<Typography>{'계약상태'}</Typography>}
              placeholder="전체"
            />
          </Box>
          <Box width={250}>
            <TextField
              $type="search"
              value={value.name}
              startAdornment="거래처명"
              placeholder="3자리 이상 입력"
              onChange={(v: any) => onChange({ name: v })}
              onEnter={handleEnter}
            />
          </Box>
          <Box width={250}>
            <TextField
              $type="search"
              value={value.employerNo}
              startAdornment="사업자번호"
              placeholder="입력"
              onChange={(v: any) => onChange({ employerNo: v })}
              onEnter={handleEnter}
            />
          </Box>
        </Box>
        <Button
          styled="outline"
          onClick={onSearch}
          className="search-btn"
          disabled={disabled}
        >
          조회
        </Button>
      </Box>
    </Box>
  );
};
