import { useMemo } from 'react';
import styled from 'styled-components';
import { DataTable } from '../DataTable.styled';
import { currency } from '~/utils/common';

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-top: 4px;
`;

type ClinicsDataTableProps = {
  data: any;
  orderBy: { id: any; value: any };
  pagination: {
    page: number;
    limit: number;
  };
  onChangePagination: (params: any) => void;
};

export const ClinicsDataTable = (props: ClinicsDataTableProps) => {
  const { data, orderBy } = props;
  const items = useMemo(() => {
    return data?.data || [];
  }, [data]);

  const schema = useMemo(
    () => ({
      columns: [
        {
          id: 'name',
          name: '카테고리',
          value: (item: any) => item.name || '-',
        },
        {
          id: 'vatExclusivePrice',
          name: 'VAT 미포함가격',
          value: (item: any) =>
            item.vatExclusivePrice !== null
              ? `${currency(item.vatExclusivePrice)}`
              : '-',
        },
        {
          id: 'price',
          name: 'VAT 포함가격',
          value: (item: any) =>
            item.price !== null ? `${currency(item.price)}` : '-',
        },
        {
          id: 'vatFree',
          name: '과세여부',
          value: (item: any) => (item?.vatFree ? '비과세' : '과세'),
        },
      ],
    }),
    []
  );

  return (
    <DataTable
      data={items}
      schema={schema}
      sorts={[orderBy]}
      placeholder={'조회 결과가 없습니다.'}
    />
  );
};
