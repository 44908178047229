import { styled, css } from '@mui/material';
import { ComponentType } from 'react';

export const Content = styled('div')`
  width: 800px;
  background-color: white;
  border: 1px solid #dee2ec;
  & > div:not(:last-child) {
    border-bottom: 1px solid #dee2ec;
  }
`;

export const Container = styled('div')`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  & > div:not(:last-child) {
    border-right: 1px solid #dee2ec;
  }
`;

export const Item = styled('div')`
  height: 34px;
  font-size: 12px;
  display: flex;
  align-items: center;

  &.label {
    padding: 8.5px 13px;
  }

  ${({ disabled }: any) =>
    disabled &&
    css`
      background-color: #f1f1f1;
    `}
` as ComponentType<any>;

export const ItemTitle = styled(Item)`
  font-weight: 700;
  background-color: #f9fbff;
  padding-left: 13px;

  ${({ disabled }: any) =>
    disabled &&
    css`
      background-color: #f1f1f1;
    `}
` as ComponentType<any>;

export const ItemWide = styled(Item)`
  grid-row: 1;
  grid-column: 2 / span 3;

  ${({ disabled }: any) =>
    disabled &&
    css`
      background-color: #f1f1f1;
    `}
` as ComponentType<any>;

export const TextFieldWrapper = styled('div')`
  width: 100%;
  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  & input {
    padding-left: 13px !important;
  }
`;

export const DropdownListWrapper = styled('div')`
  width: 100%;
  & input {
    padding-left: 13px !important;
  }
`;
