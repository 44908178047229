import { Box, Button } from '@mui/material';

type ColorSelectFieldProps = {
  value: any;
  onChange: (color: string) => void;
  colors: string[];
};

export const ColorSelectField = ({
  value,
  onChange,
  colors,
}: ColorSelectFieldProps) => {
  const onClickItem = (color: string) => {
    onChange(color);
  };

  return (
    <Box sx={{ display: 'flex', gap: 2 }}>
      {colors.map((c: string) => (
        <Button
          key={c}
          sx={{
            backgroundColor: `#${c}`,
            borderRadius: 1,
            width: 32,
            height: 32,
            p: 0,
            ...(value !== c && {
              opacity: 0.3,
            }),
            '&&': {
              ':hover': {
                backgroundColor: `#${c}`,
                opacity: 0.8,
              },
            },
          }}
          onClick={() => onClickItem(c)}
        ></Button>
      ))}
    </Box>
  );
};
