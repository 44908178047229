import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/remote-config';

const firebaseConfig = {
  // firebase 설정과 관련된 개인 정보
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: 'unocare-crm.firebaseapp.com',
  databaseURL: 'https://unocare-crm.firebaseio.com',
  projectId: 'unocare-crm',
  storageBucket: 'unocare-crm.appspot.com',
  messagingSenderId: '756897563356',
  appId: '1:756897563356:web:e29f1a561b9621694bea19',
  measurementId: 'G-PTT76JNRJP',
};

// firebaseConfig 정보로 firebase 시작
firebase.initializeApp(firebaseConfig);

const remoteConfig: any = firebase.remoteConfig();
remoteConfig.settings = {
  minimumFetchIntervalMillis: 3600000, //1시간
};

// firebase의 firestore 인스턴스를 변수에 저장
const firestore = firebase.firestore();

// 필요한 곳에서 사용할 수 있도록 내보내기
export { firestore };
