import { useApp } from '~/hooks/useApp';
import { useSnackbarContext } from '~/components/providers/SnackbarProvider';
import { DropdownList } from '~/components/commons/DropdownList';
import { TextField } from '~/components/commons/TextField';
import update from 'immutability-helper';
import { useApi } from '~/components/providers/ApiProvider';
import { roleOptions, statusOptions } from '~/pages/management/management';
import {
  Content,
  Container,
  Item,
  ItemTitle,
  ItemWide,
  TextFieldWrapper,
  DropdownListWrapper,
} from './DetailManagementTable.styled';
import { ConfirmModal } from '~/components/modals/ConfirmModal';
import { useImperativeModal } from '~/components/providers/ImperativeModalProvider';
import { Tooltip } from '~/components/commons/Tooltip';

type EditManagementPasswordTableProps = {
  userStatus: string;
  userId: number;
};

export const EditManagementPasswordTable = ({
  userStatus,
  userId,
}: EditManagementPasswordTableProps) => {
  const app = useApp();
  const snackbar = useSnackbarContext();
  const { userApi } = useApi();
  const imperativeModal = useImperativeModal();

  const onClickResetPassword = async () => {
    const confirm = await imperativeModal.open((close) => (
      <ConfirmModal
        variant="primary"
        onConfirm={() => close(true)}
        onCancel={() => close(false)}
      >
        비밀번호 초기화를 하시겠습니까?
      </ConfirmModal>
    ));

    if (confirm) {
      try {
        await userApi.userTempPassword(userId);
        snackbar.success('임시비밀번호 메일이 발송되었습니다.');
      } catch (e: any) {
        if (e.name === 'NO_PERMISSION') {
          snackbar.alert('접근 권한이 없습니다.');
        } else {
          snackbar.alert(e.description);
        }
      }
    }
  };

  return (
    <Content>
      <Container>
        <ItemTitle>비밀번호</ItemTitle>
        <ItemWide className="label">
          <Tooltip title={app.$me.role !== 'super' ? '권한이 없습니다.' : ''}>
            <div>
              <button
                onClick={() => onClickResetPassword()}
                className="btn-primary"
                disabled={userStatus === 'deleted' || app.$me.role !== 'super'}
              >
                비밀번호 초기화
              </button>
            </div>
          </Tooltip>
        </ItemWide>
      </Container>
    </Content>
  );
};

type EditManagementTableProps = {
  user: any;
  value: any;
  setValue: (value: any) => void;
};

export const EditManagementTable = ({
  user,
  value,
  setValue,
}: EditManagementTableProps) => {
  return (
    <Content>
      <Container>
        <ItemTitle disabled={true}>
          <div className="label-required">
            <label>아이디(이메일)</label>
          </div>
        </ItemTitle>
        <ItemWide className="label" disabled={true}>
          {user.email}
        </ItemWide>
      </Container>
      <Container>
        <ItemTitle disabled={true}>관리자 번호</ItemTitle>
        <Item className="label" disabled={true}>
          {user.id}
        </Item>
        <ItemTitle>
          <div className="label-required">
            <label>담당자 이름</label>
          </div>
        </ItemTitle>
        <Item>
          <TextFieldWrapper>
            <TextField
              $type="search"
              value={value.name}
              placeholder="이름을 입력하세요"
              onChange={(v) => {
                setValue(
                  update(value, {
                    name: { $set: v },
                  })
                );
              }}
            />
          </TextFieldWrapper>
        </Item>
      </Container>
      <Container>
        <ItemTitle>
          <div className="label-required">
            <label>보안등급</label>
          </div>
        </ItemTitle>
        <Item>
          <DropdownListWrapper>
            <DropdownList
              variant="search"
              options={roleOptions}
              value={value.role}
              onChange={(v) => {
                setValue(
                  update(value, {
                    role: { $set: v },
                  })
                );
              }}
              placeholder="보안등급을 선택하세요"
            />
          </DropdownListWrapper>
        </Item>
        <ItemTitle>
          <div className="label-required">
            <label>사용여부</label>
          </div>
        </ItemTitle>
        <Item>
          <DropdownListWrapper>
            <DropdownList
              variant="search"
              options={statusOptions}
              value={value.status}
              onChange={(v) => {
                setValue(
                  update(value, {
                    status: { $set: v },
                  })
                );
              }}
              placeholder="사용여부를 선택하세요"
            />
          </DropdownListWrapper>
        </Item>
      </Container>
    </Content>
  );
};
