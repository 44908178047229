export const banks = [
  { key: '산업은행', value: '002' },
  { key: '기업은행', value: '003' },
  { key: '국민은행', value: '004' },
  { key: '수협중앙회', value: '007' },
  { key: '농협중앙회', value: '011' },
  { key: '우리은행', value: '020' },
  { key: 'SC제일은행', value: '023' },
  { key: '시티은행', value: '027' },
  { key: '대구은행', value: '031' },
  { key: '부산은행', value: '032' },
  { key: '광주은행', value: '034' },
  { key: '제주은행', value: '035' },
  { key: '전북은행', value: '037' },
  { key: '경남은행', value: '039' },
  { key: '새마을금고', value: '045' },
  { key: '신협중앙회', value: '048' },
  { key: '우체국', value: '071' },
  { key: 'KEB하나', value: '081' },
  { key: '신한은행', value: '088' },
  { key: '케이뱅크', value: '089' },
  { key: '카카오뱅크', value: '090' },
  { key: '유안타증권', value: '209' },
  { key: '삼성증권', value: '240' },
];

export const agencies = [
  { key: '없음', value: '000' },
  { key: '클레버', value: '001' },
  { key: '바이브알씨', value: '002' },
  { key: '내부', value: '003' },
  { key: 'HBS', value: '004' },
  { key: '애드플라이어', value: '005' },
];

export const businessCondition = [
  { key: '병원', value: '병원' },
  { key: '일반', value: '일반' },
];

export const businessType = [
  { key: '성형', value: '성형' },
  { key: '피부과', value: '피부과' },
  { key: '일반', value: '일반' },
];

export const origin = [
  { key: '신규', value: '신규' },
  { key: '자비스', value: '자비스' },
];

export const database = [
  { key: 'IN', value: 'IN' },
  { key: 'OUT', value: 'OUT' },
];

export const clinicStatus = [
  { key: '신청', value: 'request' },
  { key: '사용중', value: 'active' },
  { key: '보류', value: 'defer' },
  { key: '해지', value: 'cancel' },
  { key: '가계약', value: 'provisional' },
];

export const insuranceType = [
  { key: '내부', value: '내부' },
  { key: '우버', value: '우버' },
];

export const salesStatus = [
  { key: '미컨택', value: 'nothing' },
  { key: '컨택중', value: 'contact' },
  { key: '계약완료', value: 'complete' },
  { key: '드랍', value: 'drop' },
  { key: '보류', value: 'defer' },
];

export const salesOrigin = [
  { key: '인바운드', value: 'INBOUND' },
  { key: '아웃바운드', value: 'OUTBOUND' },
  { key: '지인추천', value: 'RECOMMEND' },
  { key: '인바운드', value: 'IN' },
];

export const salesAgencies = [
  { key: '없음', value: '000' },
  { key: '클래버', value: '001' },
  { key: 'HBS', value: '004' },
  { key: '애드플라이어', value: '005' },
];

export const categories = ['병원', '피부관리샵', '기타'];
