import { useEffect, useState, useCallback, useRef } from 'react';
import { observer } from 'mobx-react';
import {
  Box,
  Typography,
  Stack,
  Button,
  TextField,
  IconWrapper,
  ComboBox,
  FileInput,
  RadioInput,
  CheckBox,
  DropdownList,
  Text,
  DateFieldWrapper,
} from './ClinicAdd.styled';

import $http from 'axios';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import Icon from '~/components/commons/Icon';
import DaumPostcode from 'react-daum-postcode';
import {
  banks,
  agencies,
  businessType,
  businessCondition,
  categories,
} from '~/hooks/constants';
import { useSnackbarContext } from '~/components/providers/SnackbarProvider';
import { toThousandCommas } from '~/filters';
import { DateField } from '~/components/fields/DateField';
import { ContractsFile } from '~/components/routes/ClinicAdd/ContractsFile';

type ClinicAddViewProps = {
  value: any;
  onChangeValue: (value: any) => void;
};

const ClinicAddView = (props: ClinicAddViewProps) => {
  const { value, onChangeValue } = props;
  const [isOpenPost, setIsOpenPost] = useState(false);
  const [treatmentFields, setTreatmentFields] = useState([]);
  const snackbar: any = useSnackbarContext();
  const fileRef: any = useRef();
  const treatmentFieldsCallApi = useCallback(async () => {
    const resp = await $http.get('/clinics/treatment_fields');
    const fields = resp.data.map((v: any) => {
      return {
        label: v.name,
        value: v.name,
        id: v.id,
      };
    });
    setTreatmentFields(fields);
  }, []);

  useEffect(() => {
    treatmentFieldsCallApi();
  }, []);

  const handleChangeOpenPost = (e: Event) => {
    e.stopPropagation();
    setIsOpenPost(!isOpenPost);
  };

  const handleCompletePost = (data: any) => {
    let fullAddr =
      data.userSelectedType === 'R' ? data.roadAddress : data.jibunAddress;
    let extraAddr = '';

    if (data.userSelectedType === 'R') {
      if (data.bname !== '') {
        extraAddr += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddr +=
          extraAddr !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddr += extraAddr !== '' ? ` (${extraAddr})` : '';
    }

    onChangeValue({ address: fullAddr });
    setIsOpenPost(false);
  };

  const handleChangeImage = (e: any, type: string, index?: number) => {
    let target = e.target;
    if (target.files[0] === undefined) {
      return;
    }

    const { size, name } = e.target.files[0];
    const fileType = name.split('.').pop();
    const confirmType = ['jpg', 'JPG', 'png', 'PNG', 'jpeg', 'pdf', 'PDF'];
    // 10Mb
    const confirmSize = 10;
    if (size / 1024 / 1024 > confirmSize) {
      snackbar.alert('10MB 이하로만 첨부가능합니다.');
      return;
    }

    if (confirmType.indexOf(fileType) === -1) {
      snackbar.alert('jpg 또는 PNG 또는 PDF 파일만 첨부가능합니다.');
      return;
    }

    const reader = new FileReader();
    reader.onload = function (event: any) {
      let newValue: any = {};
      const file = target.files[0];

      if (file.type === 'application/pdf') {
        newValue[`${type}`] = file;
        onChangeValue({ ...newValue });

        return;
      }

      const img: any = new Image();
      img.src = event.target.result;

      img.onload = function () {
        let newValue: any = {};
        const file = target.files[0];
        newValue[`${type}`] = file;

        onChangeValue({ ...newValue });
      };
    };
    reader.readAsDataURL(target.files[0]);
  };

  return (
    <>
      <Box className="checkbox-box">
        <Typography variant="h2" className="mt-0">
          계정 정보
        </Typography>
        <CheckBox
          checked={value.test}
          onChange={(v) => {
            onChangeValue({ test: v.target.checked });
          }}
          label="테스트 계정"
          className="checkbox"
        />
      </Box>
      <Stack flexDirection={'column'} className="mt-10 border">
        <Stack className="" flexDirection={'row'}>
          <Box className="label">
            아이디(이메일) <span className="required">*</span>
          </Box>
          <Box className="value">
            <TextField
              value={value.contactEmail || ''}
              onChange={(v) => {
                onChangeValue({ contactEmail: v });
              }}
              placeholder="아이디(이메일)을 입력하세요"
            />
          </Box>
          <Box className="label">
            임시 비밀번호 <span className="required">*</span>
          </Box>
          <Box className="value">
            <TextField
              type="password"
              value={value.contactTempPassword || ''}
              onChange={(v) => {
                onChangeValue({ contactTempPassword: v });
              }}
              placeholder="8~20자 영문자, 숫자, 특수기호"
            />
          </Box>
        </Stack>
        <Stack className="" flexDirection={'row'}>
          <Box className="label">
            담당자 이름 <span className="required">*</span>
          </Box>
          <Box className="value">
            <TextField
              value={value.contactManager || ''}
              onChange={(v) => {
                onChangeValue({ contactManager: v });
              }}
              placeholder="담당자 이름을 입력하세요"
            />
          </Box>
          <Box className="label">
            전화번호 <span className="required">*</span>
          </Box>
          <Box className="value">
            <TextField
              value={value.contactTel || ''}
              onChange={(v) => {
                onChangeValue({ contactTel: v });
              }}
              placeholder="전화번호를 입력하세요"
            />
          </Box>
        </Stack>
      </Stack>
      <Typography variant="h2">사업자 정보</Typography>
      <Stack flexDirection={'column'} className="mt-10 border">
        <Stack className="" flexDirection={'row'}>
          <Box className="label">
            거래처명(병원명) <span className="required">*</span>
          </Box>
          <Box className="value">
            <TextField
              value={value.name || ''}
              onChange={(v) => {
                onChangeValue({ name: v });
              }}
              placeholder="거래처명을 입력하세요"
            />
          </Box>
        </Stack>
        <Stack className="" flexDirection={'row'}>
          <Box className="label">구 거래처명</Box>
          <Box className="value">
            <TextField
              value={value.oldName || ''}
              onChange={(v) => {
                onChangeValue({ oldName: v });
              }}
              placeholder="구 거래처명을 입력하세요"
            />
          </Box>
        </Stack>
        <Stack className="" flexDirection={'row'}>
          <Box className="label">
            대표자 <span className="required">*</span>
          </Box>
          <Box className="value">
            <TextField
              value={value.ownerName || ''}
              onChange={(v) => {
                onChangeValue({ ownerName: v });
              }}
              placeholder="대표자명을 입력하세요"
            />
          </Box>
          <Box className="label">대표번호</Box>
          <Box className="value">
            <TextField
              value={value.clinicTel || ''}
              onChange={(v) => {
                onChangeValue({ clinicTel: v });
              }}
              placeholder="대표자번호를 입력하세요"
            />
          </Box>
        </Stack>
        <Stack className="" flexDirection={'row'}>
          <Box className="label">사업자등록번호</Box>
          <Box className="value">
            <TextField
              value={value.employerNo || ''}
              onChange={(v) => {
                onChangeValue({ employerNo: v });
              }}
              placeholder="사업자등록번호를 입력하세요"
            />
          </Box>
          <Box className="label">요양기관번호</Box>
          <Box className="value">
            <TextField
              value={value.clinicNo || ''}
              onChange={(v) => {
                onChangeValue({ clinicNo: v });
              }}
              placeholder="요양기관번호를 입력하세요"
            />
          </Box>
        </Stack>
        <Stack className="" flexDirection={'row'}>
          <Box className="label">주소</Box>
          <Box className="value">
            <TextField
              readOnly
              value={value.address || ''}
              onClick={(e) => handleChangeOpenPost(e)}
              placeholder="주소를 검색하세요"
              onChange={(v) => {
                onChangeValue({ address: v });
              }}
              endAdornment={
                <IconWrapper>
                  <Icon variant="search" />
                </IconWrapper>
              }
            />
            {isOpenPost ? (
              <ClickAwayListener
                onClickAway={() => {
                  setIsOpenPost(false);
                }}
              >
                <DaumPostcode
                  className="daum-post-code"
                  autoClose={true}
                  onComplete={handleCompletePost}
                />
              </ClickAwayListener>
            ) : null}
          </Box>
          <Box className="label">상세 주소</Box>
          <Box className="value">
            <TextField
              value={value.addressDetail || ''}
              onChange={(v) => {
                onChangeValue({ addressDetail: v });
              }}
              placeholder="상세주소를 입력하세요"
            />
          </Box>
        </Stack>
        <Stack className="" flexDirection={'row'}>
          <Box className="label">구분</Box>
          <Box className="value">
            <DropdownList
              value={categories
                .map((item) => {
                  return {
                    label: item,
                    value: item,
                    id: item,
                  };
                })
                .filter((o) => {
                  return value.category === o.id;
                })}
              options={categories.map((item) => {
                return {
                  label: item,
                  value: item,
                  id: item,
                };
              })}
              onChange={(v: any) => {
                onChangeValue({ category: v?.id || '병원' });
              }}
              variant="search"
              getOptionLabel={(o: any) => {
                if (o[0]) {
                  return o[0]?.label ? o[0].label : '';
                } else {
                  return o ? (o?.label ? o.label : '') : '';
                }
              }}
              placeholder="전체"
            />
          </Box>
          <Box className="label">주요 시/수술 분야</Box>
          <Box className="value">
            <ComboBox
              multiple={true}
              unlimit={true}
              value={
                treatmentFields.filter((o: any) => {
                  return (value?.treatmentFields || []).includes(o.value);
                }) || []
              }
              options={treatmentFields}
              onChange={(v) => {
                onChangeValue({
                  treatmentFields: v ? v.map((item: any) => item.value) : [],
                });
              }}
              variant="search"
              getOptionLabel={(o) => {
                if (o[0]) {
                  return o[0]?.label ? o[0].label : '';
                } else {
                  return o ? (o?.label ? o.label : '') : '';
                }
              }}
              placeholder="주요 시/수술 분야를 선택하세요"
            />
          </Box>
        </Stack>
        <Stack className="" flexDirection={'row'}>
          <Box className="label">업태</Box>
          <Box className="value">
            <DropdownList
              value={businessCondition.filter((o: any) => {
                return value.bizStatus === o.id;
              })}
              options={businessCondition.map((item: any) => {
                item.id = item.key;
                item.label = item.key;
                return item;
              })}
              onChange={(v: any) => {
                onChangeValue({ bizStatus: v?.id });
              }}
              variant="search"
              getOptionLabel={(o: any) => {
                if (o[0]) {
                  return o[0]?.label ? o[0].label : '';
                } else {
                  return o ? (o?.label ? o.label : '') : '';
                }
              }}
              placeholder="업태를 선택하세요"
            />
          </Box>
          <Box className="label">업종</Box>
          <Box className="value">
            <DropdownList
              value={businessType.filter((o: any) => {
                return value.bizItem === o.id;
              })}
              options={businessType.map((item: any) => {
                item.id = item.key;
                item.label = item.key;
                return item;
              })}
              onChange={(v: any) => {
                onChangeValue({ bizItem: v?.id });
              }}
              variant="search"
              getOptionLabel={(o: any) => {
                if (o[0]) {
                  return o[0]?.label ? o[0].label : '';
                } else {
                  return o ? (o?.label ? o.label : '') : '';
                }
              }}
              placeholder="업종을 선택하세요"
            />
          </Box>
        </Stack>
        <Stack className="" flexDirection={'row'}>
          <Box className="label" sx={{ padding: '0 12px !important' }}>
            사업자등록증
            <Button
              variant="outlined"
              className="file-upload-btn"
              onClick={() => fileRef.current.click()}
            >
              첨부하기
            </Button>
            <FileInput
              ref={fileRef}
              onChange={(e) => handleChangeImage(e, 'bizLicenseFile')}
            />
          </Box>
          <Box className="value align-center">
            {value?.bizLicenseFile?.name || ''}
          </Box>
        </Stack>
      </Stack>
      <Text>∙ 사업자등록증 첨부는 JPG, PNG, PDF 파일만 가능합니다.</Text>
      <Typography variant="h2">CMS 자동이체 정보</Typography>
      <Stack flexDirection={'column'} className="mt-10 border">
        <Stack className="" flexDirection={'row'}>
          <Box className="label">은행</Box>
          <Box className="value">
            <DropdownList
              value={banks.filter((o) => {
                return value.cms.bank === o.value;
              })}
              options={banks.map((item: any) => {
                item.label = item.key;
                item.id = item.value;
                return item;
              })}
              onChange={(v: any) => {
                onChangeValue({
                  cms: {
                    ...value.cms,
                    bank: v?.value,
                  },
                });
              }}
              variant="search"
              getOptionLabel={(o: any) => {
                if (o[0]) {
                  return o[0]?.label ? o[0].label : '';
                } else {
                  return o ? (o?.label ? o.label : '') : '';
                }
              }}
              placeholder="은행을 선택하세요"
            />
          </Box>
          <Box className="label">계좌번호</Box>
          <Box className="value">
            <TextField
              value={value.cms.bankAccount || ''}
              onChange={(v) => {
                onChangeValue({
                  cms: {
                    ...value.cms,
                    bankAccount: v,
                  },
                });
              }}
              placeholder="계좌번호를 입력하세요"
            />
          </Box>
        </Stack>
        <Stack className="" flexDirection={'row'}>
          <Box className="label">자동이체(매월)</Box>
          <Box className="value">
            <TextField
              value={
                value.cms.autoTransferDay === 0
                  ? ''
                  : String(value.cms?.autoTransferDay)
              }
              onChange={(v) => {
                onChangeValue({
                  cms: {
                    ...value.cms,
                    autoTransferDay: !isNaN(Number(v)) ? Number(v) : 0,
                  },
                });
              }}
              placeholder="자동이체 계좌번호를 입력하세요"
            />
          </Box>
          <Box className="label">세금계산서 발행메일</Box>
          <Box className="value">
            <TextField
              value={value.cms.taxbillEmail || ''}
              onChange={(v) => {
                onChangeValue({
                  cms: {
                    ...value.cms,
                    taxbillEmail: v,
                  },
                });
              }}
              placeholder="세금계산서 발행메일을 입력하세요"
            />
          </Box>
        </Stack>
      </Stack>
      <Typography variant="h2">대행사 및 기타 정보</Typography>
      <Stack flexDirection={'column'} className="mt-10 border">
        <Stack className="" flexDirection={'row'}>
          <Box className="label">계약 대행사</Box>
          <Box className="value">
            <DropdownList
              value={agencies.filter((o) => {
                return value.additional.agency === o.value;
              })}
              options={agencies.map((item: any) => {
                item.label = item.key;
                item.id = item.value;
                return item;
              })}
              onChange={(v: any) => {
                onChangeValue({
                  additional: {
                    ...value.additional,
                    agency: v?.value,
                  },
                });
              }}
              variant="search"
              getOptionLabel={(o: any) => {
                if (o[0]) {
                  return o[0]?.label ? o[0].label : '';
                } else {
                  return o ? (o?.label ? o.label : '') : '';
                }
              }}
              placeholder="계약 대행사를 선택하세요"
            />
          </Box>
          <Box className="label">교육 대행사</Box>
          <Box className="value">
            <DropdownList
              value={agencies.filter((o) => {
                return value.additional.educationAgency === o.value;
              })}
              options={agencies.map((item: any) => {
                item.label = item.key;
                item.id = item.value;
                return item;
              })}
              onChange={(v: any) => {
                onChangeValue({
                  additional: {
                    ...value.additional,
                    educationAgency: v?.value,
                  },
                });
              }}
              variant="search"
              getOptionLabel={(o: any) => {
                if (o[0]) {
                  return o[0]?.label ? o[0].label : '';
                } else {
                  return o ? (o?.label ? o.label : '') : '';
                }
              }}
              placeholder="교육 대행사를 선택하세요"
            />
          </Box>
        </Stack>
        <Stack className="" flexDirection={'row'}>
          <Box className="label">자동 차트번호 타입</Box>
          <Box className="value chart-no-type">
            <span className="warning-text">
              ※ 이미 사용중인 거래처의 경우 차트번호 타입 변경은 불가합니다.
            </span>
            <Box>
              <RadioInput
                value={value.additional.chartNoType}
                options={[
                  {
                    label: '날짜형 (ex:) 20190101001, 20190101002)',
                    value: 'date',
                  },
                  { label: '숫자형 (ex:) 00000001, 00000002)', value: 'num' },
                ]}
                onChange={(v) => {
                  onChangeValue({
                    additional: {
                      ...value.additional,
                      chartNoType: v,
                    },
                  });
                }}
              />
            </Box>
          </Box>
        </Stack>
      </Stack>
      <Typography variant="h2">월 관리비 정보(VAT별도)</Typography>
      <Stack flexDirection={'column'} className="mt-10 border">
        <Stack className="" flexDirection={'row'}>
          <Box className="label">CRM 운영비</Box>
          <Box className="value">
            <TextField
              value={value.crmManagementPlanPrice}
              onChange={(v) => {
                onChangeValue({
                  crmManagementPlanPrice: !isNaN(Number(v)) ? Number(v) : 0,
                });
              }}
              placeholder="CRM 운영비를 입력하세요"
            />
          </Box>
          <Box className="label">사용자 계정 수</Box>
          <Box className="value">
            <TextField
              value={value.crmManagementPlanLimit}
              onChange={(v) => {
                onChangeValue({
                  crmManagementPlanLimit: !isNaN(Number(v)) ? Number(v) : 0,
                });
              }}
              placeholder="사용자 계정 수를 입력하세요"
            />
          </Box>
        </Stack>
        <Stack className="" flexDirection={'row'}>
          <Box className="label">펜차트 관리비</Box>
          <Box className="value">
            <TextField
              value={value.penchartPlanPrice}
              onChange={(v) => {
                onChangeValue({
                  penchartPlanPrice: !isNaN(Number(v)) ? Number(v) : 0,
                });
              }}
              placeholder="CRM 운영비를 입력하세요"
            />
          </Box>
          <Box className="label">펜차트 용량(GB)</Box>
          <Box className="value">
            <TextField
              value={value.penchartPlanLimit}
              onChange={(v) => {
                onChangeValue({
                  penchartPlanLimit: !isNaN(Number(v)) ? Number(v) : 0,
                });
              }}
              placeholder="사용자 계정 수를 입력하세요"
            />
          </Box>
        </Stack>
        <Stack className="" flexDirection={'row'}>
          <Box className="label">전자서명</Box>
          <Box className="value">
            <TextField
              value={value.signCertPlanPrice}
              onChange={(v) => {
                onChangeValue({
                  signCertPlanPrice: !isNaN(Number(v)) ? Number(v) : 0,
                });
              }}
              placeholder="전자서명 비용을 입력하세요"
            />
          </Box>
          <Box className="label">전자서명 사용 수</Box>
          <Box className="value">
            <TextField
              value={value.signCertPlanLimit}
              onChange={(v) => {
                onChangeValue({
                  signCertPlanLimit: !isNaN(Number(v)) ? Number(v) : 0,
                });
              }}
              placeholder="전자서명 사용 수를 입력하세요"
            />
          </Box>
        </Stack>
        <Stack className="" flexDirection={'row'}>
          <Box className="label">080 번호사용</Box>
          <Box className="value">
            <TextField
              value={value.number080PlanPrice}
              onChange={(v) => {
                onChangeValue({
                  number080PlanPrice: !isNaN(Number(v)) ? Number(v) : 0,
                });
              }}
              placeholder="080 번호사용 비용을 입력하세요"
            />
          </Box>
          <Box className="label">080 수신거부 번호</Box>
          <Box className="value">
            <TextField
              value={value.rejectionPhoneNumber}
              onChange={(v) => {
                onChangeValue({
                  rejectionPhoneNumber: v,
                });
              }}
              placeholder="080 수신거부 번호를 입력하세요"
            />
          </Box>
        </Stack>
        <Stack className="" flexDirection={'row'}>
          <Box className="label">보험청구</Box>
          <Box className="value">
            <TextField
              value={value.insurancePlanPrice}
              onChange={(v) => {
                onChangeValue({
                  insurancePlanPrice: !isNaN(Number(v)) ? Number(v) : 0,
                });
              }}
              placeholder="보험청구 관리비를 입력하세요"
            />
          </Box>
          <Box className="label">보험청구 사용 수</Box>
          <Box className="value">
            <TextField
              value={value.insurancePlanLimit}
              onChange={(v) => {
                onChangeValue({
                  insurancePlanLimit: !isNaN(Number(v)) ? Number(v) : 0,
                });
              }}
              placeholder="보험청구 사용수를 입력하세요"
            />
          </Box>
        </Stack>
        <Stack className="" flexDirection={'row'}>
          <Box className="label">총 금액</Box>
          <Box className="value align-center total-price">
            {toThousandCommas(
              String(
                value.penchartPlanPrice +
                  value.crmManagementPlanPrice +
                  value.signCertPlanPrice +
                  value.number080PlanPrice +
                  value.insurancePlanPrice
              )
            )}
          </Box>
        </Stack>
        <Stack className="" flexDirection={'row'}>
          <Box className="label">최초 계약일</Box>
          <Box className="value align-center">
            <DateFieldWrapper>
              <DateField
                placeholder="최초 계약일을 선택하세요"
                value={value.contractDate}
                onChange={(v) => onChangeValue({ contractDate: v })}
              />
            </DateFieldWrapper>
          </Box>
        </Stack>
        {value?.contractsFiles
          ? value?.contractsFiles.map((item: any, index: number) => {
              return (
                <ContractsFile
                  key={index}
                  value={value}
                  item={item}
                  index={index}
                  onChangeValue={onChangeValue}
                />
              );
            })
          : ''}
      </Stack>
      <Text>
        ∙ 계약서 첨부는 최대 10건 까지 JPG, PNG, PDF 파일만 가능합니다.
      </Text>
      <Typography variant="h2">메모</Typography>
      <Stack flexDirection={'column'} className="mt-10 border">
        <Stack className="" flexDirection={'row'}>
          <Box className="label">메모</Box>
          <Box className="value">
            <TextField
              value={value.memo || ''}
              onChange={(v) => {
                onChangeValue({
                  memo: v,
                });
              }}
              placeholder="메모를 입력하세요"
            />
          </Box>
        </Stack>
      </Stack>
    </>
  );
};
export default observer(ClinicAddView);
