import { observer } from 'mobx-react';
import { Provider } from 'use-http';
import { useApi } from './ApiProvider';
import { ApiClient } from '~/api/apiClient';
import { useAuthStorage } from './AuthStorageProvider';
import { ReactNode } from 'react';

type HttpProviderProps = {
  children: ReactNode;
};

export const HttpProvider = observer(({ children }: HttpProviderProps) => {
  const api = useApi();
  const { accessToken } = useAuthStorage();

  const options: any = {
    headers: {
      Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
    },
    cachePolicy: 'no-cache',
    interceptors: {
      response: async ({ response }: any) => {
        if (!response.ok) {
          if (response.status === 401) {
            api._dispatch(ApiClient.Event.UNAUTHORIZED);
          } else if (response.status === 403) {
            api._dispatch(ApiClient.Event.ACCESS_DENIED);
          } else {
            console.log(`[${response.data.code}] ${response.data.description}`);
          }
        }
        return response;
      },
    },
  };

  const API_URL = process.env.REACT_APP_API_URL;

  return (
    <Provider url={API_URL} options={options}>
      {children}
    </Provider>
  );
});
