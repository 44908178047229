import { usePagination } from '@mui/lab';
import { Wrapper, Ellipsis, PageButton, MoveButton } from './Pagination.styled';
import Icon from './Icon';

type PaginationProps = {
  styleType?: 'chart' | 'statistics';
  page: number;
  total: number;
  siblingCount?: number;
  onChange: (value: any) => void;
  disabled?: boolean;
};

export const Pagination = ({
  styleType,
  page,
  total,
  siblingCount = 2,
  onChange,
  disabled = false,
}: PaginationProps) => {
  const displayOffset = 2;
  const prevs = [];
  const lastPage = total > 0 ? total : 1;

  const { items } = usePagination({
    count: total,
    page: page,
    siblingCount: siblingCount,
  });

  for (let i = Math.max(1, page - displayOffset); i < page; i++) {
    prevs.push(i);
  }

  const nexts = [];

  if (total > 0) {
    for (let i = page + 1; i <= lastPage && i - page <= displayOffset; i++) {
      nexts.push(i);
    }
  }

  function onClickPrev() {
    onChange(Math.max(page - 1, 1));
  }

  function onClickNext() {
    onChange(Math.min(page + 1, lastPage));
  }

  return (
    <Wrapper styleType={styleType}>
      {items.map(({ page, type, selected }) => {
        switch (type) {
          case 'start-ellipsis':
          case 'end-ellipsis':
            return <Ellipsis key={type}>...</Ellipsis>;
          case 'page':
            return (
              <PageButton
                key={page}
                disabled={disabled}
                selected={selected}
                onClick={() => onChange(page)}
              >
                {page}
              </PageButton>
            );
          case 'previous':
            return (
              <MoveButton
                key={type}
                disabled={disabled}
                startIcon={<Icon variant="chevron_left" />}
                onClick={onClickPrev}
              >
                이전
              </MoveButton>
            );
          case 'next':
            return (
              <MoveButton
                key={type}
                disabled={disabled}
                onClick={onClickNext}
                endIcon={<Icon variant="chevron_right" />}
              >
                다음
              </MoveButton>
            );
        }
      })}
    </Wrapper>
  );
};
