import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useSnackbarContext } from '~/components/providers/SnackbarProvider';
import { CredentialRepo } from '~/components/repo/CredentialRepo';

type CredentialFormModalProps = {
  options: any;
  close: (button?: any) => void;
};

export const CredentialFormModal = observer(
  ({ options, close }: CredentialFormModalProps) => {
    const snackbar = useSnackbarContext();
    const repo = new CredentialRepo();
    const { action, item } = options;

    const [name, setName] = useState(item?.name ?? '');
    const [type, setType] = useState('CLINIC');
    const [clinicId, setClinicId] = useState(null);

    const create = async () => {
      try {
        const data = {
          name,
          type,
          data:
            type === 'CLINIC'
              ? JSON.stringify({
                  id: clinicId,
                })
              : null,
        };

        await repo.create(data);
        snackbar.success('생성했습니다.');
        close();
      } catch (e) {
        console.error(e);
        snackbar.alert('실패했습니다.');
      }
    };

    const update = async () => {
      try {
        const data = {
          name,
        };

        await repo.update(item.id, data);

        snackbar.success('수정했습니다.');
        close();
      } catch (e) {
        console.error(e);
        snackbar.alert('실패했습니다.');
      }
    };

    const onClickSave = async () => {
      if (action === 'create') {
        create();
      } else {
        update();
      }
    };

    return (
      <div className="modal-clinic-input-form">
        <div className="head flex-row flex-between items-start">
          <div className="title">{action === 'create' ? '생성' : '수정'}</div>
          <i onClick={() => close()} className="zmdi zmdi-close" />
        </div>
        <div>
          <div>
            <span>이름</span>
            <input value={name} onChange={(e) => setName(e.target.value)} />
          </div>
        </div>
        {action === 'create' && (
          <div>
            <div>
              <span>type</span>
              {['CLINIC', 'SERVER', 'BABITALK'].map((v) => (
                <React.Fragment key={v}>
                  <input
                    id={v}
                    type="radio"
                    name="type"
                    value={v}
                    checked={type === v}
                    onChange={(e) => setType(e.target.value)}
                  />{' '}
                  <label htmlFor={v}>{v}</label>
                </React.Fragment>
              ))}
            </div>
          </div>
        )}
        {action === 'create' && type === 'CLINIC' && (
          <div>
            <div>
              <span>clinic id</span>
              <input
                value={clinicId || ''}
                onChange={(e: any) => setClinicId(e.target.value)}
              />
            </div>
          </div>
        )}
        <div className="buttons">
          <div className="flex-row">
            <button onClick={onClickSave} className="btn btn-primary">
              {action === 'create' ? '생성' : '저장'}
            </button>
          </div>
        </div>
      </div>
    );
  }
);
