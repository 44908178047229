import styled from 'styled-components';
import { FormControlLabel, Checkbox as CheckboxIcon } from '@mui/material';

export const Wrapper = styled(FormControlLabel)`
  &&& {
    column-gap: 4px;
    margin: 0;
    flex: 0 0 auto;
  }
  .MuiFormControlLabel-label {
    font-size: 11px;
    font-weight: 500;
  }
`;

export const StyledCheckbox = styled(CheckboxIcon)`
  &&& {
    padding: 0;
    border: 0;
    width: auto;
    height: auto;
    svg {
      font-size: 18px;
    }
  }
`;
