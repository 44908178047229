import { ComponentType, ReactNode, useState } from 'react';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { ClearButton as _ClearButton } from '~/components/commons/ClearButton';
import {
  IconWrapper,
  AutoCompleteSelect,
  Popper,
  Li,
  Input,
} from './DropdownList.styled';

const ClearButton = _ClearButton as ComponentType<any>;

type DropdownListProps = {
  variant?: 'default' | 'search' | 'none';
  options: any[];
  onChange: (params?: any) => void;
  value: any;
  placeholder?: string;
  startAdornment?: ReactNode;
  useClearIcon?: boolean;
  autoFocus?: boolean;
  renderInput?: any;
  renderOption?: any;
};

export const DropdownList = ({
  variant = 'default',
  options = [],
  onChange,
  value = null,
  placeholder = '',
  startAdornment,
  useClearIcon = true,
  autoFocus = false,
  renderInput,
  renderOption,
  ...props
}: DropdownListProps) => {
  const [isClose, setIsClose] = useState(true);

  return (
    <AutoCompleteSelect
      variant={variant}
      $active={!isClose}
      options={options}
      onChange={(event: any, value: any) => {
        onChange(value);
      }}
      PopperComponent={Popper}
      value={value}
      onOpen={() => {
        setIsClose(false);
      }}
      onClose={() => {
        setIsClose(true);
      }}
      renderOption={
        renderOption
          ? renderOption
          : (props: any, option: any) => (
              <div key={props.key}>
                <Li {...props}>
                  <div>{option.label}</div>
                </Li>
              </div>
            )
      }
      renderInput={
        renderInput
          ? renderInput
          : (params: any) => (
              <Input
                {...params}
                autoFocus={autoFocus}
                placeholder={placeholder ?? ''}
                inputProps={{ ...params.inputProps, readOnly: true }}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <>
                      {startAdornment && startAdornment}
                      {params.InputProps.startAdornment}
                    </>
                  ),
                }}
              />
            )
      }
      disableClearable={isClose}
      clearIcon={useClearIcon && <ClearButton />}
      popupIcon={
        <IconWrapper>
          <KeyboardArrowDownRoundedIcon />
        </IconWrapper>
      }
      {...props}
    />
  );
};
