import { ApiResource } from './apiResource';

interface CertsParams {
  limit: number;
  orderBy: string;
  certificationStatus: 'need_renew';
  status?: string;
  page?: number;
  email?: string;
  name?: string;
}

export interface CertsData {
  certId: number | null;
  certStatus: string | null;
  certCreatedAt: string;
  certEndDate: string | null;
  certSubjectDn: string | null;
  clinicId: number | null;
  clinicName: string | null;
  clinicMemo: string | null;
  userId: number | null;
  userEmail: string | null;
  userName: string | null;
  userStatus: string | null;
}

export class CertsApi extends ApiResource {
  async list(params: CertsParams) {
    return this.client.get<{ data: CertsData[]; total: number }>(
      `/admin/certs`,
      params
    );
  }
}
