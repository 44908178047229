import styled from 'styled-components';
import { Collapse } from '@mui/material';

const Tr = styled.tr`
  background: #f9fbff;
`;

const Td = styled.td`
  &&& {
    border: 0;
    padding: 0;
  }
`;

const ColumnValue = styled.span`
  display: block;
  vertical-align: middle;
  text-align: inherit;
  min-height: 12px;
`;

type DataCellProps = {
  item: any;
  column: any;
};

const DataCell = ({ item, column }: DataCellProps) => {
  if (column.value) {
    return <ColumnValue>{column.value(item)}</ColumnValue>;
  }

  if (column.component) {
    return <column.component item={item} />;
  }

  throw new Error('invalid column schema');
};

type CollapseTrProps = {
  collapsed: boolean;
  schema: any[];
  item: any;
  onMouseOver?: () => void;
};

export const CollapseTr = ({
  collapsed,
  schema,
  item,
  onMouseOver,
}: CollapseTrProps) => {
  return (
    <Tr>
      {schema.map((v: any, i: number) => (
        <Td key={i} colSpan={v.colSpan} onMouseOver={onMouseOver}>
          <Collapse in={!collapsed} timeout="auto" unmountOnExit>
            <DataCell item={item} column={v} />
          </Collapse>
        </Td>
      ))}
    </Tr>
  );
};
