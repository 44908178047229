import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Cookies, withCookies } from 'react-cookie';

function generateCookieName(name: string) {
  const COOKIE_PREFIX = process.env.REACT_APP_COOKIE_PREFIX;
  return COOKIE_PREFIX ? `${COOKIE_PREFIX}-${name}` : name;
}

const ACCESS_TOKEN_COOKIE_NAME = generateCookieName('access-token');
const REFRESH_TOKEN_COOKIE_NAME = generateCookieName('refresh-token');

const SECURE = process.env.REACT_APP_ENV !== 'local';
const COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN;

interface AutoStorage {
  accessToken: string;
  refreshToken: string;
  unmatched: boolean;
  clear: () => void;
  set: (arg: { accessToken: string; refreshToken: string }) => void;
}

export const AuthStorageContext = createContext<AutoStorage>(null as never);

export const useAuthStorage = () => {
  return useContext(AuthStorageContext);
};

export const AuthStorageProvider = withCookies(
  ({ cookies, children }: { cookies: Cookies; children: ReactNode }) => {
    const [accessToken, setAccessToken] = useState(
      cookies.get(ACCESS_TOKEN_COOKIE_NAME) ?? null
    );
    const [refreshToken, setRefreshToken] = useState(
      cookies.get(REFRESH_TOKEN_COOKIE_NAME) ?? null
    );

    const [unmatched, setUnmatched] = useState(false);

    useEffect(() => {
      if (unmatched) return;

      const interval = setInterval(() => {
        if (refreshToken !== cookies.get(REFRESH_TOKEN_COOKIE_NAME)) {
          setUnmatched(true);
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }, [accessToken, cookies, refreshToken, unmatched]);

    const clear = useCallback(() => {
      cookies.remove(ACCESS_TOKEN_COOKIE_NAME, {
        path: '/',
        domain: COOKIE_DOMAIN,
      });
      cookies.remove(REFRESH_TOKEN_COOKIE_NAME, {
        path: '/',
        domain: COOKIE_DOMAIN,
      });
      try {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
      } catch (error) {
        console.log('remove localstorage error: ', error);
      }
      setUnmatched(false);
      setAccessToken(null);
      setRefreshToken(null);
    }, [cookies]);

    const set = useCallback(
      ({ accessToken, refreshToken }: any) => {
        cookies.set(ACCESS_TOKEN_COOKIE_NAME, accessToken, {
          secure: SECURE,
          path: '/',
          domain: COOKIE_DOMAIN,
        });
        cookies.set(REFRESH_TOKEN_COOKIE_NAME, refreshToken, {
          secure: SECURE,
          path: '/',
          domain: COOKIE_DOMAIN,
        });
        setAccessToken(accessToken);
        setRefreshToken(refreshToken);
        setUnmatched(false);
      },
      [cookies]
    );

    return (
      <AuthStorageContext.Provider
        value={{
          accessToken,
          refreshToken,
          unmatched,
          clear,
          set,
        }}
      >
        {children}
      </AuthStorageContext.Provider>
    );
  }
);
