import { Box, FormControlLabel, Radio, RadioGroup, Stack } from '@mui/material';
import { ColorSelectField } from '~/components/fields/ColorSelectField';
import { DateField } from '~/components/fields/DateField';
import { HourField } from '~/components/fields/HourField';
import { ImageField } from '~/components/fields/ImageField';
import { TextArea } from '~/components/fields/TextArea';
import { TextField } from '~/components/fields/TextField';
import { FormBody } from '~/components/form/FormBody';
import { FormItem } from '~/components/form/FormItem';

export const colors = ['30C184', '5A82F2', 'EB5757'];

type PopupFormProps = {
  value: any;
  onChange: (value: any) => void;
  mode?: string;
};

export const PopupForm = ({
  value,
  onChange,
  mode = 'new',
}: PopupFormProps) => {
  const onChangeFactory = (key: string, transform?: any) => {
    return (data: any) => {
      let reset: any = {};
      if (key === 'type' && data !== 'header') {
        reset.bannerOption = 'default';
      }
      if (
        key === 'bannerOption' &&
        (transform && transform(data)) === 'refresh'
      ) {
        reset.linkUrl = '';
      }
      onChange((v: any) => ({
        ...v,
        ...reset,
        [key]: transform ? transform(data) : data,
      }));
    };
  };

  let imageUrl = '';
  if (value.imageFile) {
    imageUrl = URL.createObjectURL(value.imageFile);
  } else if (value.image) {
    imageUrl = value.image.originalUrl;
  }

  return (
    <FormBody>
      <FormItem title="제목">
        <TextField
          sx={{ width: '100%' }}
          placeholder="제목을 입력하세요(사용자에겐 표시되지 않습니다)"
          value={value.title}
          onChange={onChangeFactory('title', (e: any) => e.target.value)}
        />
      </FormItem>
      <FormItem title="시작기간">
        <Box sx={{ display: 'flex', gap: 1 }}>
          <DateField
            placeholder="시작일을 선택하세요"
            value={value.startDate}
            onChange={onChangeFactory('startDate')}
          />
          <HourField
            placeholder="시작시간을 선택하세요"
            value={value.startHour}
            onChange={onChangeFactory('startHour')}
          />
        </Box>
      </FormItem>
      <FormItem title="종료기간">
        <Box sx={{ display: 'flex', gap: 1 }}>
          <DateField
            placeholder="종료일을 선택하세요"
            value={value.endDate}
            onChange={onChangeFactory('endDate')}
          />
          <HourField
            placeholder="종료시간을 선택하세요"
            value={value.endHour}
            onChange={onChangeFactory('endHour')}
          />
        </Box>
      </FormItem>
      <Stack flexDirection={'row'}>
        <FormItem title="종류">
          <RadioGroup
            row
            value={value.type}
            onChange={onChangeFactory('type', (e: any) => e.target.value)}
            sx={{
              flexDirection: 'column',
            }}
          >
            <FormControlLabel
              value="header"
              control={<Radio />}
              label="상단 띠배너"
              disabled={mode === 'edit'}
            />
            <FormControlLabel
              value="image"
              control={<Radio />}
              label="이미지 배너"
              disabled={mode === 'edit'}
            />
            <FormControlLabel
              value="logo"
              control={<Radio />}
              label="로고 이미지"
              disabled={mode === 'edit'}
            />
            <FormControlLabel
              value="home_banner"
              control={<Radio />}
              label="홈 화면 배너"
              disabled={mode === 'edit'}
            />
          </RadioGroup>
        </FormItem>
        {value.type === 'header' && (
          <Box sx={{ marginLeft: 'auto' }}>
            <RadioGroup
              row
              value={value.bannerOption}
              onChange={onChangeFactory(
                'bannerOption',
                (e: any) => e.target.value
              )}
            >
              <FormControlLabel
                value="default"
                control={<Radio />}
                label="기본"
                disabled={mode === 'edit'}
              />
              <FormControlLabel
                value="refresh"
                control={<Radio />}
                label="새로고침"
                disabled={mode === 'edit'}
              />
            </RadioGroup>
          </Box>
        )}
      </Stack>
      {value.type === 'header' && (
        <>
          <FormItem title="내용">
            <TextArea
              placeholder="내용을 입력하세요."
              value={value.contents}
              onChange={onChangeFactory('contents', (e: any) => e.target.value)}
              limit={100}
            />
          </FormItem>
          <FormItem title="배경색 설정">
            <ColorSelectField
              value={value.color}
              onChange={onChangeFactory('color')}
              colors={colors}
            />
          </FormItem>
        </>
      )}
      {(value.type === 'image' ||
        value.type === 'logo' ||
        value.type === 'home_banner') && (
        <FormItem title="이미지">
          <ImageField
            label="이미지 등록"
            onChange={onChangeFactory('imageFile')}
          />
          <ul>
            <li>이미지는 가로 460 x 세로 최대 842px 로 등록해주세요.</li>
            <li>확장자: .png, .jpg, .jpeg</li>
          </ul>
          <img width={200} src={imageUrl} />
          <span style={{ display: 'block' }}>
            {value.imageFile?.name || ''}
          </span>
        </FormItem>
      )}
      <FormItem title="연결 링크">
        <TextField
          sx={{
            width: '100%',
          }}
          disabled={
            value.type === 'logo' ||
            (value.type === 'header' && value.bannerOption === 'refresh')
          }
          placeholder="링크를 입력하세요. ex) https://www.google.com/"
          value={value.linkUrl}
          onChange={onChangeFactory('linkUrl', (e: any) => e.target.value)}
        />
      </FormItem>
    </FormBody>
  );
};
