import { useState, useMemo } from 'react';
import { observer } from 'mobx-react';
import { OverlayWrapper } from '~/components/commons/modals/Overlay';
import { useApi } from '~/components/providers/ApiProvider';
import { stripEmpty } from '~/utils/objUtil';
import { useApiFetch } from '~/hooks/useApiFetch';
import { UsersDataTable } from './DataTable';
import {
  ModalWrapper,
  Box,
  DataPagination,
  TextField,
  ModalHeader,
  DropdownList,
  Typography,
} from './ModalUsedAccount.styled';
import { Button } from '~/components/commons/Button';

type ModalDeactivateReasonProps = {
  clinicId: number;
  onClose: () => void;
  title: string;
};

const statusOptions = [
  { label: '전체', value: 'all' },
  { label: '사용중', value: 'active' },
  { label: '미사용', value: 'inactive' },
];

const ModalDeactivateReason = ({
  clinicId,
  onClose,
  title,
}: ModalDeactivateReasonProps) => {
  const [searchParams, setSearchParams] = useState({
    clinicId,
    email: '',
    name: '',
    status: 'active',
    page: 1,
    limit: 10,
    orderBy: {
      id: 'id',
      value: 'desc',
    },
  });

  const { clinicsApi } = useApi();

  const listParams = useMemo(() => {
    return stripEmpty(
      {
        ...searchParams,
        orderBy: `${searchParams.orderBy.id} ${searchParams.orderBy.value}`,
        status: searchParams.status === 'all' ? undefined : searchParams.status,
      },
      {
        emptyString: true,
        emptyArray: true,
      }
    );
  }, [searchParams]);

  const { data = [] } = useApiFetch([listParams], clinicsApi.userList, {
    noCache: true,
  });

  const [params, setParams] = useState({
    email: '',
    name: '',
    status: 'active',
  });

  const handleChangeParams = (newParams: any) => {
    setParams((old) => ({
      ...old,
      ...newParams,
    }));
  };

  const handleChange = (newParams: any) => {
    setSearchParams({
      ...searchParams,
      ...newParams,
    });
  };

  const handleSubmit = () => {
    handleChange({
      ...params,
      page: 1,
    });
  };

  const handleChangePagination = (pg: any) => {
    setSearchParams({
      ...searchParams,
      page: pg.page,
      limit: pg.limit,
    });
  };

  return (
    <OverlayWrapper onClose={onClose}>
      <ModalWrapper>
        <ModalHeader title={title} onClose={onClose} className={'p-30'} />
        <Box className="modal-body">
          <Box className="search-wrapper">
            <Box className="search-input-field">
              <Box width={250}>
                <TextField
                  $type="search"
                  value={params.email}
                  startAdornment="ID"
                  placeholder="입력"
                  onChange={(v) => handleChangeParams({ email: v })}
                  onEnter={handleSubmit}
                />
              </Box>
              <Box width={250}>
                <TextField
                  $type="search"
                  value={params.name}
                  startAdornment="이름"
                  placeholder="2자리 이상 입력"
                  onChange={(v) => handleChangeParams({ name: v })}
                  onEnter={handleSubmit}
                />
              </Box>
              <Box className="dropdown-box">
                <DropdownList
                  value={statusOptions.filter((o) => params.status === o.value)}
                  options={statusOptions}
                  onChange={(v: any) =>
                    handleChangeParams({ status: v?.value })
                  }
                  variant="search"
                  getOptionLabel={(o: any) => {
                    if (o[0]) {
                      return o[0]?.label ? o[0].label : '';
                    } else {
                      return o ? (o?.label ? o.label : '') : '';
                    }
                  }}
                  startAdornment={<Typography>상태</Typography>}
                  placeholder="전체"
                  useClearIcon={false}
                />
              </Box>
            </Box>
            <Box className="search-btn-box">
              <Button
                styled="outline"
                className="search-btn"
                onClick={handleSubmit}
              >
                검색
              </Button>
            </Box>
          </Box>
          <Box className="pagination-wrapper">
            <DataPagination
              value={{
                limit: searchParams.limit,
                page: searchParams.page,
              }}
              pages={(data as any)?.pagination?.pages || 1}
              total={(data as any)?.pagination?.total || 1}
              onChange={handleChangePagination}
              hideTotal={false}
            />
          </Box>
          <Box className="table-wrapper">
            <UsersDataTable
              data={data}
              orderBy={searchParams.orderBy}
              pagination={{
                limit: searchParams.limit,
                page: searchParams.page,
              }}
              onChangePagination={handleChangePagination}
            />
          </Box>
        </Box>
      </ModalWrapper>
    </OverlayWrapper>
  );
};

export default observer(ModalDeactivateReason);
