import { Dialog, styled } from '@mui/material';
import { ReactNode } from 'react';

const StyledDialog = styled(Dialog)`
  & .MuiBackdrop-root {
    background: rgba(0, 0, 0, 0.2) !important;
  }
  .MuiPaper-root {
    box-shadow: none;
    max-width: 1300px;
  }
`;

type OverlayWrapperProps = {
  open?: boolean;
  onClose: () => void;
  children: ReactNode;
};

export const OverlayWrapper = ({
  open = true,
  onClose,
  children,
}: OverlayWrapperProps) => {
  return (
    <StyledDialog open={open} onClose={onClose}>
      {children}
    </StyledDialog>
  );
};
