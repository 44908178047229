import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useApp } from '~/hooks/useApp';
import { FileSampleRepo } from '~/components/repo/FileSampleRepo';
import { FileSampleTable } from './FileSampleTable';
import { FileSampleFormModal } from './FileSampleFormModal';
import { Tooltip } from '~/components/commons/Tooltip';
import PageNav from '~/components/commons/PageNav';

export const FileSamplePage = observer(() => {
  const repo = new FileSampleRepo();
  const app = useApp();
  const [items, setItems] = useState([]);

  async function load() {
    const res = await repo.all();
    setItems(res.data);
  }

  useEffect(() => {
    load();
  }, []);

  const onClickCreate = async () => {
    await app.$modal.custom({
      component: FileSampleFormModal,
      options: {
        action: 'create',
      },
    });

    load();
  };

  function onChange() {
    load();
  }

  return (
    <div className="main-contents">
      <PageNav pages={['GENERAL', `파일샘플`]} />
      <div className="content-title" style={{ verticalAlign: 'middle' }}>
        <Tooltip title={app.$me.role === 'manager' ? '권한이 없습니다.' : ''}>
          <div className="search-item">
            <button
              className="btn-sm btn-black cursor-pointer"
              onClick={onClickCreate}
              disabled={app.$me.role === 'manager'}
            >
              생성
            </button>
          </div>
        </Tooltip>
      </div>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>이름</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <FileSampleTable items={items} onChange={onChange} />
        </tbody>
      </table>
    </div>
  );
});
