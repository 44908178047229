import { useApp } from '~/hooks/useApp';
import ManagementChangedPasswordModal from '~/components/modals/management/ManagementChangedPasswordModal';
import {
  Content,
  Container,
  ItemTitle,
  ItemWide,
} from './DetailManagementTable.styled';

export const PasswordChangeTable = () => {
  const app = useApp();

  const onClickPasswordChange = () => {
    app.$modal.custom({
      component: ManagementChangedPasswordModal,
      options: {},
    });
  };

  return (
    <Content>
      <Container>
        <ItemTitle>비밀번호 변경</ItemTitle>
        <ItemWide className="label">
          <button
            onClick={() => onClickPasswordChange()}
            className="btn-primary"
          >
            비밀번호 변경
          </button>
        </ItemWide>
      </Container>
    </Content>
  );
};
