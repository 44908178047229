import Icon from './Icon';
import { IconWrapper } from './ClearButton.styled';

type ClearButtonProps = {
  onClick?: (params?: any) => void;
};

export const ClearButton = ({ onClick, ...props }: ClearButtonProps) => {
  return (
    <IconWrapper onClick={onClick} {...props}>
      <Icon variant="close" width={10} height={10} />
    </IconWrapper>
  );
};
