import { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { useApp } from '~/hooks/useApp';
import { DrugRepo } from '~/components/repo/DrugRepo';
import { CreateDrugModal } from './CreateDrugModal';
import { DrugTable } from './DrugTable';
import Pagination from '~/components/app/Pagination';
import { Tooltip } from '~/components/commons/Tooltip';
import PageNav from '~/components/commons/PageNav';

export const Title = styled.div`
  margin-top: 3px;
  margin-bottom: 24px;
  font-size: 17px;
  line-height: 17px;
  font-weight: 600;
`;

export const SearchWrapper = styled.div`
  display: flex;
  column-gap: 8px;
`;

export const DrugPage = observer(() => {
  const repo = new DrugRepo();
  const app = useApp();

  const defaultParams = app.$qb().limit(20).orderBy('createdAt desc');
  const [searchValue, setSearchValue] = useState<any>('');
  const [params, setParams] = useState<any>(defaultParams);
  const [total, setTotal] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [limit] = useState(params ? params.queryParams.limit : 20);
  const [items, setItems] = useState([]);

  async function load() {
    const res: any = await repo.all({ ...params.build() });
    setItems(res.data);
    setTotal(res.pagination.total);
  }

  useEffect(() => {
    load();
  }, [params]);

  const onClickCreate = async () => {
    await app.$modal.custom({
      component: CreateDrugModal,
      options: {
        action: 'create',
        callApi: load,
      },
    });
  };

  const onChangeSearch = useCallback(
    (e) => {
      const { name, value } = e.target;
      const searchParam = { ...searchValue, [name]: value };
      setSearchValue(searchParam);
    },
    [searchValue]
  );

  const onClickSearch = () => {
    params.customParam('page', 1);
    setCurrentIndex(0);
    const editParams = {
      ...params,
      queryParams: {
        ...defaultParams.queryParams,
        ...searchValue,
      },
    };
    setParams(editParams);
  };

  const onPage = (idx: number) => {
    if (!params) return;
    if (idx >= 0) {
      setCurrentIndex(idx);
    }
    params.customParam('page', idx + 1);
    setParams(params);
    load();
  };

  return (
    <div className="main-contents">
      <PageNav pages={['GENERAL', `의약품 등록/수정`]} />
      <SearchWrapper>
        <div className="search-item" style={{ margin: 0 }}>
          <input
            placeholder="약품명"
            name="keyword"
            onChange={onChangeSearch}
            onKeyPress={(e) => app.onKeyPressEnterFunction(e, onClickSearch)}
          />
        </div>
        <button
          className="btn btn-black cursor-pointer"
          onClick={onClickSearch}
        >
          {app.$translate('FIND')}
        </button>
        <Tooltip title={app.$me.role === 'manager' ? '권한이 없습니다.' : ''}>
          <div>
            <button
              className="btn btn-info cursor-pointer"
              onClick={onClickCreate}
              disabled={app.$me.role === 'manager'}
            >
              등록
            </button>
          </div>
        </Tooltip>
      </SearchWrapper>
      <DrugTable items={items} onChange={load} />
      <Pagination
        total={total}
        limit={limit}
        currentIndex={currentIndex}
        onPage={onPage}
      />
    </div>
  );
});
