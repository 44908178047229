import { useState, useMemo, useRef, useEffect } from 'react';
import { observer } from 'mobx-react';
import { stripEmpty } from '~/utils/objUtil';
import { useApi } from '~/components/providers/ApiProvider';
import { DataPagination, Summary, ButtonWrapper } from './ClinicUse.styled';
import { SearchForm } from './SearchForm';
import { ClinicsDataTable } from './DataTable';
import { useHistory, useLocation } from 'react-router-dom';
import querystring from 'query-string';
import { Button } from '~/components/commons/Button';
import { useApp } from '~/hooks/useApp';
import { useSnackbarContext } from '~/components/providers/SnackbarProvider';
import { useModal } from '~/hooks/useModal';
import PageNav from '~/components/commons/PageNav';

const defaultParams = {
  createdAtStart: '', // 등록일
  createdAtEnd: '', // 등록일
  id: '', // id
  name: '', // 거래처명
  oldName: '', // 구 거래처명
  employerNo: '', //사업자번호
  test: '', // 계정 유형
  status: '', // 계약 상태
  clinicTel: '', // 대표번호
  agency: '', // 계약대행사
  userCountMin: '', // 사용 계정 수 min
  userCountMax: '', // 사용 계정 수 max
  s3SizeMin: '', // 사용 용량 min
  s3SizeMax: '', // 사용 용량 max
  balanceMin: '', // 문자 잔액 min
  balanceMax: '', // 문자 잔액 max
  isOverLimit: false, // 관리비 기준 초과
  crmManagementPlanPriceMin: '', // crm 계약 운영비 min
  crmManagementPlanPriceMax: '', // crm 계약 운영비 max
  crmManagementPlanLimitMin: '', // 계약 계정 수 min
  crmManagementPlanLimitMax: '', // 계약 계정 수 min
  penchartPlanPriceMin: '', // 펜차트 계약 관리비 min
  penchartPlanPriceMax: '', // 펜차트 계약 관리비 max
  penchartPlanLimitMin: '', // 펜차트 계약 사용량 min
  penchartPlanLimitMax: '', // 펜차트 계약 사용량 max
  signCertPlanPriceMin: '', // 전자서명 계약 관리비 min
  signCertPlanPriceMax: '', // 전자서명 계약 관리비 max
  signCertPlanLimitMin: '', // 전자서명 계약 사용량 min
  signCertPlanLimitMax: '', // 전자서명 계약 사용량 max
  certificationUserCountMin: '', // 전자서명 사용량 min
  certificationUserCountMax: '', // 전자서명 사용량 max
  number080PlanPriceMin: '', // 080번호 관리비 min
  number080PlanPriceMax: '', // 080번호 관리비 max
  insurancePlanPriceMin: '', // 보험청구 관리비 min
  insurancePlanPriceMax: '', // 보험청구 관리비 max
  isUberSync: '', // 보험청구 연동
  insurancePlanLimitMin: '', // 보험청구 계약 사용수 min,
  insurancePlanLimitMax: '', // 보험청구 계약 사용수 max,
  // uberUserCountMin: '', // uber 연동 사용수 min
  // uberUserCountMax: '', // uber 연동 사용수 max
  totalManagementPriceMin: '', // 총관리비 min
  totalManagementPriceMax: '', // 총관리비 max
  existPrintLog: '', // 네모닉 출력
  page: 1,
  limit: 50,
  orderBy: {
    id: 'id',
    value: 'desc',
  },
};

const ClinicUse = () => {
  const app = useApp();
  const snackbar = useSnackbarContext();
  const modal = useModal();
  const location = useLocation();
  const history = useHistory();
  const defaultParamsRef = useRef(defaultParams);

  const querySearchParams = useMemo(() => {
    const _searchParams = querystring.parse(location.search);
    const { limit, page, orderBy, isOverLimit, ...rest } = _searchParams;
    return Object.keys(_searchParams).length > 0
      ? {
          ...rest,
          page:
            page && !isNaN(Number(page))
              ? Number(page)
              : defaultParamsRef.current.page,
          isOverLimit: isOverLimit && isOverLimit === 'true' ? true : false,
          limit:
            limit && !isNaN(Number(limit))
              ? Number(limit)
              : defaultParamsRef.current.limit,
          orderBy:
            orderBy &&
            typeof orderBy === 'string' &&
            !orderBy.includes('undefined') &&
            orderBy.split(' ').length === 2
              ? {
                  id: orderBy.split(' ')[0].trim(),
                  value: orderBy.split(' ')[1].trim(),
                }
              : defaultParamsRef.current.orderBy,
        }
      : null;
  }, [location.search]);

  const [searchParams, setSearchParams] = useState(
    querySearchParams !== null
      ? {
          ...defaultParamsRef.current,
          ...querySearchParams,
        }
      : defaultParamsRef.current
  );
  const [summaryData, setSummaryData] = useState({
    total: 0,
    active: 0,
    inactive: 0,
  });
  const { clinicsApi, excelDownloadApi } = useApi();
  const [data_v2, setData_v2] = useState<any>([]);

  useEffect(() => {
    if (
      querySearchParams !== null &&
      Object.keys(querySearchParams)?.length > 3
    ) {
      const mixed = { ...defaultParamsRef.current, ...querySearchParams };
      handleSearchSubmit(mixed);
    } else if (
      querySearchParams === null &&
      JSON.stringify(searchParams) !== JSON.stringify(defaultParamsRef.current)
    ) {
      resetAll();
    }
  }, [querySearchParams]);

  const resetAll = () => {
    setSearchParams(defaultParamsRef.current);
    setSummaryData({
      total: 0,
      active: 0,
      inactive: 0,
    });
    setData_v2([]);
  };

  const handleSearch = async (newParams: any) => {
    const newSearchParams = {
      ...searchParams,
      ...newParams,
    };

    const _newParams = stripEmpty(
      {
        ...newSearchParams,
        name: newSearchParams?.name?.length < 2 ? '' : newSearchParams.name,
        oldName:
          newSearchParams?.oldName?.length < 2 ? '' : newSearchParams.oldName,
        isOverLimit: newSearchParams?.isOverLimit === true ? true : undefined,
        orderBy: `${searchParams.orderBy.id} ${searchParams.orderBy.value}`,
        page: 1,
      },
      {
        emptyString: true,
        emptyArray: true,
      }
    );
    history.push(`/clinic_use?${querystring.stringify(_newParams)}`);
  };

  const handleSearchSubmit = (newSearchParams: any) => {
    const _newParams = stripEmpty(
      {
        ...newSearchParams,
        isOverLimit: newSearchParams?.isOverLimit === true ? true : undefined,
        orderBy: `${newSearchParams.orderBy.id} ${newSearchParams.orderBy.value}`,
      },
      {
        emptyString: true,
        emptyArray: true,
      }
    );
    handleGetSearch(_newParams);
    handleGetSearchSummary(_newParams);
    setSearchParams(newSearchParams);
  };

  const handleGetSearchSummary = async (params: any) => {
    const response: any = await clinicsApi.getSummary(params);
    setSummaryData(response?.data || []);
  };

  const handleGetSearch = async (params: any) => {
    const response = await clinicsApi.list(params);
    setData_v2(response || []);
  };

  const handleChangePagination = (pg: any) => {
    const newSearchParams = {
      ...searchParams,
      page: pg.page,
      limit: pg.limit,
    };
    const _newParams = stripEmpty(
      {
        ...newSearchParams,
        name: newSearchParams?.name?.length < 2 ? '' : newSearchParams.name,
        oldName:
          newSearchParams?.oldName?.length < 2 ? '' : newSearchParams.oldName,
        isOverLimit: newSearchParams?.isOverLimit === true ? true : undefined,
        orderBy: `${searchParams.orderBy.id} ${searchParams.orderBy.value}`,
      },
      {
        emptyString: true,
        emptyArray: true,
      }
    );
    if (Object.keys(_newParams)?.length > 3) {
      history.push(`/clinic_use?${querystring.stringify(_newParams)}`);
    }
  };

  const searchFilterValues = useMemo(() => {
    return stripEmpty(
      {
        ...searchParams,
        name: searchParams?.name?.length < 2 ? '' : searchParams.name,
        oldName: searchParams?.oldName?.length < 2 ? '' : searchParams.oldName,
        isOverLimit: searchParams?.isOverLimit === true ? true : undefined,
      },
      {
        emptyString: true,
        emptyArray: true,
      }
    );
  }, [searchParams]);

  const onFailExcelDownload = () => {
    modal.close({
      type: 'LOADING',
    });
    snackbar.alert('다운로드를 실패했습니다.');
  };

  const downloadFile = async (location: string) => {
    modal.close({
      type: 'LOADING',
    });

    const res: any = await excelDownloadApi.downloadExcel(
      location,
      '거래처_사용현황.xlsx'
    );

    const link = document.createElement('a');
    link.href = res.data.url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const onclickDownload = async () => {
    let newParams = stripEmpty(
      {
        ...searchParams,
        name: searchParams?.name?.length < 2 ? '' : searchParams.name,
        oldName: searchParams?.oldName?.length < 2 ? '' : searchParams.oldName,
        isOverLimit: searchParams?.isOverLimit === true ? true : undefined,
      },
      {
        emptyString: true,
        emptyArray: true,
      }
    );

    delete newParams.page;
    delete newParams.orderBy;
    delete newParams.limit;

    modal.loading({
      type: 'LOADING',
      msg: '다운로드중입니다. 데이터 양에 따라 \n 수 초 ~ 수 분이 걸릴 수 있습니다.',
    });

    const resp: any = await excelDownloadApi.postExcelsClinic({
      filters: newParams,
    });
    if (resp?.data) {
      const taskId: number = resp.data.id;
      const startInterval = setInterval(async () => {
        try {
          const res: any = await excelDownloadApi.getTasks(taskId);
          switch (res.data.status) {
            case 'SUCCESS':
              clearInterval(startInterval);
              downloadFile(res.data.location);
              break;
            case 'FAILURE':
              clearInterval(startInterval);
              onFailExcelDownload();
              break;
          }
        } catch (e) {
          clearInterval(startInterval);
          onFailExcelDownload();
        }
      }, 2000);
    } else {
      onFailExcelDownload();
    }
  };

  const disabled = useMemo(() => {
    return Object.keys(searchFilterValues).length <= 3;
  }, [searchFilterValues]);

  return (
    <div className="main-contents">
      <PageNav pages={['CLIENT', '거래처 사용현황']} />
      <SearchForm
        onSearch={handleSearch}
        searchParams={searchParams}
        originalParams={defaultParamsRef.current}
      />
      <Summary>
        {`조회결과 [ 총 `}
        <b>{summaryData.total}</b>
        {`ㅣ 사용중 `}
        <b>{summaryData.active ?? 0}</b>
        {`ㅣ 해지 `}
        <b>{summaryData.inactive ?? 0}</b>
        {` ]`}
        <DataPagination
          options={[
            {
              label: '50개씩',
              value: 50,
            },
            {
              label: '100개씩',
              value: 100,
            },
          ]}
          value={{
            limit: searchParams.limit,
            page: searchParams.page,
          }}
          pages={data_v2?.pagination?.pages || 1}
          total={data_v2?.pagination?.total || 1}
          disabled={Object.keys(searchFilterValues).length <= 3}
          onChange={handleChangePagination}
        />
        <ButtonWrapper>
          <Button
            disabled={app.$me.role === 'manager' || disabled}
            styled="outline"
            onClick={() => onclickDownload()}
          >
            Excel 내려받기
          </Button>
        </ButtonWrapper>
      </Summary>
      <ClinicsDataTable
        data={data_v2}
        orderBy={searchParams.orderBy}
        pagination={{
          limit: searchParams.limit,
          page: searchParams.page,
        }}
        onChangePagination={handleChangePagination}
      />
    </div>
  );
};

export default observer(ClinicUse);
