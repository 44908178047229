import $http from 'axios';
const config = {
  headers: {
    'content-type': 'multipart/form-data',
  },
};

export const ClinicRepo = {
  clinic: {
    all: (params?: any) => $http.get('/clinics', { params }),
    namesAll: () => $http.get('/clinics/names'),
    delete: (clinicId: string) => $http.delete(`/clinics/${clinicId}`),
    restore: (clinicId: string) => $http.put(`/clinics/revoke/${clinicId}`),
    detail: (clinicId: string) => $http.get(`/clinics/${clinicId}`),
    create: (clinic: any) => $http.post('/clinics', clinic),
    update: (clinic: any) => $http.put(`/clinics/${clinic.id}`, clinic),
    update_files: (id: string, body: any) =>
      $http.post(`/clinics/${id}/files`, body, config),
  },
  memo: {
    update: (params: any) => $http.put(`/clinics/memo/${params.id}`, params),
  },
  users: {
    create: (clinicId: string, payload: any) =>
      $http.put(`/users/${clinicId}`, payload),
    all: (params: any) => $http.get('/clinics/users', { params }),
    updatePassword: (clinicId: string) =>
      $http.post(`/clinics/users/password/${clinicId}`, {
        password: '11111111',
      }),
  },
  deposit: {
    all: (id: string) => $http.get(`/clinics/condition/deposits/${id}`),
    create: (params: any, id: string) =>
      $http.post(`/clinics/condition/deposits/${id}`, params),
    update: (params: any, id: string) =>
      $http.put(`/clinics/condition/deposit/${id}`, params),
    delete: (id: string) => $http.delete(`/clinics/condition/deposit/${id}`),
  },
  deactivate: {
    create: (params: any) => $http.post('/clinics/cancel/history', params),
    update: (params: any, id: string) =>
      $http.put(`/clinics/cancel/history/${id}`, params),
    all: (params: any) => $http.get('/clinics/cancel/history', { params }),
  },
  centrex: {
    all: (params: any) => $http.get('/centrex', { params }),
    detail: (id: string) => $http.get(`/centrex/${id}`),
    create: (params: any) => $http.post('/centrex', params),
    update: (params: any, id: string) => $http.put(`/centrex/${id}`, params),
    delete: (id: string) => $http.delete(`/centrex/${id}`),
  },
  summary: (params: any) =>
    $http.get(`${process.env.REACT_APP_API_URL}/statistics/clinics/summary`, {
      params,
    }),
};
