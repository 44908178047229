import { useState, useRef } from 'react';
import { observer } from 'mobx-react';
import { OverlayWrapper } from '~/components/commons/modals/Overlay';
import DatePicker from 'react-datepicker';
import { objectToFormData } from 'object-to-formdata';
import {
  ModalWrapper,
  DropdownList,
  Button,
  Box,
  TextField,
  FormControl,
  FileInput,
  TextareaAutosize,
  IconButton,
  Link,
} from '../Clinic/ModalDeactivate.styled';
import { agencies as agenciesOptions } from '~/hooks/constants';
import { useSnackbarContext } from '~/components/providers/SnackbarProvider';
import { useApi } from '~/components/providers/ApiProvider';
import { format, isValid } from 'date-fns';
import { Button as Button2 } from '~/components/commons/Button';
import Icon from '~/components/commons/Icon';

type ModalDeactivateReasonProps = {
  info: any;
  title: string;
  onClose: () => void;
  onRefresh: () => void;
};

const ModalDeactivateReason = ({
  info,
  onClose,
  title,
  onRefresh,
}: ModalDeactivateReasonProps) => {
  const [value, setValue] = useState<{
    refundPrice: string;
    reason: string;
    clinicId: string;
    contractAgency: string;
    educationAgency: string;
    cancelExcuteAt: Date;
    cancelAt: Date;
    cancelFormFile: File | null | undefined;
  }>({
    refundPrice: info.refundPrice || '',
    reason: info.reason || '',
    clinicId: info.clinicId,
    contractAgency: info.contractAgency || '',
    educationAgency: info.educationAgency || '',
    cancelExcuteAt: isValid(new Date(info.cancelExcuteAt))
      ? new Date(info.cancelExcuteAt)
      : new Date(),
    cancelAt: isValid(new Date(info.cancelAt))
      ? new Date(info.cancelAt)
      : new Date(),
    cancelFormFile: undefined,
  });
  const fileRef = useRef<HTMLInputElement>();
  const snackbar = useSnackbarContext();
  const { clinicsApi } = useApi();

  const handleChangeValue = (v: any) => {
    setValue({
      ...value,
      ...v,
    });
  };

  const handleSubmit = async () => {
    try {
      const formData = objectToFormData(
        {
          reason: value.reason,
          refundPrice: value.refundPrice,
          cancelAt: format(new Date(value.cancelAt), 'yyyy-MM-dd'),
          cancelExcuteAt: format(new Date(value.cancelExcuteAt), 'yyyy-MM-dd'),
          contractAgency: value.contractAgency,
          educationAgency: value.educationAgency,
          cancelFormFile: value.cancelFormFile,
        },
        {
          indices: true,
        }
      );
      await clinicsApi.updateDeactivate(info.id, formData);
      snackbar.success('UPDATED');
      onRefresh();
      onClose();
    } catch (e: any) {
      if (e.name === 'Error') {
        snackbar.alert('오류가 발생했습니다. 잠시후 다시 시도해주세요');
      } else if (e.name === 'NO_PERMISSION') {
        snackbar.alert('접근 권한이 없습니다.');
      } else if (e.name != null) {
        snackbar.alert('모든값을 입력해주세요');
      } else {
        snackbar.alert(e.description);
      }
    }
  };

  const handleDeleteFile = () => {
    handleChangeValue({ cancelFormFile: null });
  };

  const handleChangeImage = (e: any) => {
    const target = e.target;
    if (target.files[0] === undefined) {
      return;
    }

    const { size, name } = e.target.files[0];
    const fileType = name.split('.').pop();
    const confirmType = ['jpg', 'JPG', 'png', 'PNG', 'jpeg', 'pdf', 'PDF'];
    // 10Mb
    const confirmSize = 10;
    if (size / 1024 / 1024 > confirmSize) {
      snackbar.alert('10MB 이하로만 첨부가능합니다.');
      return;
    }

    if (confirmType.indexOf(fileType) === -1) {
      snackbar.alert('jpg 또는 PNG 또는 PDF 파일만 첨부가능합니다.');
      return;
    }

    const reader = new FileReader();
    reader.onload = function (event: any) {
      const file = target.files[0];

      if (file.type === 'application/pdf') {
        return handleChangeValue({ cancelFormFile: file });
      }

      const img: any = new Image();
      img.src = event.target.result;

      img.onload = function () {
        const file = target.files[0];
        handleChangeValue({ cancelFormFile: file });
      };
    };
    reader.readAsDataURL(target.files[0]);
  };

  return (
    <OverlayWrapper onClose={onClose}>
      <ModalWrapper>
        <Box className="modal-header p-20">{title}</Box>
        <Box className="modal-body p-20">
          <FormControl>
            <Box className="form-box">
              <label>
                환불 금액 <span className="required">*</span>
              </label>
              <TextField
                value={value.refundPrice}
                onChange={(v) => handleChangeValue({ refundPrice: v })}
                placeholder="환불 금액을 입력하세요"
                className="white-bg"
              />
            </Box>
          </FormControl>
          <FormControl>
            <Box className="form-box">
              <label>
                계약 대행사 <span className="required">*</span>
              </label>
              <DropdownList
                value={agenciesOptions.filter((o) =>
                  value.contractAgency?.includes(o.value)
                )}
                options={agenciesOptions.map((item: any) => {
                  item.id = item.value;
                  item.label = item.key;
                  item.id = item.value;
                  return item;
                })}
                onChange={(v: any) => {
                  handleChangeValue({
                    contractAgency: v?.value,
                  });
                }}
                placeholder="대행사를 선택하세요"
                getOptionLabel={(o: any) => {
                  if (o[0]) {
                    return o[0]?.label ? o[0].label : '';
                  } else {
                    return o ? (o?.label ? o.label : '') : '';
                  }
                }}
              />
            </Box>
            <Box className="form-box">
              <label>
                교육 대행사<span className="required">*</span>
              </label>
              <DropdownList
                value={agenciesOptions.filter((o) =>
                  value.educationAgency?.includes(o.value)
                )}
                options={agenciesOptions.map((item: any) => {
                  item.label = item.key;
                  item.id = item.value;
                  return item;
                })}
                onChange={(v: any) => {
                  handleChangeValue({
                    educationAgency: v?.value,
                  });
                }}
                placeholder="대행사를 선택하세요"
                getOptionLabel={(o: any) => {
                  if (o[0]) {
                    return o[0]?.label ? o[0].label : '';
                  } else {
                    return o ? (o?.label ? o.label : '') : '';
                  }
                }}
              />
            </Box>
          </FormControl>
          <FormControl>
            <Box className="form-box">
              <label>
                요청일 <span className="required">*</span>
              </label>
              <Box className="date-picker-box">
                <DatePicker
                  dateFormat="yyyy년 MM월 d일"
                  selected={value.cancelExcuteAt}
                  onChange={(date) => {
                    handleChangeValue({
                      cancelExcuteAt: date,
                    });
                  }}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </Box>
            </Box>
            <Box className="form-box">
              <label>
                해지일 <span className="required">*</span>
              </label>
              <Box className="date-picker-box">
                <DatePicker
                  dateFormat="yyyy년 MM월 d일"
                  selected={value.cancelAt}
                  onChange={(date) => {
                    handleChangeValue({
                      cancelAt: date,
                    });
                  }}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </Box>
            </Box>
          </FormControl>
          <FormControl>
            <Box className="form-box w-max">
              <label>
                해지 신청서 <span className="required">*</span>
              </label>
              <Box className="file-wrapper">
                <Button2
                  size="xs"
                  styled="outline"
                  color="black"
                  onClick={() => fileRef.current?.click()}
                >
                  첨부하기
                </Button2>
                <FileInput
                  ref={fileRef}
                  onChange={(e: any) => handleChangeImage(e)}
                />
              </Box>
              <Box className="p-8 cancel-request-link-wrapper">
                {value?.cancelFormFile ? (
                  value.cancelFormFile?.name || ''
                ) : info?.cancelRequestFileUrl &&
                  value.cancelFormFile === undefined ? (
                  <Link href={info.cancelRequestFileUrl} target="_blank">
                    {info.cancelRequestFileUrl}
                  </Link>
                ) : (
                  ''
                )}
                {(value.cancelFormFile?.name ||
                  (value.cancelFormFile === undefined &&
                    info?.cancelRequestFileUrl)) && (
                  <IconButton onClick={handleDeleteFile}>
                    <Icon variant="close" />
                  </IconButton>
                )}
              </Box>
            </Box>
          </FormControl>
          <FormControl>
            <Box className="form-box w-max">
              <label>
                해지 사유<span className="required">*</span>
              </label>
              <TextareaAutosize
                value={value.reason}
                minRows={3}
                onChange={(e) => {
                  handleChangeValue({
                    reason: e.target.value,
                  });
                }}
              />
            </Box>
          </FormControl>
        </Box>
        <Box className="modal-footer p-20">
          <Button variant="contained" className="btn-default" onClick={onClose}>
            취소
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={
              value.refundPrice === '' ||
              value.reason === '' ||
              value.contractAgency === '' ||
              value.educationAgency === '' ||
              value.cancelFormFile === null
            }
          >
            저장
          </Button>
        </Box>
      </ModalWrapper>
    </OverlayWrapper>
  );
};

export default observer(ModalDeactivateReason);
