import {
  Typography as MuiTypography,
  styled,
  css,
  Box as MuiBox,
} from '@mui/material';
import { TextField as _TextField } from '~/components/commons/TextField';
import { Button as _Button } from '~/components/commons/Button';
import { ComboBox as _ComboBox } from '~/components/commons/ComboBox';
import { DropdownList as _DropdownList } from '~/components/commons/DropdownList_v1';
import { SearchFilterRangeInput as _SearchFilterRangeInput } from '~/components/commons/SearchFilterRangeInput';
import { ComponentType } from 'react';

export const DropdownList = styled(_DropdownList)`
  height: 34px;
  .MuiAutocomplete-inputRoot.MuiInputBase-root.MuiOutlinedInput-root {
    height: 34px;
  }
`;

export const Box = styled(MuiBox)(({ theme }) => css``);

export const SearchFilterRangeInput = styled(_SearchFilterRangeInput)(
  ({ theme }) => css`
    &.account-count {
      .label-text {
        width: 120px;
      }
    }
    &.pen-chart-account-bucket-size {
      .label-text {
        width: 180px;
      }
    }
  `
) as ComponentType<any>;

export const TextField = styled(_TextField)(
  ({ theme }) => css`
    height: 34px;
  `
) as ComponentType<any>;

export const ComboBox = styled(_ComboBox)(
  ({ theme }) => css`
    height: 34px;
    .MuiAutocomplete-inputRoot.MuiInputBase-root.MuiOutlinedInput-root {
      height: 34px;
    }
  `
);

export const Typography = styled(MuiTypography)(
  ({ theme }) => css`
    width: 64px;
    font-size: 12px;
    font-weight: 500;
    padding-left: 8px;
    font-weight: 500;
    word-break: keep-all;

    &.width-wide {
      width: 80px;
    }
  `
);

export const Button = styled(_Button)`
  height: 34px;

  &.search-btn {
    margin-left: 0.5rem;
  }
` as ComponentType<any>;
