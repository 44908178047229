import {
  CustomListItemText,
  CustomMenuItem,
  CustomSelect,
} from './Select2.styled';

type Select2Props = {
  value: any;
  options: any[];
  onChange: (params?: any) => void;
  placeholder?: string;
  fieldSize?: 'default' | 'small' | 'xsmall';
};

export const Select2 = ({
  onChange,
  value,
  options,
  placeholder,
  fieldSize = 'default',
  ...props
}: Select2Props) => {
  return (
    <CustomSelect
      variant="outlined"
      value={value}
      onChange={onChange}
      $fieldSize={fieldSize}
      placeholder={placeholder}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      }}
      {...props}
    >
      {options.map((option: any) => (
        <CustomMenuItem key={option.value} value={option.value}>
          <CustomListItemText primary={option.label} />
        </CustomMenuItem>
      ))}
    </CustomSelect>
  );
};
