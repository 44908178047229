import { styled } from '@mui/material';

export const Body = styled('div')`
  margin-top: 50px;
`;

export const FlexRow = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
`;

export const Summary = styled('span')`
  display: flex;
  justify-content: flex-end;
  font-size: 13px;
  white-space: pre;
`;
