import $http from 'axios';

export class DrugRepo {
  all(params: any) {
    return $http.get('/medicines', { params });
  }

  create(data: any) {
    return $http.post('/medicines', data);
  }

  update(id: number, data: any) {
    return $http.put(`/medicines/${id}`, data);
  }
}
