import { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useApp } from '~/hooks/useApp';
import { useSnackbarContext } from '~/components/providers/SnackbarProvider';
import { EditManagementTable } from '~/components/routes/management/EditManagementTable';
import { Button } from '~/components/button/Button';
import { useApi } from '~/components/providers/ApiProvider';
import { roleOptions, statusOptions } from '~/pages/management/management';
import {
  Depth,
  Title,
  SubTitle,
  Contents,
} from './DetailManagementPage.styled';
import { ConfirmModal } from '~/components/modals/management/ConfirmModal';
import { useHistory } from 'react-router-dom';
import { useAuth } from '~/hooks/useAuth';

export const EditManagementPage = () => {
  const history = useHistory();
  const snackbar = useSnackbarContext();
  const app = useApp();
  const { userApi } = useApi();
  const auth = useAuth();

  const { id }: any = useParams();
  const [user, setUser] = useState<any>({
    email: '',
    id: null,
    name: '',
    role: null,
    status: null,
  });

  const [value, setValue] = useState<any>({
    name: user.name,
    role: roleOptions.find((f) => f.id === user.role),
    status: statusOptions.find((f) => f.id === user.status),
  });

  const callApi = useCallback(async () => {
    if (id) {
      const user: any = await userApi.getUser(id);
      setUser(user.data);
      setValue({
        name: user.data.name,
        role: roleOptions.find((f) => f.id === user.data.role),
        status: statusOptions.find((f) => f.id === user.data.status),
      });
    }
  }, []);

  useEffect(() => {
    callApi();
  }, []);

  const validate = () => {
    if (value.name === '' || value.role === null || value.status === null) {
      throw new Error('필수값이 누락되었습니다.');
    }
  };

  const onClickSave = async () => {
    try {
      validate();
    } catch (e: any) {
      snackbar.alert(e.message);
      return;
    }

    try {
      const onConfirm = async () => {
        await userApi.updateUser(
          id,
          value.name,
          value.role.id,
          value.status.id
        );
        snackbar.success('계정 수정이 완료되었습니다.');
        history.push(`/management/detail/${id}`);

        if (app.$me.id && id == app.$me.id) {
          const resp = await userApi.getUser(app.$me.id);

          auth.loadMe({
            ...app.$me,
            role: resp.data.role,
            status: resp.data.status,
          });
        }
      };

      if (value.status.id === 'deleted') {
        app.$modal.custom({
          component: ConfirmModal,
          options: {
            onConfirm: () => onConfirm(),
          },
        });
      } else {
        onConfirm();
      }
    } catch (e: any) {
      if (e.name === 'NO_PERMISSION') {
        snackbar.alert('접근 권한이 없습니다.');
      } else {
        snackbar.alert(e.message);
      }
    }
  };

  return (
    <div className="main-contents">
      <Depth>{`관리자 관리 > 계정 수정`}</Depth>
      <Title className="content-title">
        <span>계정 수정</span>
        <Button onClick={onClickSave}>저장</Button>
      </Title>
      <SubTitle>계정정보</SubTitle>
      <Contents>
        <EditManagementTable user={user} value={value} setValue={setValue} />
      </Contents>
    </div>
  );
};
