import { observer } from 'mobx-react';
import { useSnackbarContext } from '~/components/providers/SnackbarProvider';
import { useApp } from '~/hooks/useApp';
import { CentrexRepo } from './CentrexRepo';
import { format } from 'date-fns';
import { Tooltip } from '~/components/commons/Tooltip';
import { styled } from '@mui/material';

const ButtonWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const CallStatus = {
  RINGING: 'RINGING',
  CALLING: 'CALLING',
  ENDED: 'ENDED',
  MISSED: 'MISSED',
};

type CentrexTableProps = {
  items: any[];
  callApi: () => void;
};

export const CentrexTable = observer(
  ({ items, callApi }: CentrexTableProps) => {
    const app = useApp();
    const snackbar = useSnackbarContext();
    const repo = new CentrexRepo();

    const onClick = async (id: number, type: string) => {
      try {
        await repo.update(id, {
          status: type,
        });
        snackbar.success('수정되었습니다.');
        callApi();
      } catch (e: any) {
        snackbar.alert(e.description);
      }
    };

    return (
      <table>
        <thead>
          <tr>
            <th>상태</th>
            <th>발신번호</th>
            <th>call 시작시간</th>
            <th>call 종료시간</th>
            <th>action</th>
          </tr>
        </thead>
        <tbody>
          {items.map((v: any) => {
            return (
              <tr key={v.id}>
                <td>{v.centrexCallStatus}</td>
                <td>{v.phoneNumber}</td>
                <td>
                  {v.callStartAt
                    ? format(new Date(v.startAt), 'yyyy-MM-dd H:mm:ss')
                    : '-'}
                </td>
                <td>
                  {v.callEndedAt
                    ? format(new Date(v.endAt), 'yyyy-MM-dd H:mm:ss')
                    : '-'}
                </td>
                <td>
                  <div>
                    {v.centrexCallStatus === CallStatus.RINGING && (
                      <ButtonWrapper>
                        <Tooltip
                          title={
                            app.$me.role === 'manager' ? '권한이 없습니다.' : ''
                          }
                        >
                          <div>
                            <button
                              onClick={() => onClick(v.id, CallStatus.CALLING)}
                              className="btn-sm btn-primary"
                              style={{ marginRight: '10px', cursor: 'pointer' }}
                              disabled={app.$me.role === 'manager'}
                            >
                              통화중
                            </button>
                          </div>
                        </Tooltip>
                        <Tooltip
                          title={
                            app.$me.role === 'manager' ? '권한이 없습니다.' : ''
                          }
                        >
                          <div>
                            <button
                              onClick={() => onClick(v.id, CallStatus.MISSED)}
                              className="btn-sm btn-danger"
                              style={{ cursor: 'pointer' }}
                              disabled={app.$me.role === 'manager'}
                            >
                              부재
                            </button>
                          </div>
                        </Tooltip>
                      </ButtonWrapper>
                    )}
                    {v.centrexCallStatus === CallStatus.CALLING && (
                      <ButtonWrapper>
                        <Tooltip
                          title={
                            app.$me.role === 'manager' ? '권한이 없습니다.' : ''
                          }
                        >
                          <div>
                            <button
                              onClick={() => onClick(v.id, CallStatus.ENDED)}
                              className="btn-sm btn-gray"
                              style={{ cursor: 'pointer' }}
                              disabled={app.$me.role === 'manager'}
                            >
                              통화종료
                            </button>
                          </div>
                        </Tooltip>
                      </ButtonWrapper>
                    )}
                    {(v.centrexCallStatus === CallStatus.ENDED ||
                      v.centrexCallStatus === CallStatus.MISSED) &&
                      '-'}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
);
