import { useState, SyntheticEvent, useEffect } from 'react';
import { Box, TabContext, Tab, Tabs, TabPanel } from './TabContents.styled';

type TabContentsProps = {
  defaultValue?: string;
  contents: {
    label: string;
    value?: string;
    Component: (props: any) => JSX.Element;
  }[];
};

const TabContents = (props: TabContentsProps) => {
  const { defaultValue, contents } = props;
  const [tab, setTab] = useState<string>(defaultValue || '0');
  const onChangeTab = (_: SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  useEffect(() => {
    defaultValue && setTab(defaultValue);
  }, [defaultValue]);

  return (
    <TabContext value={tab}>
      <Box className="tab-wrapper">
        <Tabs value={tab} onChange={onChangeTab}>
          {contents.map((item, index) => {
            return (
              <Tab
                label={item.label}
                value={item?.value || String(index)}
                key={index}
              />
            );
          })}
        </Tabs>
      </Box>
      {contents.map((item, index) => {
        const { Component } = item;
        return (
          <TabPanel value={item?.value || String(index)} key={index}>
            <Component />
          </TabPanel>
        );
      })}
    </TabContext>
  );
};
export default TabContents;
