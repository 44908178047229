import $http from 'axios';

export class CredentialRepo {
  all() {
    return $http.get('/credentials');
  }

  create(data: any) {
    return $http.post('/credentials', data);
  }

  update(id: number, data: any) {
    return $http.put(`/credentials/${id}`, data);
  }

  delete(id: number) {
    return $http.delete(`/credentials/${id}`);
  }
}
