import {
  styled,
  Box as MuiBox,
  Typography as MuiTypography,
  typographyClasses,
} from '@mui/material';
import { DataPagination as _DataPagination } from '~/components/commons/DataPagination';
import { Button as _Button } from '~/components/commons/Button';
import { hexToRgbaString } from '~/utils/colorUtil';

export const Box = styled(MuiBox)(
  () => `
  &.page-container {
    padding: 24px 20px; 
  }
  &.table-wrapper {

    table {
      width: 100%;
      thead {
        th {
          padding: 20px;
        }
      }
    }
  }
`
);

export const Typography = styled(MuiTypography)(
  ({ theme }) => `
  &.${typographyClasses.h1} {
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;

    &.clinic-info-title {
      display: flex;
      gap: 8px;
      margin-bottom: 20px;
      align-items: center;
      .before-bar-content {
        position: relative;
        &::before {
          content: '';
          display: inline-block;
          width: 1px;
          height: 16px;
          position: relative;
          top: 2px;
          background: red;
          margin-right: 8px;
          background: ${theme.palette.grey['700']};
        }
      }
    }
  }

`
);

export const DataPagination = styled(_DataPagination)`
  margin-left: 1rem;
`;

export const Select = styled('select')`
  margin-right: 5px;
`;

export const Summary = styled('div')`
  margin-top: 1.5rem;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  white-space: pre;
  align-items: center;
  width: 100%;
`;

export const SummaryRightSection = styled('div')`
  display: flex;
  flex-direction: row;
`;

export const Button = styled(_Button)`
  &.MuiButton-root.MuiButtonBase-root {
    &.black {
      margin-right: auto;
      font-size: 12px;
      height: 24px;
      line-height: 24px;
      min-height: 24px;
      background: #000000;
      color: #ffffff;
      border: 0 none;

      &:hover {
        background: ${hexToRgbaString('#000000', 0.7)};
      }
    }
  }
`;
