import { ReactNode, ComponentType } from 'react';
import {
  LabelWrapper as _LabelWrapper,
  Wrapper,
  LabelDom,
  LabelDomWrapper,
  ConnectChip,
  InnerWrapper,
} from './Label2.styled';

export const LabelWrapper = _LabelWrapper as ComponentType<any>;

type LabelProps = {
  isRequire?: boolean;
  disabled?: boolean;
  text?: string;
  children?: ReactNode;
  labelChildren?: ReactNode;
  disabledStyle?: any;
  connect?: boolean;
};

export const Label = ({
  isRequire,
  disabled,
  text,
  connect,
  children,
}: LabelProps) => {
  return (
    <Wrapper disabled={disabled}>
      {text && (
        <LabelDomWrapper>
          <LabelDom isRequire={isRequire} htmlFor={text}>
            {text}
          </LabelDom>
          {connect && <ConnectChip />}
        </LabelDomWrapper>
      )}
      {children && <InnerWrapper>{children}</InnerWrapper>}
    </Wrapper>
  );
};

export default Label;
