import { observer } from 'mobx-react';
import { useSnackbarContext } from '~/components/providers/SnackbarProvider';
import { useApp } from '~/hooks/useApp';
import styled from 'styled-components';
import { CredentialRepo } from '~/components/repo/CredentialRepo';
import { Tooltip } from '~/components/commons/Tooltip';
import { ConfirmModal } from '~/components/modals/ConfirmModal';
import { useImperativeModal } from '~/components/providers/ImperativeModalProvider';

const Buttons = styled.div`
  display: flex;
  gap: 8px;
`;

type CredentialTableProps = {
  items: any[];
  onChange: () => void;
};

export const CredentialTable = observer(
  ({ items, onChange }: CredentialTableProps) => {
    const app = useApp();
    const snackbar = useSnackbarContext();
    const repo = new CredentialRepo();
    const imperativeModal = useImperativeModal();

    const onClickDelete = async (item: any) => {
      const confirm = await imperativeModal.open((close) => (
        <ConfirmModal
          variant="primary"
          onConfirm={() => close(true)}
          onCancel={() => close(false)}
        >
          삭제하시겠습니까?
        </ConfirmModal>
      ));

      if (confirm) {
        await repo.delete(item.id);
        snackbar.success('삭제했습니다.');

        onChange();
      }
    };

    return (
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>API Key</th>
            <th>Data</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item) => (
            <tr key={item.id}>
              <td>{item.name}</td>
              <td>{item.key}</td>
              <td>{item.data}</td>
              <td>
                <Tooltip
                  title={app.$me.role === 'manager' ? '권한이 없습니다.' : ''}
                >
                  <Buttons>
                    <button
                      onClick={() => onClickDelete(item)}
                      className="btn btn-danger"
                      disabled={app.$me.role === 'manager'}
                    >
                      삭제
                    </button>
                  </Buttons>
                </Tooltip>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
);
