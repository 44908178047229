import { ComponentType } from 'react';
import { styled, css } from '@mui/material/styles';
import { Box as MuiBox, Button as MuiButton } from '@mui/material';
import { Tooltip as _Tooltip } from '~/components/commons/Tooltip';
import { Link as _Link } from 'react-router-dom';
import { DataTable as _DataTable } from '~/components/commons/DataTable/DataTable';

export const DataTable = styled(_DataTable)(
  ({ theme }) => `
  thead th {
    font-size: 12px;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 0em;
    color: ${theme.palette.grey['700']};
  }

  tbody td {
    height: 32px;
    padding: 5px 12px;
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0em;
    color: ${theme.palette.grey['700']};
    background: #ffffff;
  }
`
);

export const Link = styled(_Link)`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #2c62f6;
  text-decoration: underline;
`;

export const Button = styled(MuiButton)`
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 11px;
  height: 24px;
  padding: 4px 6px;
`;

export const Box = styled(MuiBox)`
  white-space: normal;
`;

export const DisabledButton = styled('button')`
  margin-left: 10px;
  padding: 3px;
  font-weight: 400;
  font-size: 11px;
  border: 1px solid #bbbbbb;
  color: #bbbbbb;
  background-color: #fff;
`;

export const Tooltip = styled(_Tooltip)(() => css``) as ComponentType<any>;
