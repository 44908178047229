import '~/assets/styles/routes/Login.css';
import {
  useState,
  useEffect,
  useRef,
  KeyboardEvent,
  ChangeEvent,
  MouseEvent,
  useMemo,
} from 'react';
import { observer } from 'mobx-react';
import firebase from 'firebase/app';
import 'firebase/remote-config';
import { Redirect } from 'react-router-dom';
import { useAuth } from '~/hooks/useAuth';
import { ReactComponent as Logo } from '~/assets/images/common/logo_unocrmadmin.svg';
import { Box, Button, Stack, TextField } from './Login.styled';
import ConfirmCertNumber from './components/ConfirmCertNumber';
import { useApi } from '~/components/providers/ApiProvider';
import { AlertModal } from '~/components/modals/AlertModal';
import { useImperativeModal } from '~/components/providers/ImperativeModalProvider';
import { useAuth as useProviderAuth } from '~/components/providers/AuthProvider';
import { useSnackbarContext } from '~/components/providers/SnackbarProvider';

const Login = () => {
  const auth = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [step, setStep] = useState(1);
  const { userApi } = useApi();
  const imperativeModal = useImperativeModal();
  const trimmedEmail = useMemo(() => email.trim(), [email]);
  const auth_v2 = useProviderAuth();
  const snackbar = useSnackbarContext();

  const disabled = () => (!email || !password ? 'disabled' : '');
  const passwordInput = useRef<null | HTMLInputElement>(null);

  useEffect(() => {
    diffVersionAndReload();
  }, []);

  const diffVersionAndReload = () => {
    const webVersion = window.localStorage.getItem('webMinVersion');
    const remoteConfig = firebase.remoteConfig();
    remoteConfig
      .fetchAndActivate()
      .then(() => {
        if (
          webVersion === null ||
          remoteConfig.getValue('webLatestVersion').asString() < webVersion ||
          remoteConfig.getValue('webMinVersion').asString() > webVersion
        ) {
          window.location.reload();
          window.localStorage.setItem(
            'webMinVersion',
            remoteConfig.getValue('webMinVersion').asString()
          );
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit();
    }
  };

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleClickSignin = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    handleSubmit();
  };

  const handleSubmit = async () => {
    if (disabled()) {
      let err;
      if (!trimmedEmail) {
        err = 'ERROR_LOGIN_MISSING_EMAIL';
      }
      if (!password) {
        err = 'ERROR_LOGIN_MISSING_PASSWORD';
      }
      snackbar.alert(err);
      return;
    }
    try {
      if (process.env.REACT_APP_ENV !== 'prod') {
        const response = await userApi.signin(trimmedEmail, password);
        if (trimmedEmail === 'test@test.com' && response) {
          const { data } = response;
          auth_v2.signinLegacy(data);
        } else {
          setStep(2);
        }
      } else {
        await userApi.signin(trimmedEmail, password);
        setStep(2);
      }
    } catch (e: any) {
      console.error(e);
      if (e.code == null) {
        snackbar.alert('ERROR_UNKNOWN_LOGIN');
        return;
      }
      if (e.code === 401) {
        if (e.name === 'EXPIRE_TEMP_PASSWORD') {
          snackbar.alert(
            '만료된 임시 비밀번호 입니다. 비밀번호 초기화를 요청하세요.'
          );
        } else {
          passwordInput.current && passwordInput.current.focus();
          await imperativeModal.open((close) => (
            <AlertModal onClose={close} bgClickCancel={true}>
              등록되지 않은 아이디거나 로그인 정보가 일치하지 않습니다.
            </AlertModal>
          ));
        }
      } else if (e.code === 403 && e.name === 'ACCESS_RESTRICTED') {
        snackbar.alert(
          '현재 접속한 IP에서는 서비스를 이용할 수 없습니다. 관리자에게 문의하세요.'
        );
      } else if (e.code === 403 && e.name === 'ACCESS_DENIED') {
        passwordInput.current && passwordInput.current.focus();
        await imperativeModal.open((close) => (
          <AlertModal onClose={close} bgClickCancel={true}>
            비밀번호 5회 오류로 로그인을 할 수 없습니다.
            <br /> 최고관리자에게 비밀번호 초기화를 요청하세요.
          </AlertModal>
        ));
      } else if (e?.description?.indexOf('Not a valid email address') !== -1) {
        snackbar.alert('ERROR_LOGIN_ID_TYPE');
      } else if (e?.description?.indexOf('than minimum length 8') !== -1) {
        snackbar.alert('ERROR_LOGIN_PASSWORD_MIN');
      } else {
        snackbar.alert(e.description);
      }
    }
  };

  if (auth.token) {
    return <Redirect to="/clinics" />;
  }

  return (
    <Box className="login-page-wrapper">
      {step === 1 ? (
        <>
          <Box className="login-contents-wrapper">
            <form>
              <Stack
                className={'form-wrapper'}
                direction={'column'}
                gap={'10px'}
              >
                <Logo role={'img'} aria-label={'crm-admin-logo'} />
                <TextField
                  type="text"
                  className="id-input"
                  placeholder="아이디를 입력하세요"
                  value={email}
                  onKeyDown={handleKeyPress}
                  onChange={handleChangeEmail}
                  autoFocus
                />
                <TextField
                  type="password"
                  placeholder="비밀번호를 입력하세요"
                  value={password}
                  onKeyDown={handleKeyPress}
                  onChange={handleChangePassword}
                  autoComplete="off"
                  inputRef={passwordInput}
                />
                <Button
                  variant="contained"
                  className="black"
                  onClick={handleClickSignin}
                >
                  로그인
                </Button>
              </Stack>
            </form>
          </Box>
          <Box className="login-page-footer">
            COPYRIGHT © carelabs All rights reserved
          </Box>
        </>
      ) : (
        <ConfirmCertNumber email={trimmedEmail} />
      )}
    </Box>
  );
};
export default observer(Login);
