import { Button as MuiButton } from '@mui/material';
import { ReactNode } from 'react';

type ButtonProps = {
  color?:
    | 'primary'
    | 'inherit'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning';
  children: ReactNode;
  onClick?: (event: any) => void;
  variant?: 'contained' | 'text' | 'outlined';
  disabled?: boolean;
};

export const Button = ({
  onClick = undefined,
  children,
  color = 'primary',
  variant = 'contained',
  disabled = undefined,
}: ButtonProps) => {
  return (
    <MuiButton
      color={color}
      variant={variant}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </MuiButton>
  );
};
