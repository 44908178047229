import { NotPermissionPage } from '~/pages/NotPermissionPage';
import { useApp } from '~/hooks/useApp';
import { observer } from 'mobx-react';

type ProtectedRouterProps = {
  permission: 'management' | 'users-log' | 'clinic-add' | 'price-tables';
  checkRole: ('super' | 'admin')[];
  component: any;
};

export const ProtectedRouter = observer(
  ({ permission, checkRole, component: Component }: ProtectedRouterProps) => {
    const app = useApp();
    const checkPermission = [
      'management',
      'users-log',
      'clinic-add',
      'price-tables',
    ];

    const hasPermission = (permission: string, checkRole: any) => {
      if (checkPermission.find((f) => f === permission)) {
        if (checkRole.find((f: any) => f === app.$me?.role)) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    };

    return hasPermission(permission, checkRole) ? (
      <Component />
    ) : (
      <NotPermissionPage />
    );
  }
);
