import {
  useState,
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import { observer } from 'mobx-react';
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
} from './ConfirmCertNumber.styled';
import { addMinutes, differenceInMilliseconds } from 'date-fns';
import { useApi } from '~/components/providers/ApiProvider';
import { useApp } from '~/hooks/useApp';
import { useAuth as useProviderAuth } from '~/components/providers/AuthProvider';
import { useHistory } from 'react-router-dom';
import { useSnackbarContext } from '~/components/providers/SnackbarProvider';

type CinfirmCertNumberProps = {
  email: string;
};

const delay = (time: number) =>
  new Promise((response) => setTimeout(response, time));

const ConfirmCertNumber = (props: CinfirmCertNumberProps) => {
  const { email } = props;
  const app: any = useApp();
  const [confirmNumber, setConfirmNumber] = useState('');
  const [error, setError] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [countTime, setCountTime] = useState<Date | null>(null);
  const [timeLimit, setTimeLimit] = useState(0);
  const { userApi } = useApi();
  const history = useHistory();
  const auth = useProviderAuth();
  const numberRef = useRef<null | HTMLInputElement>(null);
  const snackbar = useSnackbarContext();

  useEffect(() => {
    startCount();
    return () => {
      setTimeLimit(0);
      setCountTime(null);
      setDisabled(false);
    };
  }, []);

  useEffect(() => {
    if (disabled) {
      resetCount();
    }
  }, [disabled]);

  useEffect(() => {
    if (countTime) {
      countDown(countTime);
    }
  }, [countTime]);

  useEffect(() => {
    if (timeLimit < 0) {
      snackbar.alert('인증시간이 만료되었습니다.');
      setDisabled(true);
    }
  }, [timeLimit]);

  const startCount = (minute?: number) => {
    if (timeLimit > 0) {
      return;
    }
    const count = addMinutes(new Date(), minute || 3);
    setCountTime(count);
  };

  const resetCount = () => {
    setCountTime(null);
    setTimeLimit(0);
  };

  const countDown = useCallback(
    async (time: Date) => {
      const timeLimit = differenceInMilliseconds(time, new Date());
      if (timeLimit > 0) {
        await delay(1000);
        setTimeLimit(timeLimit - 1000);
        countDown(time);
      }
    },
    [countTime]
  );

  const leftTimeFormat = (time: number) => {
    const mins = Math.floor(time / (1000 * 60));
    const secs = Math.floor(time / 1000);
    return `${String(mins).padStart(2, '0')}:${String(
      secs - mins * 60
    ).padStart(2, '0')}`;
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit();
    }
  };

  const handleChangeConfirmNumber = (e: ChangeEvent<HTMLInputElement>) => {
    setError(false);
    setConfirmNumber(e.target.value);
  };

  const handleSendConfirmNumber = async () => {
    try {
      await userApi.sendCertificationEmail(email);
      snackbar.success(
        '인증번호가 재전송 되었습니다. 이메일로 전송한 인증번호를 입력해주세요.'
      );
      setDisabled(false);
      startCount();
      numberRef.current && numberRef.current.focus();
    } catch (error) {
      console.error(error);
      snackbar.alert('인증번호 재전송을 실패하였습니다.');
    }
  };

  const handleSubmit = async () => {
    if (confirmNumber === '') {
      setError(true);
      numberRef.current && numberRef.current.focus();
      return snackbar.alert('인증번호를 입력해주세요.');
    }
    try {
      await auth.signin(email, confirmNumber);
      if (
        app.$me.passwordChanged === true &&
        app.$me.passwordExpiration === false
      ) {
        history.push('/clinics');
      } else {
        history.push('/change-password', {
          passwordExpired: app.$me.passwordExpiration,
        });
      }
    } catch (error: any) {
      setError(true);
      if (error.name === 'INVALID_OTP_CODE') {
        snackbar.alert('올바른 인증번호가 아닙니다. 다시 확인해주세요.');
      } else if (error.name === 'EXPIRE_OTP_CODE') {
        snackbar.alert('인증 시간이 만료되었습니다.');
      } else if (error.name === 'EXPIRE_TEMP_PASSWORD') {
        snackbar.alert(
          '만료된 임시 비밀번호 입니다. 비밀번호 초기화를 요청하세요.'
        );
      } else {
        snackbar.alert('ERROR_LOGIN_AUTH_FAIL');
      }
      numberRef.current && numberRef.current.focus();
    }
  };

  return (
    <Box className="confirm-certification-number-wrapper">
      <form className={`${disabled ? 'disabled' : ''}`}>
        <Stack className={'form-wrapper'} direction={'column'} gap={'10px'}>
          <Typography variant="body1">
            이메일(아이디)로 전송한 인증번호를 입력해주세요.
          </Typography>
          <TextField
            type="text"
            className={`confirm-input ${error ? 'error' : ''}`}
            placeholder="인증번호를 입력하세요"
            value={confirmNumber}
            onKeyDown={handleKeyPress}
            onChange={handleChangeConfirmNumber}
            disabled={disabled}
            inputRef={numberRef}
            autoFocus
          />
          <Typography variant="body2" className="time-count">
            {leftTimeFormat(timeLimit)}
          </Typography>
          <Button
            variant="contained"
            className="black"
            onClick={handleSubmit}
            disabled={disabled}
          >
            확인
          </Button>
          {disabled && (
            <Button
              variant="text"
              className="resend-btn"
              onClick={handleSendConfirmNumber}
            >
              인증번호 재전송
            </Button>
          )}
        </Stack>
      </form>
    </Box>
  );
};
export default observer(ConfirmCertNumber);
