import { styled as muiStyled } from '@mui/material/styles';
import styled from 'styled-components';
import {
  Box as MuiBox,
  Typography as MuiTypography,
  Button as MuiButton,
  IconButton as MuiIconButton,
  Link as MuiLink,
} from '@mui/material';
import { TextareaAutosize as MuiTextareaAutosize } from '@mui/base';
import { ComboBox as _ComboBox } from '~/components/commons/ComboBox';
import { TextField as _TextField } from '~/components/commons/TextField';
import { Label as _Label } from '~/components/commons/Label2';
import { DropdownList as _DropdownList } from '~/components/commons/DropdownList_v1';
import { theme } from '~/theme';

export const Link = muiStyled(MuiLink)`
  color: #008ddc !important;
`;

export const FileInput = styled.input.attrs({
  type: 'file',
})<{ ref: any }>`
  display: none;
`;

export const IconButton = muiStyled(MuiIconButton)`
  flex-shrink: 0;
  width: 30px;
  height: 30px;
  padding: 0;
  margin-left: 4px;
`;

export const DropdownList = muiStyled(_DropdownList)`
  height: 34px;
  .MuiAutocomplete-inputRoot.MuiInputBase-root.MuiOutlinedInput-root {
    height: 34px;
  }
  fieldset {
    border-color: ${theme.palette.layout.line};
  }
`;

export const TextareaAutosize = muiStyled(MuiTextareaAutosize)`
  width: 100% !important;
  background: #ffffff;
  border: 1px solid ${theme.palette.layout.line};
  &:focus-visible {
    outline: solid 1px ${({ theme }) => theme.palette.primary.unoblue};
  }
`;

export const FormControl = muiStyled(MuiBox)`
  display: flex;
  flex-direction: row;
  padding: 3px;
  row-gap: 2px;
  width: 100%;
  gap: 8px;

  label {
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    margin-bottom: 4px;
    display: inline-block;
    .required {
      color: #eb5757;
    }
  }
`;

export const ComboBox = muiStyled(_ComboBox)`
  height: 34px;
  .MuiOutlinedInput-root {
    height: 34px;
  }
`;

export const Label = muiStyled(_Label)`
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
`;

export const Button = muiStyled(MuiButton)`
  border-radius: 0;
  min-width: 85px;
  font-size: 14px;
  &.btn-default {
    box-shadow: 0 2px 6px rgba(84, 72, 203, 0.2);
    background-color: #fff;
    color: #000;
  }
  &.btn-danger {
    background: #f7685b;
    box-shadow: none;
    &:disabled {
      color: #ffffff;
    }
  }
`;

export const Typography = muiStyled(MuiTypography)(
  ({ theme }) => `
  font-size: 12px;
  line-height: 24px;
  color: ${theme.palette.grey['700']};

  &.text-section {
    font-size: 12px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
  }

  &.caution-text {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;

  }

  &.alert-text {
    color: ${theme.palette.error['main']};
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;

  }
  &.mt-20 {
    margin-top: 20px;
  }
`
);

export const Box = muiStyled(MuiBox)(
  ({ theme }) => `
  &.modal-header {
    padding: 16px 24px 20px;
    border-bottom: 1px solid #d7e3f1;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    display: flex;
    justify-content: space-between;
    color: ${theme.palette.grey['700']};

    &.c-danger {
      font-size: 18px;
      font-weight: 800;
      letter-spacing: -0.08px;
      line-height: 1.4;
    }
    &.p-20 {
      padding: 20px;
    }
  }
  &.modal-body {
    padding: 0 25px;
    &.p-20 {
      padding: 20px;
    }
  }
  &.modal-footer {
    padding: 20px 24px 16px;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    &.p-20 {
      padding: 20px;
    }
  }
  &.label-text {
    display: block;
    margin-top: 20px;
    margin-bottom: 4px;
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    .alert {
      color: ${theme.palette.error['main']};
    }
  }


  &.date {
    padding: 0 1rem;
  }
  &.phone-number {
    padding: 0 1rem;
  }
  &.memo {
    width: 200px;
  }
  &.form-box {
    width: 50%;
    
    &.w-max {
      width: 100%;
    }
  }
  &.date-picker-box {
    .react-datepicker-wrapper {
      width: 100%;
      input {
        padding-left: 1rem;
        border: 1px solid ${theme.palette.layout.line};
      }
    }
  }
  &.p-8 {
    padding: 8px;
  }
  &.cancel-request-link-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;

  }
`
);

export const DisabledButton = muiStyled('button')`
  margin-left: 10px;
  padding: 3px;
  font-weight: 400;
  font-size: 11px;
  border: 1px solid #bbbbbb;
  color: #bbbbbb;
  background-color: #fff;
`;

export const ModalWrapper = muiStyled('div')`
  width: 650px;
  max-height: calc(100vh - 40px);
  overflow: hidden;
`;

export const ModalBody = muiStyled('div')`
  padding: 0 30px;
`;

export const TextField = muiStyled(_TextField)`
  font-size: 14px;
  &.white-bg {
    background-color: #ffffff;
    input {
      background-color: #ffffff;
    }
  }
  .MuiInputBase-root {
    height: 32px;
  }

  input {
    background-color: #f3f8ff;
  }
  fieldset {
    border-color: ${theme.palette.layout.line};
  }
`;
