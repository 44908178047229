import { observer } from 'mobx-react';
import { useApp } from '~/hooks/useApp';
import { useSnackbarContext } from '~/components/providers/SnackbarProvider';
import { FileSampleFormModal } from './FileSampleFormModal';
import { FileSampleRepo } from '~/components/repo/FileSampleRepo';
import styled from 'styled-components';
import { Tooltip } from '~/components/commons/Tooltip';
import { ConfirmModal } from '~/components/modals/ConfirmModal';
import { useImperativeModal } from '~/components/providers/ImperativeModalProvider';

const Buttons = styled.div`
  display: flex;
  gap: 8px;
`;

type FileSampleTableProps = {
  items: any;
  onChange: () => void;
};

export const FileSampleTable = observer(
  ({ items, onChange }: FileSampleTableProps) => {
    const app = useApp();
    const snackbar = useSnackbarContext();
    const repo = new FileSampleRepo();
    const imperativeModal = useImperativeModal();

    const onClickEdit = async (item: any) => {
      await app.$modal.custom({
        component: FileSampleFormModal,
        options: {
          action: 'modify',
          item: item,
        },
      });

      onChange();
    };

    const onClickDelete = async (item: any) => {
      const confirm = await imperativeModal.open((close) => (
        <ConfirmModal
          variant="primary"
          onConfirm={() => close(true)}
          onCancel={() => close(false)}
        >
          삭제하시겠습니까?
        </ConfirmModal>
      ));

      if (confirm) {
        await repo.delete(item.id);
        snackbar.success('삭제했습니다.');

        onChange();
      }
    };

    return items.map((item: any) => (
      <tr key={item.id}>
        <td>{item.id}</td>
        <td>{item.name}</td>
        <td>{item.code}</td>
        <td>{item.price}</td>
        <td>{item.cost}</td>
        <td>
          <Buttons>
            <Tooltip
              title={app.$me.role === 'manager' ? '권한이 없습니다.' : ''}
            >
              <div>
                <button
                  onClick={() => onClickEdit(item)}
                  className="btn btn-info"
                  disabled={app.$me.role === 'manager'}
                >
                  수정
                </button>
              </div>
            </Tooltip>
            <Tooltip
              title={app.$me.role === 'manager' ? '권한이 없습니다.' : ''}
            >
              <div>
                <button
                  onClick={() => onClickDelete(item)}
                  className="btn btn-danger"
                  disabled={app.$me.role === 'manager'}
                >
                  삭제
                </button>
              </div>
            </Tooltip>
          </Buttons>
        </td>
      </tr>
    ));
  }
);
