import App from '~/store/app';

const useCommons = (app: typeof App) => {
  const { role } = app.$me;
  const checkPermission = (levels: string[]) => {
    return [...levels].includes(role?.toLowerCase() || '');
  };
  return { checkPermission };
};

export const paginationOptions = [
  {
    label: '10개씩',
    value: 10,
  },
  {
    label: '20개씩',
    value: 20,
  },
  {
    label: '50개씩',
    value: 50,
  },
  {
    label: '200개씩',
    value: 200,
  },
];

export default useCommons;
