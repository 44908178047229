import { makeAutoObservable } from 'mobx';
import ModalBasic from '~/components/modals/basic/ModalBasic';
import ModalImages from '~/components/modals/basic/ModalImages';
import ModalInput from '~/components/modals/basic/ModalInput';
import ModalSlot from '~/components/modals/basic/ModalSlot';
import ModalLoading from '~/components/modals/basic/ModalLoading';
import ModalConfirm from '~/components/modals/basic/ModalConfirm';

class Modal {
  list: any[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  add = (component: any, options: any, resolve: (value: any) => void) => {
    this.list.push({ component, options, resolve });
  };
  pop = () => {
    this.list.pop();
  };
  close = ({ type }: any) =>
    (this.list = this.list.filter((v) => v.options.type !== type));

  // create
  basic = (options: any) =>
    new Promise((resolve) => this.add(ModalBasic, options, resolve));
  images = (options: any) =>
    new Promise((resolve) => this.add(ModalImages, options, resolve));
  custom = ({ component, options }: any) =>
    new Promise((resolve) => this.add(component, options, resolve));
  input = (options: any) =>
    new Promise((resolve) => this.add(ModalInput, options, resolve));
  slot = (options: any) =>
    new Promise((resolve) => this.add(ModalSlot, options, resolve));
  loading = (customOptions: any) => {
    const options = Object.assign(
      {
        canClose: false,
      },
      customOptions
    );
    return new Promise((resolve) => this.add(ModalLoading, options, resolve));
  };
  confirm = (options: any) =>
    new Promise((resolve) => this.add(ModalConfirm, options, resolve));
}

export default new Modal();
