import { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useApp } from '~/hooks/useApp';
import { ManagementTable } from '~/components/routes/management/ManagementTable';
import { ManagementSearchForm } from '~/components/routes/management/ManagementSearchForm';
import { DataPagination } from '~/components/commons/DataPagination';
import { Box } from '@mui/material';
import { useApi } from '~/components/providers/ApiProvider';
import { Body, FlexRow, Summary } from './ManagementPage.styled';
import { useSnackbarContext } from '~/components/providers/SnackbarProvider';
import { Tooltip } from '~/components/commons/Tooltip';
import PageNav from '~/components/commons/PageNav';

export const ManagementPage = () => {
  const history = useHistory();
  const app = useApp();
  const snackbar = useSnackbarContext();
  const { userApi } = useApi();

  const [paginationParams, setPaginationParams] = useState({
    limit: 10,
    page: 1,
  });
  const [searchValue, setSearchValue] = useState({
    email: '',
    name: '',
    roles: [],
    statuses: [],
  });

  const [data, setData] = useState([]);
  const [total, setTotal] = useState({
    activeCount: 0,
    inactiveCount: 0,
    totalCount: 0,
    deletedCount: 0,
  });
  const [pagination, setPagination] = useState({
    limit: 10,
    pages: 1,
    total: 0,
  });

  const callApi = useCallback(async () => {
    const request = {
      ...paginationParams,
      email: searchValue.email ? searchValue.email : undefined,
      name: searchValue.name ? searchValue.name : undefined,
      roles: searchValue.roles
        ? searchValue.roles.map((v: any) => v?.id).join(',')
        : undefined,
      statuses: searchValue.statuses
        ? searchValue.statuses.map((v: any) => v?.id).join(',')
        : undefined,
    };

    try {
      const resp: any = await userApi.getUsers({ ...request });
      setData(resp.data);
      setTotal(resp.total);
      setPagination(resp.pagination);
    } catch (e: any) {
      if (e.name === 'NO_PERMISSION') {
        snackbar.alert('접근 권한이 없습니다.');
      } else {
        snackbar.alert(e.description);
      }
    }
  }, [paginationParams, searchValue]);

  useEffect(() => {
    callApi();
  }, [paginationParams]);

  const onClickSearch = () => {
    if (searchValue.name !== '' && searchValue.name.length < 3) {
      snackbar.alert('이름을 3자리 이상 입력하세요.');
      return;
    }
    setPaginationParams((params) => ({ ...params, page: 1 }));
  };

  return (
    <div className="main-contents">
      <PageNav pages={['MANAGEMENT', `관리자 관리`]} />
      <Box>
        <ManagementSearchForm
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          onClickSearch={onClickSearch}
        />
      </Box>
      <Body>
        <FlexRow>
          <Tooltip title={app.$me.role !== 'super' ? '권한이 없습니다.' : ''}>
            <div>
              <button
                className="btn-sm btn-black cursor-pointer"
                onClick={() => {
                  history.push('/management/new');
                }}
                disabled={app.$me?.role !== 'super'}
              >
                계정 등록
              </button>
            </div>
          </Tooltip>
          <FlexRow>
            <Summary>
              {`조회결과 [ 총 `}
              <b>{total.totalCount}</b>
              {`ㅣ 사용 `}
              <b>{total.activeCount}</b>
              {`ㅣ 정지 `}
              <b>{total.inactiveCount}</b>
              {` ]`}
            </Summary>
            <div>
              <DataPagination
                value={paginationParams}
                pages={pagination.pages}
                total={pagination.total}
                options={[
                  {
                    label: '10개씩',
                    value: 10,
                  },
                  {
                    label: '15개씩',
                    value: 15,
                  },
                  {
                    label: '20개씩',
                    value: 20,
                  },
                  {
                    label: '50개씩',
                    value: 50,
                  },
                ]}
                onChange={(v) => {
                  setPaginationParams(v);
                }}
              />
            </div>
          </FlexRow>
        </FlexRow>
        <table>
          <thead>
            <tr>
              <th>Id</th>
              <th>관리자 번호</th>
              <th>이름</th>
              <th>보안등급</th>
              <th>사용여부</th>
            </tr>
          </thead>
          <tbody>
            <ManagementTable data={data} />
          </tbody>
        </table>
      </Body>
    </div>
  );
};
