import { ApiClient } from '~/api/apiClient';
import { createContext, ReactNode, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useAuthStorage } from './AuthStorageProvider';
import { useReaction } from '~/hooks/useReaction';

const ApiContext = createContext<ApiClient | null>(null);

export function ApiProvider({ children }: { children: ReactNode }) {
  const client = useMemo(() => {
    return new ApiClient({
      endpoint: process.env.REACT_APP_API_URL || '',
    });
  }, []);

  const { accessToken, refreshToken } = useAuthStorage();

  useReaction(() => {
    client.setTokens(accessToken, refreshToken);

    return () => {
      client.setTokens(null, null);
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('user');
    };
  }, [client, accessToken, refreshToken]);

  return <ApiContext.Provider value={client}>{children}</ApiContext.Provider>;
}

ApiProvider.propTypes = {
  children: PropTypes.node,
};

export function useApi() {
  return useContext(ApiContext) as ApiClient;
}
