import styled from 'styled-components';
import { Snackbar } from '@mui/material';
import { IconButton as _IconButton } from '~/components/commons/IconButton';
import { color as colorTheme } from '~/theme';
import { ReactNode } from 'react';
import Icon from '../Icon';

const ColorType = {
  getColor(type?: 'unoblue' | 'alert') {
    switch (type) {
      case 'unoblue':
        return colorTheme.primary.unoblue;
      case 'alert':
      default:
        return colorTheme.alert;
    }
  },
};

const IconButton = styled(_IconButton)(
  () => `
  padding: 4px;
`
);

const SnackbarComponent = styled(Snackbar)`
  min-width: 430px;
  background-color: #ffffff;
  border: 1px solid ${({ color }: any) => ColorType.getColor(color)};
  border-radius: 4px;

  & .MuiSnackbarContent-root {
    min-width: 430px;
    background-color: #ffffff;
  }

  div {
    font-size: 12px;
    color: ${({ color }: any) => ColorType.getColor(color)};
    box-shadow: none;
  }

  .MuiSnackbarContent-message {
    color: ${({ color }: any) => ColorType.getColor(color)};
  }
`;

const Mark = styled('div')`
  width: 15px;
  height: 15px;
  border: 1px solid ${({ color }: any) => ColorType.getColor(color)};
  text-align: center;
  border-radius: 16px;
  color: ${({ color }: any) => ColorType.getColor(color)};
  font-size: 11px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
`;

const LeftSection = styled.div`
  display: flex;
  align-items: center;
`;

const Message = styled.div`
  margin-left: 5px;
`;

const RightSection = styled.div`
  display: flex;
  gap: 8px;
`;

type SnackBarProps = {
  show: boolean;
  color: 'unoblue' | 'alert';
  message: string;
  onClosed: () => void;
  actionItems: ReactNode;
  leadingItems: ReactNode;
};

export const SnackBar = ({
  show,
  color = 'unoblue',
  message,
  onClosed,
  actionItems,
  leadingItems,
}: SnackBarProps) => {
  const action = (
    <RightSection>
      {actionItems && actionItems}
      <IconButton variant="transparent" onClick={onClosed}>
        <Icon variant="close" width={10} height={10} />
      </IconButton>
    </RightSection>
  );

  return show ? (
    <SnackbarComponent
      color={color}
      open={show}
      autoHideDuration={5000}
      onClose={onClosed}
      message={
        <LeftSection>
          {leadingItems ? (
            leadingItems
          ) : (
            <>
              {color === 'unoblue' && <Icon variant="check_mark" />}
              {color === 'alert' && <Mark color="alert">!</Mark>}
            </>
          )}
          <Message className="message">{message}</Message>
        </LeftSection>
      }
      action={action}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    />
  ) : (
    <></>
  );
};
