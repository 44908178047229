import { useState } from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import { observer } from 'mobx-react';
import { DrugRepo } from '~/components/repo/DrugRepo';
import { format } from 'date-fns';
import { useSnackbarContext } from '~/components/providers/SnackbarProvider';

const Head = styled.div`
  position: relative;
  border-bottom: 0;
  font-size: 18px;
  line-height: 24px;
  padding-bottom: 20px;
  &:after {
    content: '';
    display: block;
    position: absolute;
    left: -30px;
    bottom: 0;
    width: calc(100% + 60px);
    height: 1px;
    background-color: #d7e3f1;
  }
  h1 {
    max-width: calc(100% - 40px);
    font-size: 18px;
    font-weight: 700;
    letter-spacing: -0.08px;
    line-height: 1.4;
    color: #273142;
    word-break: keep-all;
    margin: 0;
  }
`;

const Body = styled.div`
  margin-top: 16px;
`;

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 8px;

  &.date-wrapper {
    input[type='text'] {
      padding-left: 40px;
    }
  }
`;

const Label = styled.label`
  margin-bottom: 0 !important;
`;

type CreateDrugModalProps = {
  options: any;
  close: (button?: any) => void;
};

export const CreateDrugModal = observer(
  ({ options, close }: CreateDrugModalProps) => {
    const snackbar = useSnackbarContext();
    const repo = new DrugRepo();
    const { action, callApi, item } = options;
    const [id, setId] = useState(item?.id ?? '');
    const [name, setName] = useState(item?.name ?? '');
    const [dosage, setDosage] = useState(item?.dosage ?? '');
    const [unit, setUnit] = useState(item?.unit ?? '');
    const [type, setType] = useState(item?.type ?? '');
    const [paidType, setPaidType] = useState(item?.paidType ?? '');
    const [company, setCompany] = useState(item?.company ?? '');
    const [expense, setExpense] = useState(item?.expense ?? null);
    const [startDate, setStartDate] = useState<any>(
      item?.startDate ? new Date(item?.startDate) : null
    );

    const validator = () => {
      if (!(name && id && dosage && unit && type && paidType && company)) {
        snackbar.alert('필수 값을 모두 입력해주세요.');
        return false;
      }

      return true;
    };

    const getFormData = () => {
      return {
        id,
        name,
        dosage,
        unit,
        type,
        paidType,
        company,
        expense: expense ?? 0,
        startDate: startDate
          ? `${format(new Date(startDate), `yyyy-MM-dd`)} 00:00:00`
          : null,
      };
    };

    const create = async () => {
      if (!validator()) return;
      try {
        await repo.create(getFormData());
        snackbar.success('생성했습니다.');
        close();
        callApi();
      } catch (e: any) {
        if (e.name === 'REQUEST_DUPLICATED') {
          snackbar.alert('이미 등록된 약품코드(ID)가 있습니다.');
          return;
        }
        snackbar.alert('실패했습니다.');
      }
    };

    const update = async () => {
      if (!validator()) return;
      try {
        await repo.update(item.id, getFormData());
        snackbar.success('수정했습니다.');
        close();
        callApi();
      } catch (e) {
        snackbar.alert('실패했습니다.');
      }
    };

    const onClickSave = async () => {
      if (action === 'create') create();
      else update();
    };

    return (
      <div>
        <Head>
          <h1>{action === 'create' ? '등록' : '수정'}</h1>
          <i onClick={() => close()} className="zmdi zmdi-close" />
        </Head>
        <Body>
          <LabelWrapper className="form-control label-required">
            <Label>약품코드(ID)</Label>
            <input
              value={id}
              onChange={(e) => setId(e.target.value)}
              disabled={action !== 'create'}
            />
          </LabelWrapper>
          <LabelWrapper className="form-control label-required">
            <Label>약품명</Label>
            <input value={name} onChange={(e) => setName(e.target.value)} />
          </LabelWrapper>
          <LabelWrapper className="form-control label-required">
            <Label>제약사</Label>
            <input
              value={company}
              onChange={(e) => setCompany(e.target.value)}
            />
          </LabelWrapper>
          <LabelWrapper className="form-control label-required">
            <Label>투여구분</Label>
            <select
              value={type}
              onChange={(e) => setType(e.target.value)}
              style={{ color: !type ? '' : '#ADACB0' }}
            >
              <option value="" disabled>
                투여구분
              </option>
              <option value="내복">내복</option>
              <option value="외용">외용</option>
              <option value="주사">주사</option>
              <option value="기타">기타</option>
            </select>
          </LabelWrapper>
          <LabelWrapper className="form-control label-required">
            <Label>규격</Label>
            <input value={dosage} onChange={(e) => setDosage(e.target.value)} />
          </LabelWrapper>
          <LabelWrapper className="form-control label-required">
            <Label>단위</Label>
            <input value={unit} onChange={(e) => setUnit(e.target.value)} />
          </LabelWrapper>
          <LabelWrapper className="form-control label-required">
            <Label>급여기준</Label>
            <select
              value={paidType}
              onChange={(e) => setPaidType(e.target.value)}
              style={{ color: !paidType ? '' : '#ADACB0' }}
            >
              <option value="" disabled>
                급여기준
              </option>
              <option value="급여">급여</option>
              <option value="비급여">비급여</option>
              <option value="보훈급여">보훈급여</option>
              <option value="급여정지">급여정지</option>
              <option value="산정불가">산정불가</option>
              <option value="삭제">삭제</option>
            </select>
          </LabelWrapper>
          <LabelWrapper className="form-control date-wrapper">
            <Label>적용시작일자</Label>
            <DatePicker
              todayButton="오늘"
              dateFormat="yyyy년 MM월 dd일"
              placeholderText="YYYY년 MM월 DD일"
              selected={startDate}
              onChange={setStartDate}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          </LabelWrapper>
          <LabelWrapper className="form-control">
            <Label>상한가(약품비)</Label>
            <input
              type="number"
              value={expense}
              onChange={(e) => setExpense(Number(e.target.value))}
              placeholder="0"
            />
          </LabelWrapper>
          <div className="buttons">
            <button onClick={onClickSave} className="btn btn-primary">
              {action === 'create' ? '생성' : '저장'}
            </button>
          </div>
        </Body>
      </div>
    );
  }
);
