import { Pagination } from './Pagination';
import { PaginationLimitSelectContainer } from './PaginationStyle';
import { PaginationLimitSelect } from './PaginationLimitSelect';
import { Typography, Box } from '~/components/commons/DataPagination.styled';

interface PaginationData {
  page: number;
  limit: number;
}

type DataPaginationProps = {
  value: PaginationData;
  pages: number;
  total: number;
  onChange: (v: PaginationData) => void;
  disabled?: boolean;
  options?: any[];
  hideTotal?: boolean;
  hideLimit?: boolean;
};

export const DataPagination = ({
  value,
  pages,
  total,
  onChange,
  disabled,
  options,
  hideTotal = true,
  hideLimit = false,
  ...props
}: DataPaginationProps) => {
  const onChangePage = (v: number) => {
    onChange({
      ...value,
      page: v,
    });
  };

  const onChangeLimit = (v: number) => {
    onChange({
      ...value,
      limit: v,
      page: 1,
    });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
        alignItems: 'center',
      }}
      {...props}
    >
      {!hideTotal && <Typography>총 {total} 건</Typography>}
      <Pagination
        styleType="chart"
        page={value.page}
        disabled={disabled}
        total={pages}
        siblingCount={1}
        onChange={onChangePage}
      />
      {!hideLimit && (
        <PaginationLimitSelectContainer>
          <PaginationLimitSelect
            value={value.limit}
            options={options}
            onChange={onChangeLimit}
          />
        </PaginationLimitSelectContainer>
      )}
    </Box>
  );
};
