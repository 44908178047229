export function maskName(name: string) {
  if (name === undefined || name === null) {
    return '';
  }

  if (name.length < 2) {
    return name ?? '';
  } else if (name.length === 2) {
    return name.charAt(0) + '*';
  } else if (name.length === 3) {
    return name.charAt(0) + '*' + name.charAt(2);
  } else if (name.length === 4) {
    return name.charAt(0) + '**' + name.charAt(3);
  } else {
    return name.substring(0, 3) + '*'.repeat(name.length - 3);
  }
}

export function maskBirthday(birthday: string) {
  if (birthday === '' || !birthday) return;

  const birthYear = birthday.slice(0, 2) + '**';
  const maskedBirthdate = birthYear + birthday.slice(4);
  return maskedBirthdate;
}

export function maskEmail(email: string) {
  if (email === undefined || email === null) {
    return '';
  }

  let leftPart = email.split('@')[0];
  const rightPart = email.split('@')[1];

  if (leftPart.length >= 3) {
    leftPart = leftPart.substring(0, 2) + '*'.repeat(leftPart.length - 2);
  } else if (leftPart.length >= 1 && leftPart.length <= 2) {
    leftPart = '*'.repeat(leftPart.length);
  } else {
    leftPart = '';
  }

  return leftPart + '@' + rightPart;
}

export function maskPhoneNumber(phoneNumber: string) {
  if (phoneNumber === undefined || phoneNumber === null) {
    return '';
  }

  const numberOnly = phoneNumber.replace(/\D/g, '');
  if (numberOnly.length === 11) {
    return (
      numberOnly.substring(0, 3) + '-' + '****' + '-' + numberOnly.substring(7)
    );
  }

  let maskingPhoneNumber = '';
  for (let i = 0; i < numberOnly.length; i++) {
    if (i === 3 || i === 4 || i === 5 || i === 6) {
      maskingPhoneNumber += '*';
    } else {
      maskingPhoneNumber += numberOnly[i];
    }
  }
  return maskingPhoneNumber;
}

export function maskAddress(address: string) {
  if (address === undefined || address === null) {
    return '';
  }

  let isMaskingOrder = false;
  const splitSet = ['시', '군', '구'];
  const splitAddr = address.split(' ').filter((addr) => addr.trim() !== ''); // 빈 문자열 제거

  let result = '';
  for (const addr of splitAddr) {
    const lastChar = addr[addr.length - 1];
    if (splitSet.includes(lastChar) && !isMaskingOrder) {
      isMaskingOrder = true;
      result += addr + ' ';
    } else {
      if (!isMaskingOrder) {
        result += addr + ' ';
      } else {
        result += '*'.repeat(addr.length) + ' ';
      }
    }
  }

  return result.trim();
}

export function mastText(text: string) {
  if (text === undefined || text === null) {
    return '';
  }

  return text.replace(/[^ ]/g, '*');
}
