import styled, { css } from 'styled-components';
import { InnerBoxStyle } from './Label';
import ArrowDownLineSmallIcon from '~/assets/images/common/ic-arrow-down-line-small-16.svg';
import { ComponentType } from 'react';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  justify-content: center;
  height: 32px;
  margin-bottom: 10px;
`;

export const DatePickerWrapper = styled.div`
  display: flex;
  align-items: center;

  .react-datepicker-wrapper {
    width: 100%;
    height: 32px;

    input {
      background-color: unset;
    }
  }

  input {
    ${({ $focus, theme }: any) =>
      $focus &&
      css`
        color: ${theme.color.primary.unoblue};
      `}

    background-image: url(${ArrowDownLineSmallIcon}) !important;
    background-size: 16px !important;

    ${InnerBoxStyle}
    height: 27px;
    &::placeholder {
      color: #a1b1ca !important;
    }
    &:disabled {
      background-color: #f3f8ff;
      color: #4a4e70;
    }
    &:focus {
      outline: none;
    }
    border: none !important;

    width: 100px;
    cursor: pointer;

    ${({ size }: any) =>
      size === 'm' &&
      css`
        background-size: 18px !important;
        font-size: 14px;
        font-weight: bold;
        width: 130px;
      `}
  }
` as ComponentType<any>;

export const DateText = styled.div`
  font-size: 15px;
  font-weight: 700;
  line-hieght: 22px;
`;
