import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useModal } from '~/hooks/useModal';

type ModalProps = {
  modal: any;
};

const Modal = ({ modal }: ModalProps) => {
  const modalStore = useModal();

  useEffect(() => {
    document.addEventListener('keydown', onKeydown);

    return () => {
      document.removeEventListener('keydown', onKeydown);
    };
  });

  const onKeydown = (e: any) => {
    //Modal이 이중 이상으로 열린경우, 가장 마지막에 열린 모달의 프롭스를 꺼내도록 함
    const data =
      modalStore.list.length > 1
        ? modalStore.list[modalStore.list.length - 1]
        : modal;

    if (e.key === 'Escape') {
      e.stopPropagation();
      e.preventDefault();

      if (data.options?.canClose === false) {
        return;
      }

      onClose();
    }
  };

  const onClose = () => {
    if (modal.resolve) modal.resolve();
    modal.options?.onClose?.();
    modalStore.pop();
  };

  const onClickOverlay = (event: any) => {
    if (modal.options?.canClose === false) {
      return;
    }

    if (modal.options !== undefined) {
      if (modal.options.type === 'CREATE_FILE') return; // 펜차트 이미지 업로드
      if (modal.options.type == 'SUCCESS' || modal.options.type == 'ERROR')
        return; // API 호출 후 응답 확인창
    }
    if (event.target.classList.contains('modal-overlay')) {
      onClose();
      return;
    }
  };

  return (
    <div
      className={`modal modal-overlay ${
        modal.options?.type === 'LOADING' ? 'top' : ''
      }`}
      onMouseDown={onClickOverlay}
    >
      <div className="modal-container">
        <modal.component options={modal.options} close={() => onClose()} />
      </div>
    </div>
  );
};

export default observer(Modal);
