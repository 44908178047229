import {
  useState,
  useRef,
  useMemo,
  useEffect,
  ChangeEvent,
  KeyboardEvent,
} from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { useSnackbarContext } from '~/components/providers/SnackbarProvider';
import { useApi } from '~/components/providers/ApiProvider';
import { useLocation } from 'react-router-dom';
import {
  TextField,
  Typography,
  Box,
  ButtonWrapper,
  Button,
  Stack,
} from './ChangePasswordPage.styled';
import { useApp } from '~/hooks/useApp';
import { useAuth } from '~/hooks/useAuth';
import { validatePassword } from '~/utils/validationUtils';

const ChangePasswordPage = () => {
  const app = useApp();
  const auth = useAuth();
  const snackbar = useSnackbarContext();
  const history = useHistory();
  const { userApi } = useApi();
  const newPasswordRef = useRef<null | HTMLInputElement>(null);
  const confirmPasswordRef = useRef<null | HTMLInputElement>(null);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState<false | 'newPassword' | 'confirmPassword'>(
    false
  );
  const { state }: { state: { passwordExpired?: boolean } } = useLocation();
  const passwordExpired = useMemo(() => {
    return state?.passwordExpired ?? false;
  }, [state]);

  useEffect(() => {
    if (
      app.$me.passwordExpiration === false &&
      app.$me.passwordChanged === true
    ) {
      history.replace('/clinics');
    }
  }, [app.$me]);

  const validate = () => {
    const errorText = validatePassword(newPassword);
    if (errorText) {
      newPasswordRef.current && newPasswordRef.current.focus();
      setError('newPassword');
      throw new Error(errorText);
    }

    if (newPassword !== confirmPassword) {
      confirmPasswordRef.current && confirmPasswordRef.current.focus();
      setError('confirmPassword');
      throw new Error('새 비밀번호와 비밀번호 확인이 일치하지 않습니다.');
    }
  };

  const handleChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setError(false);
    setNewPassword(e.target.value);
  };

  const handleChangeConfirmPassword = (e: ChangeEvent<HTMLInputElement>) => {
    setError(false);
    setConfirmPassword(e.target.value);
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    try {
      validate();
    } catch (e: any) {
      snackbar.alert(e.message);
      return;
    }

    try {
      await userApi.changePassword(newPassword);
      auth.loadMe({
        ...app.$me,
        passwordExpiration: false,
        passwordChanged: true,
      });
      history.replace('/clinics');
    } catch (e: any) {
      if (e.code === 403 && e.name === 'INVALID_PASSWORD') {
        return snackbar.alert('올바르지 않은 비밀번호 형식입니다.');
      } else if (e.code === 403 && e.name === 'SAME_PASSWORD') {
        return snackbar.alert(
          '현재 사용 중인 비밀번호와 동일합니다. 새로운 비밀번호를 입력해 주세요. '
        );
      }
      snackbar.alert(e.name);
    }
  };

  return (
    <Box className="new-password-page-container">
      <Box className="new-password-contents-wrapper">
        <form autoComplete="off">
          <Stack className={'form-wrapper'} direction={'column'} gap={'10px'}>
            <Typography variant="subtitle1">
              {passwordExpired
                ? '비밀번호를 변경한 지 90일이 지났습니다. 새로운 비밀번호로 변경해 주세요.'
                : '임시 비밀번호를 변경해야 계속할 수 있습니다.'}
            </Typography>
            <Typography variant="body1" className="new-password-label">
              새 비밀번호
            </Typography>
            <TextField
              type="password"
              name="new-password"
              className={`password-input ${
                error === 'newPassword' ? 'error' : ''
              }`}
              placeholder="8~20자 영문자, 숫자, 특수문자"
              value={newPassword}
              onChange={handleChangePassword}
              inputRef={newPasswordRef}
              autoFocus
              inputProps={{
                autoComplete: 'new-password',
              }}
            />
            <Typography variant="body1">비밀번호 확인</Typography>
            <TextField
              type="password"
              name="new-password-confirm"
              className={`confirm-input ${
                error === 'confirmPassword' ? 'error' : ''
              }`}
              onKeyDown={handleKeyPress}
              placeholder="새 비밀번호 확인"
              value={confirmPassword}
              onChange={handleChangeConfirmPassword}
              inputRef={confirmPasswordRef}
            />
            <ButtonWrapper>
              <Button variant="contained" onClick={handleSubmit}>
                변경
              </Button>
            </ButtonWrapper>
          </Stack>
        </form>
      </Box>
    </Box>
  );
};

export default observer(ChangePasswordPage);
