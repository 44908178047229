import { Box } from '@mui/material';
import { PopupRepo } from '~/components/repo/PopupRepo';
import { PageTitle } from '~/components/page/PageTitle';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbarContext } from '~/components/providers/SnackbarProvider';
import { observer } from 'mobx-react';
import { Page } from '~/components/page/Page';
import { Button } from '~/components/button/Button';
import { format } from 'date-fns';
import { colors, PopupForm } from './PopupForm';
import { ImageRepo } from '~/components/repo/ImageRepo';
import { validateHttpsUrl } from '~/validateUtil';

const combineDateAndTime = (date: Date, time: Date) => {
  return `${format(date, 'yyyy-MM-dd')} ${time.toString().padStart(2, '0')}:00`;
};

type PopupCategoryType = 'header' | 'image' | 'logo' | 'home_banner';
type PopupType = {
  title: string;
  startDate: null | Date;
  startHour: null | Date;
  endDate: null | Date;
  endHour: null | Date;
  type: PopupCategoryType;
  contents: string;
  color: string;
  imageFile: null | File;
  linkUrl: string;
  bannerOption: 'default' | 'refresh';
};

export const NewPopupPage = observer(() => {
  const snackbar = useSnackbarContext();
  const repo = new PopupRepo();
  const imageRepo = new ImageRepo();
  const history = useHistory();

  const [value, setValue] = useState<PopupType>({
    title: '',
    startDate: null,
    startHour: null,
    endDate: null,
    endHour: null,
    type: 'header',
    contents: '',
    color: colors[0],
    imageFile: null,
    linkUrl: '',
    bannerOption: 'default',
  });

  const validate = () => {
    if (!value.title) {
      throw new Error('제목은 필수값입니다.');
    }
    if (
      value.startDate == null ||
      value.startHour == null ||
      value.endDate == null ||
      value.endHour == null
    ) {
      throw new Error('시작 및 종료 시간은 필수값입니다.');
    }

    const start = new Date(
      combineDateAndTime(value.startDate, value.startHour)
    );
    const end = new Date(combineDateAndTime(value.endDate, value.endHour));
    if (start >= end) {
      throw new Error('종료 시간이 시작 시간보다 빠릅니다');
    }

    if (value.type === 'header' && !value.contents) {
      throw new Error('내용은 필수값입니다.');
    }

    if (
      (value.type === 'image' ||
        value.type === 'logo' ||
        value.type === 'home_banner') &&
      !value.imageFile
    ) {
      throw new Error('이미지는 필수값입니다.');
    }

    if (value.linkUrl && !validateHttpsUrl(value.linkUrl)) {
      throw new Error('연결 링크 형식이 올바르지 않습니다.');
    }
  };

  const onClickCreate = async () => {
    try {
      validate();
      const type =
        value.type === 'header' && value.bannerOption === 'refresh'
          ? 'header_refresh'
          : value.type;
      const params: any = {
        startAt: combineDateAndTime(value.startDate!, value.startHour!),
        endAt: combineDateAndTime(value.endDate!, value.endHour!),
        linkUrl: value.linkUrl,
        title: value.title,
        type: type,
      };

      if (type === 'image' || type === 'logo' || type === 'home_banner') {
        if (value.imageFile) {
          params.imageId = await imageRepo.upload(value.imageFile);
        }
        params.backgroundColor = null;
        params.contents = null;
      } else {
        params.imageId = null;
        params.backgroundColor = value.color;
        params.contents = value.contents;
      }
      await repo.create(params);
      snackbar.success('등록완료되었습니다.');
      history.push('/popups');
    } catch (e: any) {
      snackbar.alert(e.message);
    }
  };

  return (
    <Page sx={{ width: 600 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: 1,
        }}
      >
        <PageTitle>팝업 등록</PageTitle>
        <Button color="primary" onClick={onClickCreate}>
          등록
        </Button>
      </Box>
      <PopupForm value={value} onChange={setValue} />
    </Page>
  );
});
