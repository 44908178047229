import { useState } from 'react';
import { observer } from 'mobx-react';
import { useApp } from '~/hooks/useApp';
import { useSnackbarContext } from '~/components/providers/SnackbarProvider';
import { TextField } from '~/components/fields/TextField';
import { useApi } from '~/components/providers/ApiProvider';
import { Body, Field } from './ResetPasswordModal.styled';

type ResetPasswordModalProps = {
  options: any;
  close: () => void;
};

const ResetPasswordModal = ({ options, close }: ResetPasswordModalProps) => {
  const app = useApp();
  const snackbar = useSnackbarContext();
  const { clinicsApi } = useApi();
  const [email, setEmail] = useState<any>(null);

  const validate = () => {
    const checkEmailFormat = /^\S+@\S+\.\S+$/.test(email);
    if (!checkEmailFormat) {
      throw new Error('올바르지 않은 이메일 형식입니다.');
    }
  };

  const onClickSave = async () => {
    try {
      validate();
    } catch (e: any) {
      snackbar.alert(e.message);
      return;
    }

    try {
      await clinicsApi.clinicUserResetPasswrod(options.id, email);
      snackbar.success('임시비밀번호 메일이 발송되었습니다.');
      close();
    } catch (e: any) {
      if (e.name === 'NO_PERMISSION') {
        snackbar.alert('접근 권한이 없습니다.');
      } else {
        snackbar.alert(e.name);
      }
    }
  };

  return (
    <div>
      <div className="head flex-row flex-between items-start">
        <div className="title">비밀번호 초기화</div>
        <i onClick={() => close()} className="zmdi zmdi-close" />
      </div>
      <Body className="body">
        <Field>
          <span>임시 비밀번호가 발송되는 이메일을 입력해주세요.</span>
          <TextField
            value={email}
            placeholder="이메일을 입력해주세요."
            onChange={(e: any) => {
              setEmail(e.target.value);
            }}
          />
        </Field>
      </Body>

      <div className="buttons">
        <div className="flex-row">
          <button onClick={() => close()} className="btn btn-default">
            {app.$translate('CANCEL')}
          </button>
          <button onClick={() => onClickSave()} className="btn btn-primary">
            전송
          </button>
        </div>
      </div>
    </div>
  );
};

export default observer(ResetPasswordModal);
