import { types } from 'mobx-state-tree';

const Certification = types.model({
  id: types.optional(types.maybeNull(types.number)),
  createdAt: types.optional(types.maybeNull(types.string)),
  updatedAt: types.optional(types.maybeNull(types.string)),
  derFilename: types.optional(types.maybeNull(types.string)),
  keyFilename: types.optional(types.maybeNull(types.string)),
  subjectDn: types.optional(types.maybeNull(types.string)),
  endDate: types.optional(types.maybeNull(types.string)),
  status: types.optional(types.maybeNull(types.string)),
  deactivatedAt: types.optional(types.maybeNull(types.string)),
  reactivatedAt: types.optional(types.maybeNull(types.string)),
});

const UserData = types.model({
  id: types.optional(types.maybeNull(types.number)),
  email: types.optional(types.maybeNull(types.string)),
  status: types.optional(types.maybeNull(types.string)),
  name: types.optional(types.maybeNull(types.string)),
  duty: types.optional(types.maybeNull(types.string)),
  position: types.optional(types.maybeNull(types.string)),
  certification: types.optional(types.maybeNull(Certification)),
});

const Data = types.model({
  userList: types.optional(types.array(UserData), []),
  total: types.optional(types.number, 0),
});

export const ClinicSubAccountModel = types
  .model('clinicSubAccountModel', {
    userData: types.optional(Data, {}),
  })
  .views((self) => ({
    get userList() {
      return self.userData.userList;
    },
    get total() {
      return self.userData.total;
    },
  }))
  .actions((self) => ({
    setData(data) {
      if (!data) return;

      self.userData.userList = data.data;
      self.userData.total = data.total;
    },
  }));
