import { observer } from 'mobx-react';
import { useApp } from '~/hooks/useApp';
import { CreateDrugModal } from './CreateDrugModal';
import { format } from 'date-fns';
import { Tooltip } from '~/components/commons/Tooltip';

const getDrugType = (type?: string) => {
  switch (type) {
    case 'internal_medicine':
      return '내복';
    case 'external_use':
      return '외용';
    case 'injection':
      return '주사';
    case 'etc':
    case 'common':
      return '기타';
    default:
      break;
  }
};

const getPaidType = (type?: string) => {
  switch (type) {
    case 'paid':
      return '급여';
    case 'paid_welfare':
      return '보훈급여';
    case 'paid_stop':
      return '급여정지';
    case 'deleted':
      return '삭제';
    case 'impossible':
      return '산정불가';
    case 'unpaid':
      return '비급여';
    case 'etc':
    case 'common':
      return '기타';
    default:
      break;
  }
};

type DrugTableProps = {
  items: any;
  onChange: () => void;
};

export const DrugTable = observer(({ items, onChange }: DrugTableProps) => {
  const app = useApp();

  const onClickDelete = async (item: any) => {
    await app.$modal.custom({
      component: CreateDrugModal,
      options: {
        item: {
          ...item,
          type: getDrugType(item.type),
          paidType: getPaidType(item.paidType),
          fileType: getPaidType(item.fileType),
        },
        action: 'update',
        callApi: onChange,
      },
    });
  };

  return (
    <table>
      <thead>
        <tr>
          <th></th>
          <th>등록일</th>
          <th>약품코드(ID)</th>
          <th>약품명</th>
          <th>제약사</th>
          <th>투여구분</th>
          <th>규격</th>
          <th>단위</th>
          <th>급여기준</th>
        </tr>
      </thead>
      <tbody>
        {items.map((item: any) => (
          <tr key={item.id}>
            <td>
              <Tooltip
                title={app.$me.role === 'manager' ? '권한이 없습니다.' : ''}
              >
                <div>
                  <button
                    onClick={() => onClickDelete(item)}
                    className="btn btn-sm btn-defult"
                    disabled={app.$me.role === 'manager'}
                  >
                    수정
                  </button>
                </div>
              </Tooltip>
            </td>
            <td>{format(new Date(item.createdAt), 'yyyy-MM-dd')}</td>
            <td>{item.id}</td>
            <td>{item.name}</td>
            <td>{item.company}</td>
            <td>{getDrugType(item.type)}</td>
            <td>{item.dosage}</td>
            <td>{item.unit}</td>
            <td>{getPaidType(item.paidType)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
});
