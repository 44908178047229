import { Select2 } from './Select2';

type PaginationLimitSelectProps = {
  value: any;
  onChange: (value: any) => void;
  fieldSize?: 'default' | 'small' | 'xsmall';
  options?: any[];
};

export const PaginationLimitSelect = ({
  value,
  onChange,
  fieldSize,
  options,
}: PaginationLimitSelectProps) => {
  const defaultOptions = [
    {
      label: '10개씩',
      value: 10,
    },
    // {
    //   label: '15개씩',
    //   value: 15,
    // },
    // {
    //   label: '20개씩',
    //   value: 20,
    // },
    // {
    //   label: '50개씩',
    //   value: 50,
    // },
  ];
  return (
    <Select2
      fieldSize={fieldSize ?? 'small'}
      value={value}
      options={options ?? defaultOptions}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};
