import { useCallback, forwardRef, ComponentType } from 'react';
import { observer } from 'mobx-react';
import { format, getMonth, getYear } from 'date-fns';
import _DatePicker from 'react-datepicker';
import Icon from './Icon';
import { ToolbarButton as _ToolbarButton } from '~/components/commons/ToolbarButton';
import _NativeSelect from '~/components/commons/NativeSelect2';
import {
  DatePickerWrapper,
  Wrapper,
  DateText,
} from './SearchFilterDateInput.styled';

const DatePicker = _DatePicker as ComponentType<any>;
const NativeSelect = _NativeSelect as ComponentType<any>;
const ToolbarButton = _ToolbarButton as ComponentType<any>;

function range(start: number, end: number) {
  return Array(end - start + 1)
    .fill('')
    .map((_, idx) => start + idx);
}
const years = range(1900, 2100);
const months = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];

type FilterDateInputProps = {
  className: string;
  placeholder: string;
  dateFormat: string;
  value: any;
  maxDate: string | Date;
  minDate: string | Date;
  onFocus: (params?: any) => void;
  onBlur: (params?: any) => void;
  onChange: (params1?: any, params2?: any) => void;
  onSelect: (params1?: any, params2?: any) => void;
  disabled: boolean;
  width: string;
  style: any;
  size: 'm' | 's';
  focus: boolean;
};

const FilterDateInput = forwardRef(
  (
    {
      className,
      placeholder,
      dateFormat = 'yyyy년 MM월 dd일',
      value,
      onChange,
      onSelect,
      disabled,
      maxDate,
      minDate,
      width,
      size = 's',
      onFocus,
      onBlur,
      focus,
      ...props
    }: FilterDateInputProps,
    ref
  ) => {
    const getDayName = (date: Date) => {
      return date.toLocaleDateString('ko-KR', { weekday: 'long' }).substr(0, 1);
    };

    const getDayClassName = useCallback((date) => {
      return getDayName(date) === '토'
        ? 'saturday'
        : getDayName(date) === '일'
        ? 'sunday'
        : undefined;
    }, []);

    const onChangeDate = (v: any, e: any) => {
      // 년도 자릿수로 정상데이터 체크
      // 정상값 일 경우에만 onChange
      if (!v || String(v.getFullYear()).length !== 4) return;
      onChange && onChange(v, e);
    };

    const onSelectDate = (v: any, e: any) => {
      onSelect(v, e);
    };

    return (
      <DatePickerWrapper
        // 스타일이 통일되지 않은 곳이 많아서 통일되기 전까지 사용
        style={{
          width: width ? width : 'auto',
          ...props?.style,
        }}
        size={size}
        onFocus={onFocus}
        onBlur={onBlur}
        focus={focus}
      >
        <DatePicker
          ref={ref}
          showYearDropdown
          showMonthDropdown
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }: any) => (
            <div>
              <Wrapper>
                <ToolbarButton
                  data-testid="previous-month"
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                >
                  <Icon variant="chevron_left" width={12} height={12} />
                </ToolbarButton>

                <DateText>{format(new Date(date), 'yyyy.MM.dd (E)')}</DateText>
                <ToolbarButton
                  data-testid="next-month"
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                >
                  <Icon variant="chevron_right" width={12} height={12} />
                </ToolbarButton>
              </Wrapper>
              <Wrapper>
                <div>
                  <NativeSelect
                    value={getYear(new Date(date))}
                    onChange={(v: any) => changeYear(Number(v))}
                    optionLabel="label"
                    optionValue="value"
                    options={years.map((v) => ({ label: `${v}년`, value: v }))}
                  />
                </div>
                <div>
                  <NativeSelect
                    value={months[getMonth(new Date(date))]}
                    onChange={(v: any) => changeMonth(months.indexOf(v))}
                    optionLabel="label"
                    optionValue="value"
                    options={months.map((v) => ({ label: `${v}월`, value: v }))}
                  />
                </div>
              </Wrapper>
            </div>
          )}
          dropdownMode="select"
          className={className}
          placeholderText={placeholder ?? 'YYYY-MM-DD'}
          dateFormat={dateFormat}
          selected={value ? new Date(value) : null}
          onChange={onChangeDate}
          onSelect={onSelectDate}
          dayClassName={getDayClassName}
          popperProps={{
            positionFixed: true,
          }}
          disabled={disabled}
          maxDate={maxDate && new Date(maxDate)}
          minDate={minDate && new Date(minDate)}
          {...props}
        />
      </DatePickerWrapper>
    );
  }
);

export default observer(FilterDateInput);
