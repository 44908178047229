import { useMemo } from 'react';
import styled from 'styled-components';
import { DataTable } from '../DataTable.styled';
import { currency } from '~/utils/common';

const translateNumbering = (str: string, num: string) => str.replace(/%s/, num);

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-top: 4px;
`;

type ClinicsDataTableProps = {
  data: any;
  orderBy: { id: any; value: any };
  pagination: {
    page: number;
    limit: number;
  };
  onChangePagination: (params: any) => void;
};

export const ClinicsDataTable = (props: ClinicsDataTableProps) => {
  const { data, orderBy } = props;
  const items = useMemo(() => {
    return data?.data || [];
  }, [data]);

  const schema = useMemo(
    () => ({
      columns: [
        {
          id: 'name',
          name: '카테고리',
          value: (item: any) => item.name || '-',
        },
        {
          id: 'visible',
          name: '카테고리 상태',
          value: (item: { visible: boolean | null | string }) =>
            !(item.visible == null || item.visible === '') ? (
              item.visible ? (
                <span>사용중</span>
              ) : (
                <span>미사용</span>
              )
            ) : (
              <span>미사용</span>
            ),
        },
        {
          id: 'items.name',
          name: '시/수술명',
          value: (item: { items: { name: string }[] }) =>
            item.items.map((v) => v.name || '-'),
        },
        {
          id: 'items.visible',
          name: '시/수술 상태',
          value: (item: { items: { visible: boolean | null | string }[] }) =>
            item.items.map((v) =>
              !(v.visible == null || v.visible === '') ? (
                v.visible ? (
                  <span>사용중</span>
                ) : (
                  <span>미사용</span>
                )
              ) : (
                <span>미사용</span>
              )
            ),
        },
        {
          id: 'items.treatmentCount',
          name: '시/수술 횟수',
          value: (item: { items: { treatmentCount: number }[] }) =>
            item.items.map((v) => `${v.treatmentCount || 0}회`),
        },
        {
          id: 'items.nextDay.nextMonth',
          name: '적정시술주기',
          value: (item: { items: { nextDay: number; nextMonth: number }[] }) =>
            item.items.map((v) =>
              v.nextDay !== 0 && v.nextMonth === 0
                ? v.nextDay === 1
                  ? translateNumbering('%s일 후', String(1))
                  : translateNumbering('%s일 후', String(v.nextDay))
                : v.nextMonth !== 0 && v.nextDay === 0
                ? v.nextMonth === 1
                  ? translateNumbering('%s개월 후', String(1))
                  : translateNumbering('%s개월 후', String(v.nextMonth))
                : '-'
            ),
        },
        {
          id: 'items.vatExclusivePrice',
          name: 'VAT 미포함가격',
          value: (item: { items: { vatExclusivePrice: string | null }[] }) =>
            item.items.map((v) =>
              v.vatExclusivePrice !== null
                ? `${currency(v.vatExclusivePrice)}`
                : '-'
            ),
        },
        {
          id: 'items.price',
          name: 'VAT 포함가격',
          value: (item: { items: { price: string | null }[] }) =>
            item.items.map((v) =>
              v.price !== null ? `${currency(v.price)}` : '-'
            ),
        },
        {
          id: 'items.vatFree',
          name: '과세여부',
          value: (item: { items: { vatFree: boolean }[] }) =>
            item.items.map((v) => (v?.vatFree ? '비과세' : '과세')),
        },
      ],
    }),
    []
  );

  return (
    <DataTable
      data={items}
      schema={schema}
      sorts={[orderBy]}
      placeholder={'조회 결과가 없습니다.'}
    />
  );
};
