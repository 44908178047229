import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
import { ReactComponent as TriangleNotiIcon } from '@ic/ic-triangle-noti.svg';
import { Wrapper, Description } from './NotPermissionPage.styled';

export const NotPermissionPage = () => {
  const history = useHistory();

  const onClick = () => {
    history.goBack();
  };

  return (
    <Wrapper>
      <TriangleNotiIcon />
      <Description>요청하신 페이지에 접근 권한이 없습니다.</Description>
      <Button variant="contained" color="primary" onClick={onClick}>
        뒤로가기
      </Button>
    </Wrapper>
  );
};
