import { Button } from './CollapseButton.styled';
import Icon from '../Icon';

type CollapseButtonProps = {
  collapsed: boolean;
  onClick?: (params?: any) => void;
};

export const CollapseButton = ({ collapsed, onClick }: CollapseButtonProps) => {
  return (
    <Button $collapsed={collapsed} onClick={onClick}>
      <Icon variant="chevron_up" />
    </Button>
  );
};
