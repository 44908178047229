import { styled, css, Box as MuiBox } from '@mui/material';
import { DataPagination as _DataPagination } from '~/components/commons/DataPagination';
import { Button as _Button } from '~/components/commons/Button';
import { hexToRgbaString } from '~/utils/colorUtil';
import { ComponentType } from 'react';

export const Box = styled(MuiBox)(({ theme }) => css``);

export const DataPagination = styled(_DataPagination)`
  margin-left: 1rem;
` as ComponentType<any>;

export const Select = styled('select')`
  margin-right: 5px;
`;

export const Summary = styled('div')`
  margin-top: 1.5rem;
  display: flex;
  justify-content: flex-end;
  font-size: 13px;
  white-space: pre;
  align-items: center;
`;

export const Button = styled(_Button)`
  &.MuiButton-root.MuiButtonBase-root {
    &.black {
      margin-right: auto;
      font-size: 12px;
      height: 24px;
      line-height: 24px;
      min-height: 24px;
      background: #000000;
      color: #ffffff;
      border: 0 none;

      &:hover {
        background: ${hexToRgbaString('#000000', 0.7)};
      }
    }
  }
` as ComponentType<any>;
