import { Paper } from '@mui/material';
import { ReactNode } from 'react';

type FormBodyProps = {
  children: ReactNode;
  sx?: any;
};

export const FormBody = ({ children, sx = undefined }: FormBodyProps) => {
  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        p: 2,
        borderRadius: 1,
        border: '1px solid #E9E9ED',
        ...sx,
      }}
    >
      {children}
    </Paper>
  );
};
