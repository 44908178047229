import { useMemo } from 'react';
import { observer } from 'mobx-react';
import { useLocation, useParams } from 'react-router-dom';
import querystring from 'query-string';
import { Box, Typography } from './Clinics.styled';
import PageNav from '~/components/commons/PageNav';
import DepartmentsTabs from './tabs/DepartmentsTabs';
import CustomersTabs from './tabs/CustomersTabs';
import ProductsTabs from './tabs/ProductsTabs';
import TreatmentsTabs from './tabs/TreatmentsTabs';
import TabContents from '~/components/commons/TabContents';

const tabContents = [
  {
    label: '예약캘린더 부서',
    Component: DepartmentsTabs,
  },
  {
    label: '수납코드 - 시/수술',
    Component: TreatmentsTabs,
  },
  {
    label: '수납코드 - 제품',
    Component: ProductsTabs,
  },
  {
    label: '고객',
    Component: CustomersTabs,
  },
];

const Clinics_Management_Detail = () => {
  const location = useLocation();
  const { id }: any = useParams();
  const queryParams = useMemo(() => {
    return querystring.parse(location.search);
  }, [location.search]);
  const tabId = useMemo(() => {
    const { tabId } = queryParams;
    return tabId && !isNaN(Number(tabId)) ? Number(tabId) : 0;
  }, [location.search]);

  const { clinicNo = '-', name = '-' } = queryParams;

  return (
    <Box className="page-container">
      <PageNav pages={['CLIENT', '거래처 기초정보 관리']} />
      <Typography variant="h1" className="clinic-info-title">
        <span>{`${id}`}</span>
        <span className="before-bar-content">{`${name}`}</span>
        <span className="before-bar-content">{`${clinicNo}`}</span>
      </Typography>
      <TabContents defaultValue={String(tabId)} contents={tabContents} />
    </Box>
  );
};
export default observer(Clinics_Management_Detail);
