import { useApp } from '~/hooks/useApp';
import { TextField } from '~/components/commons/TextField';
import { DropdownList } from '~/components/commons/DropdownList';
import update from 'immutability-helper';
import { roleOptions } from '~/pages/management/management';
import {
  Content,
  Container,
  Item,
  ItemTitle,
  ItemWide,
  TextFieldWrapper,
  DropdownListWrapper,
} from './DetailManagementTable.styled';

type NewManagementTableProps = {
  value: any;
  setValue: (value: any) => void;
};

export const NewManagementTable = ({
  value,
  setValue,
}: NewManagementTableProps) => {
  const app = useApp();

  return (
    <Content>
      <Container>
        <ItemTitle>
          <div className="label-required">
            <label>아이디(이메일)</label>
          </div>
        </ItemTitle>
        <ItemWide>
          <TextFieldWrapper>
            <TextField
              $type="search"
              value={value.email}
              placeholder="아이디(이메일)을 입력하세요"
              onChange={(v) => {
                setValue(
                  update(value, {
                    email: { $set: v },
                  })
                );
              }}
            />
          </TextFieldWrapper>
        </ItemWide>
      </Container>
      <Container>
        <ItemTitle>
          <div className="label-required">
            <label>보안등급</label>
          </div>
        </ItemTitle>
        <Item>
          <DropdownListWrapper>
            <DropdownList
              variant="search"
              options={roleOptions}
              value={roleOptions.find((f) => f.id === value.role?.id)}
              onChange={(v) => {
                setValue(
                  update(value, {
                    role: { $set: v },
                  })
                );
              }}
              placeholder="보안등급을 선택하세요"
            />
          </DropdownListWrapper>
        </Item>
        <ItemTitle>
          <div className="label-required">
            <label>담당자 이름</label>
          </div>
        </ItemTitle>
        <Item>
          <TextFieldWrapper>
            <TextField
              $type="search"
              value={value.name}
              placeholder="담당자 이름을 입력하세요"
              onChange={(v) => {
                setValue(
                  update(value, {
                    name: { $set: v },
                  })
                );
              }}
            />
          </TextFieldWrapper>
        </Item>
      </Container>
    </Content>
  );
};
