import { Wrapper, Name } from './PageNav.styled';

type PageNavProps = {
  pages: any[];
};

const PageNav = (props: PageNavProps) => {
  const { pages = [] } = props;
  return (
    <Wrapper>
      {pages.map((v, i) => (
        <Name key={i} className={`${pages.length - 1 === i ? 'bold' : ''}`}>
          {v}
        </Name>
      ))}
    </Wrapper>
  );
};

export default PageNav;
