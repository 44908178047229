import { Box } from '@mui/material';
import { TextField } from '~/components/commons/TextField';
import { FilterDateRange } from '~/components/commons/SearchFilterDateRange';
import { Button } from '~/components/commons/Button';
import update from 'immutability-helper';
import { format } from 'date-fns';
import { methodOptions } from '~/pages/management/management';
import {
  Wrapper,
  ButtonWrapper,
  Form,
  DropdownList,
  Label,
} from './ManagementSearchForm.styled';

type LogSearchFormProps = {
  searchValue: any;
  setSearchValue: (value: any) => void;
  onClickSearch: () => void;
};

export const LogSearchForm = ({
  searchValue,
  setSearchValue,
  onClickSearch,
}: LogSearchFormProps) => {
  return (
    <Wrapper>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          border: '1px solid #DEE2EC',
          width: 1380,
          maxWidth: 1800,
        }}
      >
        <Form>
          <Box width={380}>
            <FilterDateRange
              label="일자"
              placeholder={'YYYY-MM-DD'}
              startAt={searchValue.startAt}
              endAt={searchValue.endAt}
              onChange={(start, end) => {
                setSearchValue(
                  update(searchValue, {
                    startAt: {
                      $set: start ? format(start, 'yyyy-MM-dd') : undefined,
                    },
                    endAt: {
                      $set: end ? format(end, 'yyyy-MM-dd') : undefined,
                    },
                  })
                );
              }}
            />
          </Box>
          <Box width={250}>
            <TextField
              $type="search"
              value={searchValue.email}
              startAdornment="ID"
              placeholder="입력"
              onChange={(v) => {
                setSearchValue(
                  update(searchValue, {
                    email: { $set: v },
                  })
                );
              }}
            />
          </Box>
          <Box width={250}>
            <TextField
              $type="search"
              value={searchValue.name}
              startAdornment="이름"
              placeholder="입력"
              onChange={(v) => {
                setSearchValue(
                  update(searchValue, {
                    name: { $set: v },
                  })
                );
              }}
            />
          </Box>
          <Box width={250}>
            <DropdownList
              variant="search"
              options={methodOptions}
              getOptionLabel={(o: any) => {
                if (o[0]) {
                  return o[0]?.label ? o[0].label : '';
                } else {
                  return o ? (o?.label ? o.label : '') : '';
                }
              }}
              value={methodOptions.filter((f) =>
                searchValue.requestMethod?.find((r: any) => r?.id === f?.id)
              )}
              onChange={(v: any) => {
                setSearchValue(
                  update(searchValue, {
                    requestMethod: { $set: [v] },
                  })
                );
              }}
              startAdornment={<Label>메소드</Label>}
              placeholder="전체"
            />
          </Box>
          <Box width={250}>
            <TextField
              $type="search"
              value={searchValue.adminUserId}
              startAdornment="관리자 번호"
              placeholder="입력"
              onChange={(v) => {
                setSearchValue(
                  update(searchValue, {
                    adminUserId: { $set: v },
                  })
                );
              }}
            />
          </Box>
        </Form>
      </Box>
      <ButtonWrapper>
        <Button styled="outline" onClick={() => onClickSearch()}>
          조회
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};
