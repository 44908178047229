import { useEffect } from 'react';
import { observer } from 'mobx-react';
import '~/assets/styles/modal.css';
import { runInAction } from 'mobx';
import { useApp } from '~/hooks/useApp';
type ModalProps = {
  modal: any;
};

const Modal = ({ modal }: ModalProps) => {
  const app = useApp();
  useEffect(() => {
    document.addEventListener('keydown', onKeydown);

    return () => {
      document.removeEventListener('keydown', onKeydown);
    };
  });

  const onKeydown = (event: any) => {
    if (event.key === 'Escape') onClose();
  };

  const onClose = (event?: any) => {
    // event can be '0', so don't do nill check for event like: !event
    if (modal.resolve) {
      modal.resolve(event);
    }

    runInAction(() => {
      app.popModal();
    });
  };

  const onClickOverlay = (event: any) => {
    event.stopPropagation();

    if (modal.options !== undefined) {
      if (modal.options.type === 'CONSULTING_REQUESTS') return; // 상담문의 응대하기 팝업
      if (modal.options.type === 'CREATE_FILE') return; // 펜차트 이미지 업로드
      if (modal.options.type === 'LOADING') return; // 로딩
      if (modal.options.type === 'SEND_SMS_CHECK') return; //자동문자전송 팝업
    }

    if (event.target.classList.contains('modal-overlay')) {
      runInAction(() => {
        app.popModal();
      });
      return;
    }
  };

  const modalWithProp = () => {
    if (!modal.component) return;

    let ModalComponent = modal.component;
    return (
      <ModalComponent
        options={modal.options}
        close={(e: any) => onClose(e)}
        resolve={modal.resolve}
      />
    );
  };

  return (
    <div className={`modal modal-overlay`} onMouseDown={onClickOverlay}>
      {modal.options.type !== 'LOADING' ? (
        <div className="modal-container">{modalWithProp()}</div>
      ) : (
        <div className="">{modalWithProp()}</div>
      )}
    </div>
  );
};

export default observer(Modal);
