import $http from 'axios';
import { readFileMime } from '~/fileUtil';

export class ImageRepo {
  async upload(image: File) {
    const mime: any = await readFileMime(image);
    const ext = mime.split('/')[1];

    const res = await $http.post('/images/uploads', {
      extension: ext,
    });

    const presignedUrl = res.data.presignedUrl;
    const token = res.data.token;
    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'multipart/form-data');
    const uploadResponse = await fetch(presignedUrl, {
      method: 'PUT',
      headers: requestHeaders,
      body: image,
    });

    if (!uploadResponse.ok) {
      console.error(await uploadResponse.text());
      throw new Error('upload failed');
    }

    const resp = await $http.post('/images', { token });
    return resp.data?.id;
  }
}
