import {
  styled,
  Box as MuiBox,
  Tab as MuiTab,
  Tabs as MuiTabs,
} from '@mui/material';
import { TabContext as MuiTabContext, TabPanel as MuiTabPanel } from '@mui/lab';

export const Box = styled(MuiBox)(
  () => `
`
);

export const TabContext = styled(MuiTabContext)(
  () => `
  
`
);

export const TabPanel = styled(MuiTabPanel)(
  () => `
    padding: 0;
  `
);

export const Tabs = styled(MuiTabs)(
  () => `
  min-height: 29px;
  .MuiTabs-flexContainer {
    gap: 32px;
  }
`
);

export const Tab = styled(MuiTab)(
  ({ theme }) => `
  padding:0;
  min-width: 24px;
  min-height: 29px;
  font-size: 13px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  padding-bottom: 10px;
  color: ${theme.palette.grey['700']};
  
  &.Mui-selected {
    font-weight: 700;
  }
`
);
