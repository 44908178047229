import { Box } from '@mui/material';
import DatePicker from 'react-datepicker';

type DateFieldProps = {
  value: any;
  onChange: (value: any) => void;
  placeholder?: string;
  useToday?: boolean;
  dateFormat?: string;
};

export const DateField = ({
  value,
  onChange,
  placeholder = '',
  useToday = false,
  dateFormat = 'yyyy년 MM월 d일',
}: DateFieldProps) => {
  return (
    <Box>
      <DatePicker
        className="text-center"
        placeholderText={placeholder}
        todayButton={useToday ? '오늘' : false}
        dateFormat={dateFormat}
        selected={value}
        onChange={onChange}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
      />
    </Box>
  );
};
