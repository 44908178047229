import { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useApp } from '~/hooks/useApp';
import { DetailManagementTable } from '~/components/routes/management/DetailManagementTable';
import { Button } from '~/components/button/Button';
import { useApi } from '~/components/providers/ApiProvider';
import {
  Depth,
  Title,
  SubTitle,
  Contents,
} from './DetailManagementPage.styled';
import { EditManagementPasswordTable } from '~/components/routes/management/EditManagementTable';
import { useSnackbarContext } from '~/components/providers/SnackbarProvider';
import { Tooltip } from '~/components/commons/Tooltip';

export const DetailManagementPage = () => {
  const app = useApp();
  const { userApi } = useApi();
  const snackbar = useSnackbarContext();

  const history = useHistory();
  const { id } = useParams<any>();
  const [user, setUser] = useState<any>({
    email: '',
    id: null,
    name: '',
    role: null,
    status: null,
  });

  const callApi = useCallback(async () => {
    if (id) {
      try {
        const user: any = await userApi.getUser(id);
        setUser(user.data);
      } catch (e: any) {
        if (e.name === 'NO_PERMISSION') {
          snackbar.alert('접근 권한이 없습니다.');
        } else {
          snackbar.alert(e.description);
        }
      }
    }
  }, []);

  useEffect(() => {
    callApi();
  }, []);

  const onClickEdit = () => {
    history.push(`/management/edit/${id}`);
  };

  return (
    <div className="main-contents">
      <Depth>{`관리자 관리 > 계정 정보`}</Depth>
      <Title className="content-title">
        <span>계정 정보</span>
        <Tooltip title={app.$me.role !== 'super' ? '권한이 없습니다.' : ''}>
          <div>
            <Button
              onClick={onClickEdit}
              disabled={user.status === 'deleted' || app.$me?.role !== 'super'}
            >
              수정
            </Button>
          </div>
        </Tooltip>
      </Title>
      <SubTitle>계정정보</SubTitle>
      <Contents>
        <DetailManagementTable user={user} />
      </Contents>
      <SubTitle>비밀번호</SubTitle>
      <Contents>
        <EditManagementPasswordTable
          userStatus={user.status}
          userId={Number(id)}
        />
      </Contents>
    </div>
  );
};
