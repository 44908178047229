import { useState, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import DatePicker from 'react-datepicker';
import { toJS } from 'mobx';
import { useSnackbarContext } from '~/components/providers/SnackbarProvider';
import { useApp } from '~/hooks/useApp';
import { format } from 'date-fns';

type ModalTaxBillConfirmProps = {
  options: any;
  close: () => void;
};

const ModalTaxBillConfirm = ({ options, close }: ModalTaxBillConfirmProps) => {
  const app = useApp();
  const snackbar = useSnackbarContext();
  const [data, setData] = useState(toJS(options.data) || {});
  const [clinicData, setClinicData] = useState<any>({});
  const [taxBillPublishedAt, setTaxBillPublishedAt] = useState<Date>(
    new Date()
  );

  const callApi = useCallback(async () => {
    const resp = await app.$clinicRepo.clinic.detail(options.data.clinicId);

    setClinicData(resp.data);
  }, [app.$clinicRepo.clinic, options]);

  useEffect(() => {
    callApi();
  }, [callApi]);

  const onChangeTaxBillPublishedAt = (date: Date) => {
    const editData = {
      ...data,
      taxBillPublishedAt: format(new Date(date), `yyyy-MM-dd'T'HH:mm:ss`),
    };
    setData(editData);
    setTaxBillPublishedAt(date);
  };

  const taxBillCallApi = useCallback(
    async (data) => {
      try {
        const taxBillPublishedDate = format(
          new Date(taxBillPublishedAt),
          `yyyy-MM-dd'T'HH:mm:ss`
        );
        const payload = {
          isTaxBill: true,
          status: 'complete',
          taxBillPublishedAt: taxBillPublishedDate,
        };
        await app.$smsRepo.sms.update(data.id, payload);
        options.callApi();
        close();
      } catch (e: any) {
        snackbar.alert(e.description);
      }
    },
    [app, close, taxBillPublishedAt]
  );

  const onClickSave = () => {
    app.$modal
      .basic({
        body: '세금계산서 발행확인 처리하시겠습니까?',
        buttons: [
          { text: 'CANCEL', class: 'btn-default' },
          { text: 'CONFIRM', class: 'btn-primary' },
        ],
      })
      .then((idx) => {
        if (idx === 1) {
          taxBillCallApi(data);
        }
      });
  };

  return (
    <div className="modal-payment-payouts">
      <div className="head flex-row flex-between items-start">
        <div className="title">세금계산서 발행확인</div>
        <i onClick={() => close()} className="zmdi zmdi-close" />
      </div>
      <div className="body">
        <div className="form-control">
          <label>세금계산서 발행일</label>
          {options.confirm ? (
            <input
              value={format(new Date(data.taxBillPublishedAt), 'yyyy-MM-dd')}
              disabled={true}
            />
          ) : (
            <DatePicker
              className="text-center"
              todayButton="오늘"
              dateFormat="yyyy년 MM월 d일"
              selected={taxBillPublishedAt}
              onChange={onChangeTaxBillPublishedAt}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          )}
        </div>
        <div className="form-control">
          <label>상호(사업명)</label>
          <input value={data.clinicName || ''} disabled={true} />
        </div>
        <div className="form-control">
          <label>사업자등록번호</label>
          <input value={clinicData.employerNo || ''} disabled={true} />
        </div>
        <div className="form-control">
          <label>대표자</label>
          <input value={clinicData.ownerName || ''} disabled={true} />
        </div>
        <div className="form-control">
          <label>세금계산서 발행 이메일</label>
          <input defaultValue={clinicData.contactEmail || ''} disabled={true} />
        </div>
        <div className="form-control">
          <label>담당자 이름</label>
          <input
            defaultValue={clinicData.contactManager || ''}
            disabled={true}
          />
        </div>
        <div className="form-control">
          <label>담당자 전화번호</label>
          <input defaultValue={clinicData.contactTel || ''} disabled={true} />
        </div>
      </div>

      <div className="buttons">
        <div className="flex-row">
          <button onClick={() => close()} className="btn btn-default">
            {app.$translate('CANCEL')}
          </button>
          <button
            onClick={onClickSave}
            disabled={options.confirm}
            className="btn btn-primary"
          >
            {app.$translate('SAVE')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default observer(ModalTaxBillConfirm);
