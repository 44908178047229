import { styled } from '@mui/material';

export const Depth = styled('div')`
  font-size: 12px;
  margin-bottom: 10px;
`;

export const Title = styled('div')`
  display: flex;
  width: 800px;
  justify-content: space-between;
`;

export const SubTitle = styled('div')`
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 10px;
`;

export const Contents = styled('div')`
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  margin-bottom: 20px;
`;
