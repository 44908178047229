import { Box } from '@mui/material';
import { TextField } from '~/components/commons/TextField';
import update from 'immutability-helper';
import { roleOptions, statusOptions } from '~/pages/management/management';
import {
  Wrapper,
  FormWrapper,
  Form,
  ButtonWrapper,
  Label,
  DropdownList,
  Button,
} from './ManagementSearchForm.styled';

type ManagementSearchFormProps = {
  searchValue: any;
  setSearchValue: (value: any) => void;
  onClickSearch: () => void;
};

export const ManagementSearchForm = ({
  searchValue,
  setSearchValue,
  onClickSearch,
}: ManagementSearchFormProps) => {
  return (
    <Wrapper>
      <FormWrapper>
        <Form>
          <Box width={250}>
            <TextField
              $type="search"
              value={searchValue.email ?? ''}
              startAdornment="ID"
              placeholder="입력"
              onChange={(v) => {
                setSearchValue(
                  update(searchValue, {
                    email: { $set: v },
                  })
                );
              }}
            />
          </Box>
          <Box width={250}>
            <TextField
              $type="search"
              value={searchValue.name ?? ''}
              startAdornment="이름"
              placeholder="3자리 이상 입력"
              onChange={(v) => {
                setSearchValue(
                  update(searchValue, {
                    name: { $set: v },
                  })
                );
              }}
            />
          </Box>
          <Box width={250}>
            <DropdownList
              variant="search"
              startAdornment={<Label>보안등급</Label>}
              placeholder="전체"
              value={roleOptions.filter((f) =>
                searchValue.roles?.find((r: any) => r?.id === f?.id)
              )}
              options={roleOptions}
              getOptionLabel={(o: any) => {
                if (o[0]) {
                  return o[0]?.label ? o[0].label : '';
                } else {
                  return o ? (o?.label ? o.label : '') : '';
                }
              }}
              onChange={(v: any) => {
                setSearchValue(
                  update(searchValue, {
                    roles: { $set: [v] },
                  })
                );
              }}
            />
          </Box>
          <Box width={250}>
            <DropdownList
              variant="search"
              startAdornment={<Label>사용여부</Label>}
              placeholder="전체"
              value={statusOptions.filter((f) =>
                searchValue.statuses?.find((r: any) => r?.id === f?.id)
              )}
              options={statusOptions.filter((item) => item.id !== 'deleted')}
              getOptionLabel={(o: any) => {
                if (o[0]) {
                  return o[0]?.label ? o[0].label : '';
                } else {
                  return o ? (o?.label ? o.label : '') : '';
                }
              }}
              onChange={(v: any) => {
                setSearchValue(
                  update(searchValue, {
                    statuses: { $set: [v] },
                  })
                );
              }}
            />
          </Box>
        </Form>
      </FormWrapper>
      <ButtonWrapper>
        <Button styled="outline" onClick={() => onClickSearch()}>
          조회
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};
