import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { observer } from 'mobx-react';
import { PopupRepo } from '~/components/repo/PopupRepo';
import { DateField } from '~/components/fields/DateField';
import { PopupTable } from './PopupTable';
import { TextField } from '~/components/fields/TextField';
import { TableToolbar } from './TableToolbar';
import { Box } from '@mui/material';
import { Button } from '~/components/commons/Button';
import { format } from 'date-fns';
import PageNav from '~/components/commons/PageNav';

type PopupPageContextProps = {
  page: number;
  total: number;
  limit: number;
  onChangePage: (value: any) => void;
  pausePopup: (value: any) => void;
  deletePopup: (value: any) => void;
};

export const PopupPageContext = createContext<PopupPageContextProps | null>(
  null
);

export const PopupPage = observer(() => {
  const repo = new PopupRepo();
  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(0);
  const [searchParams, setSearchParams] = useState<any>({
    dateStart: null,
    dateEnd: null,
    title: undefined,
    page: 1,
    limit: 30,
  });

  const [start, setStart] = useState(
    searchParams.dateStart ? new Date(searchParams.dateStart) : null
  );
  const [end, setEnd] = useState(
    searchParams.dateEnd ? new Date(searchParams.dateEnd) : null
  );
  const [title, setTitle] = useState(searchParams.title ?? '');

  async function load() {
    const res: any = await repo.all(searchParams);
    setItems(res.data);
    setTotal(res.pagination.total);
  }

  useEffect(() => {
    load();
  }, [searchParams]);

  const onChangeStart = (date: Date) => {
    setStart(date);
  };

  const onChangeEnd = (date: Date) => {
    setEnd(date);
  };

  const onChangeTitle = (e: any) => {
    setTitle(e.target.value);
  };

  const onClickSearch = () => {
    setSearchParams({
      dateStart: start ? format(start, 'yyyy-MM-dd') : undefined,
      dateEnd: end ? format(end, 'yyyy-MM-dd') : undefined,
      title: title.trim().length > 0 ? title : undefined,
      page: 1,
      limit: searchParams.limit,
    });
  };

  const onChangePage = useCallback((p) => {
    setSearchParams((v: any) => ({
      ...v,
      page: p,
    }));
  }, []);

  const pausePopup = useCallback(async (id) => {
    await repo.update(id, {
      isActivePopup: false,
    });
    load();
  }, []);

  const deletePopup = useCallback(async (id) => {
    await repo.delete(id);
    load();
  }, []);

  const value = useMemo(
    () => ({
      page: searchParams.page,
      total: total,
      limit: searchParams.limit,
      onChangePage,
      pausePopup,
      deletePopup,
    }),
    [searchParams, onChangePage]
  );

  return (
    <PopupPageContext.Provider value={value}>
      <div className="main-contents">
        <PageNav pages={['NOTICE', `배너 관리`]} />
        <div className="content-title" style={{ verticalAlign: 'middle' }}>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <DateField
              value={start}
              onChange={onChangeStart}
              placeholder="YYYY-MM-DD"
            />
            <DateField
              value={end}
              onChange={onChangeEnd}
              placeholder="YYYY-MM-DD"
            />
            <TextField
              value={title}
              onChange={onChangeTitle}
              placeholder="제목"
            />
            <Button styled="outline" onClick={onClickSearch}>
              검색
            </Button>
          </Box>
        </div>
        <TableToolbar />
        <PopupTable items={items} />
      </div>
    </PopupPageContext.Provider>
  );
});
