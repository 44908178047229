import { Box as MuiBox, Table, styled } from '@mui/material';
import { Button } from '~/components/button/Button';
import { format } from 'date-fns';
import { useApp } from '~/hooks/useApp';
import { useSnackbarContext } from '~/components/providers/SnackbarProvider';
import { observer } from 'mobx-react';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { PopupPageContext } from './PopupPage';
import { Tooltip } from '~/components/commons/Tooltip';
import { ConfirmModal } from '~/components/modals/ConfirmModal';
import { useImperativeModal } from '~/components/providers/ImperativeModalProvider';
import BrokenImage from '~/assets/images/common/image_not_found_gray.svg';

const Box = styled(MuiBox)(
  () => `
  &.thumbnail-tooltip {
    width: 100px;
    img {
      width: 100%;
    }
  }
`
);

function getBannerType(type: string) {
  switch (type) {
    case 'header':
      return '상단 띠배너';
    case 'header_refresh':
      return '상단 띠배너(새로고침)';
    case 'image':
      return '이미지 배너';
    case 'logo':
      return '로고 이미지';
    case 'home_banner':
      return '홈 화면 배너';
    default:
      return '미등록 타입';
  }
}

function formatDate(d: string) {
  return format(new Date(d), 'yyyy-MM-dd HH:mm');
}

const PopupTableRow = observer(({ item }: any) => {
  const snackbar = useSnackbarContext();
  const history = useHistory();
  const app = useApp();
  const { pausePopup, deletePopup }: any = useContext(PopupPageContext);
  const imperativeModal = useImperativeModal();

  const onClickEdit = () => {
    history.push(`/popups/edit/${item.id}`);
  };

  const onClickPause = async () => {
    const confirm = await imperativeModal.open((close) => (
      <ConfirmModal
        variant="primary"
        onConfirm={() => close(true)}
        onCancel={() => close(false)}
      >
        게시를 중지하시겠습니까?
      </ConfirmModal>
    ));

    if (!confirm) return;

    await pausePopup(item.id);
    snackbar.success('게시를 중지했습니다.');
  };

  const onClickDelete = async () => {
    const confirm = await imperativeModal.open((close) => (
      <ConfirmModal
        variant="primary"
        onConfirm={() => close(true)}
        onCancel={() => close(false)}
      >
        삭제하시겠습니까?
      </ConfirmModal>
    ));

    if (!confirm) return;

    await deletePopup(item.id);
    snackbar.success('삭제했습니다.');
  };

  return (
    <tr>
      <td>
        {item.isActive ? (
          <Box sx={{ display: 'flex', gap: 1, justifyContent: 'center' }}>
            <Tooltip
              title={app.$me.role === 'manager' ? '권한이 없습니다.' : ''}
            >
              <div>
                <Button
                  color="success"
                  onClick={onClickEdit}
                  disabled={app.$me.role === 'manager'}
                >
                  수정
                </Button>
              </div>
            </Tooltip>
            <Tooltip
              title={app.$me.role === 'manager' ? '권한이 없습니다.' : ''}
            >
              <div>
                <Button
                  color="inherit"
                  onClick={onClickPause}
                  disabled={app.$me.role === 'manager'}
                >
                  중지
                </Button>
              </div>
            </Tooltip>
          </Box>
        ) : (
          <Tooltip title={app.$me.role === 'manager' ? '권한이 없습니다.' : ''}>
            <div>
              <Button
                color="error"
                onClick={onClickDelete}
                disabled={app.$me.role === 'manager'}
              >
                삭제
              </Button>
            </div>
          </Tooltip>
        )}
      </td>
      <td>
        {(item.type === 'image' ||
          item.type === 'logo' ||
          item.type === 'home_banner') &&
        item.image &&
        item.image.thumbnailStatus === 'SUCCESS' ? (
          <Tooltip
            title={
              <Box className="thumbnail-tooltip">
                <img
                  src={item.image?.thumbnailUrl || BrokenImage}
                  alt="thumbnail_img"
                />
              </Box>
            }
          >
            <span>{getBannerType(item.type)} </span>
          </Tooltip>
        ) : (
          getBannerType(item.type)
        )}
      </td>
      <td>{item?.clickCnt || 0}</td>
      <td>
        {formatDate(item.startAt)} - {formatDate(item.endAt)}
      </td>
      <td>{item.title}</td>
      <td>{item.creator.name}</td>
      <td>{formatDate(item.createdAt)}</td>
    </tr>
  );
});

export const PopupTable = ({ items }: any) => {
  return (
    <Table>
      <thead>
        <tr>
          <th>상태</th>
          <th>종류</th>
          <th>클릭 수</th>
          <th>게시기간</th>
          <th>제목</th>
          <th>작성자</th>
          <th>작성일시</th>
        </tr>
      </thead>
      <tbody>
        {items.map((item: any) => (
          <PopupTableRow key={item.id} item={item} />
        ))}
      </tbody>
    </Table>
  );
};
