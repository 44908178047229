import { RadioWrapper, RadioButton, Icon } from './Radio.styled';

type RadioProps = {
  options: any[];
  value: any;
  onChange: (params?: any) => void;
  disabled?: boolean;
  style?: any;
};

export const Radio = ({
  options = [],
  value,
  onChange,
  disabled,
  style,
}: RadioProps) => {
  return (
    <RadioWrapper
      row
      value={value}
      onChange={(e) => onChange(e.target.value)}
      style={style}
    >
      {options.map((v, i) => (
        <RadioButton
          key={i}
          value={v.value}
          label={v.label}
          control={<Icon color="primary" />}
          disabled={disabled || v.disabled}
        />
      ))}
    </RadioWrapper>
  );
};

export default Radio;
