import { useState, useMemo, useRef, useEffect } from 'react';
import { observer } from 'mobx-react';
import { stripEmpty } from '~/utils/objUtil';
import { useApi } from '~/components/providers/ApiProvider';
import { ClinicsDataTable } from './ProductsDataTable';
import { FileInput } from '~/components/commons/FileInput';
import { objectToFormData } from 'object-to-formdata';
import Button from '~/components/commons/Button';
import { downloadFile } from '~/utils/common';
import { DataPagination, Summary, Typography, Box } from '../Clinics.styled';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import querystring from 'query-string';
import { Stack } from '@mui/material';
import { useSnackbarContext } from '~/components/providers/SnackbarProvider';
import { useApp } from '~/hooks/useApp';
import useCommons, { paginationOptions } from './common';

const requestsForm = `${process.env.PUBLIC_URL}/assets/xlsx/products_request_form.xlsx`;

const defaultParams = {
  page: 1,
  limit: 20,
  orderBy: {
    id: 'id',
    value: 'desc',
  },
};

const tabId = 2;

const DepartmentTabs = () => {
  const app = useApp();
  const { checkPermission } = useCommons(app);
  const location = useLocation();
  const history = useHistory();
  const snackbar = useSnackbarContext();
  const { id }: any = useParams();
  const excelFileRef = useRef<HTMLInputElement>();
  const defaultParamsRef = useRef(defaultParams);
  const queryParams = useMemo(() => {
    return querystring.parse(location.search);
  }, [location.search]);
  const querySearchParams = useMemo(() => {
    const _searchParams = queryParams;
    const { limit, page, orderBy, tabId: q_tabId } = _searchParams;
    return Object.keys(_searchParams).length > 0
      ? {
          tabId: q_tabId && !isNaN(Number(q_tabId)) ? Number(q_tabId) : tabId,
          page:
            page && !isNaN(Number(page))
              ? Number(page)
              : defaultParamsRef.current.page,
          limit:
            limit && !isNaN(Number(limit))
              ? Number(limit)
              : defaultParamsRef.current.limit,
          orderBy:
            orderBy &&
            typeof orderBy === 'string' &&
            !orderBy.includes('undefined') &&
            orderBy.split(' ').length === 2
              ? {
                  id: orderBy.split(' ')[0].trim(),
                  value: orderBy.split(' ')[1].trim(),
                }
              : defaultParamsRef.current.orderBy,
        }
      : null;
  }, [location.search]);
  const searchParams = useMemo(() => {
    return querySearchParams !== null
      ? {
          ...defaultParamsRef.current,
          ...querySearchParams,
        }
      : defaultParamsRef.current;
  }, [querySearchParams]);
  const [data, setData] = useState<any>([]);
  const { clinicsApi } = useApi();
  const { clinicNo = '-', name = '-' } = queryParams;

  useEffect(() => {
    if (querySearchParams?.tabId !== tabId) {
      const _newParams = stripEmpty(
        {
          page: defaultParams.page,
          limit: defaultParams.limit,
          orderBy: `${searchParams.orderBy.id} ${searchParams.orderBy.value}`,
          tabId,
        },
        {
          emptyString: true,
          emptyArray: true,
        }
      );
      history.push(
        `/clinics_management/${id}?clinicNo=${clinicNo}&name=${name}&${querystring.stringify(
          _newParams
        )}`
      );
    }
  }, []);

  useEffect(() => {
    if (tabId === querySearchParams?.tabId) {
      handleGetList();
    }
  }, [searchParams]);

  const handleGetList = async () => {
    const params = stripEmpty(
      {
        ...searchParams,
        orderBy: `${searchParams.orderBy.id} ${searchParams.orderBy.value}`,
      },
      {
        emptyString: true,
        emptyArray: true,
      }
    );
    const response: any = await clinicsApi.getProducts(id, params);
    if (response) {
      setData(response);
    }
  };

  const handleSelectFile = (e: any) => {
    const file = e.target.files[0];
    uploadFile(file);
  };

  const uploadFile = async (excelFile: File) => {
    snackbar.success('등록 중입니다.');
    try {
      const response = await clinicsApi.excelCreateProducts(
        id,
        objectToFormData({ excelFile })
      );

      const stringBlob = response.data;
      const url = window.URL.createObjectURL(new Blob([stringBlob]));
      const fileName = response.headers['file-name'];
      const errorCount = Number(
        response.headers['excel-create-request-fail-count']
      );
      if (errorCount < 1) {
        snackbar.success('등록 완료됐습니다.');
        handleGetList();
      } else {
        downloadFile(url, fileName);
        snackbar.success(
          '내용 오류로 전체 등록이 취소됐습니다. 엑셀 파일 다운로드하여 확인 후 다시 등록해 주세요.'
        );
      }
    } catch (error) {
      snackbar.alert('서버에러가 발생하여 전체 등록이 취소됐습니다.');
    }
  };

  const handleChangePagination = (pg: any) => {
    const newSearchParams = {
      ...searchParams,
      page: pg.page,
      limit: pg.limit,
    };
    const _newParams = stripEmpty(
      {
        ...newSearchParams,
        orderBy: `${searchParams.orderBy.id} ${searchParams.orderBy.value}`,
        tabId,
      },
      {
        emptyString: true,
        emptyArray: true,
      }
    );
    history.push(
      `/clinics_management/${id}?clinicNo=${clinicNo}&name=${name}&${querystring.stringify(
        _newParams
      )}`
    );
  };

  return (
    <>
      <Summary>
        <Typography variant="h1">내역</Typography>
        <Stack flexDirection={'row'} gap={'10px'}>
          <DataPagination
            options={paginationOptions}
            value={{
              limit: searchParams.limit,
              page: searchParams.page,
            }}
            pages={data?.pagination?.pages || 1}
            total={data?.pagination?.total || 1}
            onChange={handleChangePagination}
          />
          {checkPermission(['super', 'admin']) && (
            <>
              <Button
                styled={'outline'}
                size="m"
                onClick={() => {
                  downloadFile(requestsForm, '수납코드_제품_양식.xlsx');
                }}
              >
                Excel 양식 다운로드
              </Button>
              <Button
                styled={'fill'}
                size="m"
                onClick={() => excelFileRef.current?.click()}
              >
                일괄 등록 파일 등록
              </Button>
              <FileInput
                ref={excelFileRef as any}
                onChange={handleSelectFile}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              />
            </>
          )}
        </Stack>
      </Summary>
      <Box className="table-wrapper">
        <ClinicsDataTable
          data={data}
          orderBy={searchParams.orderBy}
          pagination={{
            limit: searchParams.limit,
            page: searchParams.page,
          }}
          onChangePagination={handleChangePagination}
        />
      </Box>
    </>
  );
};
export default observer(DepartmentTabs);
