import { useState, useRef } from 'react';
import { observer } from 'mobx-react';
import { OverlayWrapper } from '~/components/commons/modals/Overlay';
import { objectToFormData } from 'object-to-formdata';
import DatePicker from 'react-datepicker';
import {
  ModalWrapper,
  Box,
  Typography,
  TextField,
  FormControl,
  TextareaAutosize,
  DropdownList,
  IconButton,
  FileInput,
  Link,
} from './ModalDeactivate.styled';
import { agencies as agenciesOptions } from '~/hooks/constants';
import { useApi } from '~/components/providers/ApiProvider';
import { useSnackbarContext } from '~/components/providers/SnackbarProvider';
import { runInAction } from 'mobx';
import { format } from 'date-fns';
import Icon from '~/components/commons/Icon';
import { Button as Button2 } from '~/components/commons/Button';

type ModalDeactivateReasonProps = {
  info: any;
  title: string;
  onClose: () => void;
};

const ModalDeactivateReason = ({
  info,
  onClose,
  title,
}: ModalDeactivateReasonProps) => {
  const snackbar = useSnackbarContext();
  const [stage, setStage] = useState(1);
  const [value, setValue] = useState<{
    refundPrice: string;
    memo: string;
    agency: string;
    educationAgency: string;
    requestAt: Date;
    cancelAt: Date;
    cancelFormFile: File | null | undefined;
  }>({
    refundPrice: '',
    memo: '',
    agency: '',
    educationAgency: '',
    requestAt: new Date(),
    cancelAt: new Date(),
    cancelFormFile: undefined,
  });
  const [clinicName, setClinicName] = useState('');
  const { clinicsApi } = useApi();
  const fileRef = useRef<HTMLInputElement>();

  const handleConfirm = () => {
    setStage(2);
  };

  const handleChangeValue = (v: any) => {
    setValue({
      ...value,
      ...v,
    });
  };

  const handleSubmit = async () => {
    try {
      const formData = objectToFormData(
        {
          reason: value.memo,
          refundPrice: value.refundPrice,
          cancelAt: format(new Date(value.cancelAt), 'yyyy-MM-dd'),
          cancelExcuteAt: format(new Date(value.requestAt), 'yyyy-MM-dd'),
          contractAgency: value.agency,
          educationAgency: value.educationAgency,
          clinicId: info.id,
          cancelFormFile: value.cancelFormFile,
        },
        {
          indices: true,
        }
      );
      await clinicsApi.deactivate(info.id, formData);
      runInAction(() => {
        info.status = 'inactive';
      });
      onClose();
      snackbar.success(`UPDATED`);
    } catch (e: any) {
      if (e.name === 'Error') {
        snackbar.alert('오류가 발생했습니다. 잠시후 다시 시도해주세요');
      } else if (e.name === 'NO_PERMISSION') {
        snackbar.alert('접근 권한이 없습니다.');
      } else if (e.name != null) {
        snackbar.alert('모든값을 입력해주세요');
      } else {
        snackbar.alert(e.description);
      }
    }
  };

  const handleDeleteFile = () => {
    handleChangeValue({ cancelFormFile: null });
  };

  const handleChangeImage = (e: any) => {
    const target = e.target;
    if (target.files[0] === undefined) {
      return;
    }

    const { size, name } = e.target.files[0];
    const fileType = name.split('.').pop();
    const confirmType = ['jpg', 'JPG', 'png', 'PNG', 'jpeg', 'pdf', 'PDF'];
    // 10Mb
    const confirmSize = 10;
    if (size / 1024 / 1024 > confirmSize) {
      snackbar.alert('10MB 이하로만 첨부가능합니다.');
      return;
    }

    if (confirmType.indexOf(fileType) === -1) {
      snackbar.alert('jpg 또는 PNG 또는 PDF 파일만 첨부가능합니다.');
      return;
    }

    const reader = new FileReader();
    reader.onload = function (event: any) {
      const file = target.files[0];

      if (file.type === 'application/pdf') {
        return handleChangeValue({ cancelFormFile: file });
      }

      const img: any = new Image();
      img.src = event.target.result;

      img.onload = function () {
        const file = target.files[0];
        handleChangeValue({ cancelFormFile: file });
      };
    };
    reader.readAsDataURL(target.files[0]);
  };

  return (
    <OverlayWrapper onClose={onClose}>
      {stage === 1 ? (
        <ModalWrapper>
          <Box className="modal-header">
            안내
            <IconButton onClick={onClose}>
              <Icon variant="close" />
            </IconButton>
          </Box>
          <Box className="modal-body">
            <Typography variant="subtitle1" className="caution-text mt-20">
              정말로 계약해지 하시겠습니까?
            </Typography>
            <Box component={'label'} className="label-text">
              거래처명 <span className="alert">*</span>
            </Box>
            <TextField
              value={clinicName}
              className="white-bg"
              placeholder="해지하고자 하는 곳의 상호명을 입력하세요"
              onChange={(v: any) => {
                setClinicName(v);
              }}
            />
            <Typography variant="subtitle1" className="alert-text mt-20">
              ※ 계약해지 전, 반드시 읽어주세요
            </Typography>
            <Typography variant="body1" className="text-section mt-20">
              1. 계약해지 시, 해당 클라이언트의 모든 계정은 접속불가 및 삭제처리
              됩니다. <br />
              2. 클라이언트가 입력한 고객정보, 예약정보 등의 모든 정보가
              삭제됩니다.
              <br />
              3. 단, 월 관리비 청구 및 문자충전금은 자동으로 취소/환불되지
              않습니다.
            </Typography>
          </Box>
          <Box className="modal-footer">
            <Button2 size="m" styled="outline" color="mix" onClick={onClose}>
              취소
            </Button2>
            <Button2
              size="m"
              styled="fill"
              color="primary"
              disabled={clinicName !== info.name}
              onClick={handleConfirm}
            >
              확인
            </Button2>
          </Box>
        </ModalWrapper>
      ) : (
        <ModalWrapper>
          <Box className="modal-header p-20">{title}</Box>
          <Box className="modal-body p-20">
            <FormControl>
              <Box className="form-box">
                <label>
                  환불 금액 <span className="required">*</span>
                </label>
                <TextField
                  value={value.refundPrice}
                  onChange={(v) => handleChangeValue({ refundPrice: v })}
                  placeholder="환불 금액을 입력하세요"
                  className="white-bg"
                />
              </Box>
            </FormControl>
            <FormControl>
              <Box className="form-box">
                <label>
                  계약 대행사 <span className="required">*</span>
                </label>
                <DropdownList
                  value={agenciesOptions.filter((o) =>
                    value.agency?.includes(o.value)
                  )}
                  options={agenciesOptions.map((item: any) => {
                    item.id = item.value;
                    item.label = item.key;
                    item.id = item.value;
                    return item;
                  })}
                  onChange={(v: any) => {
                    handleChangeValue({
                      agency: v?.value,
                    });
                  }}
                  placeholder="대행사를 선택하세요"
                  getOptionLabel={(o: any) => {
                    if (o[0]) {
                      return o[0]?.label ? o[0].label : '';
                    } else {
                      return o ? (o?.label ? o.label : '') : '';
                    }
                  }}
                />
              </Box>
              <Box className="form-box">
                <label>
                  교육 대행사<span className="required">*</span>
                </label>
                <DropdownList
                  value={agenciesOptions.filter((o) =>
                    value.educationAgency?.includes(o.value)
                  )}
                  options={agenciesOptions.map((item: any) => {
                    item.id = item.value;
                    item.label = item.key;
                    item.id = item.value;
                    return item;
                  })}
                  onChange={(v: any) => {
                    handleChangeValue({
                      educationAgency: v?.value,
                    });
                  }}
                  placeholder="대행사를 선택하세요"
                  getOptionLabel={(o: any) => {
                    if (o[0]) {
                      return o[0]?.label ? o[0].label : '';
                    } else {
                      return o ? (o?.label ? o.label : '') : '';
                    }
                  }}
                />
              </Box>
            </FormControl>
            <FormControl>
              <Box className="form-box">
                <label>
                  요청일 <span className="required">*</span>
                </label>
                <Box className="date-picker-box">
                  <DatePicker
                    dateFormat="yyyy년 MM월 d일"
                    selected={value.requestAt}
                    onChange={(date) => {
                      handleChangeValue({
                        requestAt: date,
                      });
                    }}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                </Box>
              </Box>
              <Box className="form-box">
                <label>
                  해지일 <span className="required">*</span>
                </label>
                <Box className="date-picker-box">
                  <DatePicker
                    dateFormat="yyyy년 MM월 d일"
                    selected={value.cancelAt}
                    onChange={(date) => {
                      handleChangeValue({
                        cancelAt: date,
                      });
                    }}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                </Box>
              </Box>
            </FormControl>
            <FormControl>
              <Box className="form-box w-max">
                <label>
                  해지 신청서 <span className="required">*</span>
                </label>
                <Box className="file-wrapper">
                  <Button2
                    size="xs"
                    styled="outline"
                    color="black"
                    onClick={() => fileRef.current?.click()}
                  >
                    첨부하기
                  </Button2>
                  <FileInput
                    ref={fileRef}
                    onChange={(e: any) => handleChangeImage(e)}
                  />
                </Box>
                <Box className="p-8 cancel-request-link-wrapper">
                  {value?.cancelFormFile ? (
                    value.cancelFormFile?.name || ''
                  ) : info?.cancelRequestFileUrl &&
                    value.cancelFormFile === undefined ? (
                    <Link href={info.cancelRequestFileUrl} target="_blank">
                      {info.cancelRequestFileUrl}
                    </Link>
                  ) : (
                    ''
                  )}
                  {(value.cancelFormFile?.name ||
                    (value.cancelFormFile !== undefined &&
                      info?.cancelRequestFileUrl)) && (
                    <IconButton onClick={handleDeleteFile}>
                      <Icon variant="close" />
                    </IconButton>
                  )}
                </Box>
              </Box>
            </FormControl>
            <FormControl>
              <Box className="form-box w-max">
                <label>
                  해지 사유<span className="required">*</span>
                </label>
                <TextareaAutosize
                  value={value.memo}
                  minRows={3}
                  onChange={(e) => {
                    handleChangeValue({
                      memo: e.target.value,
                    });
                  }}
                />
              </Box>
            </FormControl>
          </Box>
          <Box className="modal-footer p-20">
            <Button2 size="m" styled="outline" color="mix" onClick={onClose}>
              취소
            </Button2>
            <Button2
              size="m"
              styled="fill"
              color="primary"
              onClick={handleSubmit}
              disabled={
                value.refundPrice === '' ||
                value.memo === '' ||
                value.agency === '' ||
                value.educationAgency === '' ||
                !value.cancelFormFile
              }
            >
              저장
            </Button2>
          </Box>
        </ModalWrapper>
      )}
    </OverlayWrapper>
  );
};

export default observer(ModalDeactivateReason);
