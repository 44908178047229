import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { Button } from '~/components/commons/Button';

type ConfirmModalProps = {
  options: any;
  close: () => void;
};

export const ConfirmModal = ({ options, close }: ConfirmModalProps) => {
  return (
    <Dialog open onClose={close}>
      <DialogContent
        sx={{
          fontSize: '14px',
          padding: '40px 27px 24px !important',
        }}
      >
        계정을 해지하면 같은 이메일로 다시 가입할 수 없고 <br />
        이름은 삭제됩니다.
      </DialogContent>
      <DialogActions sx={{ padding: '0 24px 16px 24px' }}>
        <Button styled="outline" onClick={close} color="mix">
          취소
        </Button>
        <Button
          onClick={() => {
            options.onConfirm();
            close();
          }}
        >
          해지
        </Button>
      </DialogActions>
    </Dialog>
  );
};
