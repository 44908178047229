import translation from '~/store/translation';

export const translate = (key: string, forcedLocale?: string) => {
  const locale = forcedLocale || translation.locale || 'en';
  return (translation.texts[key] || {})[locale] || key;
};

export const numArray = (len: number) => {
  let arr = [];
  for (let i = 0; i < len; i++) arr.push(i);
  return arr;
};

export const maskName = (name: string) => {
  if (name.length === 1) {
    return name;
  } else if (name.length === 2) {
    return `${name[0]}*`;
  } else if (name.length >= 3 && name.length <= 4) {
    return `${name[0]}${'*'.repeat(name.length - 2)}${name[name.length - 1]}`;
  } else {
    return `${name.slice(0, 3)}`.padEnd(name.length, '*');
  }
};

export const maskPhoneNumber = (number: any) => {
  if (number.length < 9) {
    return number;
  } else {
    const lastNumber = number.slice(-4);
    return `${number.slice(0, 3)}${'*'.repeat(
      number.length - (3 + lastNumber.length)
    )}${lastNumber}`;
  }
};

export const $case = {
  toSnake: function (str: string, delim?: string) {
    if (!str) return;

    let upperChars = str.match(/([A-Z])/g);
    if (!upperChars) {
      return str;
    }

    for (let i = 0, n = upperChars.length; i < n; i++) {
      str = str.replace(
        new RegExp(upperChars[i]),
        (delim || '_') + upperChars[i].toLowerCase()
      );
    }

    if (str.slice(0, 1) === (delim || '_')) {
      str = str.slice(1);
    }

    return str;
  },
  toConst: function (str: string, delim?: string) {
    if (!str) return;

    return this.toSnake(str, delim)?.toUpperCase();
  },
  toPascal: function (str: string, delim?: string, limit?: number) {
    let splitted = str.split(delim || '_');
    if (limit) {
      splitted = splitted.slice(0, limit);
    }

    return splitted.reduce(
      (result, word) => (result += this.toCapital(word)),
      ''
    );
  },
  toCamel: function (str: string, delim?: string) {
    const pascal = this.toPascal(str, delim);
    return pascal[0].toLowerCase() + pascal.substr(1);
  },
  toCapital: function (str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  },
};

export const copy = (obj: any) => JSON.parse(JSON.stringify(obj));

export const toThousandCommas = (number: string) => {
  const regexp = /\B(?=(\d{3})+(?!\d))/g;
  return number !== undefined ? number.toString().replace(regexp, ',') : '';
};

export const phoneNumberFormatHyphen = (phoneNumber: string) => {
  return (phoneNumber ?? '')
    .replace(/[^0-9]/g, '')
    .replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})/, '$1-$2-$3')
    .replace('--', '-');
};
