import { useState, useMemo, useRef, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory, useLocation } from 'react-router-dom';
import querystring from 'query-string';
import { stripEmpty } from '~/utils/objUtil';
import { useApi } from '~/components/providers/ApiProvider';
import { ClinicsDeactivateDataTable } from './DataTable';
import { DataPagination, Summary } from './ClinicDeactivate.styled';
import { SearchForm } from './SearchForm';
import PageNav from '~/components/commons/PageNav';

const defaultParams = {
  startCancelAt: '', // 해지일
  endCancelAt: '', // 해지일
  educationAgency: '',
  contractAgency: '',
  page: 1,
  limit: 50,
  orderBy: {
    id: 'clinic_id',
    value: 'desc',
  },
};

const ClinicDeactivate = () => {
  const location = useLocation();
  const history = useHistory();
  const defaultParamsRef = useRef(defaultParams);
  const querySearchParams = useMemo(() => {
    const _searchParams = querystring.parse(location.search);
    const { limit, page, orderBy, ...rest } = _searchParams;
    return Object.keys(_searchParams).length > 0
      ? {
          ...rest,
          page:
            page && !isNaN(Number(page))
              ? Number(page)
              : defaultParamsRef.current.page,
          limit:
            limit && !isNaN(Number(limit))
              ? Number(limit)
              : defaultParamsRef.current.limit,
          orderBy:
            orderBy &&
            typeof orderBy === 'string' &&
            !orderBy.includes('undefined') &&
            orderBy.split(' ').length === 2
              ? {
                  id: orderBy.split(' ')[0].trim(),
                  value: orderBy.split(' ')[1].trim(),
                }
              : defaultParamsRef.current.orderBy,
        }
      : null;
  }, [location.search]);

  const [searchParams, setSearchParams] = useState(
    querySearchParams !== null
      ? {
          ...defaultParamsRef.current,
          ...querySearchParams,
        }
      : defaultParamsRef.current
  );
  const [data, setData] = useState<any>([]);
  const { clinicsApi } = useApi();

  useEffect(() => {
    if (
      querySearchParams !== null &&
      Object.keys(querySearchParams)?.length > 3
    ) {
      const mixed = { ...defaultParamsRef.current, ...querySearchParams };
      handleSearchSubmit(mixed);
    } else if (
      querySearchParams === null &&
      JSON.stringify(searchParams) !== JSON.stringify(defaultParamsRef.current)
    ) {
      resetAll();
    }
  }, [querySearchParams]);

  const resetAll = () => {
    setSearchParams(defaultParamsRef.current);
    setData([]);
  };

  const handleGetSearch = async (params: any) => {
    const response = await clinicsApi.deactivatelist(params);
    setData(response || []);
  };

  const handleSearchSubmit = (newSearchParams: any) => {
    const _newParams = stripEmpty(
      {
        ...newSearchParams,
        orderBy: `${newSearchParams.orderBy.id} ${newSearchParams.orderBy.value}`,
      },
      {
        emptyString: true,
        emptyArray: true,
      }
    );
    handleGetSearch(_newParams);
    setSearchParams(newSearchParams);
  };

  const handleRefresh = () => {
    const params = stripEmpty(
      {
        ...searchParams,
        orderBy: `${searchParams.orderBy.id} ${searchParams.orderBy.value}`,
      },
      {
        emptyString: true,
        emptyArray: true,
      }
    );
    handleGetSearch(params);
  };

  const handleSearch = async (newParams: any) => {
    const newSearchParams = {
      ...searchParams,
      ...newParams,
      page: 1,
    };
    const _newParams = stripEmpty(
      {
        ...newSearchParams,
        orderBy: `${searchParams.orderBy.id} ${searchParams.orderBy.value}`,
      },
      {
        emptyString: true,
        emptyArray: true,
      }
    );
    history.push(
      `/clinic_deactivate_reason?${querystring.stringify(_newParams)}`
    );
  };

  const handleChangePagination = (pg: any) => {
    const newSearchParams = {
      ...searchParams,
      page: pg.page,
      limit: pg.limit,
    };
    const _newParams = stripEmpty(
      {
        ...newSearchParams,
      },
      {
        emptyString: true,
        emptyArray: true,
      }
    );
    if (Object.keys(_newParams)?.length > 3) {
      setSearchParams(newSearchParams);
      handleSearchSubmit(_newParams);
    }
  };

  const searchFilterValues = useMemo(() => {
    return stripEmpty(
      {
        ...searchParams,
        orderBy: `${searchParams.orderBy.id} ${searchParams.orderBy.value}`,
      },
      {
        emptyString: true,
        emptyArray: true,
      }
    );
  }, [searchParams]);

  return (
    <div className="main-contents">
      <PageNav
        pages={['CLIENT', `해지현황 (${data?.pagination?.total || 0})`]}
      />
      <SearchForm
        onSearch={handleSearch}
        searchParams={searchParams}
        originalParams={defaultParamsRef.current}
      />
      <Summary>
        <DataPagination
          options={[
            {
              label: '50개씩',
              value: 50,
            },
            {
              label: '100개씩',
              value: 100,
            },
          ]}
          value={{
            limit: searchParams.limit,
            page: searchParams.page,
          }}
          pages={data?.pagination?.pages || 1}
          total={data?.pagination?.total || 1}
          onChange={handleChangePagination}
          disabled={Object.keys(searchFilterValues).length < 4}
        />
      </Summary>
      <ClinicsDeactivateDataTable
        data={data}
        orderBy={searchParams.orderBy}
        pagination={{
          limit: searchParams.limit,
          page: searchParams.page,
        }}
        onRefresh={handleRefresh}
        onChangePagination={handleChangePagination}
      />
    </div>
  );
};
export default observer(ClinicDeactivate);
