export const roleOptions = [
  {
    id: 'super',
    label: '최고관리자',
  },
  {
    id: 'admin',
    label: '관리자',
  },
  {
    id: 'manager',
    label: '취급자',
  },
];

export const statusOptions = [
  {
    label: '사용',
    id: 'active',
  },
  {
    label: '정지',
    id: 'inactive',
  },
  {
    label: '해지',
    id: 'deleted',
  },
];

export const methodOptions = [
  {
    id: 'ALL',
    label: '전체',
  },
  {
    id: 'GET',
    label: '조회',
  },
  {
    id: 'PUT',
    label: '수정',
  },
  {
    id: 'POST',
    label: '등록',
  },
  {
    id: 'DELETE',
    label: '삭제',
  },
];

export const getRoleName = (role: string) => {
  switch (role) {
    case 'super':
      return '최고관리자';
    case 'admin':
      return '관리자';
    case 'manager':
      return '취급자';
    default:
      return '';
  }
};

export const getStatusName = (status: string) => {
  switch (status) {
    case 'active':
      return '사용';
    case 'inactive':
      return '정지';
    case 'deleted':
      return '해지';
    default:
      return '';
  }
};
