import { styled as muiStyled } from '@mui/material/styles';
import styled from 'styled-components';
import {
  Autocomplete as MuiAutocomplete,
  TextField as MuiTextField,
  autocompleteClasses,
  Popper as MuiPopper,
  textFieldClasses,
} from '@mui/material';
import { ComponentType } from 'react';

const fontSize = '12px';

export const TextField = styled(MuiTextField)`
  &.${textFieldClasses.root} {
    width: 100%;
    height: 100%;
    padding-left: 0;

    & input {
      height: 29px;
      box-sizing: border-box;
      padding: 4px 8px;
      font-size: 12px;
      text-align: left;
      line-height: 1rem;
      margin-right: 20px;
    }

    & .MuiOutlinedInput-root {
      position: absolute;
      padding: 0 !important;
      height: ${({ $isopen }: any) => (!$isopen ? '29px' : '')};
      overflow: ${({ $isopen }: any) => (!$isopen ? 'clip' : '')};
      background: ${({ $isopen }: any) => ($isopen ? 'white' : '')};
    }

    & .MuiInputBase-root.Mui-focused {
      z-index: 2;
    }
  }
` as ComponentType<any>;

export const Li = styled('li')`
  height: 29px;
  font-size: ${fontSize};
  padding: 0 10px !important;
`;

export const IconWrapper = styled('div')`
  display: flex;
  align-items: center;
  svg {
    width: 16px;
    height: 16px;
  }
`;

export const Autocomplete = styled(MuiAutocomplete)`
  &.${autocompleteClasses.root} {
    & .MuiOutlinedInput-notchedOutline {
      border: ${({ variant, $active }: any) =>
        $active
          ? 'solid 1px #2C62F6 !important'
          : variant === 'search' && 'none !important'};
      border-radius: ${({ variant }: any) => variant === 'search' && 0};
    }
    & .MuiOutlinedInput-root {
      padding: 0 !important;
    }
    & .MuiInputBase-root.Mui-disabled {
      background-color: #f1f1f1 !important;
      color: #273142 !important;
    }
    & .MuiInputBase-root.Mui-disabled input {
      background-color: #f1f1f1 !important;
      color: #bbbbbb !important;
    }
  }
  &.${autocompleteClasses.input} {
    font-size: ${fontSize};
  }
  .${autocompleteClasses.endAdornment} {
    right: 4px !important;
  }
  .${autocompleteClasses.clearIndicator} {
    margin-right: 8px;
  }
` as ComponentType<any>;

export const SelectLabel = muiStyled('div')(
  ({ theme }) => `
    width: 100%;
    cursor: default;
    font-size: 11px;
    color: ${theme ? theme.palette.bluegrey[600] : ''};
`
);

export const Total = styled('span')`
  font-size: ${fontSize};
  color: #a1b1ca;
`;

export const AutoCompleteSelect = styled(Autocomplete)`
  &.${autocompleteClasses.root} {
    & .MuiOutlinedInput-notchedOutline {
      border: ${({ variant, $active }: any) =>
        $active
          ? 'solid 1px #2C62F6 !important'
          : variant === 'search'
          ? 'none !important'
          : ''};
      border-radius: ${({ variant }: any) => variant === 'search' && 0};
    }

    & .MuiOutlinedInput-root {
      padding: 0 !important;
    }

    & .MuiInputBase-root.Mui-disabled {
      background-color: #f1f1f1 !important;
      color: #273142 !important;
    }

    & .MuiInputBase-root.Mui-disabled input {
      background-color: #f1f1f1 !important;
      color: #bbbbbb !important;
    }
  }

  .${autocompleteClasses.input} {
    font-size: ${fontSize};
  }

  .${autocompleteClasses.noOptions} {
    font-size: ${fontSize};
    padding: 10px !important;
    min-height: 100px;
  }

  .${autocompleteClasses.endAdornment} {
    right: 4px !important;
    top: 0 !important;

    .MuiButtonBase-root {
      position: relative;
      top: 3px;
    }
  }

  .${autocompleteClasses.clearIndicator} {
    margin-right: 8px;
  }

  .${autocompleteClasses.paper} {
    box-shadow: none !important;
    border: 1px solid #dee2ec;
    border-radius: initial !important;
  }

  .${autocompleteClasses.option} {
    background: white;
    &.Mui-focused {
      background-color: #f1f1f1;
    }
  }
` as ComponentType<any>;

export const Popper = styled(MuiPopper)`
  .${autocompleteClasses.paper} {
    box-shadow: none !important;
    border: 1px solid #dee2ec;
    border-radius: initial !important;
  }
  .${autocompleteClasses.option} {
    background: none !important;
    &.Mui-focused {
      background-color: #f1f1f1 !important;
    }
  }
  .${autocompleteClasses.noOptions} {
    font-size: ${fontSize};
    padding: 10px !important;
    min-height: 100px;
  }
`;
