import { useMemo } from 'react';
import { Box, DataTable } from './DataTable.styled';

type ClinicsDataTableProps = {
  loading: boolean;
  data: any;
  pagination: {
    page: number;
    limit: number;
  };
  onChangePagination: (params: any) => void;
};

export const HospitalDataTable = (props: ClinicsDataTableProps) => {
  const { data = [], loading } = props;
  const items = useMemo(() => data, [data]);
  const schema = useMemo(
    () => ({
      columns: [
        {
          id: 'crtrYm',
          name: '기준연월일',
          value: (item: any) => {
            const date =
              `${item?.crtrYm}`.slice(0, 4) + '-' + `${item?.crtrYm}`.slice(4);
            return <Box>{date}</Box>;
          },
        },
        {
          id: 'estbCnclTp',
          name: '개폐업구분',
          value: (item: any) => <Box>{item?.estbCnclTp || '-'}</Box>,
        },
        {
          id: 'yadmNm',
          name: '요양기관명',
          value: (item: any) => <Box>{item?.yadmNm || '-'}</Box>,
        },
        {
          id: 'clCdNm',
          name: '진료과목',
          value: (item: any) => <Box>{item?.clCdNm || '-'}</Box>,
        },
        {
          id: 'addr',
          name: '주소',
          value: (item: any) => <Box>{item?.addr || '-'}</Box>,
        },
        {
          id: 'telno',
          name: '연락처',
          value: (item: any) => <Box>{item?.telno || '-'}</Box>,
        },
      ],
    }),
    []
  );

  return (
    <DataTable
      loading={loading}
      data={items}
      schema={schema}
      className="hospital-list-table"
      placeholder={'조회된 항목이 없습니다.'}
      resizable
    />
  );
};
