import { observer } from 'mobx-react';
import { useModal } from '~/hooks/useModal';
import Modal from '~/components/app/Modal2';

export const ModalView = observer(() => {
  const modal = useModal();

  return (
    <>
      {modal.list.map((v, idx) => (
        <Modal key={idx} modal={v} />
      ))}
    </>
  );
});
