import { useState, useMemo, useRef, useEffect } from 'react';
import { observer } from 'mobx-react';
import { stripEmpty } from '~/utils/objUtil';
import { useApi } from '~/components/providers/ApiProvider';
import { DataPagination, Summary } from './HospPharmacy.styled';
import { SearchForm } from './SearchForm';
import { HospitalDataTable } from './DataTable';
import { useHistory, useLocation } from 'react-router-dom';
import querystring from 'query-string';
import { useSnackbarContext } from '~/components/providers/SnackbarProvider';
import PageNav from '~/components/commons/PageNav';

const defaultParams = {
  yadmTp: '1', // 0: 전체 1: 병원 2:약국 type: string
  opCloTp: '1', // 0: 전체 1:개업 2:폐업 type: string
  year: new Date().getFullYear().toString().padStart(2, '0'),
  month: (new Date().getMonth() + 1).toString().padStart(2, '0'),
  pageNo: 1,
  numOfRows: 10,
};

const HospPharmacy = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const snackbar = useSnackbarContext();
  const defaultParamsRef = useRef(defaultParams);
  const querySearchParams: any = useMemo(() => {
    const _searchParams = querystring.parse(location.search);
    const { numOfRows, pageNo, ...rest } = _searchParams;
    return Object.keys(_searchParams).length > 0
      ? {
          ...rest,
          pageNo:
            pageNo && !isNaN(Number(pageNo))
              ? Number(pageNo)
              : defaultParamsRef.current.pageNo,
          numOfRows:
            numOfRows && !isNaN(Number(numOfRows))
              ? Number(numOfRows)
              : defaultParamsRef.current.numOfRows,
        }
      : null;
  }, [location.search]);
  const [pagination, setPagination] = useState({
    pages: 1,
    total: 1,
  });
  const [searchParams, setSearchParams] = useState(
    querySearchParams !== null
      ? {
          ...defaultParamsRef.current,
          ...querySearchParams,
        }
      : defaultParamsRef.current
  );
  const { hospPharmacyApi } = useApi();
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    if (
      querySearchParams !== null &&
      Object.keys(querySearchParams)?.length > 3
    ) {
      const mixed = { ...defaultParamsRef.current, ...querySearchParams };
      handleSearchSubmit(mixed);
    } else if (
      querySearchParams === null &&
      JSON.stringify(searchParams) !== JSON.stringify(defaultParamsRef.current)
    ) {
      resetAll();
    }
  }, [querySearchParams]);

  const resetAll = () => {
    setSearchParams(defaultParamsRef.current);
    setData([]);
  };

  const handleSearch = async (newParams: any) => {
    const _newParams = stripEmpty(
      {
        ...searchParams,
        ...newParams,
        pageNo: 1,
      },
      {
        emptyString: true,
        emptyArray: true,
      }
    );
    history.push(`/hosp_pharmacy_op_list?${querystring.stringify(_newParams)}`);
  };

  const handleSearchSubmit = (newSearchParams: any) => {
    handleGetSearch(querySearchParams);
    setSearchParams(newSearchParams);
  };

  const handleGetSearch = async (params: any) => {
    const { yadmTp, opCloTp, pageNo, numOfRows } = params;
    setLoading(true);
    try {
      const response: any = await hospPharmacyApi.list({
        yadmTp,
        opCloTp,
        pageNo,
        numOfRows,
        crtrYm: `${querySearchParams.year}${querySearchParams.month}`,
      });
      const { body } = response?.response || {};
      const newData = body.items === '' ? [] : body.items?.item;
      const pages = Math.ceil(body.totalCount / numOfRows) || 1;
      const total = body.totalCount || 1;
      setPagination({
        pages,
        total,
      });
      if (!(newData instanceof Object)) {
        return setData([]);
      }
      if (Array.isArray(newData)) {
        setData(newData);
      } else {
        setData([newData]);
      }
    } catch (error) {
      snackbar.alert('데이터 에러가 발생했습니다. 잠시 후 다시 시도해주세요.');
    } finally {
      setLoading(false);
    }
  };

  const handleChangePagination = (pg: any) => {
    const _newParams = stripEmpty(
      {
        ...searchParams,
        pageNo: pg.page,
        numOfRows: pg.limit,
      },
      {
        emptyString: true,
        emptyArray: true,
      }
    );
    if (Object.keys(_newParams)?.length > 3) {
      history.push(
        `/hosp_pharmacy_op_list?${querystring.stringify(_newParams)}`
      );
    }
  };

  return (
    <div className="main-contents">
      <PageNav pages={['GENERAL', `요양기관 개폐업 정보`]} />
      <SearchForm
        onSearch={handleSearch}
        searchParams={searchParams}
        originalParams={defaultParamsRef.current}
      />
      <Summary>
        <DataPagination
          value={{
            limit: searchParams.numOfRows,
            page: searchParams.pageNo,
          }}
          options={[
            {
              label: '10개씩',
              value: 10,
            },
            {
              label: '15개씩',
              value: 15,
            },
            {
              label: '20개씩',
              value: 20,
            },
            {
              label: '50개씩',
              value: 50,
            },
          ]}
          pages={pagination?.pages || 1}
          total={pagination?.total || 1}
          onChange={handleChangePagination}
        />
      </Summary>
      <HospitalDataTable
        loading={loading}
        data={data}
        pagination={{
          limit: searchParams.numOfRows,
          page: searchParams.pageNo,
        }}
        onChangePagination={handleChangePagination}
      />
    </div>
  );
};

export default observer(HospPharmacy);
