export const downloadFile = (url: string, fileName: string) => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
};

export const currency = (value: number | string, decimalCount?: number) => {
  const digitsRegex = /(\d{3})(?=\d)/g;
  const convertNumberValue: number = parseFloat(String(value));
  if (
    !isFinite(convertNumberValue) ||
    (!convertNumberValue && convertNumberValue !== 0)
  )
    return '';
  decimalCount = decimalCount || 0;
  const valueStr = Math.abs(convertNumberValue).toFixed(decimalCount);
  const integer = decimalCount
    ? valueStr.slice(0, -1 - decimalCount)
    : valueStr;
  const i = integer.length % 3;
  const head =
    i > 0 ? integer.slice(0, i) + (integer.length > 3 ? ',' : '') : '';
  const decimals = decimalCount ? valueStr.slice(-1 - decimalCount) : '';
  const sign = convertNumberValue < 0 ? '-' : '';
  return sign + head + integer.slice(i).replace(digitsRegex, '$1,') + decimals;
};

export const removeCurrency = (value: string) => {
  // 모든 , 를 '' 로 치환
  return Number(value.replace(/,/g, ''));
};
