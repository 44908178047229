import { useEffect, useState, useCallback } from 'react';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { useApp } from '~/hooks/useApp';
import { useApi } from '~/components/providers/ApiProvider';
import { CertsData } from '~/api/resources/certsApi';
import PageNav from '~/components/commons/PageNav';
import ModalCertifications from '../../modals/certification/ModalCertifications';
import Checkbox from '~/components/app/Checkbox';
import CertificationTable from './CertificationTable';
import Pagination from '~/components/app/Pagination';
import { Tooltip } from '~/components/commons/Tooltip';

const Certifications = () => {
  const { certsApi, clinicsApi } = useApi();
  const app = useApp();
  const defaultParams = app
    .$qb()
    .limit(20)
    .orderBy('id desc')
    .customParam('certificationStatus', 'need_renew');
  const [certifications, setCertifications] = useState<CertsData[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [params, setParams] = useState<any>(defaultParams);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<any>('');
  const [hideHidden, setHideHidden] = useState<boolean>(false);
  const limit = params ? params.queryParams.limit : 20;

  const callApi = useCallback(async () => {
    const response = await certsApi.list(params.build());
    if (response) {
      setTotal(response.total);
      setCertifications(response.data);
    }
  }, [params]);

  useEffect(() => {
    callApi();
  }, [app, callApi]);

  const onParams = (p: any) => {
    setParams(p);
    callApi();
  };

  const openModalEditCertification = async (cert?: any) => {
    const response = await clinicsApi.getClinicsNames();
    if (response) {
      runInAction(() => {
        app.$modal.custom({
          component: ModalCertifications,
          options: {
            clinics: response.data ?? [],
            users: [],
            cert,
            callApi,
          },
        });
      });
    }
  };

  const onChangeSearch = (e: any) => {
    const { name, value } = e.target;
    const searchParam = { ...searchValue, [name]: value };
    setSearchValue(searchParam);
  };

  const onClickSearch = () => {
    params.customParam('page', 1);
    setCurrentIndex(0);
    const editParams = {
      ...params,
      queryParams: {
        ...params.queryParams,
        ...searchValue,
      },
    };
    setParams(editParams);
  };

  const onClickCheckBox = () => {
    const param = hideHidden
      ? params.customParam('status', 'need_renew')
      : params.customParam('status', 'all');
    params.customParam('page', 1);
    setCurrentIndex(0);
    onParams(param);
    setHideHidden(!hideHidden);
  };

  const onPage = (idx: number) => {
    if (!params) return;

    if (idx >= 0) {
      setCurrentIndex(idx);
    }
    params.customParam('page', idx + 1);
    setParams(params);
    callApi();
  };

  return (
    <div className="main-contents">
      <PageNav pages={['CLIENT', `전자서명 관리 (${total})`]} />
      <div className="content-title-sub">
        ※ 유의사항
        <br />
        1) 전자서명 등록 시, 사용자 DN의 cn=[이름]과 연동하는 계정의 이름이
        일치하는지 확인.
        <br />
        {`2) 전자서명 갱신 방법 : tradesign.net 홈페이지 -> 인증서 갱신발급 메뉴
          -> 만료일 갱신진행`}
      </div>
      <div className="search-bar">
        <div className="search-item m-r-8">
          <input
            placeholder="연동계정"
            name="email"
            onChange={onChangeSearch}
            autoComplete="off"
            onKeyPress={(e) => app.onKeyPressEnterFunction(e, onClickSearch)}
          />
        </div>
        <div className="search-item m-r-8">
          <input
            placeholder="이름"
            name="name"
            onChange={onChangeSearch}
            onKeyPress={(e) => app.onKeyPressEnterFunction(e, onClickSearch)}
          />
        </div>
        <div className="search-item">
          <button className="btn btn-black" onClick={onClickSearch}>
            {app.$translate('FIND')}
          </button>
        </div>
      </div>
      <div className="content-title">
        <Tooltip title={app.$me.role === 'manager' ? '권한이 없습니다.' : ''}>
          <div className="search-item">
            <button
              style={{ cursor: 'pointer' }}
              onClick={() => openModalEditCertification()}
              className="btn-sm btn-black"
              disabled={app.$me.role === 'manager'}
            >
              전자서명 등록
            </button>
          </div>
        </Tooltip>
        <label className="search-item m-r-8" style={{ cursor: 'pointer' }}>
          <Checkbox
            checked={hideHidden}
            toggleHandler={onClickCheckBox}
            label={app.$translate('ALL_VIEW')}
          />
        </label>
      </div>
      <table>
        <thead>
          <tr>
            <th>인증서 상태</th>
            <th>인증서 만료일</th>
            <th>전자서명 등록일</th>
            <th>사용자 DN</th>
            <th>상호(병원명)</th>
            <th>연동 계정</th>
            <th>계정 상태</th>
            <th>이름</th>
            <th>cs 메모</th>
          </tr>
        </thead>
        <tbody>
          <CertificationTable
            item={certifications}
            callApi={callApi}
            openModalEditCertification={openModalEditCertification}
          />
        </tbody>
      </table>
      <div className="flex-row flex-between wrap-pagination">
        <Pagination
          total={total}
          limit={limit}
          currentIndex={currentIndex}
          onPage={onPage}
        />
      </div>
    </div>
  );
};

export default observer(Certifications);
