import styled from 'styled-components';
import {
  TextField,
  Popper as MuiPopper,
  textFieldClasses,
  Autocomplete,
  autocompleteClasses,
} from '@mui/material';
import { ComponentType } from 'react';

const fontSize = '12px';

export const Input = styled(TextField)`
  &.${textFieldClasses.root} {
    width: 100%;
    height: 100%;
    padding-left: 0;

    & input {
      height: 29px;
      box-sizing: border-box;
      padding: 4px 8px;
      font-size: 12px;
      text-align: left;
      line-height: 1rem;
      cursor: pointer;
    }

    & .MuiOutlinedInput-root {
      height: 100%;
      padding: 0 !important;
    }
  }
`;

export const Li = styled.li`
  height: 29px;
  font-size: ${fontSize};
  padding: 0 10px !important;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  svg {
    width: 16px;
    height: 16px;
  }
`;

export const Popper = styled(MuiPopper)`
  .${autocompleteClasses.listbox} {
    padding: 4px 0 !important;
    max-height: 406px !important;
  }

  .${autocompleteClasses.paper} {
    box-shadow: none !important;
    border: 1px solid #dee2ec;
    border-radius: initial !important;
  }

  .${autocompleteClasses.option} {
    background: none !important;
    &.Mui-focused {
      background-color: #f1f1f1 !important;
    }
  }

  .${autocompleteClasses.noOptions} {
    font-size: ${fontSize};
    padding: 4px 10px !important;
    min-height: 100px;
  }
`;

export const AutoCompleteSelect = styled(Autocomplete)`
  &.${autocompleteClasses.root} {
    width: 100%;

    & .MuiOutlinedInput-notchedOutline {
      border: ${({ variant, $active }: any) =>
        variant === 'none'
          ? 'none !important'
          : $active
          ? 'solid 1px #2C62F6 !important'
          : variant === 'search' && 'none !important'};
      border-radius: ${({ variant }: any) => variant === 'search' && 0};
    }
  }

  .${autocompleteClasses.input} {
    font-size: ${fontSize};
  }

  .${autocompleteClasses.endAdornment} {
    right: 4px !important;
    position: relative !important;
    top: 0 !important;
    display: flex;
    align-items: center;
  }

  .Mui-disabled {
    input {
      background: none;
    }
    button {
      border: 0 none;
    }
  }

  .${autocompleteClasses.clearIndicator} {
    margin-right: -10px !important;
  }
` as ComponentType<any>;
